/**********************************************************************************************************************
 * Stores and manages Favorite Bets data.
 *********************************************************************************************************************/
import { Bets, FavoriteBets, FavoriteBetsIndex, FavoriteBetsLookup, IFavoriteBetData } from 'server/types';
import {
	makeBetsUniqueId,
	makeFavoriteDefaultName,
	resolveFavoriteBets as resolveBets,
	resolveFavoriteBetData,
} from 'server/utility';
import { utcNowSecs } from 'helpers/dateTime';
import { makeAutoObservable } from 'helpers/mobx';
import LocalStorage from '../FavoriteBetsLocalStorage';

// ---- Store ---------------------------------------------------------------------------------------------------------

class FavoriteBetsStore {
	// RPC client used to talk to the server
	private rpcClient: RpcClient = new RpcClient();

	// Favorite bets data that will come from the server via GRPC
	private _bets: FavoriteBets = [];

	// We generate these based on new favorite bets being set on the `bets` property
	private _betsIndex: FavoriteBetsIndex = new Map();
	private _betsLookup: FavoriteBetsLookup = new Map();

	// Whether the stream is currently active or not
	private isStreamActive: boolean = false;

	// When the store last receieved stream data
	private lastStreamUpdateTs: number = 0;

	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		makeAutoObservable(this);

		if (!this.isStreamActive) {
			this.startStream();
		}
	}

	// Get/set current list of favorite bets
	public get bets(): FavoriteBets {
		return this._bets;
	}
	private set bets(betsData: FavoriteBets) {
		this._bets = this.resolveBets(betsData);
	}

	// Map of betId -> favoriteBetData for the current list of favorite bets
	public get betsLookup(): FavoriteBetsLookup {
		return this._betsLookup;
	}

	// How many favorite bets we currently have
	public get betCount(): number {
		return this.bets.length;
	}

	// Whether we currently have any favorite bets or not
	public get hasBets(): boolean {
		return this.betCount > 0;
	}

	// Gets the next available favorite bet default name
	public getNextFavoriteDefaultName() {
		return makeFavoriteDefaultName(this.betCount + 1);
	}

	// Gets a favorite bet by ID from the current favorite bets
	public betById(betId: string): Nullable<IFavoriteBetData> {
		const i = this._betsIndex.get(betId) || -1;

		return i >= 0 ? this.bets[i] : null;
	}

	// Returns TRUE if the specified favorite bet ID exists in the current bet list
	public betExists(betId: string) {
		return betId !== '' && this._betsIndex.has(betId);
	}

	// Returns TRUE if the specified bet data currently exists as a favorite bet
	public doesBetDataExistAsFavorite(bets: Bets): boolean {
		const betId = makeBetsUniqueId(bets) || '';

		return this.betExists(betId);
	}

	private resolveBets(bets: FavoriteBets): FavoriteBets {
		const { betsList, betsIndex, betsLookup } = resolveBets(bets);

		this._betsIndex = betsIndex;
		this._betsLookup = betsLookup;

		return betsList;
	}

	public async addNewFavoriteForBets(bets: Bets, name?: string): Promise<boolean> {
		return this.addFavorite({ bets, name });
	}

	public async addFavorite(favoriteData: IFavoriteBetData): Promise<boolean> {
		const newFavBet = this.resolveFavoriteBetData(favoriteData);
		if (this.betExists(newFavBet.id || '')) {
			return false;
		}

		// New bets go to the front of the array (temporarily)
		this.bets = [newFavBet, ...this.bets];

		// Send change to server via RPC client
		return this.rpcClient.addFavorite(newFavBet);
	}

	public async updateFavorite(betId: string, favoriteData: IFavoriteBetData): Promise<boolean> {
		const betPos = this._betsIndex.get(betId) ?? -1;
		if (betPos < 0) {
			return false;
		}

		const nowTs = utcNowSecs();
		const newFavBet = this.resolveFavoriteBetData(favoriteData);
		newFavBet.modifiedTs = nowTs;

		const currentFav = this.bets[betPos];
		const newFavorite = { ...currentFav, ...newFavBet };

		const newBets = this.bets.slice();
		newBets.splice(betPos, 1, newFavorite);
		this.bets = newBets;

		// Send change to server via RPC client
		return this.rpcClient.updateFavorite(betId, newFavBet);
	}

	public async removeFavorite(favoriteData: IFavoriteBetData): Promise<boolean> {
		const favBet = this.resolveFavoriteBetData(favoriteData);

		return this.removeFavoriteById(favBet.id || '');
	}

	public async removeFavoriteById(betId: string): Promise<boolean> {
		if (!this.betExists(betId)) {
			return false;
		}

		this.bets = this.bets.slice().filter((bet) => bet.id !== betId);

		// Send change to server via RPC client
		return this.rpcClient.deleteFavorite(betId);
	}

	public clear(): void {
		// TODO: Clear everything
	}

	private resolveFavoriteBetData(favoriteData: IFavoriteBetData): IFavoriteBetData {
		const favBet = resolveFavoriteBetData(favoriteData);
		favBet.name = favBet.name || this.getNextFavoriteDefaultName();

		return favBet;
	}

	private async startStream(): Promise<boolean> {
		// TODO: We can wire this up when the server is ready
		this.isStreamActive = true;

		// TODO: Populate the favorite bets data like the stream would
		if (this.lastStreamUpdateTs === 0) {
			this.bets = await this.rpcClient.getFavorites();
			this.lastStreamUpdateTs = Date.now();
		}

		return this.isStreamActive;
	}

	private async stopStream(): Promise<boolean> {
		// TODO: We can wire this up when the server is ready
		this.isStreamActive = false;

		return this.isStreamActive;
	}
}

// ---- RpcClient -----------------------------------------------------------------------------------------------------

class RpcClient {
	// Local storage, used temporarily until we have an actual server
	storage: LocalStorage = new LocalStorage();

	public async getFavorites(): Promise<FavoriteBets> {
		// TODO: Ask server for favorites data via GRPC unary call: GetFavoriteBets
		// TODO: Remove use of local storage when making the actual RPC call
		return this.storage.items;
	}

	public async addFavorite(favData: IFavoriteBetData): Promise<boolean> {
		// TODO: Send new bet to server via GRPC unary call: AddFavoriteBet
		// TODO: Remove use of local storage when making the actual RPC call
		return this.storage.add(favData);
	}

	public async deleteFavorite(favoriteId: string): Promise<boolean> {
		// TODO: Send delete to server via GRPC unary call: RemoveFavoriteBet
		// TODO: Remove use of local storage when making the actual RPC call
		return this.storage.remove(favoriteId);
	}

	public async updateFavorite(favoriteId: string, favData: IFavoriteBetData): Promise<boolean> {
		// TODO: Send updated bet to server via GRPC unary call: UpdateFavoriteBet
		// TODO: Remove use of local storage when making the actual RPC call
		return this.storage.replace(favoriteId, favData);
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { FavoriteBetsStore as default };
export { FavoriteBetsStore };
