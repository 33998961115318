/**********************************************************************************************************************
 * Global authentication session (shared across all RPC calls).
 *********************************************************************************************************************/
import { AuthSession, AuthSessionProvider } from 'server/lib-rpc';

let _session: AuthSessionProvider;

// ---- Initialize ----------------------------------------------------------------------------------------------------

let _isInitialized = false;

const initialize = (): boolean => {
	if (_isInitialized) {
		return false;
	}

	_session = AuthSession;

	_isInitialized = true;

	return true;
};

// ---- Utility -------------------------------------------------------------------------------------------------------

const getSession = (): AuthSessionProvider => _session;
const setSession = (val: AuthSessionProvider) => (_session = val);
const hasActiveSession = (): boolean => _session.hasToken();

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initSession };
export { getSession, setSession, hasActiveSession };
