/**********************************************************************************************************************
 * Stores and manages data for the current table play.
 *********************************************************************************************************************/
import memoize from 'memoize-one';
import { RpcPlayStore } from 'server/lib-rpc';
import { RpcPlayStoreMobXKeys } from 'server/lib-rpc';
import { ActiveWagerData, DragonTigerPlayStateData, TablePlayData } from 'server/lib-rpc';
import { ITableService } from 'server/lib-rpc';
import { Bets } from 'server/types';
import { countPositiveBetsEntries, makeBetsUniqueId, totalBetsEntries } from 'server/utility';
import { DragonTigerHand } from '@sandsb2b/areax-client-library-rpc/dist/grpc/spoke_game_pb';
import { action, computed, makeObservable, observable } from 'helpers/mobx';
import { entries } from 'helpers/object';

type StorePrivateProps = '_bets' | '_getBetCountFn' | '_getBetTotalValFn' | '_betHistory';
type StoreMobXKeys = RpcPlayStoreMobXKeys | StorePrivateProps;

interface IResolutionSummaryDataResult {
	isResolved: boolean;
	winner: string;
	winTotal: number;
	wagerTotal: number;
	winLevel: number;
}

type BetHistoryItem = [string, number];
type BetHistory = BetHistoryItem[];

interface IGetWagerResultData {
	amount: number;
	pending: boolean;
	state?: Maybe<string>;
}

class PlayStore extends RpcPlayStore {
	protected _bets: Bets = {};
	protected _getBetCountFn;
	protected _getBetTotalValFn;
	protected _betHistory: BetHistory = [];

	/**
	 * CONSTRUCTOR.
	 *
	 * @param  service  RPC service to use when calling `populate`.
	 */
	constructor(service: ITableService, opts?: Maybe<{ data?: Maybe<TablePlayData> }>) {
		opts = opts || {};

		super(service);

		// Note: We cannot use `makeAutoObservable` because this class extends a parent class. So we are declaring the
		// annotations right here instead. We can probably make this better in the future by carefully applying observables
		// on the parent class or via some other technique.. but for now, just roll with it.
		makeObservable<PlayStore, StoreMobXKeys>(this, {
			// ---- Inherited from DataStore --------------------------------------------------------------------------------

			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			isPopulated: computed,
			lastUpdatedTs: computed,

			// Actions
			populate: action,
			setData: action,

			// ---- Inherited from RpcPlayStore -----------------------------------------------------------------------------

			playId: computed,
			id: computed,
			state: computed,
			activeWagers: computed,
			activeWagersTotal: computed,
			hasWagers: computed,
			isResolved: computed,
			roundStartTime: computed,
			roundTimerDuration: computed,
			shoePlayNum: computed,
			playStates: computed,
			resolutions: computed,
			resolutionsTotalAmount: computed,

			// Non-annotated
			lookupResolutions: false,

			// ---- Local ---------------------------------------------------------------------------------------------------

			_bets: observable,
			_betHistory: observable,
			betHistory: computed,
			hasBetHistory: computed,
			playStateData: computed,
			gameState: computed,
			resolutionSummaryData: computed,
			resolutionType: computed,
			playerCards: computed,
			bankerCards: computed,
			bets: computed,
			betEntries: computed,
			betCount: computed,
			betTotalVal: computed,
			betTotalAmount: computed,
			hasBets: computed,
			betsUniqueId: computed,

			// Actions
			clear: action,
			clearBets: action,
			addToBetHistory: action,
			clearBetHistory: action,
			revertWagersToServer: action,

			// Non-annotated
			_getBetCountFn: false,
			_getBetTotalValFn: false,
			getResolvedWager: false,
		});

		opts.data && this.setData(opts.data);

		// Bound memoized functions
		this._getBetCountFn = memoize(countPositiveBetsEntries);
		this._getBetTotalValFn = memoize(totalBetsEntries);
	}

	/**
	 * Baccarat: Play state data.
	 */
	public get playStateData(): Nullable<DragonTigerPlayStateData> {
		return this.playStates?.dragonTiger ?? null;
	}

	/**
	 * Baccarat: Play state value
	 */
	public get gameState(): string {
		return this.playStateData?.state || '';
	}

	/**
	 * Baccarat: Player cards
	 */
	public get playerCards(): Nullable<DragonTigerHand.AsObject> {
		return this.playStateData?.dragonhand ?? null;
	}

	/**
	 * Baccarat: Banker cards
	 */
	public get bankerCards(): Nullable<DragonTigerHand.AsObject> {
		return this.playStateData?.tigerhand ?? null;
	}

	// ---- Resolutions -------------------------------------------------------------------------------------------------

	/**
	 * Baccarat: Resolution summary data
	 */
	public get resolutionSummaryData(): IResolutionSummaryDataResult {
		const player = this.playerCards;
		const banker = this.bankerCards;
		let winner = '';

		if (player && banker) {
			if (player.score === banker.score) {
				winner = 'tie';
			} else if (player.score > banker.score) {
				winner = 'dragon';
			} else {
				winner = 'cat';
			}
		}

		const winTotal = this.resolutionsTotalAmount;
		const wagerTotal = this.betTotalVal;

		return {
			isResolved: this.isResolved,
			winner: winner,
			winTotal: winTotal,
			wagerTotal: wagerTotal,
			winLevel: wagerTotal > 0 ? Math.ceil(winTotal / wagerTotal) : 0,
		};
	}

	public get resolutionType(): string {
		const payouts = this.lookupResolutions({ filterByType: 'payout' });
		const isWin = payouts.size > 0;
		const isBigWin = payouts.nameLookup.has('pla') || payouts.nameLookup.has('ban');

		return isBigWin ? 'bigWin' : isWin ? 'win' : 'continue';
	}

	// ---- Bets/Wagers -------------------------------------------------------------------------------------------------

	/**
	 * Bets
	 */
	public get bets(): Bets {
		return this._bets;
	}
	public set bets(value: Bets) {
		this._bets = value;
	}

	/**
	 * Bet entries
	 */
	public get betEntries(): Entries<Bets> {
		return entries(this.bets);
	}

	/**
	 * Bet count
	 */
	public get betCount(): number {
		return this._getBetCountFn(this.betEntries);
	}

	/**
	 * Bet total (value)
	 */
	public get betTotalVal(): number {
		return this._getBetTotalValFn(this.betEntries);
	}

	/**
	 * Bet total (amount)
	 */
	public get betTotalAmount(): number {
		return this.betTotalVal / 100;
	}

	/**
	 * TRUE if we currently have any non-zero bets.
	 */
	public get hasBets(): boolean {
		return this.betTotalVal > 0;
	}

	/**
	 * Hashed unique ID for the current bets. Used for Favorite Bets.
	 */
	public get betsUniqueId(): string {
		return makeBetsUniqueId(this.bets) || '';
	}

	/**
	 * Returns the resolved bet information for the specified bet name.
	 */
	public getResolvedWager = (betName: string): IGetWagerResultData => {
		if (betName === '') {
			return { amount: 0, pending: false, state: null };
		}

		const currentAmt = this.bets[betName] ?? 0;
		const wager: Nullable<ActiveWagerData> = this.activeWagers.find((w) => w.wagerName === betName) ?? null;

		let state: Maybe<string> = null;
		let amount = currentAmt || 0;
		let pending = false;

		if (wager) {
			state = wager.wagerState ?? null;
			amount = currentAmt !== undefined ? currentAmt : wager.amount;
			pending = currentAmt !== undefined && wager.amount !== currentAmt;
		}

		return {
			amount,
			pending,
			state,
		};
	};

	/**
	 * Action. Revert bets to the the most recent server values.
	 */
	public revertWagersToServer() {
		const bets: Bets = {};
		this.activeWagers.forEach((w) => (bets[w.wagerName] = w.amount));
		this.bets = bets;
	}

	/**
	 * Action. Clear the current bets.
	 */
	public clearBets(): void {
		const newBets = { ...this.bets };

		Object.keys(newBets).forEach((betName) => {
			newBets[betName] = 0;
		});

		this.activeWagers.forEach(({ wagerName }) => {
			newBets[wagerName] = 0;
		});

		this.bets = newBets;
	}

	// ---- Bet History -------------------------------------------------------------------------------------------------

	/**
	 * Bet history
	 */
	public get betHistory(): BetHistory {
		return this._betHistory;
	}
	public get hasBetHistory(): boolean {
		return this._betHistory.length > 0;
	}

	/**
	 * Action. Adds the specified item to bet history.
	 */
	public addToBetHistory(betName: string, amount: number): void {
		this._betHistory.push([betName, amount]);
	}

	/**
	 * Action. Clear the bet history.
	 */
	public clearBetHistory(): void {
		this._betHistory = [];
	}

	// ---- Other -------------------------------------------------------------------------------------------------------

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
		this.clearBets();
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { PlayStore as default };
export { PlayStore };
