/**********************************************************************************************************************
 * Stores and manages data regarding the current table data.
 *
 * Note: This will be used as the base parent class for the same store inside of the game clients.
 *********************************************************************************************************************/
import { CurrencyCode, DEFAULT_CURRENCY_CODE } from 'helpers/currency';
import {
	AvailableWagerData,
	ChoiceData,
	SeatData,
	TableData,
	TableGameData,
	TablePlayData,
} from '../client/rpc/types/spoke';
import { ITableService } from '../client/service/types';
import { Bets } from '../constants';
import { DataStore } from './DataStore';

class TableStore extends DataStore<ITableService, TableData> {
	/**
	 * @returns The unique table ID.
	 */
	public get tableId(): string {
		return this.tableData?.id ?? '';
	}

	/**
	 * DEPRECATED. Alias for `tableId`.
	 */
	public get tableKey(): string {
		console.warn('[DEPRECATED] Use of `tableKey` is deprecated. Use `tableId` instead.');
		return this.tableId;
	}

	public get rulesId(): string {
		return this.tableData?.rulesId ?? '';
	}

	public get playData(): Nullable<TablePlayData> {
		return this.tableData?.play ?? null;
	}

	public get state(): string {
		return this.tableData?.tableState ?? '';
	}

	public get game(): string {
		return this.tableData?.game ?? '';
	}

	public get availableChoicesList(): ChoiceData[] {
		return this.tableData?.choicesList ?? [];
	}
	public get hasAvailableChoices(): boolean {
		return this.availableChoicesList.length > 0;
	}

	public get availableWagers(): AvailableWagerData[] {
		return this.tableData?.availableWagersList ?? [];
	}

	public get availableSideBets(): AvailableWagerData[] {
		const availableWagers = this.availableWagers.slice();

		return availableWagers.filter((w: AvailableWagerData) => w.wagerName !== Bets.WAGER);
	}

	public get canPlaceBets(): boolean {
		return this.availableWagers.length !== 0;
	}

	public get seatCount(): number {
		return this.tableData?.seatCount ?? 0;
	}

	public get seatsList(): SeatData[] {
		return this.tableData?.seatsList ?? [];
	}

	public get placeBetsTimeAllowed(): number {
		return this.tableData?.autoRunDelaySec ?? 0;
	}

	public get shoeNumber(): number {
		return this.tableData?.shoeNum ?? 0;
	}

	public get serverTime(): number {
		return this.data?.serverTime ?? 0;
	}

	public get currency(): string {
		const currencyCode = (this.tableData?.currency || '').toUpperCase();

		if (['', CurrencyCode.VIRTUAL_CHIP].includes(currencyCode)) {
			return DEFAULT_CURRENCY_CODE;
		}

		return currencyCode;
	}

	public get actionTimeoutSec(): number {
		return this.tableData?.actionTimeoutSec ?? 0;
	}

	public get autoRunDelaySec(): number {
		return this.tableData?.autoRunDelaySec ?? 0;
	}

	public get insuranceChoice(): Nullable<ChoiceData> {
		return this.availableChoicesList.find((choice: ChoiceData) => choice.action === Bets.INSURANCE) ?? null;
	}

	public get isOfferingInsurance(): boolean {
		return this.insuranceChoice != null;
	}

	protected get tableData(): Nullable<TableGameData> {
		return this.data?.table ?? null;
	}

	protected set tableData(val: Nullable<TableGameData>) {
		this.data && (this.data.table = val ?? undefined);
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}

	// ---- Populate ----------------------------------------------------------------------------------------------------

	/**
	 * Action. Populates the store (via unary RPC service call) using the specified table ID.
	 */
	public async populate(tableId: string): Promise<boolean> {
		if (tableId === '') {
			this.debugError('A valid table ID must be specified', 'populate');
		}

		return await super.populate(tableId);
	}

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(tableId: string): Promise<Nullable<TableData>> {
		if (!this.service) {
			this.debugError('No service was specified', 'fetchPopulateData');
			return null;
		}

		tableId = tableId ?? '';
		if (tableId === '') {
			this.debugError('A valid table ID must be specified', 'fetchPopulateData');
		}

		return (await this.service.getTable(tableId)).data ?? null;
	}
}

export { TableStore as default };
export { TableStore };
