// source: dealing_device.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var hub_game_pb = require('./hub_game_pb.js');
goog.object.extend(proto, hub_game_pb);
var hub_error_pb = require('./hub_error_pb.js');
goog.object.extend(proto, hub_error_pb);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DealtInputRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DeviceNewPlayRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DeviceNewPlayResponse', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DevicePausePlayReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DevicePausePlayRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DeviceRunPlayReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DeviceRunPlayRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.displayName = 'proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.displayName = 'proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DeviceNewPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.displayName = 'proto.com.sands.live_dealer.hub.DeviceNewPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DeviceNewPlayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.displayName = 'proto.com.sands.live_dealer.hub.DeviceNewPlayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DealtInputRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DealtInputRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DealtInputRequest.displayName = 'proto.com.sands.live_dealer.hub.DealtInputRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DeviceRunPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.displayName = 'proto.com.sands.live_dealer.hub.DeviceRunPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DeviceRunPlayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DeviceRunPlayReply.displayName = 'proto.com.sands.live_dealer.hub.DeviceRunPlayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DevicePausePlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DevicePausePlayRequest.displayName = 'proto.com.sands.live_dealer.hub.DevicePausePlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DevicePausePlayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DevicePausePlayReply.displayName = 'proto.com.sands.live_dealer.hub.DevicePausePlayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.displayName = 'proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.displayName = 'proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.displayName = 'proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.displayName = 'proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest;
  return proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    hub_game_pb.DealtRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply;
  return proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.DealtRequest;
      reader.readMessage(value,hub_game_pb.DealtRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hub_game_pb.DealtRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply} returns this
*/
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated DealtRequest requests = 3;
 * @return {!Array<!proto.com.sands.live_dealer.hub.DealtRequest>}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.DealtRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hub_game_pb.DealtRequest, 3));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.DealtRequest>} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply} returns this
*/
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.DealtRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.DealtRequest}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.sands.live_dealer.hub.DealtRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceRequestsReply.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DeviceNewPlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayRequest}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DeviceNewPlayRequest;
  return proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DeviceNewPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayRequest}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DeviceNewPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayRequest} returns this
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    playId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DeviceNewPlayResponse;
  return proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse} returns this
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse} returns this
*/
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse} returns this
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 play_id = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DeviceNewPlayResponse} returns this
 */
proto.com.sands.live_dealer.hub.DeviceNewPlayResponse.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DealtInputRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DealtInputRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    input: (f = msg.getInput()) && hub_game_pb.DealtInput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DealtInputRequest}
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DealtInputRequest;
  return proto.com.sands.live_dealer.hub.DealtInputRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DealtInputRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DealtInputRequest}
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 2:
      var value = new hub_game_pb.DealtInput;
      reader.readMessage(value,hub_game_pb.DealtInput.deserializeBinaryFromReader);
      msg.setInput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DealtInputRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DealtInputRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInput();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_game_pb.DealtInput.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.DealtInputRequest} returns this
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DealtInput input = 2;
 * @return {?proto.com.sands.live_dealer.hub.DealtInput}
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.prototype.getInput = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.DealtInput} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.DealtInput, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.DealtInput|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.DealtInputRequest} returns this
*/
proto.com.sands.live_dealer.hub.DealtInputRequest.prototype.setInput = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.DealtInputRequest} returns this
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.prototype.clearInput = function() {
  return this.setInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.DealtInputRequest.prototype.hasInput = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DeviceRunPlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DeviceRunPlayRequest}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DeviceRunPlayRequest;
  return proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DeviceRunPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DeviceRunPlayRequest}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DeviceRunPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.DeviceRunPlayRequest} returns this
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DeviceRunPlayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DeviceRunPlayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DeviceRunPlayReply}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DeviceRunPlayReply;
  return proto.com.sands.live_dealer.hub.DeviceRunPlayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DeviceRunPlayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DeviceRunPlayReply}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DeviceRunPlayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DeviceRunPlayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.DeviceRunPlayReply} returns this
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.DeviceRunPlayReply} returns this
*/
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.DeviceRunPlayReply} returns this
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.DeviceRunPlayReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DevicePausePlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DevicePausePlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayRequest}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DevicePausePlayRequest;
  return proto.com.sands.live_dealer.hub.DevicePausePlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DevicePausePlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayRequest}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DevicePausePlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DevicePausePlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayRequest} returns this
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayRequest} returns this
 */
proto.com.sands.live_dealer.hub.DevicePausePlayRequest.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DevicePausePlayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DevicePausePlayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayReply}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DevicePausePlayReply;
  return proto.com.sands.live_dealer.hub.DevicePausePlayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DevicePausePlayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayReply}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DevicePausePlayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DevicePausePlayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayReply} returns this
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayReply} returns this
*/
proto.com.sands.live_dealer.hub.DevicePausePlayReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.DevicePausePlayReply} returns this
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.DevicePausePlayReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest;
  return proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest} returns this
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply;
  return proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply} returns this
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply} returns this
*/
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply} returns this
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceShoeReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    playId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest;
  return proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 play_id = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdRequest.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    deviceKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceState: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply;
  return proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} returns this
*/
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string device_key = 3;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string device_state = 4;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.getDeviceState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.setDeviceState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string table_id = 5;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply} returns this
 */
proto.com.sands.live_dealer.hub.GetDealerDeviceByPlayIdReply.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.com.sands.live_dealer.hub);
