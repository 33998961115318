import { getStores } from 'server/store';
import { DEFAULT_CURRENCY_CODE, formatCurrency, getCurrencySymbolForCode } from 'helpers/currency';

interface IBalanceDataSimple {
	currencyCode: string;
	currencySymbol: string;
	amount: number;
	amountMoney: string;
}

/**
 * TODO: This will eventually go through the WalletManager.
 *
 * @returns The balance data for the current table balance.
 */
const getCurrentTableBalanceData = (): IBalanceDataSimple => {
	const { tableStore, userStore } = getStores();

	const currencyCode = tableStore.currency || DEFAULT_CURRENCY_CODE;
	const currencySymbol: string = getCurrencySymbolForCode(currencyCode);
	const balance = userStore.getBalanceDataForCurrency(currencyCode);

	if (balance == null) {
		return { currencyCode, currencySymbol, amount: 0, amountMoney: formatCurrency(0, { currencyCode }) };
	}

	const amount = balance.amount;
	const amountMoney = formatCurrency(balance.amountMoney, { currencyCode });

	return { currencyCode, currencySymbol, amount, amountMoney };
};

/**
 * TODO: This will eventually go through the WalletManager.
 *
 * @returns The balance amount for the current table balance.
 */
const getCurrentTableBalanceAmount = (): number => {
	const balanceData = getCurrentTableBalanceData();

	return balanceData.amount;
};

/**
 * TODO: This will eventually go through the WalletManager.
 *
 * @returns The formatted balance amount money value for the current table balance.
 */
const getCurrentTableBalanceAmountMoney = (): string => {
	const balanceData = getCurrentTableBalanceData();

	return balanceData.amountMoney;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { getCurrentTableBalanceData, getCurrentTableBalanceAmount, getCurrentTableBalanceAmountMoney };
