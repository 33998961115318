import { toJS } from 'helpers/mobx';
import 'helpers/window';
import { getGameStore } from './stores.game';

// TODO: Eventually we should remove this
// @ts-ignore
window.areax = getGameStore();

// TODO: Eventually we should remove this
// @ts-ignore
window.toJS = toJS;

// ---- Export --------------------------------------------------------------------------------------------------------

export * from './constants';
export * from './stores';
export * from './stores.game';
export * from './autorun';
export * from './autorun.dealer';
export * from './utility';
