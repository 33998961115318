enum Theme {
	BASE = 'base',
	SANDS = 'sands',
	TWO_BET = 'two_bet',
	GLI1 = 'dragontiger_gli1',
	GLI2 = 'dragontiger_gli2',
	GLI3 = 'dragontiger_gli3',
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { Theme };
