enum GameState {
	INIT = 'initial',
	RUN = 'running',
	RES = 'resolved',
	COMP = 'completed',
}

const SideBets: StringDict = {
	INSURANCE: 'ins',
	SPLIT: 'spl',
	DOUBLE_DOWN: 'dou',
};

// Bets map - currently known bets.
const Bets: StringDict = {
	...SideBets,
	WAGER: 'ini',
};

enum ResolutionType {
	RESOLUTION = 'resolution',
	PAYOUT = 'payout',
	PUSH = 'push',
}

export { GameState, Bets, ResolutionType };
