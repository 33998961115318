/**********************************************************************************************************************
 * Actions related to login.
 *********************************************************************************************************************/
import Config from 'helpers/config';
import { SelfData } from '../lib-rpc';
import { resolveExistingLogin } from '../selectors';
import { getUserService } from '../services';
import { getSession } from '../session';
import { getUserStore } from '../store';
import { debug } from './debug';
import { logout } from './logout';
import { setActivePlayer } from './player';

/**
 * Smart auto-login process. Designed for use with the main app workflow.
 */
const appAutoLogin = async (
	credentials?: Maybe<{ email: string; pass: string }>,
	opts?: Maybe<{ throwErrorOnFail?: Maybe<boolean> }>
): Promise<Nullable<SelfData>> => {
	const { throwErrorOnFail = false } = opts ?? {};

	// If we are already logged-in we don't need to do anything, just return the existing user data
	const { isLoggedIn, userData: existingUserData } = resolveExistingLogin();
	if (isLoggedIn) {
		return existingUserData;
	}

	const { email = '', pass = '' } = credentials ?? {};
	const hasValidCredentials = email !== '' && pass !== '';

	const session = getSession();
	const token = session.token;
	const hasToken = token !== '';

	let userData: Nullable<SelfData> = null;

	const errors: Error[] = [];

	const addError = (e: Error | unknown) => {
		const err = e as Error;
		err.stack = undefined;
		errors.push(err);
	};

	if (!hasToken && !hasValidCredentials) {
		// addError(new Error('No authentication token or credentials are available'));
		return null;
	}

	//-----------------------------------------------------------------------------------------------------------
	// Check for an existing session token, if it exists then use it (regardless of the user it belongs to)
	// TODO: Eventually we might want to store the user `email` in the auth session and compare...
	//-----------------------------------------------------------------------------------------------------------
	if (hasToken) {
		try {
			userData = await loginWithToken(token, { rethrowErrors: true, enablePostLogin: false });
		} catch (e: unknown) {
			addError(e);

			// If an error occurs while trying to login using the token then just logout of the session - we'll try to
			// re-login down below
			logout();
		}
	}

	//-----------------------------------------------------------------------------------------------------------
	// No user data but we have valid credentials, so try to login via RPC
	//-----------------------------------------------------------------------------------------------------------
	if (userData == null && hasValidCredentials) {
		try {
			userData = await loginWithRpc(email, pass, { rethrowErrors: true, enablePostLogin: false });
		} catch (e: unknown) {
			addError(e);
		}
	}

	if (userData == null) {
		const errSummary = JSON.stringify(errors);

		if (throwErrorOnFail) {
			throw new Error(`Unable to login. Errors:\n${errSummary}`);
		}

		debug.warn('Unable to login. Errors:', 'appAutoLogin', errors);
	}

	// Post-login processing
	afterLogin(userData);

	return userData;
};

const appAutoLoginFromConfig = async (): Promise<Nullable<SelfData>> => {
	const userName = Config.userName || '';
	const password = Config.password || '';

	return await appAutoLogin({ email: userName, pass: password });
};

/**
 * Register a user with specified credentials. Designed for use with the login page. Will fully re-login.
 */
const registerUser = async (
	credentials: { email: string; pass: string },
	opts?: Maybe<{ throwErrorOnFail?: Maybe<boolean> }>
): Promise<Nullable<SelfData>> => {
	const { throwErrorOnFail = false } = opts ?? {};

	const { email = '', pass = '' } = credentials ?? {};
	const hasValidCredentials = email !== '' && pass !== '';

	debug.info('Attempting to register:', 'registerUser', { credentials, opts, hasValidCredentials });

	let userData: Nullable<SelfData> = null;

	const errors: Error[] = [];

	const addError = (e: unknown) => {
		const err = e as Error;
		err.stack = undefined;
		errors.push(err);
	};

	if (hasValidCredentials) {
		logout();

		try {
			userData = await registerWithRpc(email, pass, { rethrowErrors: true, enablePostLogin: false });
		} catch (e: unknown) {
			addError(e);
		}
	}

	if (userData == null) {
		const errSummary = JSON.stringify(errors);

		if (throwErrorOnFail) {
			throw new Error(`Unable to register user '${email}'. Errors:\n${errSummary}`);
		}

		debug.error('Unable to register. Errors:', 'registerUser', errors);
	} else {
		addError(new Error('Unable to register due to invalid credentials'));
	}

	// Post-login processing
	afterLogin(userData);

	return userData;
};

/**
 * Login a user with specified credentials. Designed for use with the login page. Will clear any current
 * authentication session and re-login.
 */
const loginUser = async (
	credentials: { email: string; pass: string },
	opts?: Maybe<{ throwErrorOnFail?: Maybe<boolean> }>
): Promise<Nullable<SelfData>> => {
	const { throwErrorOnFail = false } = opts ?? {};

	const { email = '', pass = '' } = credentials ?? {};
	const hasValidCredentials = email !== '' && pass !== '';

	let userData: Nullable<SelfData> = null;

	const errors: Error[] = [];

	const addError = (e: unknown) => {
		const err = e as Error;
		err.stack = undefined;
		errors.push(err);
	};

	if (hasValidCredentials) {
		logout();

		try {
			userData = await loginWithRpc(email, pass, { rethrowErrors: true, enablePostLogin: false });
		} catch (e: unknown) {
			addError(e);
		}
	} else {
		addError(new Error('Unable to login due to invalid credentials'));
	}

	if (userData == null) {
		const errSummary = JSON.stringify(errors);

		if (throwErrorOnFail) {
			throw new Error(`Unable to register user '${email}'. Errors:\n${errSummary}`);
		}

		debug.error('Unable to register. Errors:', 'registerUser', errors);
	}

	// Post-login processing
	afterLogin(userData);

	return userData;
};

/**
 * Attempt to login and get user data using the specified token.
 */
const loginWithToken = async (
	token: string,
	opts?: Maybe<{ enablePostLogin?: Maybe<boolean>; rethrowErrors?: Maybe<boolean> }>
): Promise<Nullable<SelfData>> => {
	const { enablePostLogin = true, rethrowErrors = false } = opts ?? {};

	let userData: Nullable<SelfData> = null;

	try {
		if (token === '') {
			throw new Error('Token must be specified');
		}

		// Set the token on the auth session
		const session = getSession();
		session.token = token;

		// Get the user player data
		userData = await getUserService().getSelf();

		// Look for errors in the user data
		throwUserDataErrors(userData);

		// Post-login processing
		enablePostLogin && afterLogin(userData);
	} catch (e) {
		userData = null;

		const err = e as Error;
		const message = `Error while attempting to login with token '${token}':`;

		if (rethrowErrors) {
			throw new Error(message);
		}

		debug.error(message, 'loginWithToken', err);
	}

	return userData;
};

/**
 * Attempt to login via RPC and get user data using the specified user credentials.
 */
const loginWithRpc = async (
	email: string,
	pass: string,
	opts?: Maybe<{ enablePostLogin?: Maybe<boolean>; rethrowErrors?: Maybe<boolean> }>
): Promise<Nullable<SelfData>> => {
	const { enablePostLogin = true, rethrowErrors = false } = opts ?? {};
	const hasValidCredentials = email !== '' && pass !== '';

	let userData: Nullable<SelfData> = null;

	try {
		if (!hasValidCredentials) {
			throw new Error('Invalid user credentials specified');
		}

		// Login via RPC user service
		userData = await getUserService().login(email, pass);

		// Look for errors in the user data
		throwUserDataErrors(userData);

		// Post-login processing
		enablePostLogin && afterLogin(userData);
	} catch (e) {
		userData = null;

		const err = e as Error;
		const message = `Error while attempting to login with email '${email}':`;

		if (rethrowErrors) {
			throw new Error(message);
		}

		debug.error(message, 'loginWithRpc', err);
	}

	return userData;
};

/**
 * Attempt to register via RPC and get user data using the specified user credentials.
 */
const registerWithRpc = async (
	email: string,
	pass: string,
	opts?: Maybe<{ enablePostLogin?: Maybe<boolean>; rethrowErrors?: Maybe<boolean> }>
): Promise<Nullable<SelfData>> => {
	const { enablePostLogin = true, rethrowErrors = false } = opts ?? {};

	let userData: Nullable<SelfData> = null;

	try {
		// Register and login via RPC user service
		userData = await getUserService().registerAndLogin(email, pass);

		// Look for errors in the user data
		throwUserDataErrors(userData);

		// Post-login processing
		enablePostLogin && afterLogin(userData);
	} catch (e) {
		userData = null;

		const err = e as Error;
		const message = `Error while attempting to register with email '${email}':`;

		if (rethrowErrors) {
			throw new Error(message);
		}

		debug.error(message, 'registerRpc', err);
	}

	return userData;
};

const throwUserDataErrors = (userData: SelfData) => {
	if (!userData.success) {
		const { message } = userData.error ?? {};
		throw new Error(message);
	}

	if (userData.playerId < 1) {
		throw new Error('Invalid player data');
	}
};

const afterLogin = (userData: Nullable<SelfData>) => {
	if (userData == null) {
		return;
	}

	const userStore = getUserStore();
	if (userStore.playerId === userData.playerId) {
		return;
	}

	userStore.setData(userData);
	setActivePlayer(userData.playerId);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { appAutoLogin, appAutoLoginFromConfig, loginUser, loginUser as login, registerUser, registerUser as register };
