// Generic interface type for a cancelable promise
interface ICancelablePromise<T> {
	promise: Promise<T>;
	cancel: () => void;
	reject: PromiseRejector;
	resolve: PromiseResolver<T>;
}

type PromiseResolver<T> = (value: T | PromiseLike<T>) => void;
type PromiseRejector = (reason?: any) => void;

const NewCancelablePromise = <P>(promise: Promise<P>, onCancel?: () => void): ICancelablePromise<P> => {
	let isCanceled: boolean = false;

	let resolver: PromiseResolver<P>;
	let rejector: PromiseRejector;

	const wrapperPromise = new Promise<P>((resolve, reject) => {
		resolver = resolve;
		rejector = reject;

		promise
			.then((val) => (isCanceled ? reject({ isCanceled: true, result: val }) : resolve(val)))
			.catch((e: unknown) => {
				const err = e as PlainObject;
				isCanceled ? reject({ isCanceled: true, result: { ...err } }) : reject(err);
			});
	});

	return {
		promise: wrapperPromise,
		resolve: (val: P) => resolver(val),
		reject: (reason?: any) => rejector(reason),
		cancel: () => {
			isCanceled = true;
			onCancel && onCancel();
		},
	};
};

export { NewCancelablePromise };
export type { ICancelablePromise };

/**
 * // --------------------------------------------------------
 * // EXAMPLE USAGE
 * // --------------------------------------------------------
 * const cp = newCancelablePromise<PromiseResultType>(
 *   new Promise((resolve, reject) => {
 *     // DO SOMETHING
 *   })
 * );
 *
 * cp.promise.then(() => console.log('resolved')).catch(err => {
 *   if (err.isCanceled) {
 *     console.log('Wrapped promise canceled');
 *     return;
 *   }
 *
 *   console.log('Promise was not canceled but rejected due to errors: ', err);
 * });
 *
 * cp.cancel();
 * // --------------------------------------------------------
 */
