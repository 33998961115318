import { Stream } from '../../client/core/Stream';
import { IStreamController, IStreamOptions, IValidateStartResult, StreamStatus } from '../../client/core/types';
import { SpokeClient } from '../rpc/clients/spoke';
import { GetSelfReply } from '../rpc/replies/spoke';
import { GetSelfRequest } from '../rpc/requests/spoke';
import { SelfData } from '../rpc/types/spoke';
import { IUserDataStream, UserDataStreamRequestProps } from './types';

type RequestProps = UserDataStreamRequestProps;
type ValidateStartResult = IValidateStartResult<RequestProps>;

class UserDataStream extends Stream<SpokeClient, SelfData, RequestProps> implements IUserDataStream {
	/**
	 * CONSTRUCTOR.
	 *
	 * @param url   Stream service url to connect to.
	 * @param opts  Options to use when creating this stream.
	 */
	constructor(url: string, opts?: IStreamOptions) {
		super(url, opts);

		this.client = this.createStreamClient(SpokeClient, opts?.grpcOptions);
		this.streamClientListener = this.createStreamClientListener<GetSelfReply>();
	}

	/**
	 * Attempt to start this stream.
	 *
	 * @returns TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *          connected and received data - you must subscribe to the stream to know that.
	 */
	public start = (): boolean => {
		const { isValid } = this.validateStart();

		if (!isValid) {
			return false;
		}

		if (this.isActive) {
			console.warn(
				this.debugMsg('Attempted to start an already running stream. Use `restart` if this is intended', 'start')
			);

			return false;
		}

		// Manual starts will clear any auto-restart cycle that might be active
		this.clearAutoRestarts();
		this.currentState.status = StreamStatus.STARTING;

		const didRun = this.runStream();
		if (didRun) {
			this.afterStart();
		}

		return didRun;
	};

	/**
	 * Determines if we are allowed to start/restart this stream.
	 *
	 * @returns The result of the validation. This also includes the processed request props to apply.
	 */
	protected validateStart(): ValidateStartResult {
		if (!this.isEnabled) {
			console.warn(this.debugMsg('Stream is disabled', 'validateStart'));
			return { isValid: false, requestProps: null };
		}

		return { isValid: true, requestProps: null };
	}

	/**
	 * Creates and starts a new user stream.
	 *
	 * @returns TRUE if successfully able to create and start the stream.
	 */
	protected runStream(): boolean {
		this.dataStream = this.newStream();

		return true;
	}

	/**
	 * Starts a new user data stream.
	 */
	protected newStream = (): IStreamController => {
		const request = new GetSelfRequest();

		return this.stream<typeof request, GetSelfReply>('streamSelf', request);
	};
}

export { UserDataStream as default };
export { UserDataStream };
