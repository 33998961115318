/**********************************************************************************************************************
 * Actions related to logout.
 *********************************************************************************************************************/
import { isLoggedIn } from '../selectors';
import { getSession } from '../session';
import { clearAllStores } from '../store';
import { stopAllStreams } from './streams';

const clearSession = () => getSession().clear();

const logout = (full?: Maybe<boolean>) => {
	if (full === true || isLoggedIn()) {
		return fullLogout();
	}

	clearSession();
};

const fullLogout = () => {
	stopAllStreams();
	clearAllStores();
	clearSession();

	// TODO: Cancel all in-flight unary calls (how??)
	// TODO: Might want to re-setup ALL the server stuff
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { logout, fullLogout, clearSession };
