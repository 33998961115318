/**********************************************************************************************************************
 * Setup & initialization for the server layer.
 *********************************************************************************************************************/
import { initServices } from './services';
import { initSession } from './session';
import {
	initDealerAutorun as initDealerStoreAutorun,
	initDealerStores,
	initGameStore,
	initAutorun as initStoreAutorun,
	initStores,
} from './store';
import { initDealerStreams, initStreams } from './streams';
import { initDealerStreamSubscriptions, initStreamSubscriptions } from './streams.subscriptions';

const initialize = (): void => {
	// Setup the session storage used for authentication
	initSession();
	// Setup the services used to make unary RPC calls
	initServices();

	// ---- Player ----------------------------------------------------------------------

	// Setup the RPC player streams used to feed data into the stores
	initStreams();
	// Setup the stores used to receive & manage the data
	initStores();
	// Setup the player stream subscriptions
	initStreamSubscriptions();
	// Setup the main game store
	initGameStore();
	// Initialize the store mobx autorun & reactions
	initStoreAutorun();

	// ---- Dealer ----------------------------------------------------------------------

	// Setup the RPC dealer streams used to feed data into the stores
	initDealerStreams();
	// Setup the dealer stores used to receive & manage the data
	initDealerStores();
	// Setup the dealer stream subscriptions
	initDealerStreamSubscriptions();
	// Initialize the dealer store mobx autorun & reactions
	initDealerStoreAutorun();
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initServer };
