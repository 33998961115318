import { getDeviceService, getDevService } from 'server/services';
import { getDealerStores as getStores } from 'server/store';
import { DEFAULT_CURRENCY_CODE } from 'helpers/currency';
import { HubDealtRequestData, IError } from '../lib-rpc';
import { debug } from './debug';

interface ISharedRpcResult {
	success: boolean;
	error?: Maybe<IError>;
}

interface IGetDealerRequestsRpcResult extends ISharedRpcResult {
	data: HubDealtRequestData[];
}

/**
 * Makes the RPC dealer request to run the play (calls `HubDeviceService.runPlay`).
 *
 * @param tableId  The table ID to run play for.
 */
const devDealerRunPlayRpc = async (tableId: string): Promise<ISharedRpcResult> => {
	const svc = getDeviceService();
	const response = await svc.runPlay(tableId);

	const defaultResult = { success: false, error: null };

	if (!response.success) {
		const error = { code: 0, message: 'Unknown error', ...(response.error ?? {}) };
		return { ...defaultResult, error };
	}

	if (response.data == null) {
		const error = { code: -1, message: 'Invalid data' } as IError;
		return { ...defaultResult, error };
	}

	const data = response.data.toObject();

	if (!data.success) {
		const error = { code: 0, message: 'Unknown error', ...(data.error ?? {}) };
		return { ...defaultResult, error };
	}

	return { ...defaultResult, success: true };
};

/**
 * Makes the RPC dealer request to start a new play for the table (`calls DevService.newPlay`)
 *
 * @param tableId  The table ID to run play for.
 */
const devDealerNewPlayRpc = async (tableId: string): Promise<ISharedRpcResult> => {
	const svc = getDevService();
	const response = await svc.newPlay(tableId);

	const defaultResult = { success: false, error: null };

	if (!response.success) {
		const error = { code: 0, message: 'Unknown error', ...(response.error ?? {}) };
		return { ...defaultResult, error };
	}

	if (response.data == null) {
		const error = { code: -1, message: 'Invalid data' } as IError;
		return { ...defaultResult, error };
	}

	const data = response.data.toObject();

	if (!data.success) {
		const error = { code: 0, message: 'Unknown error', ...(data.error ?? {}) };
		return { ...defaultResult, error };
	}

	return { ...defaultResult, success: true };
};

/**
 * Makes the RPC dealer request to get the list of available dealer inputs (calls `HubDeviceService.getDealerDeviceRequests`).
 *
 * @param deviceKey  The device key to get the requests for.
 */
const devGetDealerRequestsRpc = async (deviceKey: string): Promise<IGetDealerRequestsRpcResult> => {
	const svc = getDeviceService();
	const response = await svc.getDealerDeviceRequests(deviceKey);

	const defaultResult = { success: false, error: null, data: [] };

	if (!response.success) {
		const error = { code: 0, message: '', ...(response.error ?? {}) };
		return { ...defaultResult, error };
	}

	if (response.data == null) {
		const error = { code: -1, message: 'Invalid data' };
		return { ...defaultResult, error };
	}

	const data = response.data;

	if (!data.success) {
		const error = { code: 0, message: '', ...(data.error ?? {}) };
		return { ...defaultResult, error };
	}

	return { ...defaultResult, success: true, data: data.requestsList };
};

/**
 * Makes the RPC dealer request to input an action (calls `HubDeviceService.dealtInput`).
 */
const devDealerDealtInputRpc = async (deviceKey: string, inputNum: number, requestType: string, values: string[]) => {
	const svc = getDeviceService();
	const response = await svc.dealtInput(deviceKey, inputNum, requestType, values);

	const defaultResult = { success: false, error: null };

	if (!response.success) {
		const error = { code: 0, message: 'Unknown error', ...(response.error ?? {}) };
		return { ...defaultResult, error };
	}

	if (response.data == null) {
		const error = { code: -1, message: 'Invalid data' } as IError;
		return { ...defaultResult, error };
	}

	const data = response.data.toObject();

	if (!data.success) {
		const error = { code: 0, message: 'Unknown error', ...(data.error ?? {}) };
		return { ...defaultResult, error };
	}

	return { ...defaultResult, success: true };
};

/**
 * Makes the RPC dealer request to start a new play for the table (`calls DevService.newPlay`)
 *
 * @param amount        The amount to deposit.
 * @param currencyCode  The currency to deposit.
 */
const devDepositMoneyRpc = async (amount: number, currencyCode?: string): Promise<ISharedRpcResult> => {
	currencyCode = currencyCode || DEFAULT_CURRENCY_CODE;

	const svc = getDevService();
	const response = await svc.testDepositMoney(amount, currencyCode);

	const defaultResult = { success: false, error: null };

	if (!response.success) {
		const error = { code: 0, message: 'Unknown error', ...(response.error ?? {}) };
		return { ...defaultResult, error };
	}

	if (response.data == null) {
		const error = { code: -1, message: 'Invalid data' } as IError;
		return { ...defaultResult, error };
	}

	const data = response.data.toObject();

	if (!data.success) {
		const error = { code: 0, message: 'Unknown error', ...(data.error ?? {}) };
		return { ...defaultResult, error };
	}

	return { ...defaultResult, success: true };
};

/**
 * Use the Hub dealer device service to progress play on the current table.
 */
const devDealerRunPlay = async (tableId?: string): Promise<boolean> => {
	const { dealerTableStore } = getStores();
	tableId = tableId || dealerTableStore.tableId;

	if (tableId === '') {
		return false;
	}

	const result = await devDealerRunPlayRpc(tableId);
	debug.info(`Issued dealer play request for ${tableId}:`, 'devDealerRunPlay');

	return result.success;
};

/**
 * Use the dev service to start a new play for the current table.
 */
const devDealerNewPlay = async (tableId?: string): Promise<boolean> => {
	const { dealerTableStore } = getStores();
	tableId = tableId || dealerTableStore.tableId;

	if (tableId === '') {
		return false;
	}

	const result = await devDealerNewPlayRpc(tableId);
	debug.info(`Issued dealer new play request for ${tableId}:`, 'devDealerNewPlay');

	return result.success;
};

/**
 * Use the Hub dealer device service to get a list of valid requests for the specified dealer device/table.
 */
const devDealerGetRequests = async (deviceKey: string): Promise<HubDealtRequestData[]> => {
	const { dealerTableStore } = getStores();

	deviceKey = deviceKey ?? dealerTableStore.tableId;

	if (deviceKey === '') {
		return [];
	}

	const result = await devGetDealerRequestsRpc(deviceKey);
	debug.info(`Got dealer device requests for ${deviceKey}:`, 'devDealerGetRequests', result);

	return result.data;
};

/**
 * Use the Hub dealer device service to run a dealer action for the specified device/table.
 */
const devDealerInput = async (
	deviceKey: string,
	inputNum: number,
	requestType: string,
	values: string[]
): Promise<boolean> => {
	const { dealerTableStore } = getStores();

	deviceKey = deviceKey ?? dealerTableStore.tableId;

	if (deviceKey === '') {
		return false;
	}

	const result = await devDealerDealtInputRpc(deviceKey, inputNum, requestType, values);

	debug.info(
		`Applied dealer input for device ${deviceKey}:`,
		'devDealerInput',
		{ inputNum, requestType, values },
		{ result }
	);

	return result.success;
};

/**
 * Use the dev service to increment or decrement the player balance with the specified currency amount.
 */
const devDepositMoney = async (amount: number, currencyCode?: string): Promise<boolean> => {
	currencyCode = currencyCode || DEFAULT_CURRENCY_CODE;

	const result = await devDepositMoneyRpc(amount, currencyCode);

	debug.info(`Deposited amount ${amount} in ${currencyCode}:`, 'devDepositMoney', result);

	return result.success;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { devDealerGetRequests, devDealerInput, devDealerNewPlay, devDealerRunPlay, devDepositMoney };
