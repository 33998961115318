/**********************************************************************************************************************
 * Stores and manages data regarding the current table rules.
 *
 * Note: This will be used as the base parent class for the same store inside of the game clients.
 *********************************************************************************************************************/
import { RulesData, RulesReplyData, VariantRulesData, WagerRuleData } from '../client/rpc/types/spoke';
import { IRulesService } from '../client/service/types';
import { DataStore } from './DataStore';

class RulesStore extends DataStore<IRulesService, RulesReplyData> {
	/**
	 * Rules data. TODO: We should probably make THIS the data for the store.
	 */
	public get rules(): Nullable<RulesData> {
		return this.data?.rules ?? null;
	}

	/**
	 * @returns Unique rules Id for the rules data.
	 */
	public get rulesId(): string {
		return this.rules?.id ?? '';
	}

	/**
	 * @returns An array of the wagering rules.
	 */
	public get wagerRulesList(): WagerRuleData[] {
		return this.rules?.wagerRulesList ?? [];
	}

	/**
	 * @returns TRUE if we have wagering rules.
	 */
	public get hasWagerRules(): boolean {
		return this.wagerRulesList.length > 0;
	}

	/**
	 * @returns An array of all the wagering rule names.
	 */
	public get wagerNames(): string[] {
		return this.wagerRulesList.map((r) => r.wagerName);
	}

	/**
	 * @returns The minimum allowed bet value.
	 */
	public get min() {
		return (this.hasWagerRules ? this.wagerRulesList[0].minBet : null) ?? 0;
	}

	/**
	 * @returns The maximum allowed bet value.
	 */
	public get max() {
		return (this.hasWagerRules ? this.wagerRulesList[0].maxBet : null) ?? 0;
	}

	/**
	 * @returns Game variant rules. Provides specific rule data for the various games.
	 */
	public get variantRules(): Nullable<VariantRulesData> {
		return this.rules?.variantRules ?? null;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}

	// ---- Populate ----------------------------------------------------------------------------------------------------

	/**
	 * Action. Populates the store (via unary RPC service call) using the specified rules ID.
	 */
	public async populate(rulesId: string): Promise<boolean> {
		if (rulesId === '') {
			this.debugError('A valid rules key ID must be specified', 'populate');
		}

		return super.populate(rulesId);
	}

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(rulesId: string): Promise<Nullable<RulesReplyData>> {
		if (!this.service) {
			this.debugError('No service was specified', 'fetchPopulateData');
			return null;
		}

		if (rulesId === '') {
			this.debugError('A valid rules ID must be specified', 'fetchPopulateData');
		}

		return (await this.service.getRules(rulesId)).data ?? null;
	}
}

export { RulesStore as default };
export { RulesStore };
