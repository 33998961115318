/**********************************************************************************************************************
 * Utility methods related to Favorite Bets
 *
 * >>> Important <<<
 * - To avoid circular deps do not make the mistake of mixing-up selectors with utility. Selectors can call utility
 *   methods but you should avoid utility methods calling selectors where possible.
 * - Always make utility methods as PURE functions - ie. They only use the params you pass in and other utility
 *   methods.
 *********************************************************************************************************************/
import clamp from 'lodash/clamp';
import { DEFAULT_CURRENCY_CODE } from 'helpers/currency';
import { utcNowSecs } from 'helpers/dateTime';
import { isObjectEmpty } from 'helpers/object';
import { NEW_FAVORITE_BET_PREFIX } from '../constants';
import { FavoriteBets, FavoriteBetsIndex, FavoriteBetsLookup, IFavoriteBetData } from '../types';
import { filterEmptyBets, makeBetsUniqueId, totalBets } from './bets';

const makeFavoriteDefaultName = (betNumber?: number): string => {
	return `${NEW_FAVORITE_BET_PREFIX}${betNumber ? betNumber : '1'}`;
};

const makeFavoriteUniqueId = (fav: IFavoriteBetData, noFilter: boolean = false): Nullable<string> => {
	return makeBetsUniqueId(fav.bets, noFilter);
};

const resolveFavoriteBetData = (favoriteData: IFavoriteBetData): IFavoriteBetData => {
	const methodPrefix = '[resolveFavoriteBetData]';

	const favBet = { ...favoriteData };

	favBet.bets = filterEmptyBets(favBet.bets);

	if (isObjectEmpty(favBet.bets)) {
		throw new Error(
			`${methodPrefix}: Unable to process favorite bet with empty bet amounts data: ${JSON.stringify(favBet)}`
		);
	}

	if (!favBet.id) {
		favBet.id = makeBetsUniqueId(favBet.bets) || favBet.id;

		if (!favBet.id) {
			throw new Error(`${methodPrefix}: Unable to resolve ID for new favorite: ${JSON.stringify(favBet)}`);
		}
	}

	favBet.historicalTimesPlayed = clamp(favBet.historicalTimesPlayed ?? 0, 0, Number.MAX_SAFE_INTEGER);
	favBet.historicalTimesWon = clamp(favBet.historicalTimesWon ?? 0, 0, Number.MAX_SAFE_INTEGER);
	favBet.historicalTotalAmountWagered = clamp(favBet.historicalTotalAmountWagered ?? 0, 0, Number.MAX_SAFE_INTEGER);
	favBet.historicalTotalAmountWon = clamp(favBet.historicalTotalAmountWon ?? 0, 0, Number.MAX_SAFE_INTEGER);
	favBet.name = favBet.name || makeFavoriteDefaultName();
	favBet.betTotal = totalBets(favBet.bets);
	favBet.currency = favBet.currency || DEFAULT_CURRENCY_CODE;
	favBet.createdTs = favBet.createdTs ?? utcNowSecs();
	favBet.modifiedTs = favBet.modifiedTs ?? 0;

	return favBet;
};

const resolveFavoriteBets = (
	bets: Nullable<FavoriteBets>
): {
	betsList: FavoriteBets;
	betsIndex: FavoriteBetsIndex;
	betsLookup: FavoriteBetsLookup;
} => {
	bets = bets ?? [];
	const betsIndex: FavoriteBetsIndex = new Map();
	const betsLookup: FavoriteBetsLookup = new Map();
	const betsList: FavoriteBets = [];

	let i = -1;
	bets.forEach((bet) => {
		++i;

		if (isObjectEmpty(bet.bets)) {
			return;
		}

		const betData = filterEmptyBets(bet.bets);
		const betId = bet.id || makeBetsUniqueId(betData);
		if (!betId) {
			return;
		}

		bet.id = betId;
		bet.bets = betData;

		betsLookup.set(betId, bet);
		betsIndex.set(betId, i);
		betsList.push(bet);
	});

	return { betsList, betsIndex, betsLookup };
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { makeFavoriteUniqueId, makeFavoriteDefaultName, resolveFavoriteBetData, resolveFavoriteBets };
