/*********************************************************************************************************************
 * This module exports methods to setup the DataDog tracing & metrics.
 *********************************************************************************************************************/
import {
	DDBrowserLogsSDK,
	DDRumSDK,
	initBrowerLogs,
	initRum,
	TracerConfig,
	whenBrowserLogsAvailable,
	whenRumAvailable,
} from '@sandsb2b/areax-client-library/dist/tracer';
import Config from 'helpers/config';
import pkg from '../../../package.json';

const applicationId: string = TracerConfig.applicationId;
const clientToken: string = TracerConfig.clientToken;
const appVersion: string = pkg.version;

/**
 * Setup the DataDog RUM (Realtime User Metrics) SDK.
 *
 * @returns A promise that resolves when the SDK becomes available for use. Otherwise it will be rejected.
 */
const setupRumMetrics = async (): Promise<DDRumSDK> => {
	const origins = [Config.serverURI, Config.deviceServerURI, Config.backOfficeServerURI].filter(Boolean);

	initRum(applicationId, clientToken, {
		version: appVersion,
		allowedTracingOrigins: origins,
	});

	const whenAvailable: Promise<DDRumSDK> = whenRumAvailable();

	whenAvailable.then((sdk: DDRumSDK) => {
		// EXTRA STUFF TO SETUP RUM SDK CAN GO HERE
	});

	return whenAvailable;
};

/**
 * Setup the DataDog Browser Logging SDK.
 *
 * @returns A promise that resolves when the SDK becomes available for use. Otherwise it will be rejected.
 */
const setupBrowserLogging = async (): Promise<DDBrowserLogsSDK> => {
	initBrowerLogs(clientToken, { version: appVersion });

	const whenAvailable: Promise<DDBrowserLogsSDK> = whenBrowserLogsAvailable();

	whenAvailable.then((sdk: DDBrowserLogsSDK) => {
		// EXTRA STUFF TO SETUP BROWSER LOGGING SDK CAN GO HERE
	});

	return whenAvailable;
};

/**
 * Setup all the tracing SDKs. Returns a promise that resolves when all SDK setup promises resolve/reject.
 */
const setupAllTracing = () => {
	const setup = [];

	if (TracerConfig.enableRum) {
		setup.push(setupRumMetrics());
	}
	if (TracerConfig.enableBrowserLogs) {
		setup.push(setupBrowserLogging());
	}

	return Promise.all(setup);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { setupRumMetrics, setupBrowserLogging, setupAllTracing };
