/**********************************************************************************************************************
 * Utility methods related to Bets/Wagers.
 *
 * >>> Important <<<
 * - To avoid circular deps do not make the mistake of mixing-up selectors with utility. Selectors can call utility
 *   methods but you should avoid utility methods calling selectors where possible.
 * - Always make utility methods as PURE functions - ie. They only use the params you pass in and other utility
 *   methods.
 *********************************************************************************************************************/
import { entries, makeSimpleObjectHash } from 'helpers/object';
import { Bets } from '../types';

const filterEmptyBets = (bets: Bets): Bets => {
	const result: Bets = {};
	entries(bets).forEach(([betId, betAmount]) => betAmount > 0 && (result[betId] = betAmount));

	return result;
};

const makeBetsUniqueId = (bets: Bets, noFilter: boolean = false): Nullable<string> => {
	return makeSimpleObjectHash(noFilter ? bets : filterEmptyBets(bets));
};

const countBets = (bets: Bets): number => countPositiveBetsEntries(entries(bets));
const totalBets = (bets: Bets): number => totalBetsEntries(entries(bets));

const countPositiveBetsEntries = (be: Entries<Bets>): number => {
	return be.reduce((count, [_, val]) => (val > 0 ? ++count : count), 0);
};

const totalBetsEntries = (be: Entries<Bets>): number => {
	return be.reduce((total, [_, val]) => (val > 0 ? total + val : total), 0);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { countBets, countPositiveBetsEntries, filterEmptyBets, makeBetsUniqueId, totalBets, totalBetsEntries };
