/**********************************************************************************************************************
 * Actions related to the current table rules
 *********************************************************************************************************************/
import { getStores } from '../store';
import { debug } from './debug';

let _currentRulesId: string = '';

const setRules = async (rulesId: string): Promise<void> => {
	const { rulesStore } = getStores();

	// Unsetting the rules
	if (rulesId === '') {
		_currentRulesId = '';
		rulesStore.clear();
		return;
	}

	// Populate the rules store (if necessary)
	if (rulesStore.rulesId !== rulesId) {
		await rulesStore.populate(rulesId);

		if (!rulesStore.isPopulated || rulesStore.rulesId !== rulesId) {
			_currentRulesId = '';
			rulesStore.clear();
			debug.warn(`Unable to populate rules store using rules ID '${rulesId}'`, 'setRules');
			return;
		}
	}

	_currentRulesId = rulesStore.rulesId;
};

const setActiveRules = async (rulesId: string): Promise<void> => {
	if (rulesId === _currentRulesId) {
		return;
	}

	debug.info('Props:', 'setActiveRules', { rulesId });

	await setRules(rulesId);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { setActiveRules };
