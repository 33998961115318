/**********************************************************************************************************************
 * Utility methods related to Rules.
 *
 * >>> Important <<<
 * - To avoid circular deps do not make the mistake of mixing-up selectors with utility. Selectors can call utility
 *   methods but you should avoid utility methods calling selectors where possible.
 * - Always make utility methods as PURE functions - ie. They only use the params you pass in and other utility
 *   methods.
 *********************************************************************************************************************/
import { PaylineDescriptors } from 'Constants';
import memoize from 'memoize-one';
import { WagerRuleData } from 'server/lib-rpc/types';
import { IWagerRuleDataExt } from 'server/types/rules';
import { toJS } from 'helpers/mobx';

/**
 * Get a version of the wager rules list that has no losing paylines included in the list of paylines.
 */
const getRulesListNoLosingPaylines = (wagerRulesList: WagerRuleData[]): IWagerRuleDataExt[] => {
	const wrList = wagerRulesList.slice();

	return wrList.map((wr: WagerRuleData): IWagerRuleDataExt => {
		const list = wr.paylinesList.slice();
		const listNoLosing = list.filter((pl) => pl.descriptor !== PaylineDescriptors.NONE);

		return { ...toJS(wr), rtp: 0, wagerLabel: wr.wagerName, paylinesList: listNoLosing };
	});
};

/**
 * Memoized version of `getRulesListNoLosingPaylines`.
 *
 * Note: Param `rulesId` is accepted for memoization sake. It's not used for anything else but please do not remove it.
 */
const getRulesListNoLosingPaylinesMemoized = memoize(
	/* @ts-ignore */
	(rulesId: string, wagerRulesList: WagerRuleData[]): IWagerRuleDataExt[] => {
		return getRulesListNoLosingPaylines(wagerRulesList);
	}
);

// ---- Export --------------------------------------------------------------------------------------------------------

export { getRulesListNoLosingPaylinesMemoized as getRulesListNoLosingPaylines };
