/**********************************************************************************************************************
 * MobX autorun and reactions for the various stores.
 *********************************************************************************************************************/
import { DebugLogger } from 'helpers/debug';
import { reaction, toJS } from 'helpers/mobx';
import { setActiveDealerPlay } from '../actions';
import { getDealerStores } from './utility';

const debug = new DebugLogger('Store.Autorun.Dealer');

// ---- Initialize ----------------------------------------------------------------------------------------------------

let _isInitialized = false;

const initDealerPlayStoreReactions = () => {
	const { dealerPlayStore } = getDealerStores();
	// When the play ID changes, set the active play via action.
	// This will populate play related stores and start relevant streams
	reaction(
		() => dealerPlayStore.playId,
		(value: number, prev: number) => {
			debug.info(`Play ID change: ${prev} --> ${value}`, 'DealerPlayStore.PlayId');
			setActiveDealerPlay(value);
		}
	);
};

const initDebugStoreReactions = () => {
	const { dealerHudStore } = getDealerStores();

	// Dealer HUD
	reaction(
		() => dealerHudStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`Dealer HUD data updated:`, 'DealerHudStore', { DealerHudStore: toJS(dealerHudStore.data) });
		}
	);
};

/**
 * Initializes all the dealer store autorun/reactions.
 */
const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	initDealerPlayStoreReactions();

	// Debug reactions - can be safely commented out when not needed.
	initDebugStoreReactions();

	_isInitialized = true;

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initDealerAutorun };
