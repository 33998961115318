/**********************************************************************************************************************
 * MobX autorun and reactions for the various player stores.
 *********************************************************************************************************************/
import { GameState } from 'Constants';
import { DebugLogger } from 'helpers/debug';
import { reaction, toJS } from 'helpers/mobx';
import { setActivePlay, setActiveRules, updateTableHistory } from '../actions';
import { getStores } from './utility';

const debug = new DebugLogger('Store.Autorun');

// ---- Initialize ----------------------------------------------------------------------------------------------------

let _isInitialized = false;

const initTableStoreReactions = () => {
	const { tableStore } = getStores();

	// When the table's rules ID changes, set the new active rules via action.
	// This will populate the rules store with the correct rules.
	reaction(
		() => tableStore.rulesId,
		(value: string, prev: string) => {
			debug.info(`Rules ID change: '${prev}' --> '${value}'`, 'TableStore.RulesID');
			setActiveRules(value);
		}
	);
};

const initPlayStoreReactions = () => {
	const { tableStore, playStore } = getStores();

	// When the play ID changes, set the active play via action.
	// This will populate play related stores and start relevant streams
	reaction(
		() => playStore.playId,
		(value: number, prev: number) => {
			debug.info(`Play ID change: ${prev} --> ${value}`, 'PlayStore.PlayId');
			setActivePlay(value);
		}
	);

	// When the play state changes run certain actions
	reaction(
		() => playStore.state,
		(value: string, prev: string) => {
			debug.info(`Play state change: ${prev} --> ${value}`, 'PlayStore.State');

			// Update the table history store when we transition into completed game state
			const isCompleted = value === GameState.COMP;
			isCompleted && updateTableHistory(tableStore.tableId, playStore.playId);
		}
	);
};

const initDebugStoreReactions = () => {
	const {
		gameStore,
		gameHistoryStore,
		tableHistoryStore,
		playStore,
		rulesStore,
		settingsStore,
		tableStore,
		tableSeatsPlayStore,
		userStore,
	} = getStores();

	// Game
	reaction(
		() => gameStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`Game updated:`, 'GameStore', { GameStore: toJS(gameStore.data) });
		}
	);

	// Game History
	reaction(
		() => gameHistoryStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`Game history updated:`, 'GameHistoryStore', { GameHistoryStore: toJS(gameHistoryStore.data) });
		}
	);

	// Table History
	reaction(
		() => tableHistoryStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`History data updated:`, 'TableHistoryStore', { TableHistoryStore: toJS(tableHistoryStore.toJson()) });
		}
	);

	// Play
	reaction(
		() => userStore.playerId,
		(value: number, prev: number) => {
			debug.info(`Player ID change: ${prev} --> ${value}`, 'UserStore.PlayerId');
		}
	);

	reaction(
		() => playStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`Play data updated:`, 'PlayStore', {
				data: toJS(playStore.data),
				activeWagers: toJS(playStore.activeWagers),
			});
		}
	);

	// Rules
	reaction(
		() => rulesStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`Rules data updated:`, 'RulesStore', { RulesStore: toJS(rulesStore.data) });
		}
	);

	// Settings
	reaction(
		() => settingsStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`Settings data updated:`, 'SettingsStore', { SettingsStore: toJS(settingsStore.data) });
		}
	);

	// Table
	reaction(
		() => tableStore.tableId,
		(value: string, prev: string) => {
			debug.info(`Table ID change: '${prev}' --> '${value}'`, 'TableStore.TableId');
		}
	);
	reaction(
		() => tableStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`Table data updated:`, 'TableStore', { TableStore: toJS(tableStore.data) });
		}
	);

	// Table Seats Play (aka SeatHistory)
	reaction(
		() => tableSeatsPlayStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`Table seat play data updated:`, 'TableSeatsPlayStore', {
				TableSeatsPlayStore: toJS(tableSeatsPlayStore.data),
			});
		}
	);

	// User
	reaction(
		() => userStore.lastUpdatedTs,
		(value: number, prev: number) => {
			debug.info(`User data updated:`, 'UserStore', { UserStore: toJS(userStore.data) });
		}
	);
};

/**
 *
 * @returns Initializes all the store autorun/reactions.
 */
const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	initTableStoreReactions();
	initPlayStoreReactions();

	// Debug reactions - can be safely commented out when not needed.
	initDebugStoreReactions();

	_isInitialized = true;

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initAutorun };

// Dealer
export * from './autorun.dealer';
