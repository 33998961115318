import { clearMultipleStores, getStores, StoreKey } from '../store';
import { StreamKey, streamManager } from '../streams';
import { debug } from './debug';

let _currentTableId: string = '';

/**
 * Clears stores that are considered to be related to the choice of active table.
 *
 * @param includeTableStore
 */
const clearRelatedStores = (includeTableStore?: Optional<boolean>) => {
	includeTableStore = includeTableStore ?? false;

	const storeKeys: Array<StoreKey> = [
		StoreKey.PlayStore,
		StoreKey.TableSeatsPlayStore,
		StoreKey.RulesStore,
		StoreKey.TableHistoryStore,
	];

	if (includeTableStore) {
		storeKeys.unshift(StoreKey.TableStore);
	}

	clearMultipleStores(storeKeys);
};

interface ISetTableOpts {
	// When TRUE will start the table stream.
	startStream?: Maybe<boolean>;
}

/**
 * Sets the current active table for the game. This will ensure data state is correct and that the relevant streams
 * are stopped and the table stream started.
 *
 * @param tableId   Table ID to make active.
 * @param opts      Various options.
 * @returns
 */
const setActiveTable = async (tableId: string, opts?: Maybe<ISetTableOpts>): Promise<void> => {
	// Do nothing if the table ID is not changing
	if (tableId === _currentTableId) {
		return;
	}

	debug.info('Props:', 'setActiveTable', { tableId, opts });

	await setTable(tableId, opts);
};

const setTable = async (tableId: string, opts?: Maybe<ISetTableOpts>): Promise<void> => {
	const { startStream = true } = opts ?? {};

	// Stop all the streams that relate to the table and clear the related stores
	streamManager.stopMultiple([StreamKey.TableSeatsPlayStream, StreamKey.TableStream]);
	clearRelatedStores();

	const { tableStore, tableSeatsPlayStore } = getStores();

	// Unsetting the table
	if (tableId === '') {
		_currentTableId = '';
		tableStore.clear();
		return;
	}

	// Populate the table store (if necessary)
	if (tableStore.tableId !== tableId) {
		// debug.info(`Attempting to populate table store for table ID '${tableId}'`, 'setTable');

		await tableStore.populate(tableId);

		if (!tableStore.isPopulated || tableStore.tableId !== tableId) {
			debug.warn(`Unable to populate table store using table ID '${tableId}'. Is the table ID valid?`, 'setTable');

			_currentTableId = '';
			tableStore.clear();
			return;
		}
	}

	// Populate the table seats play store (if necessary)
	if (tableSeatsPlayStore.tableId !== tableId) {
		// debug.info(`Attempting to populate table seats play store for table ID '${tableId}'`, 'setTable');

		await tableSeatsPlayStore.populate(tableId);

		if (!tableSeatsPlayStore.isPopulated || tableSeatsPlayStore.tableId !== tableId) {
			debug.warn(`Unable to populate table seats play store using table ID '${tableId}'`, 'setTable');

			tableSeatsPlayStore.clear();
			return;
		}
	}

	_currentTableId = tableStore.tableId;

	if (startStream && streamManager.isEnabled(StreamKey.TableStream)) {
		// debug.info(`Attempting to start the table stream for table ID '${tableId}'`, 'setTable');
		streamManager.start(StreamKey.TableStream, { tableId });
	}

	if (startStream && streamManager.isEnabled(StreamKey.TableSeatsPlayStream)) {
		// debug.info(`Attempting to start the table seats play stream for table ID '${tableId}'`, 'setTable');
		streamManager.start(StreamKey.TableSeatsPlayStream, { tableId });
	}
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { setActiveTable };
