/**********************************************************************************************************************
 * Stores and manages data regarding the current table rules.
 *********************************************************************************************************************/
import { IRulesService } from 'server/lib-rpc';
import { RpcRulesStoreMobXKeys } from 'server/lib-rpc';
import { RpcRulesStore } from 'server/lib-rpc';
import { BaccaratRulesData } from 'server/lib-rpc/types';
import { polyfillRtpAndPaylines } from 'server/polyfill';
import { IWagerRuleDataExt, WagerRulesPayoutLookup } from 'server/types/rules';
import { getRulesListNoLosingPaylines } from 'server/utility';
import { action, computed, makeObservable, observable, toJS } from 'helpers/mobx';

class RulesStore extends RpcRulesStore {
	/**
	 * CONSTRUCTOR.
	 *
	 * @param  service  RPC service to use when calling `populate`.
	 */
	constructor(service: IRulesService) {
		super(service);

		// Note: We cannot use `makeAutoObservable` because this class extends a parent class. So we are declaring the
		// annotations right here instead. We can probably make this better in the future by carefully applying observables
		// on the parent class or via some other technique.. but for now, just roll with it.
		makeObservable<RulesStore, RpcRulesStoreMobXKeys>(this, {
			// ---- Inherited from DataStore --------------------------------------------------------------------------------

			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			isPopulated: computed,
			lastUpdatedTs: computed,

			// Actions
			populate: action,
			setData: action,

			// ---- Inherited from RpcRulesStore ----------------------------------------------------------------------------

			rulesId: computed,
			rules: computed,
			wagerRulesList: computed,
			hasWagerRules: computed,
			min: computed,
			max: computed,
			variantRules: computed,
			wagerNames: computed,

			// ---- Local ---------------------------------------------------------------------------------------------------

			baccaratRules: computed,
			wagerRulesListNoLosingPaylines: computed,
			wagerRulesPayoutLookup: computed,

			// Actions
			clear: action,
		});
	}

	/**
	 * @returns Baccarat specific game rule data.
	 */
	public get baccaratRules(): Nullable<BaccaratRulesData> {
		return this.variantRules?.baccarat ?? null;
	}

	/**
	 * @returns A version of `wagerRulesList` with the paylines list in each entry containing only winning payline.
	 */
	public get wagerRulesListNoLosingPaylines(): IWagerRuleDataExt[] {
		return getRulesListNoLosingPaylines(this.rulesId, this.wagerRulesList);
	}

	/**
	 * @returns An object lookup version of `wagerRulesList` that includes RTP and payline info.
	 */
	public get wagerRulesPayoutLookup(): WagerRulesPayoutLookup {
		let result: WagerRulesPayoutLookup = {};

		this.wagerRulesListNoLosingPaylines.forEach((wr) => {
			result[wr.wagerName] = { ...toJS(wr) };
		});

		// Polyfill fake RTP and Payline info
		result = polyfillRtpAndPaylines(result, this.baccaratRules ?? {});

		return result;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { RulesStore as default };
export { RulesStore };
