// package: sands.live_dealer.spoke
// file: spoke.proto

var spoke_pb = require("./spoke_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Spoke = (function () {
  function Spoke() {}
  Spoke.serviceName = "sands.live_dealer.spoke.Spoke";
  return Spoke;
}());

Spoke.GetSelf = {
  methodName: "GetSelf",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetSelfRequest,
  responseType: spoke_pb.GetSelfReply
};

Spoke.StreamSelf = {
  methodName: "StreamSelf",
  service: Spoke,
  requestStream: false,
  responseStream: true,
  requestType: spoke_pb.StreamSelfRequest,
  responseType: spoke_pb.GetSelfReply
};

Spoke.UpdateDisplayName = {
  methodName: "UpdateDisplayName",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.UpdateDisplayNameRequest,
  responseType: spoke_pb.GetSelfReply
};

Spoke.UpdateImageUrl = {
  methodName: "UpdateImageUrl",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.UpdateImageUrlRequest,
  responseType: spoke_pb.GetSelfReply
};

Spoke.UpdatePreferences = {
  methodName: "UpdatePreferences",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.UpdatePreferencesRequest,
  responseType: spoke_pb.GetSelfReply
};

Spoke.GetRules = {
  methodName: "GetRules",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetRulesRequest,
  responseType: spoke_pb.GetRulesReply
};

Spoke.GetTables = {
  methodName: "GetTables",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetTablesRequest,
  responseType: spoke_pb.GetTablesReply
};

Spoke.GetTable = {
  methodName: "GetTable",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetTableRequest,
  responseType: spoke_pb.GetTableReply
};

Spoke.GetTableHistory = {
  methodName: "GetTableHistory",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetTableHistoryRequest,
  responseType: spoke_pb.GetTableHistoryReply
};

Spoke.StreamTable = {
  methodName: "StreamTable",
  service: Spoke,
  requestStream: false,
  responseStream: true,
  requestType: spoke_pb.StreamTableRequest,
  responseType: spoke_pb.GetTableReply
};

Spoke.SitTable = {
  methodName: "SitTable",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.SitTableRequest,
  responseType: spoke_pb.GetTableReply
};

Spoke.StandTable = {
  methodName: "StandTable",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.StandTableRequest,
  responseType: spoke_pb.GetTableReply
};

Spoke.MakeWagers = {
  methodName: "MakeWagers",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.MakeWagersRequest,
  responseType: spoke_pb.MakeWagersReply
};

Spoke.MakeChoice = {
  methodName: "MakeChoice",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.MakeChoiceRequest,
  responseType: spoke_pb.MakeChoiceReply
};

Spoke.GetWagerHistory = {
  methodName: "GetWagerHistory",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetWagerHistoryRequest,
  responseType: spoke_pb.GetWagerHistoryReply
};

Spoke.GetPlay = {
  methodName: "GetPlay",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetPlayRequest,
  responseType: spoke_pb.GetPlayReply
};

Spoke.GetTablePlayData = {
  methodName: "GetTablePlayData",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetTablePlayDataRequest,
  responseType: spoke_pb.GetTablePlayDataReply
};

Spoke.StreamTablePlayData = {
  methodName: "StreamTablePlayData",
  service: Spoke,
  requestStream: false,
  responseStream: true,
  requestType: spoke_pb.StreamTablePlayDataRequest,
  responseType: spoke_pb.GetTablePlayDataReply
};

Spoke.NewPlay = {
  methodName: "NewPlay",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.NewPlayRequest,
  responseType: spoke_pb.NewPlayReply
};

Spoke.RunPlay = {
  methodName: "RunPlay",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.RunPlayRequest,
  responseType: spoke_pb.RunPlayReply
};

Spoke.GetDealerDeviceRequests = {
  methodName: "GetDealerDeviceRequests",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetDealerDeviceRequestsRequest,
  responseType: spoke_pb.GetDealerDeviceRequestsReply
};

Spoke.DealtInput = {
  methodName: "DealtInput",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.DealtInputRequest,
  responseType: spoke_pb.GetDealerDeviceRequestsReply
};

Spoke.DealerDeviceState = {
  methodName: "DealerDeviceState",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.DealerDeviceStateRequest,
  responseType: spoke_pb.DealerDeviceStateReply
};

Spoke.TestDepositMoney = {
  methodName: "TestDepositMoney",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.TestDepositMoneyRequest,
  responseType: spoke_pb.GetSelfReply
};

Spoke.EmailRegister = {
  methodName: "EmailRegister",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.EmailRegisterRequest,
  responseType: spoke_pb.LoginReply
};

Spoke.Login = {
  methodName: "Login",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.LoginRequest,
  responseType: spoke_pb.LoginReply
};

Spoke.GetSidebet = {
  methodName: "GetSidebet",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetSidebetRequest,
  responseType: spoke_pb.GetSidebetReply
};

Spoke.GetPaytable = {
  methodName: "GetPaytable",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetPaytableRequest,
  responseType: spoke_pb.GetPaytableReply
};

Spoke.FutureActionSet = {
  methodName: "FutureActionSet",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.FutureActionSetRequest,
  responseType: spoke_pb.FutureActionSetReply
};

Spoke.AddFavoriteBet = {
  methodName: "AddFavoriteBet",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.AddFavoriteBetRequest,
  responseType: spoke_pb.AddFavoriteBetResponse
};

Spoke.GetFavoriteBets = {
  methodName: "GetFavoriteBets",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.GetFavoriteBetsRequest,
  responseType: spoke_pb.GetFavoriteBetsResponse
};

Spoke.UpdateFavoriteBet = {
  methodName: "UpdateFavoriteBet",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.UpdateFavoriteBetRequest,
  responseType: spoke_pb.UpdateFavoriteBetResponse
};

Spoke.RemoveFavoriteBet = {
  methodName: "RemoveFavoriteBet",
  service: Spoke,
  requestStream: false,
  responseStream: false,
  requestType: spoke_pb.RemoveFavoriteBetRequest,
  responseType: spoke_pb.RemoveFavoriteBetResponse
};

exports.Spoke = Spoke;

function SpokeClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SpokeClient.prototype.getSelf = function getSelf(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetSelf, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.streamSelf = function streamSelf(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Spoke.StreamSelf, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SpokeClient.prototype.updateDisplayName = function updateDisplayName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.UpdateDisplayName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.updateImageUrl = function updateImageUrl(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.UpdateImageUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.updatePreferences = function updatePreferences(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.UpdatePreferences, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getRules = function getRules(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetRules, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getTables = function getTables(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetTables, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getTable = function getTable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetTable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getTableHistory = function getTableHistory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetTableHistory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.streamTable = function streamTable(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Spoke.StreamTable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SpokeClient.prototype.sitTable = function sitTable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.SitTable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.standTable = function standTable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.StandTable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.makeWagers = function makeWagers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.MakeWagers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.makeChoice = function makeChoice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.MakeChoice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getWagerHistory = function getWagerHistory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetWagerHistory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getPlay = function getPlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetPlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getTablePlayData = function getTablePlayData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetTablePlayData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.streamTablePlayData = function streamTablePlayData(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Spoke.StreamTablePlayData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SpokeClient.prototype.newPlay = function newPlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.NewPlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.runPlay = function runPlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.RunPlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getDealerDeviceRequests = function getDealerDeviceRequests(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetDealerDeviceRequests, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.dealtInput = function dealtInput(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.DealtInput, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.dealerDeviceState = function dealerDeviceState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.DealerDeviceState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.testDepositMoney = function testDepositMoney(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.TestDepositMoney, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.emailRegister = function emailRegister(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.EmailRegister, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.login = function login(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.Login, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getSidebet = function getSidebet(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetSidebet, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getPaytable = function getPaytable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetPaytable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.futureActionSet = function futureActionSet(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.FutureActionSet, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.addFavoriteBet = function addFavoriteBet(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.AddFavoriteBet, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.getFavoriteBets = function getFavoriteBets(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.GetFavoriteBets, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.updateFavoriteBet = function updateFavoriteBet(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.UpdateFavoriteBet, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SpokeClient.prototype.removeFavoriteBet = function removeFavoriteBet(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Spoke.RemoveFavoriteBet, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.SpokeClient = SpokeClient;

