import { ILocalStorageCacheOptions, LocalStorageCache } from './LocalStorageCache';
import { IAuthSessionData, IAuthSessionProvider } from './types/session';

/**
 * Authentication session provider. Stores the session token (and other information) to be passed along when making
 * calls to the server that require identity information.
 */
class AuthSessionProvider extends LocalStorageCache<IAuthSessionData> implements IAuthSessionProvider {
	constructor(storageKey?: Maybe<string>, opts?: ILocalStorageCacheOptions) {
		storageKey = storageKey || 'areax.session';
		super(storageKey, opts);
	}

	protected makeDefaults = (): IAuthSessionData => {
		return {
			...super.makeDefaults(),
			token: '',
		};
	};

	public get token(): string {
		return this.getDataProp<string>('token') ?? '';
	}

	public set token(token: string) {
		this.setDataProp<typeof token>('token', token);
	}

	public hasToken(): boolean {
		return this.token !== '';
	}
}

const instance = new AuthSessionProvider(null, { useStorage: true });

export { instance as default };
export { instance as AuthSession, AuthSessionProvider };

export type { IAuthSessionData, IAuthSessionProvider } from './types/session';
