// package: com.sands.live_dealer.hub
// file: dealing_device.proto

var dealing_device_pb = require("./dealing_device_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DealingDevice = (function () {
  function DealingDevice() {}
  DealingDevice.serviceName = "com.sands.live_dealer.hub.DealingDevice";
  return DealingDevice;
}());

DealingDevice.GetDealerDeviceRequests = {
  methodName: "GetDealerDeviceRequests",
  service: DealingDevice,
  requestStream: false,
  responseStream: false,
  requestType: dealing_device_pb.GetDealerDeviceRequestsRequest,
  responseType: dealing_device_pb.GetDealerDeviceRequestsReply
};

DealingDevice.StreamDealerDeviceRequests = {
  methodName: "StreamDealerDeviceRequests",
  service: DealingDevice,
  requestStream: false,
  responseStream: true,
  requestType: dealing_device_pb.GetDealerDeviceRequestsRequest,
  responseType: dealing_device_pb.GetDealerDeviceRequestsReply
};

DealingDevice.DeviceNewPlay = {
  methodName: "DeviceNewPlay",
  service: DealingDevice,
  requestStream: false,
  responseStream: false,
  requestType: dealing_device_pb.DeviceNewPlayRequest,
  responseType: dealing_device_pb.DeviceNewPlayResponse
};

DealingDevice.DealtInput = {
  methodName: "DealtInput",
  service: DealingDevice,
  requestStream: false,
  responseStream: false,
  requestType: dealing_device_pb.DealtInputRequest,
  responseType: dealing_device_pb.GetDealerDeviceRequestsReply
};

DealingDevice.DeviceRunPlay = {
  methodName: "DeviceRunPlay",
  service: DealingDevice,
  requestStream: false,
  responseStream: false,
  requestType: dealing_device_pb.DeviceRunPlayRequest,
  responseType: dealing_device_pb.DeviceRunPlayReply
};

DealingDevice.DevicePausePlay = {
  methodName: "DevicePausePlay",
  service: DealingDevice,
  requestStream: false,
  responseStream: false,
  requestType: dealing_device_pb.DevicePausePlayRequest,
  responseType: dealing_device_pb.DevicePausePlayReply
};

DealingDevice.UpdateDeviceShoe = {
  methodName: "UpdateDeviceShoe",
  service: DealingDevice,
  requestStream: false,
  responseStream: false,
  requestType: dealing_device_pb.UpdateDeviceShoeRequest,
  responseType: dealing_device_pb.UpdateDeviceShoeReply
};

DealingDevice.GetDealerDeviceByPlayId = {
  methodName: "GetDealerDeviceByPlayId",
  service: DealingDevice,
  requestStream: false,
  responseStream: false,
  requestType: dealing_device_pb.GetDealerDeviceByPlayIdRequest,
  responseType: dealing_device_pb.GetDealerDeviceByPlayIdReply
};

exports.DealingDevice = DealingDevice;

function DealingDeviceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DealingDeviceClient.prototype.getDealerDeviceRequests = function getDealerDeviceRequests(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DealingDevice.GetDealerDeviceRequests, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DealingDeviceClient.prototype.streamDealerDeviceRequests = function streamDealerDeviceRequests(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DealingDevice.StreamDealerDeviceRequests, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

DealingDeviceClient.prototype.deviceNewPlay = function deviceNewPlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DealingDevice.DeviceNewPlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DealingDeviceClient.prototype.dealtInput = function dealtInput(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DealingDevice.DealtInput, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DealingDeviceClient.prototype.deviceRunPlay = function deviceRunPlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DealingDevice.DeviceRunPlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DealingDeviceClient.prototype.devicePausePlay = function devicePausePlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DealingDevice.DevicePausePlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DealingDeviceClient.prototype.updateDeviceShoe = function updateDeviceShoe(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DealingDevice.UpdateDeviceShoe, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DealingDeviceClient.prototype.getDealerDeviceByPlayId = function getDealerDeviceByPlayId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DealingDevice.GetDealerDeviceByPlayId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DealingDeviceClient = DealingDeviceClient;

