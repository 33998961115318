import { Stream } from '../../client/core/Stream';
import { IStreamController, IStreamOptions, IValidateStartResult, StreamStatus } from '../../client/core/types';
import { SpokeClient } from '../../client/rpc/clients/spoke';
import { GetTableSeatsPlayDataReply } from '../../client/rpc/replies/spoke';
import { GetTableSeatsPlayDataRequest } from '../../client/rpc/requests/spoke';
import { TableSeatsPlayData } from '../../client/rpc/types/spoke';
import { ITableSeatsPlayDataStream, TableSeatsPlayDataStreamRequestProps } from './types';

type RequestProps = TableSeatsPlayDataStreamRequestProps;
type ValidateStartResult = IValidateStartResult<RequestProps> & {
	tableId: string;
};

class TableSeatsPlayDataStream
	extends Stream<SpokeClient, TableSeatsPlayData, RequestProps>
	implements ITableSeatsPlayDataStream
{
	/**
	 * CONSTRUCTOR.
	 *
	 * @param url   Stream service url to connect to.
	 * @param opts  Options to use when creating this stream.
	 */
	constructor(url: string, opts?: IStreamOptions) {
		super(url, opts);

		this.client = this.createStreamClient(SpokeClient, opts?.grpcOptions);
		this.streamClientListener = this.createStreamClientListener<GetTableSeatsPlayDataRequest>();
	}

	/**
	 * Attempt to start this stream.
	 *
	 * @returns TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *          connected and received data - you must subscribe to the stream to know that.
	 */
	public start(props?: Maybe<RequestProps>): boolean {
		const { tableId, isValid, requestProps } = this.validateStart(props);

		if (!isValid) {
			return false;
		}

		const currentTableId = this.lastRequestProps?.tableId || '';

		// Stream is already running
		if (this.isActive) {
			// Different play ID specified? Treat it as a restart
			if (tableId !== currentTableId) {
				return this.restart({ tableId });
			}

			console.warn(
				this.debugMsg(
					'Attempted to start an already running stream using the same Play ID. Use `restart` if this is intended',
					'start'
				),
				{ tableId }
			);

			return false;
		}

		// Manual starts will clear any auto-restart cycle that might be active
		this.clearAutoRestarts();
		this.currentState.status = StreamStatus.STARTING;

		const didRun = this.runStream(requestProps);
		if (didRun) {
			this.afterStart(requestProps);
		}

		return didRun;
	}

	/**
	 * Determines if we are allowed to start/restart this stream.
	 *
	 * @returns The result of the validation. This also includes the processed request props to apply.
	 */
	protected validateStart(requestProps?: Maybe<RequestProps>): ValidateStartResult {
		requestProps = requestProps ?? this.lastRequestProps ?? null;

		if (requestProps == null) {
			console.error(this.debugMsg('Request props must be specified', 'validate'));
			return { isValid: false, requestProps: null, tableId: '' };
		}

		const { tableId = '' } = requestProps;

		if (tableId == '') {
			console.error(this.debugMsg('A valid table ID must be specified', 'validateStart'));
			return { isValid: false, requestProps, tableId };
		}

		if (!this.isEnabled) {
			console.warn(this.debugMsg('Stream is disabled', 'validateStart'), { tableId });
			return { isValid: false, requestProps, tableId };
		}

		return { isValid: true, requestProps, tableId };
	}

	/**
	 * Creates and starts a new play data stream.
	 *
	 * @returns TRUE if successfully able to create and start the stream.
	 */
	protected runStream(requestProps?: Maybe<RequestProps>): boolean {
		const { tableId = '' } = requestProps ?? {};

		if (tableId === '') {
			console.error(this.debugMsg('Valid table ID must be specified', 'runStream'));
			return false;
		}

		this.dataStream = this.newStream(tableId);

		return true;
	}

	/**
	 * Starts a new table seats play data stream for the specified table ID.
	 *
	 * @param  tableId  Table ID to use when starting the stream.
	 */
	protected newStream = (tableId: string): IStreamController => {
		const request = new GetTableSeatsPlayDataRequest();
		request.setTableId(tableId);

		return this.stream<typeof request, GetTableSeatsPlayDataReply>('streamTablePlayData', request);
	};
}

export { TableSeatsPlayDataStream as default };
export { TableSeatsPlayDataStream };
