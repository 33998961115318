import { FutureAction } from 'grpc/spoke_game_pb';
import { Service } from '../../client/core/Service';
import { IServiceOptions } from '../../client/core/types/service';
import { SpokeClient } from '../../client/rpc/clients/spoke';
// ---- RPC reply classes ----
import {
	FutureActionSetReply,
	GetRulesReply,
	GetTableHistoryReply,
	GetTablePlayReply,
	GetTableReply,
	GetTableSeatsPlayDataReply,
	GetTablesReply,
	GetWagerHistoryReply,
	MakeChoiceReply,
	MakeWagersReply,
	SitTableReply,
	StandTableReply,
} from '../../client/rpc/replies/spoke';
// ---- RPC request classes ----
import {
	FutureActionSetRequest,
	GetRulesRequest,
	GetTableHistoryRequest,
	GetTablePlayRequest,
	GetTableRequest,
	GetTableSeatsPlayDataRequest,
	GetTablesRequest,
	GetWagerHistoryRequest,
	MakeChoiceRequest,
	MakeWagersRequest,
	SitTableRequest,
	StandTableRequest,
} from '../../client/rpc/requests/spoke';
// ---- RPC data types ----
import {
	FutureActionReplyData,
	MakeChoiceData,
	MakeWagersData,
	RulesReplyData,
	TableData,
	TableHistoryData,
	TablePlayReplyData,
	TablesData,
	TableSeatsPlayData,
	WagerHistoryData,
} from '../../client/rpc/types/spoke';
import { IGameService } from './types';

class GameService extends Service<SpokeClient> implements IGameService {
	constructor(url: string, opts?: IServiceOptions) {
		super(url, opts);
		this.client = this.createUnaryClient(SpokeClient, opts?.grpcOptions);
	}

	// ---- Table Actions -----------------------------------------------------------------------------------------------

	public makeChoice = async (request?: MakeChoiceRequest) => {
		return this.unaryAsObject<typeof request, MakeChoiceReply, MakeChoiceData>('makeChoice', request);
	};

	// ---- Future Actions -----------------------------------------------------------------------------------------------

	public makeFutureChoice = async (playId: number, playerId: number, seatNum: number, action: string) => {
		const futureAction = new FutureAction();
		futureAction.setPlayId(playId);
		futureAction.setPlayerId(playerId);
		futureAction.setSeatNum(seatNum);
		futureAction.setAction(action);

		const request = new FutureActionSetRequest();
		request.setFutureAction(futureAction);

		return this.unaryAsObject<typeof request, FutureActionSetReply, FutureActionReplyData>('futureActionSet', request);
	};

	// ---- Rules -------------------------------------------------------------------------------------------------------

	public getRules = async (rulesId: string) => {
		const request = new GetRulesRequest();
		request.setRulesId(rulesId);

		return this.unaryAsObject<typeof request, GetRulesReply, RulesReplyData>('getRules', request);
	};

	// ---- Wagers ------------------------------------------------------------------------------------------------------

	public getWagerHistory = async (perPage: number = 100, pageNum: number = 1) => {
		const request = new GetWagerHistoryRequest();
		request.setPerPage(perPage);
		request.setPageNum(pageNum);

		return this.unaryAsObject<typeof request, GetWagerHistoryReply, WagerHistoryData>('getWagerHistory', request);
	};

	public sendWagers = async (request: MakeWagersRequest) => {
		return this.unaryAsObject<typeof request, MakeWagersReply, MakeWagersData>('makeWagers', request);
	};

	// ---- Table -------------------------------------------------------------------------------------------------------

	public getTables = async () => {
		const request = new GetTablesRequest();

		return this.unaryAsObject<typeof request, GetTablesReply, TablesData>('getTables', request);
	};

	public getTable = async (tableId: string) => {
		const request = new GetTableRequest();
		request.setTableId(tableId);

		return this.unaryAsObject<typeof request, GetTableReply, TableData>('getTable', request);
	};

	public sitTable = async (tableId: string, seatNum: number) => {
		const request = new SitTableRequest();
		request.setTableId(tableId);
		request.setSeatNum(seatNum);

		return this.unaryAsObject<typeof request, SitTableReply, TableData>('sitTable', request);
	};

	public standTable = async (tableId: string, seatNum: number) => {
		const request = new StandTableRequest();
		request.setTableId(tableId);
		request.setSeatNum(seatNum);

		return this.unaryAsObject<typeof request, StandTableReply, TableData>('standTable', request);
	};

	public getTableHistory = async (tableId: string) => {
		const request = new GetTableHistoryRequest();
		request.setTableId(tableId);

		return this.unaryAsObject<typeof request, GetTableHistoryReply, TableHistoryData>('getTableHistory', request);
	};

	// ---- Play --------------------------------------------------------------------------------------------------------

	public getTablePlay = async (playId: number) => {
		const request = new GetTablePlayRequest();
		request.setPlayId(playId);

		return this.unaryAsObject<typeof request, GetTablePlayReply, TablePlayReplyData>('getPlay', request);
	};

	public getTableSeatsPlayData = async (tableId: string) => {
		const request = new GetTableSeatsPlayDataRequest();
		request.setTableId(tableId);

		return this.unaryAsObject<typeof request, GetTableSeatsPlayDataReply, TableSeatsPlayData>(
			'getTablePlayData',
			request
		);
	};
}

export { GameService as default };
export { GameService };
