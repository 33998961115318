/**********************************************************************************************************************
 * Rules related selectors
 *********************************************************************************************************************/
import { getRulesStore } from '../store';

const getRulesId = (): string => getRulesStore().rulesId;

// ---- Export --------------------------------------------------------------------------------------------------------

export { getRulesId };
