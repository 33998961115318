/**********************************************************************************************************************
 * Stores and manages play data (including history) for all the players seated at the table.
 *********************************************************************************************************************/
import { PlaySeatData, SeatActionData, TableSeatsPlayData } from '../client/rpc/types/spoke';
import { ITableSeatsPlayService } from '../client/service/types';
import { DataStore } from './DataStore';
import { ISeatWagerActions } from './types';

class TableSeatsPlayStore extends DataStore<ITableSeatsPlayService, TableSeatsPlayData> {
	// The table ID used to populate this store.
	// This is necessary because the dataset used to populate does not contain the table ID.
	protected _tableId: string = '';

	/**
	 * @returns The table ID used to populate this store.
	 */
	public get tableId(): string {
		return this._tableId;
	}

	/**
	 * The current play ID
	 */
	public get playId(): number {
		return this.data?.playId ?? 0;
	}

	/**
	 * Device state.
	 */
	public get deviceState(): string {
		return this.data?.deviceState ?? '';
	}

	/**
	 * @returns List of seats with actions and possible actions.
	 */
	public get seatsList(): PlaySeatData[] {
		return this.data?.seatsList ?? [];
	}

	/**
	 * @returns Total number of seats.
	 */
	public get totalSeats(): number {
		return this.seatsList.length;
	}

	/**
	 * @returns Total number of players represented in the data.
	 */
	public get totalUsers(): number {
		const seats = this.seatsList.slice();

		if (seats.length === 0) {
			return 0;
		}

		const seat = seats[0];
		const actions = seat.actionsList.slice();

		return actions.reduce((count: number, current: SeatActionData) => current.count, 0);
	}

	/**
	 * @returns A map of the number of choices per action.
	 */
	public get choiceCounts(): Map<string, number> {
		const seats = this.seatsList.slice();
		const choices: Map<string, number> = new Map<string, number>();

		if (seats.length === 0) {
			return choices;
		}

		seats.forEach((seat) => {
			const actions = seat.actionsList.slice();

			actions.forEach((entry) => {
				const action = entry.action;
				const count = choices.get(action) ?? 0;
				choices.set(action, count + entry.count);
			});
		});

		return choices;
	}

	/**
	 * @returns Wager information per seat.
	 */
	public get tableWagerInfo(): ISeatWagerActions {
		const actions: ISeatWagerActions = {};

		this.seatsList.forEach((seat) => {
			const possible = seat.actionsPossible;

			seat.actionsList.forEach((entry) => {
				const action = entry.action;
				const count = entry.count;

				if (actions[action] !== undefined) {
					this.debugInfo('Overwriting wager action info:', 'tableWagerInfo', action);
				}

				const percent = possible > 0 ? Math.round((count / possible) * 100) : 0;
				actions[action] = { count, percent };
			});
		});

		return actions;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
		this._tableId = '';
	}

	// ---- Populate ----------------------------------------------------------------------------------------------------

	/**
	 * Action. Populates the store (via unary RPC service call) using the specified play ID.
	 */
	public async populate(tableId: string): Promise<boolean> {
		if (tableId === '') {
			this.debugError('A valid table ID must be specified', 'populate');
		}

		const success = await super.populate(tableId);
		if (success) {
			this._tableId = tableId;
		}

		return success;
	}

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(tableId: string): Promise<Nullable<TableSeatsPlayData>> {
		if (!this.service) {
			this.debugError('No service was specified', 'fetchPopulateData');
			return null;
		}

		if (tableId === '') {
			this.debugError('A valid table ID must be specified', 'fetchPopulateData');
		}

		return (await this.service.getTableSeatsPlayData(tableId)).data ?? null;
	}
}

export { TableSeatsPlayStore as default };
export { TableSeatsPlayStore };
