import debounce from 'lodash/debounce';
import { RpcDataStore } from 'server/lib-rpc';
import { ITableService, IWagerService } from 'server/lib-rpc';
import { RpcDataStoreAddKeys } from 'server/lib-rpc';
import { TablePlayReplyData, WagerHistoryData } from 'server/lib-rpc';
import { HistoryPlayData, WagerHistory } from 'server/types/wagerHistory';
import { makeHistoryDataDisplay, makeHistoryPlayData, sortHistoryEntries } from 'server/utility/gameHistory';
import { action, computed, makeObservable, observable } from 'helpers/mobx';

type StorePrivateProps =
	| 'page'
	| 'pageSize'
	| 'playData'
	| 'tableService'
	| 'loadNextPage'
	| 'loadPreviousPage'
	| 'fetchPopulateData'
	| 'fetchPopulatePlayData';

type StoreMobXKeys = RpcDataStoreAddKeys | StorePrivateProps;

class GameHistoryStore extends RpcDataStore<IWagerService, WagerHistoryData> {
	protected page: number = 1;
	protected pageSize: number = 100;
	protected playData: Nullable<HistoryPlayData> = null;
	protected tableService: ITableService;

	constructor(service: IWagerService, tableService: ITableService) {
		super(service);

		makeObservable<GameHistoryStore, StoreMobXKeys>(this, {
			// ---- Inherited from DataStore --------------------------------------------------------------------------------

			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			isPopulated: computed,
			lastUpdatedTs: computed,

			// Actions
			setData: action,

			// ---- Local ---------------------------------------------------------------------------------------------------

			page: observable,
			pageSize: observable,
			playData: observable,
			currentPage: computed,
			itemsPerPage: computed,
			currentPlayData: computed,
			historyData: computed,

			// Actions
			loadNextPage: action,
			loadPreviousPage: action,
			populate: action,
			populatePlayData: action,

			// Non-annotated
			nextPage: false,
			previousPage: false,
			refresh: false,
			loadPlayData: false,
			tableService: false,
			fetchPopulateData: false,
			fetchPopulatePlayData: false,
		});

		this.tableService = tableService;
	}

	/**
	 * @returns Current page number.
	 */
	public get currentPage(): number {
		return this.page;
	}

	/**
	 * @returns Items per page.
	 */
	public get itemsPerPage(): number {
		return this.pageSize;
	}
	public set itemsPerPage(count: number) {
		this.pageSize = count;
	}

	/**
	 * @returns Play data.
	 */
	public get currentPlayData(): Nullable<HistoryPlayData> {
		return this.playData;
	}

	/**
	 * @returns History data.
	 */
	public get historyData(): WagerHistory[] {
		const data = makeHistoryDataDisplay(this.data?.wagersList || []);
		if (!data) {
			return [];
		}

		const sorted = new Map(Array.from(data.entries()).sort(sortHistoryEntries));

		return Array.from(sorted.values());
	}

	/**
	 * Action. Loads the next page of historical wager data.
	 */
	protected loadNextPage = () => {
		if (this.historyData.length === 0) {
			return;
		}

		this.populate(this.page + 1);
	};

	/**
	 * Action. Loads the previous page of historical data.
	 */
	protected loadPreviousPage = () => {
		if (this.page === 1) {
			return;
		}

		this.populate(this.page - 1);
	};

	/**
	 * Debounced version of `loadNextPage`.
	 */
	public nextPage = debounce(this.loadNextPage, 250, { leading: false, trailing: true });

	/**
	 * Debounced version of `loadPreviousPage`.
	 */
	public previousPage = debounce(this.loadPreviousPage, 250, { leading: false, trailing: true });

	/**
	 * Debounced version of `populatePlayData`.
	 */
	public loadPlayData = debounce(
		(playId: number) => {
			this.populatePlayData(playId);
		},
		250,
		{ leading: false, trailing: true }
	);

	/**
	 * Debounced. Re-populates using the specified page number if provided, otherwise defaults to the current page.
	 */
	public refresh = debounce(
		(page?: Maybe<number>) => {
			page = page ?? this.page;
			this.populate(page);
		},
		250,
		{ leading: false, trailing: true }
	);

	// ---- Populate ----------------------------------------------------------------------------------------------------

	/**
	 * Action. Populate the store with wager data for the specified page.
	 */
	public async populate(page: number): Promise<boolean> {
		if (page < 1) {
			this.debugError('A valid page number must be specified', 'populate');
			return false;
		}

		return super.populate(page);
	}

	/**
	 * Action. Populate the store with play data for the specified play ID.
	 */
	public async populatePlayData(playId: number): Promise<boolean> {
		if (playId < 0) {
			this.debugError('A valid play id must be specified', 'populatePlayData');
			return false;
		}

		if (!this.service) {
			this.debugError('Unable to populate, play service not specified', 'populatePlayData');
			return false;
		}

		if (this.playData?.playId === playId) {
			this.debugInfo('Requested the same play that is currently loaded', 'populatePlayData', { playId });
			return false;
		}

		try {
			const playData = await this.fetchPopulatePlayData(playId);
			this.playData = makeHistoryPlayData(playData?.play ?? null);
		} catch (err) {
			this.debugError('Unable to populate play data due to an error:', 'populatePlayData', { error: err as Error });
			return false;
		}

		return true;
	}

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(page: number): Promise<Nullable<WagerHistoryData>> {
		if (!this.service) {
			this.debugError('No service was specified', 'fetchServiceData');
			return null;
		}

		return (await this.service.getWagerHistory(this.pageSize, page)).data ?? null;
	}

	/**
	 * Gets data from the play service in order to populate the store play data.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulatePlayData(playId: number): Promise<Nullable<TablePlayReplyData>> {
		if (!this.tableService) {
			this.debugError('No play service was specified', 'fetchPlayData');
			return null;
		}

		return (await this.tableService.getTablePlay(playId)).data ?? null;
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { GameHistoryStore as default };
export { GameHistoryStore };
