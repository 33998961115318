import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import hash from 'object-hash';

const getObjectValue = <T extends object, U extends keyof T>(obj: T, key: U) => obj[key];
const entries = <T>(obj: { [s: string]: T } | ArrayLike<T>): [string, T][] => Object.entries(obj);
const keys = (obj: object): string[] => Object.keys(obj);

const isObjectEmpty = (obj?: Nullable<AnyObject>): boolean => {
	if (obj == null) {
		return true;
	}

	if (isPlainObject(obj) && isEmpty(obj)) {
		return true;
	}

	return isEmpty(obj);
};

const makeSimpleObjectHash = (obj: Nullable<AnyObject>): Nullable<string> => {
	if (isObjectEmpty(obj)) {
		return null;
	}

	return hash.MD5(obj);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { getObjectValue, isObjectEmpty, entries, keys, makeSimpleObjectHash };
