/**********************************************************************************************************************
 * Local storage adapter used for Favorite Bets data.
 *********************************************************************************************************************/
import { FavoriteBets, FavoriteBetsIndex, IFavoriteBetData } from '../types';
import { resolveFavoriteBets as resolveBets, resolveFavoriteBetData } from '../utility';

class FavoriteBetsLocalStorage {
	private storageKey = 'areax.favorite_bets.baccarat_basic';
	private cachedItems: Nullable<FavoriteBets> = [];
	private cachedIndex: FavoriteBetsIndex = new Map();

	/**
	 * CONSTRUCTOR.
	 */
	constructor() {
		// Load stored data into cache
		this.updateCacheData(this.storedBets);
	}

	private get storage(): Storage {
		return globalThis.sessionStorage;
	}

	private get storedData(): Nullable<string> {
		return this.storage.getItem(this.storageKey) || null;
	}

	private set storedData(value: Nullable<string>) {
		this.storage.setItem(this.storageKey, value ?? '');
	}

	private get storedBets(): FavoriteBets {
		return JSON.parse(this.storedData ?? '[]') as FavoriteBets;
	}

	private set storedBets(data: Nullable<FavoriteBets>) {
		this.storedData = JSON.stringify(data || []);
	}

	private updateCacheData(bets: Nullable<FavoriteBets>): FavoriteBets {
		const { betsList, betsIndex } = resolveBets(bets);

		this.cachedItems = betsList;
		this.cachedIndex = betsIndex;

		return this.cachedItems;
	}

	public get items(): FavoriteBets {
		if (this.cachedItems == null) {
			this.updateCacheData(this.storedBets);
		}

		return this.cachedItems ?? [];
	}

	public set items(betsData: Nullable<FavoriteBets>) {
		this.storedBets = this.updateCacheData(betsData);
	}

	public itemExists(id: string): boolean {
		return id !== '' && this.cachedIndex.has(id);
	}

	public add(favData: IFavoriteBetData): boolean {
		const newItem = favData.id ? favData : resolveFavoriteBetData(favData);
		if (this.itemExists(newItem.id || '')) {
			return false;
		}

		this.items = [...this.items, newItem];

		return true;
	}

	public remove(id: string): boolean {
		if (!this.itemExists(id)) {
			return false;
		}

		this.items = this.items.slice().filter((item) => item.id !== id);

		return true;
	}

	public replace(id: string, favData: IFavoriteBetData): boolean {
		const pos = this.cachedIndex.get(id) ?? -1;
		if (pos < 0) {
			return false;
		}

		const items = this.items;
		const newFav = id !== favData.id || '' ? resolveFavoriteBetData(favData) : favData;
		const currentFav = items[pos];
		const newItem = { ...currentFav, ...newFav };
		const newItems = items.slice();
		newItems.splice(pos, 1, newItem);

		this.items = newItems;

		return true;
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { FavoriteBetsLocalStorage as default };
export { FavoriteBetsLocalStorage };
