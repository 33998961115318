/**********************************************************************************************************************
 * Stores and manages data regarding user-controlled game settings.
 *********************************************************************************************************************/
import { RpcSettingsStoreMobXKeys } from 'server/lib-rpc';
import { RpcSettingsStore } from 'server/lib-rpc';
import { action, computed, makeObservable, observable } from 'helpers/mobx';

class SettingsStore extends RpcSettingsStore {
	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		super();

		// Note: We cannot use `makeAutoObservable` because this class extends a parent class. So we are declaring the
		// annotations right here instead. We can probably make this better in the future by carefully applying observables
		// on the parent class or via some other technique.. but for now, just roll with it.
		makeObservable<SettingsStore, RpcSettingsStoreMobXKeys>(this, {
			// ---- Inherited from RpcSettingsStore -------------------------------------------------------------------------

			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			lastUpdatedTs: computed,
			videoSetting: computed,
			dealerVolume: computed,
			gameVolume: computed,
			ambientVolume: computed,
			isChatEnabled: computed,
			isChatFilterEnabled: computed,

			// Actions
			toggleChatEnabled: action,
			toggleChatFilterEnabled: action,

			// ---- Local ---------------------------------------------------------------------------------------------------

			clear: action,
		});
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { SettingsStore as default };
export { SettingsStore };
