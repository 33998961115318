const DEFAULT_EXPIRY_SECS = 24 * 60 * 60;

enum StorageType {
	LOCAL = 'LOCAL',
	SESSION = 'SESSION',
}

interface ILocalStorageCacheData extends PlainObject {
	// When this session will expire
	expirationTs?: number;
}

interface ILocalStorageCache<T> {
	readonly data: Nullable<T>;
	readonly expirationTs: number;
	reset: () => void;
	clear: () => void;
	refreshExpiry: () => void;
	hasExpired: () => boolean;
}

interface ILocalStorageCacheOptions {
	// Seconds after which the session storage is considered to be expired
	expirySecs?: number;
	// Storage type (local or session)
	storageType?: StorageType;
	// Whether or not to use local storage or just act as a memory store
	useStorage?: boolean;
}

/**
 * This will be used as the base for any storage provider that needs to use any type of local storage.
 */
abstract class LocalStorageCache<T extends ILocalStorageCacheData> implements ILocalStorageCache<T> {
	// Storage key to use when placing the data in local storage
	private storageKey: string = '';

	// When the data is considered expired. Expired data will automatically get thrown away and defaults returned.
	private expirySecs: number = DEFAULT_EXPIRY_SECS;

	// Memory data cache. Local storage is updated shortly after this is changed.
	private cachedData: Nullable<T> = null;

	// If this is FALSE then only the memory cache storage is used. Useful for unit tests.
	private useStorage: boolean = false;

	// What type of storage to use (ie. Local or Session)
	private storageType: StorageType = StorageType.SESSION;

	/**
	 * CONSTRUCTOR
	 * @param storageKey
	 * @param opts
	 */
	constructor(storageKey: string, opts?: ILocalStorageCacheOptions) {
		if (storageKey === '') {
			throw new Error('A storage key must be specified');
		}

		const expirySecs = opts?.expirySecs ?? 0;

		this.storageKey = storageKey || this.storageKey;
		this.expirySecs = expirySecs > 0 ? expirySecs : this.expirySecs;
		this.useStorage = opts?.useStorage ?? this.useStorage;
		this.storageType = opts?.storageType || this.storageType;

		this.loadCache();
	}

	/**
	 * Get all of the data currently stored for the local storage key.
	 */
	public get data(): Nullable<T> {
		if (this.cachedData == null) {
			return null;
		}

		// If the data has expired then reset it before returning
		if (this.hasDataExpired(this.cachedData)) {
			this.reset();
		}

		return this.cachedData;
	}

	/**
	 * Returns the UTC unix timestamp for the time at which when the cached data is considered to be expired.
	 */
	public get expirationTs(): number {
		return this.cachedData?.expirationTs || 0;
	}

	/**
	 * @returns TRUE if the data is considered to be expired.
	 */
	public hasExpired(): boolean {
		return this.hasDataExpired();
	}

	/**
	 * Keep the cache alive.
	 */
	public refreshExpiry() {
		this.update(this.cachedData, true);
	}

	/**
	 * Resets the cached data and relevant local storage back to default values.
	 */
	public reset = () => {
		this.updateCache(this.makeDefaults());
	};

	/**
	 * Clears (deletes) the cached data and relevant local storage.
	 */
	public clear = () => {
		this.updateCache(null);
	};

	protected update(data: Nullable<T>, refreshExpirationTs: boolean = true) {
		if (data != null && refreshExpirationTs) {
			data = { ...data, expirationTs: this.newExpirationTs() };
		}

		this.updateCache(data);
	}

	protected newExpirationTs = (expirySecs?: Maybe<number>) => {
		expirySecs = expirySecs ?? 0;
		return Date.now() + (expirySecs > 0 ? expirySecs : this.expirySecs) * 1000;
	};

	protected hasDataExpired(data?: Nullable<T>): boolean {
		data = data ?? this.cachedData;
		return (data?.expirationTs || 0) <= Date.now();
	}

	protected get storedData(): Nullable<T> {
		const value = (this.useStorage && this.storage.getItem(this.storageKey)) || '';
		return (value ? (JSON.parse(value) as T) : null) || null;
	}

	protected set storedData(value: Nullable<T>) {
		if (!this.useStorage) {
			return;
		}

		if (value == null) {
			this.storage.removeItem(this.storageKey);
		}

		this.storage.setItem(this.storageKey, JSON.stringify(value));
	}

	protected get storage(): Storage {
		return this.storageType === StorageType.LOCAL ? this.localStorage : this.sessionStorage;
	}

	protected get sessionStorage(): Storage {
		return globalThis.sessionStorage;
	}

	protected get localStorage(): Storage {
		return globalThis.localStorage;
	}

	protected updateCache = (data: Nullable<T>, saveToStorage: boolean = true): Nullable<T> => {
		this.cachedData = data;
		saveToStorage && this.syncToStorage();

		return this.cachedData;
	};

	protected makeDefaults(): T {
		return { expirationTs: this.newExpirationTs() } as T;
	}

	protected loadCache = () => {
		const data = { ...this.makeDefaults(), ...(this.storedData || {}) };

		if (this.hasDataExpired(data)) {
			this.reset();
			return;
		}

		this.updateCache(data, false);
	};

	protected expiryCheck = (): void => {
		if (this.hasDataExpired(this.cachedData)) {
			this.reset();
		}
	};

	protected getDataProp = <V = unknown>(key: string): Nullable<V> => {
		const data = this.data;
		const value = (data ? data[key] : null) ?? null;

		return value as Nullable<V>;
	};

	protected setDataProp = <V = unknown>(key: string, value: V): void => {
		const newData = { ...(this.data || {}), [key]: value } as T;
		this.update(newData);
	};

	protected syncToStorage = () => {
		this.storedData = this.cachedData;
	};
}

export { LocalStorageCache as default };
export { LocalStorageCache };
export type { StorageType, ILocalStorageCacheData, ILocalStorageCache, ILocalStorageCacheOptions };
