import { pad } from './string';

const getNowTs = (): number => new Date().getTime();
const getNowUnix = (): number => Date.now();

const timeRemaining = (ms?: number) => {
	ms = ms ?? 0;

	if (ms <= 0) {
		return '0:00';
	}

	const seconds = Math.floor(ms / 1000) % 60;
	const minutes = Math.floor(seconds / 60) % 60;
	const hours = Math.floor(minutes / 60);

	let result = '';

	hours && (result += `${pad(hours)}:`);
	minutes && (result += `${pad(minutes)}:`);
	seconds && (result += `${pad(seconds)}`);

	return result;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { getNowTs, getNowUnix, timeRemaining };
