import { ChatServiceClient } from 'rpc/clients/chat';
import { ChatMessage, StreamChatsRequest } from 'rpc/requests/chat';
import { Stream } from '../core/Stream';
import { IStreamController, IStreamOptions, IValidateStartResult, StreamStatus } from '../core/types';
import { ChatStreamRequestProps, IChatStream } from './types';

type RequestProps = ChatStreamRequestProps;
type ValidateStartResult = IValidateStartResult<RequestProps> & {
	userId: string;
};

class ChatDataStream extends Stream<ChatServiceClient, ChatMessage, RequestProps> implements IChatStream {
	/**
	 * CONSTRUCTOR.
	 *
	 * @param url   Stream service url to connect to.
	 * @param opts  Options to use when creating this stream.
	 */
	constructor(url: string, opts?: IStreamOptions) {
		super(url, opts);

		this.client = this.createStreamClient(ChatServiceClient, opts?.grpcOptions);
		this.streamClientListener = this.createStreamClientListener<ChatMessage>();
	}

	/**
	 * Attempt to start this stream.
	 *
	 * @returns TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *          connected and received data - you must subscribe to the stream to know that.
	 */
	public start = (props?: Maybe<RequestProps>): boolean => {
		const { isValid } = this.validateStart(props);

		if (!isValid) {
			return false;
		}

		if (this.isActive) {
			console.warn(
				this.debugMsg('Attempted to start an already running stream. Use `restart` if this is intended', 'start')
			);

			return false;
		}

		// Manual starts will clear any auto-restart cycle that might be active
		this.clearAutoRestarts();
		this.currentState.status = StreamStatus.STARTING;

		const didRun = this.runStream(props);
		if (didRun) {
			this.afterStart();
		}

		return didRun;
	};

	/**
	 * Determines if we are allowed to start/restart this stream.
	 *
	 * @returns The result of the validation. This also includes the processed request props to apply.
	 */
	protected validateStart(requestProps?: Maybe<RequestProps>): ValidateStartResult {
		requestProps = requestProps ?? this.lastRequestProps ?? null;

		if (requestProps == null) {
			console.error(this.debugMsg('Request props must be specified', 'validateStart'));
			return { isValid: false, requestProps: null, userId: '' };
		}

		const { userId = '' } = requestProps;

		if (userId === '') {
			console.error(this.debugMsg('Table ID must be specified', 'validateStart'));
			return { isValid: false, requestProps, userId: '' };
		}

		if (!this.isEnabled) {
			console.warn(this.debugMsg('Stream is disabled', 'validateStart'), { userId });
			return { isValid: false, requestProps, userId };
		}

		return { isValid: true, requestProps, userId };
	}

	/**
	 * Creates and starts a new chat stream.
	 *
	 * @returns TRUE if successfully able to create and start the stream.
	 */
	protected runStream(requestProps: Maybe<RequestProps>): boolean {
		if (requestProps) {
			this.dataStream = this.newStream(requestProps.userId);
			return true;
		}
		console.warn(this.debugMsg('request props (userId) not found for chat stream restart'));
		return false;
	}

	/**
	 * Starts a new chat data stream.
	 */
	protected newStream = (userId: string): IStreamController => {
		const request = new StreamChatsRequest();
		request.setUserId(userId);
		return this.stream<typeof request, ChatMessage>('streamChats', request);
	};
}

export { ChatDataStream as default };
export { ChatDataStream };
