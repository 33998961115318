/**********************************************************************************************************************
 * Creates and initializes the various player data stores that underpin this application. Does NOT include the game store.
 *********************************************************************************************************************/
import {
	IRulesService,
	IStoreBase,
	ITableSeatsPlayService,
	ITableService,
	IUserService,
	IWagerService,
} from '../lib-rpc';
import { getGameService, getUserService } from '../services';
import { StoreKey, StoreKeys } from './constants';
import {
	DragonTigerTableHistoryStore,
	FavoriteBetsStore,
	GameHistoryStore,
	PlayStore,
	RulesStore,
	SettingsStore,
	TableSeatsPlayStore,
	TableStore,
	UserStore,
} from './storeClasses/index.mainStores';

const newUserStore = (service?: Maybe<IUserService>): UserStore => {
	return new UserStore(service ?? getUserService());
};

const newTableStore = (service?: Maybe<ITableService>): TableStore => {
	return new TableStore(service ?? getGameService());
};

const newTableSeatsPlayStore = (service?: Maybe<ITableSeatsPlayService>): TableSeatsPlayStore => {
	return new TableSeatsPlayStore(service ?? getGameService());
};

const newPlayStore = (service?: Maybe<ITableService>): PlayStore => {
	return new PlayStore(service ?? getGameService());
};

const newRulesStore = (service?: Maybe<IRulesService>): RulesStore => {
	return new RulesStore(service ?? getGameService());
};

const newSettingsStore = (): SettingsStore => {
	return new SettingsStore();
};

const newFavoriteBetsStore = (): FavoriteBetsStore => {
	return new FavoriteBetsStore();
};

const newTableHistoryStore = (service?: Maybe<ITableService>): DragonTigerTableHistoryStore => {
	return new DragonTigerTableHistoryStore(service ?? getGameService());
};

const newGameHistoryStore = (service?: Maybe<IWagerService>, tableService?: Maybe<ITableService>): GameHistoryStore => {
	return new GameHistoryStore(service ?? getGameService(), tableService ?? getGameService());
};

// ----- Store instances ----------------------------------------------------------------------------------------------

let _favoriteBetsStore: FavoriteBetsStore;
let _gameHistoryStore: GameHistoryStore;
let _tableHistoryStore: DragonTigerTableHistoryStore;
let _playStore: PlayStore;
let _rulesStore: RulesStore;
let _settingsStore: SettingsStore;
let _tableStore: TableStore;
let _tableSeatsPlayStore: TableSeatsPlayStore;
let _userStore: UserStore;

// ---- Initialize ----------------------------------------------------------------------------------------------------

let _isInitialized = false;

const makeStores = () => {
	_favoriteBetsStore = newFavoriteBetsStore();
	_gameHistoryStore = newGameHistoryStore();
	_tableHistoryStore = newTableHistoryStore();
	_playStore = newPlayStore();
	_rulesStore = newRulesStore();
	_settingsStore = newSettingsStore();
	_tableStore = newTableStore();
	_tableSeatsPlayStore = newTableSeatsPlayStore();
	_userStore = newUserStore();
};

const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	makeStores();

	_isInitialized = true;

	return true;
};

// ---- Utility -------------------------------------------------------------------------------------------------------

const getFavoriteBetsStore = (): FavoriteBetsStore => _favoriteBetsStore;
const getGameHistoryStore = (): GameHistoryStore => _gameHistoryStore;
const getTableHistoryStore = (): DragonTigerTableHistoryStore => _tableHistoryStore;
const getPlayStore = (): PlayStore => _playStore;
const getRulesStore = (): RulesStore => _rulesStore;
const getSettingsStore = (): SettingsStore => _settingsStore;
const getTableStore = (): TableStore => _tableStore;
const getTableSeatsPlayStore = (): TableSeatsPlayStore => _tableSeatsPlayStore;
const getUserStore = (): UserStore => _userStore;

const setFavoriteBetsStore = (val: FavoriteBetsStore) => (_favoriteBetsStore = val);
const setGameHistoryStore = (val: GameHistoryStore) => (_gameHistoryStore = val);
const setTableHistoryStore = (val: DragonTigerTableHistoryStore) => (_tableHistoryStore = val);
const setPlayStore = (val: PlayStore) => (_playStore = val);
const setRulesStore = (val: RulesStore) => (_rulesStore = val);
const setSettingsStore = (val: SettingsStore) => (_settingsStore = val);
const setTableStore = (val: TableStore) => (_tableStore = val);
const setTableSeatsPlayStore = (val: TableSeatsPlayStore) => (_tableSeatsPlayStore = val);
const setUserStore = (val: UserStore) => (_userStore = val);

const clearMultipleStores = (storeKeys: StoreKey[]) => {
	storeKeys.forEach((storeKey: StoreKey) => clearStoreByKey(storeKey));
};

const clearAllStores = () => clearMultipleStores(StoreKeys);

const clearStoreByKey = (storeKey: StoreKey): boolean => {
	const store = getStoreByKey(storeKey);
	if (store == null) {
		return false;
	}

	store.clear();

	return true;
};

const getStoreByKey = (storeKey: StoreKey): Nullable<IStoreBase> => {
	switch (storeKey) {
		case StoreKey.FavoriteBetsStore:
			return _favoriteBetsStore;
		case StoreKey.GameHistoryStore:
			return _gameHistoryStore;
		case StoreKey.TableHistoryStore:
			return _tableHistoryStore;
		case StoreKey.PlayStore:
			return _playStore;
		case StoreKey.RulesStore:
			return _rulesStore;
		case StoreKey.SettingsStore:
			return _settingsStore;
		case StoreKey.TableSeatsPlayStore:
			return _tableSeatsPlayStore;
		case StoreKey.TableStore:
			return _tableStore;
		case StoreKey.UserStore:
			return _userStore;
		default:
			return null;
	}
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initStores };

export {
	getFavoriteBetsStore,
	getGameHistoryStore,
	getTableHistoryStore,
	getPlayStore,
	getRulesStore,
	getUserStore,
	getSettingsStore,
	getTableStore,
	getTableSeatsPlayStore,
	getStoreByKey,
};

export {
	setFavoriteBetsStore,
	setGameHistoryStore,
	setTableHistoryStore,
	setPlayStore,
	setRulesStore,
	setUserStore,
	setSettingsStore,
	setTableStore,
	setTableSeatsPlayStore,
};

export {
	newTableStore,
	newTableSeatsPlayStore,
	newPlayStore,
	newRulesStore,
	newSettingsStore,
	newFavoriteBetsStore,
	newTableHistoryStore,
	newGameHistoryStore,
	newUserStore,
};

export { clearMultipleStores, clearAllStores };

// Dealer
export * from './stores.dealer';

// Export the store classes
export * from './storeClasses/index.mainStores';
