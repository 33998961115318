// package: com.sands.live_dealer.hub
// file: hub.proto

var hub_pb = require("./hub_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Hub = (function () {
  function Hub() {}
  Hub.serviceName = "com.sands.live_dealer.hub.Hub";
  return Hub;
}());

Hub.GetRules = {
  methodName: "GetRules",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.GetRulesRequest,
  responseType: hub_pb.GetRulesReply
};

Hub.GetRulesSet = {
  methodName: "GetRulesSet",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.GetRulesSetRequest,
  responseType: hub_pb.GetRulesSetReply
};

Hub.GetTable = {
  methodName: "GetTable",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.GetTableRequest,
  responseType: hub_pb.GetTableReply
};

Hub.GetTables = {
  methodName: "GetTables",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.GetTablesRequest,
  responseType: hub_pb.GetTablesReply
};

Hub.ClaimSeat = {
  methodName: "ClaimSeat",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.ClaimSeatRequest,
  responseType: hub_pb.GetTableReply
};

Hub.UnclaimSeat = {
  methodName: "UnclaimSeat",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.UnclaimSeatRequest,
  responseType: hub_pb.GetTableReply
};

Hub.NewPlay = {
  methodName: "NewPlay",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.NewPlayRequest,
  responseType: hub_pb.GetTableReply
};

Hub.GetPlay = {
  methodName: "GetPlay",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.GetPlayRequest,
  responseType: hub_pb.GetPlayReply
};

Hub.SpokeInput = {
  methodName: "SpokeInput",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.SpokeInputRequest,
  responseType: hub_pb.GetPlayReply
};

Hub.UpdateDeviceState = {
  methodName: "UpdateDeviceState",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.UpdateDeviceStateRequest,
  responseType: hub_pb.UpdateDeviceStateReply
};

Hub.GetIncompletePlayIds = {
  methodName: "GetIncompletePlayIds",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.GetIncompletePlayIdsRequest,
  responseType: hub_pb.GetIncompletePlayIdsReply
};

Hub.RegisterSpoke = {
  methodName: "RegisterSpoke",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.RegisterSpokeRequest,
  responseType: hub_pb.RegisterSpokeReply
};

Hub.GetSpokeUpdates = {
  methodName: "GetSpokeUpdates",
  service: Hub,
  requestStream: false,
  responseStream: false,
  requestType: hub_pb.GetSpokeUpdatesRequest,
  responseType: hub_pb.GetSpokeUpdatesReply
};

exports.Hub = Hub;

function HubClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

HubClient.prototype.getRules = function getRules(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.GetRules, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.getRulesSet = function getRulesSet(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.GetRulesSet, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.getTable = function getTable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.GetTable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.getTables = function getTables(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.GetTables, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.claimSeat = function claimSeat(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.ClaimSeat, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.unclaimSeat = function unclaimSeat(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.UnclaimSeat, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.newPlay = function newPlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.NewPlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.getPlay = function getPlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.GetPlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.spokeInput = function spokeInput(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.SpokeInput, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.updateDeviceState = function updateDeviceState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.UpdateDeviceState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.getIncompletePlayIds = function getIncompletePlayIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.GetIncompletePlayIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.registerSpoke = function registerSpoke(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.RegisterSpoke, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HubClient.prototype.getSpokeUpdates = function getSpokeUpdates(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Hub.GetSpokeUpdates, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.HubClient = HubClient;

