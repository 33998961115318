/**********************************************************************************************************************
 * Handles player stream subscriptions and various resulting data flow.
 *********************************************************************************************************************/
import { clearSession } from './actions';
import { ServerErrorCodes } from './constants';
import { StreamKey } from './constants';
import { SelfData, TableData, TableSeatsPlayData } from './lib-rpc';
import { streamManager } from './lib-rpc';
import { getStores } from './store';

const setupUserStreamSubscriptions = () => {
	streamManager.subscribe<SelfData>(StreamKey.UserStream, {
		onData: (data: SelfData) => {
			const { userStore } = getStores();
			data.success && data.playerId > 0 && userStore.setData(data);
		},
		onError: (error) => {
			if (error.code === ServerErrorCodes.SESSION_NOT_FOUND) {
				clearSession();
				globalThis.location.href = '/login';
				return;
			}
		},
	});
};

const setupTableStreamSubscriptions = () => {
	streamManager.subscribe<TableData>(StreamKey.TableStream, {
		onData: (data: TableData) => {
			const { tableStore } = getStores();
			if (!data.success && data.table == null) {
				return;
			}

			tableStore.setData(data);
		},
	});
};

const setupTableSeatsPlayStreamSubscriptions = () => {
	streamManager.subscribe<TableSeatsPlayData>(StreamKey.TableSeatsPlayStream, {
		onData: (data: TableSeatsPlayData) => {
			const { tableSeatsPlayStore } = getStores();
			data.success && tableSeatsPlayStore.setData(data);
		},
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

let _isInitialized = false;

const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	if (!streamManager.hasStreams()) {
		return false;
	}

	setupUserStreamSubscriptions();
	setupTableStreamSubscriptions();
	setupTableSeatsPlayStreamSubscriptions();

	_isInitialized = true;

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initStreamSubscriptions };

// Dealer
export * from './streams.subscriptions.dealer';
