/**********************************************************************************************************************
 * Stores and manages play data (including history) for all the players seated at the table.
 *********************************************************************************************************************/
import { RpcTableSeatsPlayStoreMobXKeys } from 'server/lib-rpc';
import { RpcTableSeatsPlayStore } from 'server/lib-rpc';
import { ITableSeatsPlayService } from 'server/lib-rpc';
import { action, computed, makeObservable, observable } from 'helpers/mobx';

class TableSeatsPlayStore extends RpcTableSeatsPlayStore {
	constructor(service: ITableSeatsPlayService) {
		super(service);

		// Note: We cannot use `makeAutoObservable` because this class extends a parent class. So we are declaring the
		// annotations right here instead. We can probably make this better in the future by carefully applying observables
		// on the parent class or via some other technique.. but for now, just roll with it.
		makeObservable<TableSeatsPlayStore, RpcTableSeatsPlayStoreMobXKeys>(this, {
			// ---- Inherited from DataStore --------------------------------------------------------------------------------

			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			isPopulated: computed,
			lastUpdatedTs: computed,

			// Actions
			populate: action,
			setData: action,

			// ---- Inherited from RpcTableSeatsPlayStore -------------------------------------------------------------------

			_tableId: observable,
			tableId: computed,
			playId: computed,
			seatsList: computed,
			totalSeats: computed,
			totalUsers: computed,
			choiceCounts: computed,
			tableWagerInfo: computed,

			// ---- Local ---------------------------------------------------------------------------------------------------

			clear: action,
		});
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { TableSeatsPlayStore as default };
export { TableSeatsPlayStore };
