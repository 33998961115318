import { Logger as DebugLogger } from '../helpers/debug';
import { entries } from '../helpers/object';
import { IPlayMethodOptions, ISoundAdapter, ISoundAsset } from './types';
import { PixiLoader, PixiLoaderAddOptions, PixiLoaderResource, PixiLoaderResources } from './types.pixi_loader';

interface ISoundManagerSubscriber {
	onLoadingStarted?: () => void;
	onLoadingComplete?: (soundKeys: string[], sounds: IManagedSound[]) => void;
	onLoadSoundStart?: (soundKey: string, sound: IManagedSound) => void;
	onLoadSoundError?: (soundKey: string, sound: IManagedSound, error: Error) => void;
	onLoadSoundComplete?: (soundKey: string, sound: IManagedSound) => void;
}

interface ISoundManagerOptions {
	// Asset base URL to assign the loader. All asset resource URLs are relative to this.
	// Note: If this is NULL, UNDEFINED or EMPTY STRING then no base url will be used and the full url should be specified for each sound.
	assetBaseUrl?: Maybe<string>;

	// The number of sound resources to load concurrently.
	loadConcurrency?: Maybe<number>;

	// Timeout in milliseconds assigned to loading assets.
	loadTimeoutMs?: Optional<number>;

	// Optional. Injected loader instance to use (instead of creating a new one).
	loader?: Maybe<PixiLoader>;

	// Optional. Sounds to load into the manager.
	sounds?: Maybe<ManagedSounds>;
}

// Debug logging
const debug = new DebugLogger('SoundManager');

/**
 * Dynamically imports the loader.
 */
const importLoader = async () => (await import('@pixi/loaders')).Loader;

/**
 * Imports and creates a loader instance.
 *
 * @param baseUrl      Optional. Base Url to assign the loader. All asset resource urls are relative to this base url.
 * @param concurrency  Optional. The number of resources to load concurrently.
 */
const createLoader = async (baseUrl?: Maybe<string>, concurrency?: Maybe<number>): Promise<PixiLoader> => {
	baseUrl = baseUrl ?? '';
	concurrency = concurrency ?? 0;

	const Loader = await importLoader();

	return new Loader(baseUrl !== '' ? baseUrl : undefined, concurrency > 0 ? concurrency : undefined);
};

/**
 * @returns Default options to use when creating the sound manager.
 */
const getDefaultOptions = (): ISoundManagerOptions => {
	return {
		assetBaseUrl: null,
		loadConcurrency: 50,
		loadTimeoutMs: 30 * 1000,
		loader: null,
		sounds: null,
	};
};

enum LoadState {
	// Unknown/Not Loaded
	NOT_SET = '',
	// Loading
	LOADING = 'LOADING',
	// Loaded
	LOADED = 'LOADED',
	// Error occured when loading
	ERROR = 'ERROR',
}

// Represents a sound being managed by the sound manager
interface IManagedSound {
	// Unique key assigned to this sound
	key: string;
	// Asset data for all managed sounds
	asset: ISoundAsset;
	// Load state of the managed sound
	loadState: LoadState;
	// Loader resource for this managed sound
	resource?: Maybe<PixiLoaderResource>;
}

// Collection of sounds being managed
type ManagedSounds = Map<string, IManagedSound>;

class SoundManager {
	// Options used when configuring this sound manager
	protected options: ISoundManagerOptions;

	// Sound adapter used to play the sounds
	protected soundAdapter: ISoundAdapter;

	// Loader instance used to load the sound resources
	protected loader: Nullable<PixiLoader> = null;

	// Map of managed sound data - this allows us to easily determine which sounds are registered and their load state
	protected sounds: ManagedSounds = new Map<string, IManagedSound>();

	// Subscribers. These are things that are listening to activity on this sound manager.
	protected subscribers: ISoundManagerSubscriber[] = [];

	// TRUE when this sound manager has been initialized
	protected isInitialized: boolean = false;

	/**
	 * CONSTRUCTOR.
	 *
	 * @param  soundAdapter  Sound adapter to use when adding and playing sounds.
	 * @param  opts          Optional. Options to use. Will default if not provided.
	 * @param  init          When TRUE will immediately initialize.
	 */
	constructor(soundAdapter: ISoundAdapter, opts?: Maybe<ISoundManagerOptions>, init: boolean = false) {
		this.soundAdapter = soundAdapter;
		this.options = { ...getDefaultOptions(), ...opts };

		init && this.initialize(this.options);
	}

	/**
	 * Initializes the sound manager.
	 *
	 * @param  opts  Optional. Options to use. Will default if not provided.
	 */
	public initialize = async (opts?: Maybe<ISoundManagerOptions>): Promise<boolean> => {
		if (this.isInitialized) {
			return false;
		}

		this.options = { ...getDefaultOptions(), ...opts };
		await this.initLoader(this.options.loader);

		// TODO: Finish this... eventually...
		// this.initSounds(this.options.sounds);

		this.isInitialized = true;

		return true;
	};

	/**
	 * @param   soundKey  Unique sound key.
	 * @returns TRUE if the specified sound is already registered with this sound manager.
	 */
	public has = (soundKey: string) => {
		return soundKey !== '' ? this.sounds.has(soundKey) : false;
	};

	/**
	 * @param   soundKey  Unique sound key.
	 * @returns The managed sound that is registered with this sound manager, or NULL
	 */
	public get = (soundKey: string): Nullable<IManagedSound> => {
		return (soundKey !== '' ? this.sounds.get(soundKey) : null) ?? null;
	};

	/**
	 * @returns The number of sounds managed by this sound manager.
	 */
	public get size(): number {
		return this.sounds.size;
	}

	/**
	 * Attempts to add the specified sound asset to the sound manager.
	 *
	 * @param    asset  The sound asset to add.
	 * @returns  TRUE if successfully added.
	 */
	public add = (asset: ISoundAsset, opts?: Maybe<{ loadImmediately?: boolean }>): boolean => {
		if (!this.loader) {
			debug.error('Loader is not available', 'add');
			return false;
		}

		const soundKey = asset.key;
		if (soundKey === '') {
			debug.error('Specified sound key must not be empty', 'add');
			return false;
		}

		if (this.has(soundKey)) {
			debug.warn(`Sound with key '${soundKey}' already added`, 'add');
			return false;
		}

		const { loadImmediately = false } = opts ?? {};
		const { loadTimeoutMs } = this.options;

		const props: PixiLoaderAddOptions = {
			key: asset.key,
			url: asset.url,
			timeout: loadTimeoutMs,
		};

		const sound = this.newManagedSound(asset);

		this.loader.add(props);

		const resource = this.getLoaderResource(soundKey);
		if (resource) {
			resource.onStart.add(() => this.onLoadSoundStart(resource));
			sound.resource = resource;
		}

		this.sounds.set(soundKey, sound);

		if (loadImmediately) {
			this.load();
		}

		return true;
	};

	/**
	 * Attempts to add the specified sound assets to the sound manager.
	 *
	 * @param    assets  Array of sound assets to add.
	 * @returns  The number of sounds successfully added.
	 */
	public addMultiple = (assets: ISoundAsset[], opts?: Maybe<{ loadImmediately?: boolean }>): number => {
		if (!this.loader) {
			debug.error('Loader is not available', 'addMultiple');
			return 0;
		}

		const { loadImmediately = false } = opts ?? {};

		let count = 0;

		assets.forEach((asset: ISoundAsset) => {
			const added = this.add(asset);
			added && ++count;
		});

		if (loadImmediately) {
			this.load();
		}

		return count;
	};

	/**
	 * Removes the specified sound from the sound manager.
	 *
	 * @param   soundKey  Unique sound key.
	 * @returns TRUE if successfully removed.
	 */
	public remove = (soundKey: string): boolean => {
		if (soundKey === '') {
			debug.error('Specified sound key must not be empty', 'remove');
			return false;
		}

		let sound = this.get(soundKey);

		if (sound == null) {
			debug.warn(`Sound with key '${soundKey}' does not exist`, 'remove');
			return false;
		}

		sound.resource = null;
		sound = null;

		this.sounds.delete(soundKey);
		this.removeLoaderResource(soundKey);

		return true;
	};

	/**
	 * Removes multiple sounds from the sound manager.
	 *
	 * @param    soundKeys  Array of unique sound keys.
	 * @returns  The number of sounds successfully removed.
	 */
	public removeMultiple = (soundKeys: string[]): number => {
		if (this.size === 0 || soundKeys.length === 0) {
			return 0;
		}

		let count = 0;

		soundKeys.forEach((soundKey) => {
			const removed = this.remove(soundKey);
			removed && ++count;
		});

		return count;
	};

	/**
	 * Load all assets that have been queued via `add` and `addMultiple`.
	 *
	 * @returns
	 */
	public load = async (): Promise<boolean> => {
		return new Promise<boolean>((resolve, reject) => {
			if (!this.loader) {
				reject(new Error('Loader is not available'));
			}

			try {
				const onComplete = (loader: PixiLoader, resources: PixiLoaderResources) => {
					// TODO: Resolve with the collection of managed sounds that were loaded
					resolve(true);
				};

				const onError = (error: Error, loader: PixiLoader, resource: PixiLoaderResource) => {
					reject(error);
				};

				this.loader?.onError.once(onError);
				this.loader?.load(onComplete);
			} catch (e) {
				reject(e as Error);
			}
		});
	};

	/**
	 * Play the specified sound using the sound adapter.
	 *
	 * @param  soundKey  The unique key assigned to the sound.
	 * @param  opts     Optional. Additional options to apply for this play attempt only.
	 */
	public play(soundKey: string, opts?: Maybe<IPlayMethodOptions>): boolean {
		if (soundKey === '') {
			debug.error('Specified sound key must not be empty', 'play');
			return false;
		}

		this.soundAdapter.play(soundKey, opts);
		return true;
	}

	/**
	 * Stop the specified sound using the sound adapter.
	 *
	 * @param  soundKey  The unique key assigned to the sound.
	 */
	public stop(soundKey: string) {
		if (soundKey === '') {
			debug.error('Specified sound key must not be empty', 'stop');
			return false;
		}

		this.soundAdapter.stop(soundKey);
		return true;
	}

	/**
	 * Subscribes to this sound manager. By default will throw an error if the specified subscriber already exists.
	 *
	 * @param  subscriber  Set of callbacks to execute when the relevant sound manager actions occur (eg. onLoadingStarted, etc)
	 */
	public subscribe(subscriber: ISoundManagerSubscriber, opts?: { throwError?: boolean }) {
		const { throwError = true } = opts || {};

		const index = this.findSubscriptionIndex(subscriber);
		if (index > -1) {
			const message = debug.makeMessage('Specified subscriber already exists', 'subscribe');
			if (throwError) {
				throw new Error(message);
			}
			console.error(message);

			return;
		}

		this.subscribers.push(subscriber);
	}

	/**
	 * Unsubscribes (removes) the specified subscriber from this sound manager.
	 *
	 * @param  subscriber  The subscriber we want to remove.
	 */
	public unsubscribe(subscriber: ISoundManagerSubscriber): boolean {
		const index = this.findSubscriptionIndex(subscriber);
		if (index === -1) {
			return false;
		}

		this.subscribers.splice(index, 1);

		return true;
	}

	/**
	 * Unsubscribes (removes) all the subscribers from this sound manager.
	 */
	public unsubscribeAll(): void {
		this.subscribers = [];
	}

	public get subscriberCount(): number {
		return this.subscribers.length;
	}

	public get hasSubscribers(): boolean {
		return this.subscriberCount > 0;
	}

	/**
	 * @param subscriber
	 * @returns The index of the specified subscriber in the the current subscriptions. Returns -1 if not found.
	 */
	protected findSubscriptionIndex(subscriber: ISoundManagerSubscriber): number {
		if (this.subscribers.length === 0) {
			return -1;
		}

		return this.subscribers.findIndex((s: ISoundManagerSubscriber) => Object.is(s, subscriber));
	}

	/**
	 * Setup the loader used to load the sound assets.
	 *
	 * @param  loader  Optional. Injected loader instance to use.
	 */
	protected initLoader = async (loader?: Maybe<PixiLoader>) => {
		if (this.loader != null) {
			debug.error('Loader already initialized', 'initLoader');
			return;
		}

		const { assetBaseUrl, loadConcurrency } = this.options;

		try {
			if (loader) {
				this.loader = loader;
			} else {
				this.loader = await createLoader(assetBaseUrl, loadConcurrency);
			}

			this.loader.onStart.add(this.onLoadingStarted, this);
			this.loader.onComplete.add(this.onLoadingComplete, this);
			this.loader.onLoad.add(this.onLoadSoundComplete);
			this.loader.onError.add(this.onLoadSoundError, this);

			// TODO: Register/Load any existing items that are not loaded
			const loaderResources = this.getLoaderResourceEntries();
			if (loaderResources.length > 0) {
				// TODO: Finish this... eventually...
			}
		} catch (e) {
			const err = e as Error;
			debug.error('Error creating Pixi.Loader:', 'initLoader', err);
		}
	};

	/**
	 * Initialize the sound manager with the specified sounds.
	 *
	 * @param sounds
	 * @returns
	 */
	protected initSounds = (sounds?: Maybe<ManagedSounds>) => {
		this.sounds = sounds ?? this.sounds;
		if (this.sounds.size === 0) {
			return;
		}

		// Resources already in the loader
		const loaderResources = this.getLoaderResources() ?? {};

		// Whether to call `load` after sounds are processed
		let shouldLoad = false;

		this.sounds.forEach((s, key) => {
			s.resource = s.resource ?? null;

			// -----------------------------------------------------------------------------------------------------
			// If a loader resource already exists for this sound key then check if we can use it
			// -----------------------------------------------------------------------------------------------------
			const lr = loaderResources[key] ?? null;
			if (lr != null) {
				const loadState = this.getLoadStateForResource(lr);

				// Not loaded: Re-add & load this sound
				if (loadState === LoadState.NOT_SET) {
					delete loaderResources[key];
					this.initSound(s);
					shouldLoad = true;
					return;
				}

				// Use the loader resource as the sound resource
				s.resource = lr;
				s.loadState = loadState;
				return;
			}

			// -----------------------------------------------------------------------------------------------------
			// If we were provided a sound with a resource then attempt to use it and sync it up with the loader
			// -----------------------------------------------------------------------------------------------------
			if (s.resource) {
				const loadState = this.getLoadStateForResource(s.resource);

				// Not loaded: Re-add & load this sound
				if (loadState === LoadState.NOT_SET) {
					this.initSound(s);
					shouldLoad = true;
					return;
				}

				s.loadState = loadState;
				loaderResources[key] = s.resource;
				return;
			}

			// -----------------------------------------------------------------------------------------------------
			// New sound with no resource
			// -----------------------------------------------------------------------------------------------------
			this.initSound(s);
			shouldLoad = true;
		});

		// Load now if needed
		shouldLoad && this.load();
	};

	protected initSound = (sound: IManagedSound) => {
		sound.resource = null;
		sound.loadState = LoadState.NOT_SET;

		this.add(sound.asset);
	};

	protected getLoadStateForResource = (resource: PixiLoaderResource): LoadState => {
		if (resource.isComplete) {
			return LoadState.LOADED;
		}

		if (resource.isLoading) {
			return LoadState.LOADING;
		}

		if (resource.error.name !== '') {
			return LoadState.ERROR;
		}

		return LoadState.NOT_SET;
	};

	protected getLoaderResourceEntries(): [string, PixiLoaderResource][] {
		return entries(this.getLoaderResources() ?? {});
	}

	protected getLoaderResources(): Nullable<PixiLoaderResources> {
		return this.loader?.resources ?? null;
	}

	protected getLoaderResource(key: string): Nullable<PixiLoaderResource> {
		const resources = this.loader?.resources ?? null;

		return (resources ? resources[key] : null) ?? null;
	}

	protected removeLoaderResource(key: string) {
		const resources = this.loader?.resources ?? null;
		if (resources && resources[key]) {
			delete resources[key];
		}
	}

	protected getLoaderResourceCount(): number {
		return this.getLoaderResourceEntries().length;
	}

	/**
	 * Registers the specified sound with the underlying sound adapter.
	 *
	 * @param   sound  The sound to register.
	 * @returns TRUE if successful
	 */
	protected registerSoundWithAdapter(sound: IManagedSound): boolean {
		const soundKey = sound.key;
		if (soundKey === '') {
			debug.error('Specified sound key must not be empty', 'registerSoundWithAdapter');
			return false;
		}

		// Already registered
		if (this.soundAdapter.has(soundKey)) {
			debug.error('Specified sound already registered', 'registerSoundWithAdapter', soundKey, { sound });
			return false;
		}

		// Not loaded or in an error state
		if (![LoadState.LOADED, LoadState.LOADING].includes(sound.loadState)) {
			debug.error('Invalid load state for sound', 'registerSoundWithAdapter', soundKey, { sound });
			return false;
		}

		const soundResource = sound.resource?.sound;

		// Invalid sound resource
		if (!soundResource) {
			debug.error('Invalid sound resource', 'registerSoundWithAdapter', soundKey, { sound });
			return false;
		}

		// Do not autoplay or loop
		soundResource.autoPlay = false;
		soundResource.loop = false;

		// Add the sound
		return this.soundAdapter.add(sound.key, soundResource, sound.asset);
	}

	/**
	 * Unregisters the specified sound from the underlying sound adapter.
	 *
	 * @param   soundKey  Unique sound key.
	 * @returns TRUE if successful
	 */
	protected unregisterSoundFromAdapter(soundKey: string): boolean {
		if (soundKey === '') {
			debug.error('Specified sound key must not be empty', 'unregisterSoundFromAdapter');
			return false;
		}

		// Not registered
		if (!this.soundAdapter.has(soundKey)) {
			debug.error('Specified sound key not registered', 'unregisterSoundFromAdapter', soundKey);
			return false;
		}

		// Remove the sound
		return this.soundAdapter.remove(soundKey);
	}

	/**
	 * Creates a new managed sound using the specified data.
	 */
	protected newManagedSound = (
		asset: ISoundAsset,
		opts?: { loadState?: LoadState; resource?: PixiLoaderResource }
	): IManagedSound => {
		return {
			key: asset.key,
			asset: asset,
			loadState: opts?.loadState ?? LoadState.NOT_SET,
			resource: opts?.resource ?? null,
		};
	};

	/**
	 * Called once when the loading of all queued sounds is started.
	 */
	protected onLoadingStarted = () => {
		debug.info('Loading sounds...', 'onLoadingStarted');

		this.subscribers.forEach((subscriber) => {
			subscriber.onLoadingStarted && subscriber.onLoadingStarted();
		});
	};

	/**
	 * Called once when the loading of all queued sounds is completed.
	 */
	protected onLoadingComplete = (loader: PixiLoader, resources: PixiLoaderResources) => {
		debug.info('Loaded sounds...', 'onLoadingComplete', resources);

		const soundKeys: string[] = [];
		const sounds: IManagedSound[] = [];

		entries(resources ?? {}).forEach(([soundKey, res]) => {
			const sound = this.get(soundKey);
			if (!sound) {
				return;
			}

			soundKeys.push(soundKey);
			sounds.push(sound);
		});

		this.subscribers.forEach((subscriber) => {
			subscriber.onLoadingComplete && subscriber.onLoadingComplete(soundKeys, sounds);
		});
	};

	/**
	 * Called once per sound when loading starts.
	 */
	protected onLoadSoundStart = (resource: PixiLoaderResource) => {
		const soundKey = resource.name;
		const sound = this.get(soundKey);

		debug.info('Loading sound:', 'onLoadSoundStart', soundKey, { resource, sound });

		if (!sound) {
			debug.error(`Unable to find sound '${soundKey}'`, 'onLoadSoundStart');
			return;
		}

		sound.resource = resource;
		sound.loadState = LoadState.LOADING;
		this.sounds.set(soundKey, sound);

		this.subscribers.forEach((subscriber) => {
			subscriber.onLoadSoundStart && subscriber.onLoadSoundStart(soundKey, sound);
		});
	};

	/**
	 * Called once per sound when loading fails.
	 */
	protected onLoadSoundError = (error: Error, loader: PixiLoader, resource: PixiLoaderResource) => {
		const soundKey = resource.name;
		const sound = this.get(soundKey);

		debug.error('Error while loading sound:', 'onLoadSoundError', soundKey, { resource, error, sound });

		if (!sound) {
			debug.error(`Unable to find sound '${soundKey}'`, 'onLoadSoundError');
			return;
		}

		sound.resource = resource;
		sound.loadState = LoadState.ERROR;
		this.sounds.set(soundKey, sound);

		this.subscribers.forEach((subscriber) => {
			subscriber.onLoadSoundError && subscriber.onLoadSoundError(soundKey, sound, error);
		});
	};

	/**
	 * Called once per sound when loading completes.
	 */
	protected onLoadSoundComplete = (loader: PixiLoader, resource: PixiLoaderResource) => {
		const soundKey = resource.name;
		const sound = this.get(soundKey);

		debug.info('Load sound completed:', 'onLoadSoundComplete', soundKey, { resource, sound });

		if (!sound) {
			debug.error(`Unable to find sound '${soundKey}'`, 'onLoadSoundComplete');
			return;
		}

		sound.resource = resource;
		sound.loadState = LoadState.LOADED;
		this.sounds.set(soundKey, sound);

		this.registerSoundWithAdapter(sound);

		this.subscribers.forEach((subscriber) => {
			subscriber.onLoadSoundComplete && subscriber.onLoadSoundComplete(soundKey, sound);
		});
	};
}

export { SoundManager as default };
export { SoundManager };
export type { ISoundManagerOptions };
