import { ResolutionType } from 'Constants';
import { ActiveWagerData, ResolutionData, TablePlayData, WagerResolutionData } from 'server/lib-rpc';
import {
	Hand,
	HistoryPlayData,
	Wager,
	WagerHistory,
	WagerResolution,
	WagerResolutionListData,
} from 'server/types/wagerHistory';
import { DEFAULT_CURRENCY_CODE } from 'helpers/currency';

const handDefaults = (): Hand => ({
	card: undefined,
	score: 0,
});

const newHistoryPlayData = (playId: number, game: string): HistoryPlayData => {
	const data: HistoryPlayData = {
		playId: playId,
		game: game,
		isResolved: false,
		betTotal: 0,
		winTotal: 0,
		wagers: [],
		results: [],
		completedTime: 0,
		voidedTime: 0,
		createdTime: 0,
		dragonHand: handDefaults(),
		tigerHand: handDefaults(),
		startBalance: { amount: 0, currency: DEFAULT_CURRENCY_CODE },
		endBalance: { amount: 0, currency: DEFAULT_CURRENCY_CODE },
	};

	return data;
};

const sortHistoryEntries = (a: [number, WagerHistory], b: [number, WagerHistory]): number => {
	return b[0] - a[0];
};

const makeHistoryPlayData = (data: Nullable<TablePlayData>): Nullable<HistoryPlayData> => {
	if (!data) {
		return null;
	}

	// console.log('makeHistoryPlayData', data);

	const result = newHistoryPlayData(data.playId, data.game);
	const playState = data.playStates?.dragonTiger || null;

	result.isResolved = data.state === 'completed' ? true : false;
	result.voidedTime = data.voidedTime;
	result.completedTime = data.completedTime;
	result.createdTime = data.createdTime;
	result.startBalance = data.startingBalance || result.startBalance;
	result.endBalance = data.endingBalance || result.endBalance;

	const wagerLookup = new Map<string, Wager>();

	result.wagers = data.effectivePlayerWagersList.map((wager: ActiveWagerData) => {
		const item: Wager = {
			name: wager.wagerName,
			amount: wager.amount,
		};

		wagerLookup.set(item.name, item);

		return item;
	});

	result.results = data.resolutionsList.map((res: ResolutionData) => {
		const wagerName = res.wagerName;
		const wager = wagerLookup.get(wagerName) ?? null;

		const item: WagerResolution = {
			name: res.wagerName,
			amount: res.amount,
			wagerAmount: wager?.amount ?? 0,
			isPush: res.resolutionType === ResolutionType.PUSH,
		};

		if (item.isPush && item.amount === 0 && item.wagerAmount > 0) {
			item.amount = item.wagerAmount;
		}

		return item;
	});

	result.betTotal =
		data.effectivePlayerWagersList.reduce((total: number, current: ActiveWagerData) => total + current.amount, 0) || 0;

	result.winTotal =
		data.resolutionsList.reduce((total: number, current: ResolutionData) => total + current.amount, 0) || 0;

	if (playState?.tigerhand) {
		const hand = playState?.tigerhand;
		result.tigerHand = hand;
	}

	if (playState?.dragonhand) {
		const hand = playState.dragonhand;
		result.dragonHand = hand;
	}

	return result;
};

const makeHistoryDataDisplay = (data: Nullable<WagerResolutionListData>): Nullable<Map<number, WagerHistory>> => {
	if (data === null || data.length === 0) {
		return null;
	}

	const playLookup = new Map<number, WagerHistory>();

	data.forEach((entry: WagerResolutionData) => {
		if (playLookup.has(entry.playId)) {
			const pl = playLookup.get(entry.playId);
			if (!pl) {
				return;
			}

			playLookup.set(entry.playId, pl);
			return;
		}

		const data: WagerHistory = {
			playId: entry.playId,
			betTotal: 0,
			totalWon: 0,
			date: 0,
		};

		playLookup.set(entry.playId, data);
	});

	return playLookup;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { sortHistoryEntries, makeHistoryPlayData, makeHistoryDataDisplay };
