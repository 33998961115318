enum SoundEffects {
	STEREO_POSITION = 'STEREO_POSITION',
	STEREO_TRANSITION = 'STEREO_TRANSITION',
	FAR_LEFT = 'FAR_LEFT',
	FAR_RIGHT = 'FAR_RIGHT',
	PAN_LEFT_RIGHT = 'PAN_LEFT_RIGHT',
	PAN_RIGHT_LEFT = 'PAN_RIGHT_LEFT',
	PAN_CENTER_LEFT = 'PAN_CENTER_LEFT',
	PAN_CENTER_RIGHT = 'PAN_CENTER_RIGHT',
}

export { SoundEffects };
