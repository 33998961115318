/**********************************************************************************************************************
 * Actions related to the current user/player.
 *********************************************************************************************************************/
import { clearAllDealerStores, clearMultipleStores, getStores, StoreKey } from '../store';
import { DealerStreamKey, StreamKey, streamManager } from '../streams';
import { debug } from './debug';

let _currentPlayerId: number = 0;

const stopRelatedStreams = (includeUserStream?: Optional<boolean>) => {
	includeUserStream = includeUserStream ?? false;

	const streamKeys: Array<string> = [
		DealerStreamKey.DealerHudStream,
		StreamKey.TableSeatsPlayStream,
		StreamKey.TableStream,
	];

	if (includeUserStream) {
		streamKeys.unshift(StreamKey.UserStream);
	}

	streamManager.stopMultiple(streamKeys);
};

const clearRelatedStores = (includeUserStore?: Optional<boolean>) => {
	includeUserStore = includeUserStore ?? false;

	const storeKeys: Array<StoreKey> = [
		StoreKey.FavoriteBetsStore,
		StoreKey.TableHistoryStore,
		StoreKey.PlayStore,
		StoreKey.RulesStore,
		StoreKey.SettingsStore,
		StoreKey.TableStore,
		StoreKey.TableSeatsPlayStore,
	];

	if (includeUserStore) {
		storeKeys.unshift(StoreKey.UserStore);
	}

	clearAllDealerStores();
	clearMultipleStores(storeKeys);
};

interface ISetPlayerOpts {
	startStream?: Maybe<boolean>;
}

const setActivePlayer = async (playerId: number, opts?: Maybe<ISetPlayerOpts>): Promise<void> => {
	if (playerId === _currentPlayerId) {
		return;
	}

	debug.info('Props:', 'setActivePlayer', { playerId, opts });

	await setPlayer(playerId, opts);
};

const setPlayer = async (playerId: number, opts?: Maybe<ISetPlayerOpts>): Promise<void> => {
	const { startStream = true } = opts ?? {};

	// Stop all the streams that relate the player and clear the related stores
	stopRelatedStreams(true);
	clearRelatedStores();

	const { userStore } = getStores();

	// Unsetting the player
	if (playerId < 1) {
		_currentPlayerId = 0;
		userStore.clear();
		return;
	}

	// Populate the user store (if necessary)
	if (userStore.playerId !== playerId) {
		// debug.info(`Attempting to populate user store using player ID '${playerId}'`, 'setPlayer');

		await userStore.populate();

		if (!userStore.isPopulated || userStore.playerId !== playerId) {
			debug.warn(`Unable to populate user store for player ID '${playerId}'`, 'setPlayer');

			_currentPlayerId = 0;
			userStore.clear();
			return;
		}
	}

	_currentPlayerId = userStore.playerId;

	if (startStream) {
		// debug.info(`Attempting to start the user stream for player '${playerId}'`, 'setPlayer');
		streamManager.start(StreamKey.UserStream);
	}
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { setActivePlayer };
