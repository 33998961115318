import { authenticateRequest, getDefaultAuthSession, IAuthSessionProvider } from './helpers/authenticate';
import { RpcClientConstructor, RpcOptions } from './types/client';
import { IRpcServiceResponse, IUnaryMethodOptions } from './types/client';
import { IService, IServiceOptions, ServiceResult } from './types/service';
import { UnaryClient } from './UnaryClient';

abstract class Service<RpcClientType> implements IService {
	// The service URL
	protected url: string = '';

	// Unary client instance
	/* @ts-ignore : Because this gets instantiated in the streams that inherit the class and strict mode complains. */
	protected client: UnaryClient<RpcClientType>;

	// Session data
	protected _session: Nullable<IAuthSessionProvider>;

	/**
	 * CONSTRUCTOR.
	 *
	 * @param  url   Service url.
	 * @param  opts
	 */
	constructor(url: string, opts?: IServiceOptions) {
		this.url = url;
		this._session = opts?.session ?? getDefaultAuthSession();

		if (this.url === '') {
			throw new Error('Service url must be specified');
		}
	}

	public get session() {
		return this._session;
	}

	public get token(): string {
		return this.session?.token ?? '';
	}

	protected set token(value: string) {
		this.session && (this.session.token = value);
	}

	/**
	 * Gets the method keys supported by the RPC client.
	 */
	public get rpcClientMethods() {
		return this.client.rpcClientMethods;
	}

	/**
	 * Make an unary call via the client attached to this service. Returns the raw RPC data in the `data` prop.
	 *
	 * @returns See `UnaryClient.unary` method
	 */
	protected unary = async <RequestType, ResponseType extends IRpcServiceResponse>(
		rpcMethod: typeof this.rpcClientMethods,
		request: RequestType,
		opts?: IUnaryMethodOptions
	): Promise<ServiceResult<ResponseType>> => {
		const authReq = this.authenticateRequest<RequestType>(request);

		return this.client.unary<RequestType, ResponseType>(rpcMethod, authReq, opts);
	};

	/**
	 * Make an unary call via the client attached to this service. Returns the `AsObject` data in the `data` prop.
	 */
	protected unaryAsObject = async <RequestType, ResponseType extends IRpcServiceResponse, ObjectType>(
		rpcMethod: typeof this.rpcClientMethods,
		request: RequestType,
		opts?: IUnaryMethodOptions
	): Promise<ServiceResult<ObjectType>> => {
		const authReq = this.authenticateRequest<RequestType>(request);

		return this.client.unaryAsObject<RequestType, ResponseType, ObjectType>(rpcMethod, authReq, opts);
	};

	/**
	 * Authenticate the request by adding the token data to the request if we have it.
	 *
	 * @param request
	 * @returns
	 */
	protected authenticateRequest<RequestType>(request: RequestType): RequestType {
		try {
			return authenticateRequest<RequestType>(request, this.token);
		} catch (e) {
			const err = e as Error;
			const errData: ServiceResult<unknown> = { success: false, error: { message: err.message } };
			throw errData;
		}
	}

	/**
	 * @param  RpcClient   The specific grpc generated client.
	 */
	protected createRpcClient(RpcClient: RpcClientConstructor<RpcClientType>, rpcOptions?: RpcOptions): RpcClientType {
		return new RpcClient(this.url, rpcOptions);
	}

	/**
	 * @param  RpcClient   The specific grpc generated client.
	 * @param  rpcOptions  Options to pass to the specified RPC client
	 */
	protected createUnaryClient(
		RpcClient: RpcClientConstructor<RpcClientType>,
		rpcOptions?: RpcOptions
	): UnaryClient<RpcClientType> {
		const rpcClient = this.createRpcClient(RpcClient, rpcOptions);

		return new UnaryClient<RpcClientType>(rpcClient);
	}
}

export { Service as default };
export { Service };
