/**********************************************************************************************************************
 * Actions related to streams.
 *********************************************************************************************************************/
import { streamManager } from '../streams';

const stopAllStreams = () => streamManager.stopAll();

// ---- Export --------------------------------------------------------------------------------------------------------

export { stopAllStreams };
