import { getRulesId } from 'server/selectors';
import styled from 'styled-components/macro';
import { DebugLogger } from 'helpers/debug';
import { IBetAreasProps } from './base/BetAreas';
import { Theme } from './constants';
import { ThemeManager } from './ThemeManager';
import { ThemedComponents } from './types';

// Debug logging
const debug = new DebugLogger('Themed');

// Theme manager instance
const manager = new ThemeManager({ debugEnabled: true });

const defaultThemedComponents = () => {
	return {
		TestDisplay: (props: { cowName: string }) => <ThemeWarn>TestDisplay: No theme defined</ThemeWarn>,
		BetAreas: (props: IBetAreasProps) => <ThemeWarn>BetAreas: No theme defined</ThemeWarn>,
	};
};

let Themed = defaultThemedComponents();

const applyThemes = async (names: string[]): Promise<ThemedComponents> => {
	debug.info('Props:', 'applyThemes', { names });

	if (names.length === 0) {
		return Themed;
	}

	const rulesId = getRulesId();

	debug.info('rulesId:', 'applyThemes', rulesId);

	if (rulesId) {
		names.push(rulesId);
	} else {
		debug.warn('A rules ID was not specified', 'applyThemes');
	}

	// Reset the manager to a known applied state based on the names
	debug.info('Attempting to apply the following themes:', 'applyThemes', names.join(' | '));

	const applied = await manager.resetWith(names);

	// Assign the applied themes to `Themed`
	Themed = { ...defaultThemedComponents(), ...applied.components };

	debug.info('Themes successfully applied are:', 'applyThemes', applied.names.join(' | '));
	debug.info('Themed components resolved are:', 'applyThemes', Themed);

	return Themed;
};

const hasAppliedTheme = (name: string) => {
	return manager.hasAppliedTheme(name);
};

const ThemeWarn = styled.div`
	background-color: yellow;
	color: black;
	font-weight: bold;
	padding: 1em;
`;

// ---- Export --------------------------------------------------------------------------------------------------------

export { applyThemes, hasAppliedTheme, Themed, Theme };
