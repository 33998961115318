import isFunction from 'lodash/isFunction';
import { IClientResult, IError, RpcReplyError } from './types/client';

type RpcClientProps<RpcClientType> = keyof RpcClientType;

abstract class Client<RpcClientType> {
	// Protobuf RPC client (from generated code)
	protected rpcClient: RpcClientType;

	// PbClient class props used for type-checking
	/* @ts-ignore : Because strict mode does not like this at all */
	protected rpcClientProps: RpcClientProps<RpcClientType>;

	// The client is tied to a specific RPC client (eg. SpokeClient)
	constructor(rpcClient: RpcClientType) {
		this.rpcClient = rpcClient;
	}

	/**
	 * Gets the method keys supported by the RPC client.
	 */
	public get rpcClientMethods(): RpcClientProps<RpcClientType> {
		return this.rpcClientProps;
	}

	/**
	 * Dynamically gets a bound version of the named RPC method from the RPC client (if it exists).
	 *
	 * @param   rpcMethodName  Name of the method on the RPC client.
	 * @returns A bound version of the method, or NULL if not found.
	 */
	protected getRpcMethodFn(rpcMethodName: string): Nullable<CallableFunction> {
		/* @ts-ignore : Because strict mode does not like us accessing a property on a generic like this. */
		const prop: Nullable<unknown> = this.rpcClient[rpcMethodName] ?? null;
		const isCallable = prop != null && isFunction(prop);
		const rpcMethod: Nullable<CallableFunction> = isCallable ? (prop as CallableFunction) : null;

		if (rpcMethod == null) {
			return null;
		}

		return rpcMethod.bind(this.rpcClient);
	}

	/**
	 * Converts an RPC error object into a regular generic error structure by calling the relevant methods.
	 *
	 * @param err The RPC error object.
	 * @returns
	 */
	protected extractReplyError(err?: Maybe<RpcReplyError>): Nullable<IError> {
		if (err == null) {
			return null;
		}

		const message = err.getMessage() || '';
		if (message == '') {
			return null;
		}

		return { ...{ message, code: err.getCode() || undefined } };
	}

	/**
	 * Generates an erroneous client result containing the error details.
	 */
	protected makeErrorResult<ResponseType>(
		props?: Maybe<{ message?: string; error?: Maybe<IError> }>
	): IClientResult<ResponseType> {
		const { message = '', error = null } = props || {};

		return { success: false, error: error ?? { message: message } };
	}
}

export { Client as default };
export { Client };
export type { RpcClientProps };
