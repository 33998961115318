// package: com.sands.live_dealer.back_office
// file: back_office.proto

var back_office_pb = require("./back_office_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BackOffice = (function () {
  function BackOffice() {}
  BackOffice.serviceName = "com.sands.live_dealer.back_office.BackOffice";
  return BackOffice;
}());

BackOffice.GetRules = {
  methodName: "GetRules",
  service: BackOffice,
  requestStream: false,
  responseStream: false,
  requestType: back_office_pb.BackOfficeGetRulesRequest,
  responseType: back_office_pb.BackOfficeGetRulesReply
};

BackOffice.GetRulesSet = {
  methodName: "GetRulesSet",
  service: BackOffice,
  requestStream: false,
  responseStream: false,
  requestType: back_office_pb.BackOfficeGetRulesSetRequest,
  responseType: back_office_pb.BackOfficeGetRulesSetReply
};

BackOffice.StreamDealerHud = {
  methodName: "StreamDealerHud",
  service: BackOffice,
  requestStream: false,
  responseStream: true,
  requestType: back_office_pb.DealerHudRequest,
  responseType: back_office_pb.DealerHudReply
};

BackOffice.GetDealerHud = {
  methodName: "GetDealerHud",
  service: BackOffice,
  requestStream: false,
  responseStream: false,
  requestType: back_office_pb.DealerHudRequest,
  responseType: back_office_pb.DealerHudReply
};

BackOffice.UpdateDeviceTable = {
  methodName: "UpdateDeviceTable",
  service: BackOffice,
  requestStream: false,
  responseStream: false,
  requestType: back_office_pb.UpdateDeviceTableRequest,
  responseType: back_office_pb.UpdateDeviceTableReply
};

BackOffice.PausePlay = {
  methodName: "PausePlay",
  service: BackOffice,
  requestStream: false,
  responseStream: false,
  requestType: back_office_pb.PausePlayRequest,
  responseType: back_office_pb.PausePlayReply
};

BackOffice.UnpausePlay = {
  methodName: "UnpausePlay",
  service: BackOffice,
  requestStream: false,
  responseStream: false,
  requestType: back_office_pb.UnpausePlayRequest,
  responseType: back_office_pb.UnpausePlayReply
};

BackOffice.OpenCloseTable = {
  methodName: "OpenCloseTable",
  service: BackOffice,
  requestStream: false,
  responseStream: false,
  requestType: back_office_pb.OpenCloseTableRequest,
  responseType: back_office_pb.OpenCloseTableReply
};

exports.BackOffice = BackOffice;

function BackOfficeClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BackOfficeClient.prototype.getRules = function getRules(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BackOffice.GetRules, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BackOfficeClient.prototype.getRulesSet = function getRulesSet(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BackOffice.GetRulesSet, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BackOfficeClient.prototype.streamDealerHud = function streamDealerHud(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(BackOffice.StreamDealerHud, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

BackOfficeClient.prototype.getDealerHud = function getDealerHud(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BackOffice.GetDealerHud, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BackOfficeClient.prototype.updateDeviceTable = function updateDeviceTable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BackOffice.UpdateDeviceTable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BackOfficeClient.prototype.pausePlay = function pausePlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BackOffice.PausePlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BackOfficeClient.prototype.unpausePlay = function unpausePlay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BackOffice.UnpausePlay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BackOfficeClient.prototype.openCloseTable = function openCloseTable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BackOffice.OpenCloseTable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BackOfficeClient = BackOfficeClient;

