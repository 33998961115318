/**********************************************************************************************************************
 * Stores and manages data regarding the current authenticated user/player.
 *********************************************************************************************************************/
import { CurrencyCode, DEFAULT_CURRENCY_CODE } from 'helpers/currency';
import { isArrayEqual } from 'helpers/object';
import { BalanceData, ProfileData, SelfData } from '../client/rpc/types/spoke';
import { IUserService } from '../client/service/types';
import { DataStore } from './DataStore';
import { IUserStoreBalanceDataExt } from './types';

interface IUserStoreBalancesData {
	list: IUserStoreBalanceDataExt[];
	listIndex: Map<string, number>;
	lookup: Map<string, IUserStoreBalanceDataExt>;
	lastUpdatedTs: number;
}

const defaultBalancesData = (lastUpdatedTs?: number): IUserStoreBalancesData => ({
	list: [],
	listIndex: new Map<string, number>(),
	lookup: new Map<string, IUserStoreBalanceDataExt>(),
	lastUpdatedTs: lastUpdatedTs ?? 0,
});

class UserStore extends DataStore<IUserService, SelfData> {
	/**
	 * Internal balance data.
	 */
	protected _balancesData: IUserStoreBalancesData = defaultBalancesData();

	/**
	 * @returns Unique player ID for the current user.
	 */
	public get playerId(): number {
		return this.data?.playerId ?? 0;
	}

	/**
	 * List of balance entries (one per currency) for the current user.
	 */
	public get balancesList(): IUserStoreBalanceDataExt[] {
		return this._balancesData.list;
	}

	/**
	 * Map of balance entries (one per currency) for the current user.
	 */
	public get balancesLookup(): Map<string, IUserStoreBalanceDataExt> {
		return this._balancesData.lookup;
	}

	/**
	 * @returns The balance data for the specified currency code or NULL if not found.
	 */
	public getBalanceDataForCurrency(currencyCode: string): Nullable<IUserStoreBalanceDataExt> {
		currencyCode = currencyCode.toUpperCase();
		return this.balancesLookup.get(currencyCode) ?? null;
	}

	/**
	 * Various preferences associated with the current user.
	 */
	public get profile(): Nullable<ProfileData> {
		return this.data?.profile ?? null;
	}

	/**
	 * Display name associated with the current user.
	 */
	public get displayName(): string {
		return this.profile?.displayName ?? '';
	}

	/**
	 * @returns TRUE if the current user is considered to be logged-in and valid.
	 */
	public get isLoggedIn(): boolean {
		return this.playerId > 0;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}

	/**
	 * Action. Override the parent store `setData` action.
	 */
	public setData(data: Nullable<SelfData>): void {
		const prevData: Nullable<SelfData> = this.data != null ? { ...this.data } : null;

		super.setData(data);
		this.onDataUpdate(data, prevData);
	}

	/**
	 * Called immediately when the store receives new data.
	 */
	protected onDataUpdate = (data: Nullable<SelfData>, prevData: Nullable<SelfData>) => {
		const prevBalanceList = prevData?.balancesList ?? [];
		const newBalanceList = data?.balancesList ?? [];

		if (!isArrayEqual(newBalanceList, prevBalanceList)) {
			this.resolveBalanceDataUpdate(newBalanceList);
		}
	};

	/**
	 * Resolves the balance list sent by the server and converts it to our internal balance list and lookup.
	 */
	protected resolveBalanceDataUpdate = (balanceList: BalanceData[]) => {
		if (balanceList.length === 0) {
			this.clearBalancesData();
			return;
		}

		const lookup = new Map<string, IUserStoreBalanceDataExt>();

		balanceList.forEach((bd: BalanceData) => {
			if (bd.playerId !== this.playerId) {
				return;
			}

			let currencyCode = bd.currency.toUpperCase();

			// Hack to mask the virtual chip balance as the default currency.
			if (currencyCode === CurrencyCode.VIRTUAL_CHIP) {
				if (!lookup.has(DEFAULT_CURRENCY_CODE)) {
					currencyCode = DEFAULT_CURRENCY_CODE;
				} else {
					return; // Skip
				}
			}

			const { amount, amountMoney } = this.resolveAmount(bd.amount);
			const balExt: IUserStoreBalanceDataExt = { ...bd, amount, amountMoney, currency: currencyCode };

			lookup.set(currencyCode, balExt);
		});

		// Index the list
		const list: IUserStoreBalanceDataExt[] = Array.from(lookup.values());
		const listIndex = new Map<string, number>();
		list.forEach((bal: IUserStoreBalanceDataExt, i) => {
			listIndex.set(bal.currency, i);
		});

		this._balancesData.lookup = lookup;
		this._balancesData.list = list;
		this._balancesData.listIndex = listIndex;
		this._balancesData.lastUpdatedTs = Date.now();
	};

	/**
	 * Clear the internal balance data.
	 */
	protected clearBalancesData = () => {
		this._balancesData = defaultBalancesData(Date.now());
	};

	/**
	 * Updates the balance data entry for the specified currency code to the specified amount.
	 */
	protected updateBalanceAmount(newAmount: number, currencyCode: string): void {
		currencyCode = currencyCode.toUpperCase();

		if (currencyCode === '') {
			this.debugError('Empty currency code specified', 'updateBalanceAmount');
			return;
		}

		const index: Nullable<number> = this._balancesData.listIndex.get(currencyCode) ?? null;
		if (index == null) {
			return;
		}

		const currentBalanceData = this._balancesData.list[index] ?? null;
		if (currentBalanceData == null) {
			return;
		}

		const { amount, amountMoney } = this.resolveAmount(newAmount);
		const newBalanceData = { ...currentBalanceData, amount, amountMoney };

		this._balancesData.lookup.set(currencyCode, newBalanceData);
		this._balancesData.list.splice(index, 1, newBalanceData);
		this._balancesData.lastUpdatedTs = Date.now();
	}

	/**
	 * Normalize the specified amount into a valid raw amount and monetary amount.
	 */
	protected resolveAmount = (amount: number): { amount: number; amountMoney: number } => {
		amount = amount >= 0 ? amount : 0;
		const amountMoney = amount / 100;

		return { amount, amountMoney };
	};

	// ---- Legacy Balance stuff, will probably be migrated to a wallet manager -----------------------------------------

	/**
	 * Gets the currency and amount for the default currency.
	 */
	public get balance(): { currency: string; amount: number } {
		const bal = this.getBalanceDataForCurrency(DEFAULT_CURRENCY_CODE);
		if (bal == null) {
			return { currency: DEFAULT_CURRENCY_CODE, amount: 0 };
		}

		return { currency: bal.currency, amount: bal.amount };
	}

	/**
	 * Gets the balance amount for the default balance.
	 */
	public get balanceAmount() {
		return this.balance.amount;
	}

	/**
	 * Gets the balance currency code for the default balance.
	 */
	public get balanceCurrency() {
		return this.balance.currency;
	}

	/**
	 * Action. Decrease the default (or specified) balance by the specified amount.
	 *
	 * TODO: It needs to go away and be replaced by the WalletManager.
	 */
	public decreaseBalance(byAmount: number, currencyCode?: Maybe<string>): void {
		currencyCode = (currencyCode || DEFAULT_CURRENCY_CODE).toUpperCase();

		if (byAmount <= 0) {
			return;
		}

		const currentBalanceData = this._balancesData.lookup.get(currencyCode);
		const currentAmount = currentBalanceData?.amount ?? 0;

		currentAmount >= 0 && this.updateBalanceAmount(currentAmount - byAmount, currencyCode);
	}

	/**
	 * Action. Increase the default (or specified) balance by the specified amount.
	 *
	 * TODO: It needs to go away and be replaced by the WalletManager.
	 */
	public increaseBalance(byAmount: number, currencyCode?: Maybe<string>): void {
		currencyCode = (currencyCode || DEFAULT_CURRENCY_CODE).toUpperCase();

		if (byAmount <= 0) {
			return;
		}

		const currentBalanceData = this._balancesData.lookup.get(currencyCode);
		const currentAmount = currentBalanceData?.amount ?? 0;

		this.updateBalanceAmount(currentAmount + byAmount, currencyCode);
	}

	// ---- Populate ----------------------------------------------------------------------------------------------------

	// Note: This has no `populate` method because the current RPC client has no way of querying user data other
	// than `GetSelf` - which gets the data based on the current issued token.

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(): Promise<Nullable<SelfData>> {
		if (!this.service) {
			this.debugError('No service was specified', 'fetchPopulateData');
			return null;
		}

		return this.service.getSelf();
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { UserStore as default };
export { UserStore };
