import { BankerCommissionType, PaylineDescriptors, WagerName } from 'Constants';
import { PaylineData } from 'server/lib-rpc';
import { WagerRulesPayoutLookup } from 'server/types/rules';
import { keys } from 'helpers/object';

type RtpDict = { [key: string]: number };

interface IFakePaylineListsByWager {
	[key: string | WagerName]: PaylineData[];
}
interface IFakePaylineLists {
	[key: string]: IFakePaylineListsByWager;
}

interface IBaccaratRules {
	decks?: number;
	commissionType?: string;
}

enum PaylineGroups {
	MAIN = 'main',
}

const FakeWagerRtp: RtpDict = Object.freeze({
	[WagerName.DRAGON]: 96.27,
	[WagerName.TIGER]: 96.27,
	[WagerName.TIE]: 89.64,
	[WagerName.SUITED_TIE]: 86.02,
	[WagerName.DRAGON_BIG]: 92.31,
	[WagerName.DRAGON_SMALL]: 92.31,
	[WagerName.DRAGON_EVEN]: 92.31,
	[WagerName.DRAGON_ODD]: 92.31,
	[WagerName.DRAGON_RED]: 92.31,
	[WagerName.DRAGON_BLACK]: 92.31,
	[WagerName.TIGER_BIG]: 92.31,
	[WagerName.TIGER_SMALL]: 92.31,
	[WagerName.TIGER_EVEN]: 92.31,
	[WagerName.TIGER_ODD]: 92.31,
	[WagerName.TIGER_RED]: 92.31,
	[WagerName.TIGER_BLACK]: 92.31,
});

const FakeBankerRtp: RtpDict = Object.freeze({
	[BankerCommissionType.STANDARD]: 98.9421,
	[BankerCommissionType.BANKER_6]: 98.5419,
	[BankerCommissionType.THREE_CARD_7]: 98.9817,
});

const paylineEntryDefaults = (): PaylineData => {
	return { group: PaylineGroups.MAIN, amount: 0, push: false, descriptor: PaylineDescriptors.NONE };
};

const getFakePaylineLists = (): IFakePaylineLists => {
	const defaults = paylineEntryDefaults();

	return {
		standard: {
			[WagerName.PLAYER]: [
				{ ...defaults, descriptor: PaylineDescriptors.WIN, amount: 10000 },
				{ ...defaults, descriptor: PaylineDescriptors.TIE_LOSE_HALF, amount: 5000 },
			],
			[WagerName.TIGER]: [
				{ ...defaults, descriptor: PaylineDescriptors.WIN, amount: 10000 },
				{ ...defaults, descriptor: PaylineDescriptors.TIE_LOSE_HALF, amount: 5000 },
			],
			[WagerName.TIE]: [{ ...defaults, descriptor: PaylineDescriptors.WIN, amount: 110000 }],
		},
	};
};

const polyfillPlayerRtpAndPaylines = (data: WagerRulesPayoutLookup): WagerRulesPayoutLookup => {
	const wagerNames = keys(data);
	const rtpLookup = FakeWagerRtp;

	// Add in fake RTP data
	wagerNames.forEach((wagerName) => {
		data[wagerName].rtp = rtpLookup[wagerName] ?? data[wagerName].rtp ?? 0;
	});

	// Add in fake Paylines
	const fakePaylineLists = getFakePaylineLists();
	[WagerName.PLAYER, WagerName.TIE, WagerName.BANKER].forEach((wagerName) => {
		data[wagerName] && (data[wagerName].paylinesList = fakePaylineLists.standard[wagerName] ?? []);
	});

	return data;
};

const polyfillBankerRtpAndPaylines = (
	data: WagerRulesPayoutLookup,
	baccaratRules: IBaccaratRules
): WagerRulesPayoutLookup => {
	const commissionType = baccaratRules.commissionType ?? '';

	const wagerName = WagerName.BANKER;
	if (commissionType === '' || !data[wagerName]) {
		return data;
	}

	// Add in fake RTP data
	const rtpLookup = FakeBankerRtp;
	data[wagerName].rtp = rtpLookup[commissionType] ?? data[wagerName].rtp ?? 0;

	// Add in fake Paylines
	const fakePaylineLists = getFakePaylineLists();
	data[wagerName].paylinesList = fakePaylineLists.banker[commissionType] ?? [];

	return data;
};

const polyfillRtpAndPaylines = (
	data: WagerRulesPayoutLookup,
	baccaratRules: IBaccaratRules
): WagerRulesPayoutLookup => {
	polyfillPlayerRtpAndPaylines(data);
	polyfillBankerRtpAndPaylines(data, baccaratRules);

	return data;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { polyfillRtpAndPaylines };
