// source: spoke.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var spoke_game_pb = require('./spoke_game_pb.js');
goog.object.extend(proto, spoke_game_pb);
var spoke_error_pb = require('./spoke_error_pb.js');
goog.object.extend(proto, spoke_error_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol('proto.sands.live_dealer.spoke.AddFavoriteBetRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.AddFavoriteBetResponse', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BaccaratDefaultRules', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BaseCondition', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.ConditionCard', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DealerDeviceStateReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DealerDeviceStateRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DealtInput', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DealtInputRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DealtRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.EmailRegisterRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.FavoriteBet', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.FutureActionSetReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.FutureActionSetRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetFavoriteBetsRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetFavoriteBetsResponse', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetPaytableReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetPaytableRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetPlayReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetPlayRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetRulesReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetRulesRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetSelfReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetSelfRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetSidebetReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetSidebetRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetTableHistoryReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetTableHistoryRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetTablePlayDataReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetTablePlayDataRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetTableReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetTableRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetTablesReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetTablesRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetWagerHistoryReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GetWagerHistoryRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.LoginReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.LoginRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.MakeChoiceReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.MakeChoiceRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.MakeWagersReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.MakeWagersRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.NewPlayReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.NewPlayRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Paytable', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Paytable.PaytableRows', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.PaytableRow', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Profile', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Rtp', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RunPlayReply', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RunPlayRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.SeatWager', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Sidebet', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Sidebet.SidebetRows', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.SidebetRow', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.SidebetRow.ConditionList', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.SitTableRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.StandTableRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.StreamSelfRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.StreamTablePlayDataRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.StreamTableRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.TestDepositMoneyRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.UpdateDisplayNameRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.UpdateImageUrlRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.UpdatePreferencesRequest', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.WagerAndResolution', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBaccaratHandWins', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBaccaratPointTotal', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBaccaratTie', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBlackjack', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBlackjackBust', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBlackjackPointTotal', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcCardColor', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcHandLengthEquals', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcHandLengthSame', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcHandMatch', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcHandMatchOrBetter', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcMatchCards', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcMatchCardsRank', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcMatchCardsWithin', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcMultiple', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcMultipleSuited', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcPair', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcPairEither', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcPairMatchRank', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcPairMatchSuit', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcPairSame', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.sbcSuited', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetSelfRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetSelfRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetSelfRequest.displayName = 'proto.sands.live_dealer.spoke.GetSelfRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.StreamSelfRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.StreamSelfRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.StreamSelfRequest.displayName = 'proto.sands.live_dealer.spoke.StreamSelfRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.UpdateDisplayNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.displayName = 'proto.sands.live_dealer.spoke.UpdateDisplayNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.UpdateImageUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.UpdateImageUrlRequest.displayName = 'proto.sands.live_dealer.spoke.UpdateImageUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.UpdatePreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.UpdatePreferencesRequest.displayName = 'proto.sands.live_dealer.spoke.UpdatePreferencesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetSelfReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.GetSelfReply.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetSelfReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetSelfReply.displayName = 'proto.sands.live_dealer.spoke.GetSelfReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Profile.displayName = 'proto.sands.live_dealer.spoke.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetRulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetRulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetRulesRequest.displayName = 'proto.sands.live_dealer.spoke.GetRulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetRulesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetRulesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetRulesReply.displayName = 'proto.sands.live_dealer.spoke.GetRulesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetTablesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetTablesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetTablesRequest.displayName = 'proto.sands.live_dealer.spoke.GetTablesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetTablesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.GetTablesReply.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetTablesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetTablesReply.displayName = 'proto.sands.live_dealer.spoke.GetTablesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetTableRequest.displayName = 'proto.sands.live_dealer.spoke.GetTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetTablePlayDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetTablePlayDataRequest.displayName = 'proto.sands.live_dealer.spoke.GetTablePlayDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetTableHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetTableHistoryRequest.displayName = 'proto.sands.live_dealer.spoke.GetTableHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.StreamTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.StreamTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.StreamTableRequest.displayName = 'proto.sands.live_dealer.spoke.StreamTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.StreamTablePlayDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.displayName = 'proto.sands.live_dealer.spoke.StreamTablePlayDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.SitTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.SitTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.SitTableRequest.displayName = 'proto.sands.live_dealer.spoke.SitTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.StandTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.StandTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.StandTableRequest.displayName = 'proto.sands.live_dealer.spoke.StandTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetTableReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetTableReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetTableReply.displayName = 'proto.sands.live_dealer.spoke.GetTableReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.GetTableHistoryReply.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetTableHistoryReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetTableHistoryReply.displayName = 'proto.sands.live_dealer.spoke.GetTableHistoryReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.MakeWagersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.MakeWagersRequest.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.MakeWagersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.MakeWagersRequest.displayName = 'proto.sands.live_dealer.spoke.MakeWagersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.SeatWager = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.SeatWager, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.SeatWager.displayName = 'proto.sands.live_dealer.spoke.SeatWager';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.MakeWagersReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.MakeWagersReply.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.MakeWagersReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.MakeWagersReply.displayName = 'proto.sands.live_dealer.spoke.MakeWagersReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.MakeChoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.MakeChoiceRequest.displayName = 'proto.sands.live_dealer.spoke.MakeChoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.MakeChoiceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.MakeChoiceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.MakeChoiceReply.displayName = 'proto.sands.live_dealer.spoke.MakeChoiceReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetWagerHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetWagerHistoryRequest.displayName = 'proto.sands.live_dealer.spoke.GetWagerHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.GetWagerHistoryReply.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetWagerHistoryReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetWagerHistoryReply.displayName = 'proto.sands.live_dealer.spoke.GetWagerHistoryReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.WagerAndResolution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.WagerAndResolution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.WagerAndResolution.displayName = 'proto.sands.live_dealer.spoke.WagerAndResolution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetPlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetPlayRequest.displayName = 'proto.sands.live_dealer.spoke.GetPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetPlayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetPlayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetPlayReply.displayName = 'proto.sands.live_dealer.spoke.GetPlayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.GetTablePlayDataReply.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetTablePlayDataReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetTablePlayDataReply.displayName = 'proto.sands.live_dealer.spoke.GetTablePlayDataReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.NewPlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.NewPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.NewPlayRequest.displayName = 'proto.sands.live_dealer.spoke.NewPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.NewPlayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.NewPlayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.NewPlayReply.displayName = 'proto.sands.live_dealer.spoke.NewPlayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RunPlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RunPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RunPlayRequest.displayName = 'proto.sands.live_dealer.spoke.RunPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RunPlayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RunPlayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RunPlayReply.displayName = 'proto.sands.live_dealer.spoke.RunPlayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.displayName = 'proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DealtInputRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DealtInputRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DealtInputRequest.displayName = 'proto.sands.live_dealer.spoke.DealtInputRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DealtInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.DealtInput.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DealtInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DealtInput.displayName = 'proto.sands.live_dealer.spoke.DealtInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.displayName = 'proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DealtRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DealtRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DealtRequest.displayName = 'proto.sands.live_dealer.spoke.DealtRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DealerDeviceStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DealerDeviceStateRequest.displayName = 'proto.sands.live_dealer.spoke.DealerDeviceStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DealerDeviceStateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DealerDeviceStateReply.displayName = 'proto.sands.live_dealer.spoke.DealerDeviceStateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.TestDepositMoneyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.TestDepositMoneyRequest.displayName = 'proto.sands.live_dealer.spoke.TestDepositMoneyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.EmailRegisterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.EmailRegisterRequest.displayName = 'proto.sands.live_dealer.spoke.EmailRegisterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.LoginRequest.displayName = 'proto.sands.live_dealer.spoke.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.LoginReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.LoginReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.LoginReply.displayName = 'proto.sands.live_dealer.spoke.LoginReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetSidebetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetSidebetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetSidebetRequest.displayName = 'proto.sands.live_dealer.spoke.GetSidebetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetSidebetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetSidebetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetSidebetReply.displayName = 'proto.sands.live_dealer.spoke.GetSidebetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetPaytableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetPaytableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetPaytableRequest.displayName = 'proto.sands.live_dealer.spoke.GetPaytableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetPaytableReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetPaytableReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetPaytableReply.displayName = 'proto.sands.live_dealer.spoke.GetPaytableReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Sidebet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Sidebet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Sidebet.displayName = 'proto.sands.live_dealer.spoke.Sidebet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.Sidebet.SidebetRows.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Sidebet.SidebetRows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Sidebet.SidebetRows.displayName = 'proto.sands.live_dealer.spoke.Sidebet.SidebetRows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.SidebetRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.SidebetRow.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.SidebetRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.SidebetRow.displayName = 'proto.sands.live_dealer.spoke.SidebetRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.SidebetRow.ConditionList.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.SidebetRow.ConditionList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.SidebetRow.ConditionList.displayName = 'proto.sands.live_dealer.spoke.SidebetRow.ConditionList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Paytable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.Paytable.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Paytable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Paytable.displayName = 'proto.sands.live_dealer.spoke.Paytable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.Paytable.PaytableRows.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Paytable.PaytableRows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Paytable.PaytableRows.displayName = 'proto.sands.live_dealer.spoke.Paytable.PaytableRows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.PaytableRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.PaytableRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.PaytableRow.displayName = 'proto.sands.live_dealer.spoke.PaytableRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Rtp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Rtp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Rtp.displayName = 'proto.sands.live_dealer.spoke.Rtp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BaseCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.BaseCondition.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BaseCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BaseCondition.displayName = 'proto.sands.live_dealer.spoke.BaseCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BaccaratDefaultRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BaccaratDefaultRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BaccaratDefaultRules.displayName = 'proto.sands.live_dealer.spoke.BaccaratDefaultRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ConditionCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ConditionCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ConditionCard.displayName = 'proto.sands.live_dealer.spoke.ConditionCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.displayName = 'proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBaccaratHandWins, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBaccaratHandWins.displayName = 'proto.sands.live_dealer.spoke.sbcBaccaratHandWins';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.displayName = 'proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.displayName = 'proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBaccaratPointTotal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.displayName = 'proto.sands.live_dealer.spoke.sbcBaccaratPointTotal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBaccaratTie, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBaccaratTie.displayName = 'proto.sands.live_dealer.spoke.sbcBaccaratTie';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.displayName = 'proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBlackjack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBlackjack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBlackjack.displayName = 'proto.sands.live_dealer.spoke.sbcBlackjack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBlackjackBust, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBlackjackBust.displayName = 'proto.sands.live_dealer.spoke.sbcBlackjackBust';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBlackjackPointTotal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.displayName = 'proto.sands.live_dealer.spoke.sbcBlackjackPointTotal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.displayName = 'proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcCardColor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcCardColor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcCardColor.displayName = 'proto.sands.live_dealer.spoke.sbcCardColor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcHandLengthEquals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcHandLengthEquals.displayName = 'proto.sands.live_dealer.spoke.sbcHandLengthEquals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcHandLengthSame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcHandLengthSame.displayName = 'proto.sands.live_dealer.spoke.sbcHandLengthSame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcHandMatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcHandMatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcHandMatch.displayName = 'proto.sands.live_dealer.spoke.sbcHandMatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcHandMatchOrBetter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.displayName = 'proto.sands.live_dealer.spoke.sbcHandMatchOrBetter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.displayName = 'proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcMatchCards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.sbcMatchCards.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcMatchCards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcMatchCards.displayName = 'proto.sands.live_dealer.spoke.sbcMatchCards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.sbcMatchCardsRank.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcMatchCardsRank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcMatchCardsRank.displayName = 'proto.sands.live_dealer.spoke.sbcMatchCardsRank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.sbcMatchCardsWithin.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcMatchCardsWithin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcMatchCardsWithin.displayName = 'proto.sands.live_dealer.spoke.sbcMatchCardsWithin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.displayName = 'proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcMultiple = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcMultiple, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcMultiple.displayName = 'proto.sands.live_dealer.spoke.sbcMultiple';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.sbcMultipleSuited.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcMultipleSuited, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcMultipleSuited.displayName = 'proto.sands.live_dealer.spoke.sbcMultipleSuited';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcPair.displayName = 'proto.sands.live_dealer.spoke.sbcPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcPairEither = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcPairEither, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcPairEither.displayName = 'proto.sands.live_dealer.spoke.sbcPairEither';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.sbcPairMatchRank.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcPairMatchRank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcPairMatchRank.displayName = 'proto.sands.live_dealer.spoke.sbcPairMatchRank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.sbcPairMatchSuit.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcPairMatchSuit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcPairMatchSuit.displayName = 'proto.sands.live_dealer.spoke.sbcPairMatchSuit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcPairSame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcPairSame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcPairSame.displayName = 'proto.sands.live_dealer.spoke.sbcPairSame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.sbcSuited = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.sbcSuited.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.sbcSuited, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.sbcSuited.displayName = 'proto.sands.live_dealer.spoke.sbcSuited';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.FutureActionSetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.FutureActionSetRequest.displayName = 'proto.sands.live_dealer.spoke.FutureActionSetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.FutureActionSetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.FutureActionSetReply.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.FutureActionSetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.FutureActionSetReply.displayName = 'proto.sands.live_dealer.spoke.FutureActionSetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.FavoriteBet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.FavoriteBet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.FavoriteBet.displayName = 'proto.sands.live_dealer.spoke.FavoriteBet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.AddFavoriteBetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.AddFavoriteBetRequest.displayName = 'proto.sands.live_dealer.spoke.AddFavoriteBetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.AddFavoriteBetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.AddFavoriteBetResponse.displayName = 'proto.sands.live_dealer.spoke.AddFavoriteBetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetFavoriteBetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.displayName = 'proto.sands.live_dealer.spoke.GetFavoriteBetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GetFavoriteBetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.displayName = 'proto.sands.live_dealer.spoke.GetFavoriteBetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.displayName = 'proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.displayName = 'proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.displayName = 'proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.displayName = 'proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetSelfRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetSelfRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetSelfRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetSelfRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetSelfRequest}
 */
proto.sands.live_dealer.spoke.GetSelfRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetSelfRequest;
  return proto.sands.live_dealer.spoke.GetSelfRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetSelfRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetSelfRequest}
 */
proto.sands.live_dealer.spoke.GetSelfRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetSelfRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetSelfRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetSelfRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetSelfRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetSelfRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetSelfRequest} returns this
 */
proto.sands.live_dealer.spoke.GetSelfRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.StreamSelfRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.StreamSelfRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.StreamSelfRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.StreamSelfRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.StreamSelfRequest}
 */
proto.sands.live_dealer.spoke.StreamSelfRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.StreamSelfRequest;
  return proto.sands.live_dealer.spoke.StreamSelfRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.StreamSelfRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.StreamSelfRequest}
 */
proto.sands.live_dealer.spoke.StreamSelfRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.StreamSelfRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.StreamSelfRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.StreamSelfRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.StreamSelfRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.StreamSelfRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.StreamSelfRequest} returns this
 */
proto.sands.live_dealer.spoke.StreamSelfRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.UpdateDisplayNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.UpdateDisplayNameRequest}
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.UpdateDisplayNameRequest;
  return proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.UpdateDisplayNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.UpdateDisplayNameRequest}
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.UpdateDisplayNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdateDisplayNameRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdateDisplayNameRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdateDisplayNameRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.UpdateImageUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.UpdateImageUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.UpdateImageUrlRequest}
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.UpdateImageUrlRequest;
  return proto.sands.live_dealer.spoke.UpdateImageUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.UpdateImageUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.UpdateImageUrlRequest}
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.UpdateImageUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.UpdateImageUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdateImageUrlRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_url = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdateImageUrlRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdateImageUrlRequest.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.UpdatePreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.UpdatePreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preferencesMap: (f = msg.getPreferencesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.UpdatePreferencesRequest}
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.UpdatePreferencesRequest;
  return proto.sands.live_dealer.spoke.UpdatePreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.UpdatePreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.UpdatePreferencesRequest}
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = msg.getPreferencesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.UpdatePreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.UpdatePreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreferencesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdatePreferencesRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> preferences = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.prototype.getPreferencesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sands.live_dealer.spoke.UpdatePreferencesRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdatePreferencesRequest.prototype.clearPreferencesMap = function() {
  this.getPreferencesMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.GetSelfReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetSelfReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetSelfReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetSelfReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    playerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    balancesList: jspb.Message.toObjectList(msg.getBalancesList(),
    spoke_game_pb.Balance.toObject, includeInstance),
    profile: (f = msg.getProfile()) && proto.sands.live_dealer.spoke.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply}
 */
proto.sands.live_dealer.spoke.GetSelfReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetSelfReply;
  return proto.sands.live_dealer.spoke.GetSelfReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetSelfReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply}
 */
proto.sands.live_dealer.spoke.GetSelfReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 4:
      var value = new spoke_game_pb.Balance;
      reader.readMessage(value,spoke_game_pb.Balance.deserializeBinaryFromReader);
      msg.addBalances(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.Profile;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetSelfReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetSelfReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetSelfReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      spoke_game_pb.Balance.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply} returns this
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply} returns this
*/
proto.sands.live_dealer.spoke.GetSelfReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply} returns this
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 player_id = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply} returns this
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Balance balances = 4;
 * @return {!Array<!proto.sands.live_dealer.spoke.Balance>}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.getBalancesList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Balance>} */ (
    jspb.Message.getRepeatedWrapperField(this, spoke_game_pb.Balance, 4));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Balance>} value
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply} returns this
*/
proto.sands.live_dealer.spoke.GetSelfReply.prototype.setBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Balance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Balance}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.addBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sands.live_dealer.spoke.Balance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply} returns this
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.clearBalancesList = function() {
  return this.setBalancesList([]);
};


/**
 * optional Profile profile = 5;
 * @return {?proto.sands.live_dealer.spoke.Profile}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.getProfile = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Profile} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.Profile, 5));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Profile|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply} returns this
*/
proto.sands.live_dealer.spoke.GetSelfReply.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetSelfReply} returns this
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetSelfReply.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preferencesMap: (f = msg.getPreferencesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Profile}
 */
proto.sands.live_dealer.spoke.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Profile;
  return proto.sands.live_dealer.spoke.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Profile}
 */
proto.sands.live_dealer.spoke.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 3:
      var value = msg.getPreferencesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreferencesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Profile.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Profile} returns this
 */
proto.sands.live_dealer.spoke.Profile.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_url = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Profile.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Profile} returns this
 */
proto.sands.live_dealer.spoke.Profile.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> preferences = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.sands.live_dealer.spoke.Profile.prototype.getPreferencesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sands.live_dealer.spoke.Profile} returns this
 */
proto.sands.live_dealer.spoke.Profile.prototype.clearPreferencesMap = function() {
  this.getPreferencesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetRulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetRulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetRulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetRulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetRulesRequest}
 */
proto.sands.live_dealer.spoke.GetRulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetRulesRequest;
  return proto.sands.live_dealer.spoke.GetRulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetRulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetRulesRequest}
 */
proto.sands.live_dealer.spoke.GetRulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetRulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetRulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetRulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetRulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetRulesRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetRulesRequest} returns this
 */
proto.sands.live_dealer.spoke.GetRulesRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetRulesRequest.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetRulesRequest} returns this
 */
proto.sands.live_dealer.spoke.GetRulesRequest.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetRulesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetRulesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetRulesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && spoke_game_pb.Rules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetRulesReply}
 */
proto.sands.live_dealer.spoke.GetRulesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetRulesReply;
  return proto.sands.live_dealer.spoke.GetRulesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetRulesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetRulesReply}
 */
proto.sands.live_dealer.spoke.GetRulesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new spoke_game_pb.Rules;
      reader.readMessage(value,spoke_game_pb.Rules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetRulesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetRulesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetRulesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spoke_game_pb.Rules.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetRulesReply} returns this
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetRulesReply} returns this
*/
proto.sands.live_dealer.spoke.GetRulesReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetRulesReply} returns this
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Rules rules = 3;
 * @return {?proto.sands.live_dealer.spoke.Rules}
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.getRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Rules} */ (
    jspb.Message.getWrapperField(this, spoke_game_pb.Rules, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Rules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetRulesReply} returns this
*/
proto.sands.live_dealer.spoke.GetRulesReply.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetRulesReply} returns this
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetRulesReply.prototype.hasRules = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetTablesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetTablesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetTablesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTablesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetTablesRequest}
 */
proto.sands.live_dealer.spoke.GetTablesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetTablesRequest;
  return proto.sands.live_dealer.spoke.GetTablesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetTablesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetTablesRequest}
 */
proto.sands.live_dealer.spoke.GetTablesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetTablesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetTablesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetTablesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTablesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTablesRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTablesRequest} returns this
 */
proto.sands.live_dealer.spoke.GetTablesRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.GetTablesReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetTablesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetTablesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTablesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    tablesList: jspb.Message.toObjectList(msg.getTablesList(),
    spoke_game_pb.SimpleTable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetTablesReply}
 */
proto.sands.live_dealer.spoke.GetTablesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetTablesReply;
  return proto.sands.live_dealer.spoke.GetTablesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetTablesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetTablesReply}
 */
proto.sands.live_dealer.spoke.GetTablesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new spoke_game_pb.SimpleTable;
      reader.readMessage(value,spoke_game_pb.SimpleTable.deserializeBinaryFromReader);
      msg.addTables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetTablesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetTablesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTablesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getTablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      spoke_game_pb.SimpleTable.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetTablesReply} returns this
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetTablesReply} returns this
*/
proto.sands.live_dealer.spoke.GetTablesReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetTablesReply} returns this
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SimpleTable tables = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.SimpleTable>}
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.getTablesList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.SimpleTable>} */ (
    jspb.Message.getRepeatedWrapperField(this, spoke_game_pb.SimpleTable, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.SimpleTable>} value
 * @return {!proto.sands.live_dealer.spoke.GetTablesReply} returns this
*/
proto.sands.live_dealer.spoke.GetTablesReply.prototype.setTablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.SimpleTable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.SimpleTable}
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.addTables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.SimpleTable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.GetTablesReply} returns this
 */
proto.sands.live_dealer.spoke.GetTablesReply.prototype.clearTablesList = function() {
  return this.setTablesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetTableRequest}
 */
proto.sands.live_dealer.spoke.GetTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetTableRequest;
  return proto.sands.live_dealer.spoke.GetTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetTableRequest}
 */
proto.sands.live_dealer.spoke.GetTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTableRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTableRequest} returns this
 */
proto.sands.live_dealer.spoke.GetTableRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTableRequest} returns this
 */
proto.sands.live_dealer.spoke.GetTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetTablePlayDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetTablePlayDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataRequest}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetTablePlayDataRequest;
  return proto.sands.live_dealer.spoke.GetTablePlayDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetTablePlayDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataRequest}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetTablePlayDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetTablePlayDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataRequest} returns this
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataRequest} returns this
 */
proto.sands.live_dealer.spoke.GetTablePlayDataRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetTableHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetTableHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryRequest}
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetTableHistoryRequest;
  return proto.sands.live_dealer.spoke.GetTableHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetTableHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryRequest}
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetTableHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetTableHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryRequest} returns this
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryRequest} returns this
 */
proto.sands.live_dealer.spoke.GetTableHistoryRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.StreamTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.StreamTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.StreamTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.StreamTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.StreamTableRequest}
 */
proto.sands.live_dealer.spoke.StreamTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.StreamTableRequest;
  return proto.sands.live_dealer.spoke.StreamTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.StreamTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.StreamTableRequest}
 */
proto.sands.live_dealer.spoke.StreamTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.StreamTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.StreamTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.StreamTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.StreamTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.StreamTableRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.StreamTableRequest} returns this
 */
proto.sands.live_dealer.spoke.StreamTableRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.StreamTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.StreamTableRequest} returns this
 */
proto.sands.live_dealer.spoke.StreamTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.StreamTablePlayDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.StreamTablePlayDataRequest}
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.StreamTablePlayDataRequest;
  return proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.StreamTablePlayDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.StreamTablePlayDataRequest}
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.StreamTablePlayDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.StreamTablePlayDataRequest} returns this
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.StreamTablePlayDataRequest} returns this
 */
proto.sands.live_dealer.spoke.StreamTablePlayDataRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.SitTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.SitTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.SitTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SitTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seatNum: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.SitTableRequest}
 */
proto.sands.live_dealer.spoke.SitTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.SitTableRequest;
  return proto.sands.live_dealer.spoke.SitTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.SitTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.SitTableRequest}
 */
proto.sands.live_dealer.spoke.SitTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeatNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.SitTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.SitTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.SitTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SitTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SitTableRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SitTableRequest} returns this
 */
proto.sands.live_dealer.spoke.SitTableRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SitTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SitTableRequest} returns this
 */
proto.sands.live_dealer.spoke.SitTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 seat_num = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.SitTableRequest.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.SitTableRequest} returns this
 */
proto.sands.live_dealer.spoke.SitTableRequest.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.StandTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.StandTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.StandTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.StandTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seatNum: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.StandTableRequest}
 */
proto.sands.live_dealer.spoke.StandTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.StandTableRequest;
  return proto.sands.live_dealer.spoke.StandTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.StandTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.StandTableRequest}
 */
proto.sands.live_dealer.spoke.StandTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeatNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.StandTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.StandTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.StandTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.StandTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.StandTableRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.StandTableRequest} returns this
 */
proto.sands.live_dealer.spoke.StandTableRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.StandTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.StandTableRequest} returns this
 */
proto.sands.live_dealer.spoke.StandTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 seat_num = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.StandTableRequest.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.StandTableRequest} returns this
 */
proto.sands.live_dealer.spoke.StandTableRequest.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetTableReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetTableReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTableReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    table: (f = msg.getTable()) && spoke_game_pb.Table.toObject(includeInstance, f),
    hubServerTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    serverTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetTableReply}
 */
proto.sands.live_dealer.spoke.GetTableReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetTableReply;
  return proto.sands.live_dealer.spoke.GetTableReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetTableReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetTableReply}
 */
proto.sands.live_dealer.spoke.GetTableReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new spoke_game_pb.Table;
      reader.readMessage(value,spoke_game_pb.Table.deserializeBinaryFromReader);
      msg.setTable(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHubServerTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetTableReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetTableReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTableReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getTable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spoke_game_pb.Table.serializeBinaryToWriter
    );
  }
  f = message.getHubServerTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getServerTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetTableReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetTableReply} returns this
*/
proto.sands.live_dealer.spoke.GetTableReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetTableReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Table table = 3;
 * @return {?proto.sands.live_dealer.spoke.Table}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.getTable = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Table} */ (
    jspb.Message.getWrapperField(this, spoke_game_pb.Table, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Table|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetTableReply} returns this
*/
proto.sands.live_dealer.spoke.GetTableReply.prototype.setTable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetTableReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.clearTable = function() {
  return this.setTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.hasTable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 hub_server_time = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.getHubServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetTableReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.setHubServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 server_time = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.getServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetTableReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableReply.prototype.setServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetTableHistoryReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetTableHistoryReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    tableId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    spoke_game_pb.PlaySummary.toObject, includeInstance),
    baccaratHistory: (f = msg.getBaccaratHistory()) && spoke_game_pb.BaccaratHistory.toObject(includeInstance, f),
    dragonTigerHistory: (f = msg.getDragonTigerHistory()) && spoke_game_pb.DragonTigerHistory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetTableHistoryReply;
  return proto.sands.live_dealer.spoke.GetTableHistoryReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetTableHistoryReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 4:
      var value = new spoke_game_pb.PlaySummary;
      reader.readMessage(value,spoke_game_pb.PlaySummary.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 5:
      var value = new spoke_game_pb.BaccaratHistory;
      reader.readMessage(value,spoke_game_pb.BaccaratHistory.deserializeBinaryFromReader);
      msg.setBaccaratHistory(value);
      break;
    case 6:
      var value = new spoke_game_pb.DragonTigerHistory;
      reader.readMessage(value,spoke_game_pb.DragonTigerHistory.deserializeBinaryFromReader);
      msg.setDragonTigerHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetTableHistoryReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetTableHistoryReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      spoke_game_pb.PlaySummary.serializeBinaryToWriter
    );
  }
  f = message.getBaccaratHistory();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spoke_game_pb.BaccaratHistory.serializeBinaryToWriter
    );
  }
  f = message.getDragonTigerHistory();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spoke_game_pb.DragonTigerHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
*/
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string table_id = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PlaySummary history = 4;
 * @return {!Array<!proto.sands.live_dealer.spoke.PlaySummary>}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.PlaySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, spoke_game_pb.PlaySummary, 4));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.PlaySummary>} value
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
*/
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.PlaySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.PlaySummary}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sands.live_dealer.spoke.PlaySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * optional BaccaratHistory baccarat_history = 5;
 * @return {?proto.sands.live_dealer.spoke.BaccaratHistory}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.getBaccaratHistory = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratHistory} */ (
    jspb.Message.getWrapperField(this, spoke_game_pb.BaccaratHistory, 5));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratHistory|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
*/
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.setBaccaratHistory = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.clearBaccaratHistory = function() {
  return this.setBaccaratHistory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.hasBaccaratHistory = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DragonTigerHistory dragon_tiger_history = 6;
 * @return {?proto.sands.live_dealer.spoke.DragonTigerHistory}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.getDragonTigerHistory = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DragonTigerHistory} */ (
    jspb.Message.getWrapperField(this, spoke_game_pb.DragonTigerHistory, 6));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DragonTigerHistory|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
*/
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.setDragonTigerHistory = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetTableHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.clearDragonTigerHistory = function() {
  return this.setDragonTigerHistory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTableHistoryReply.prototype.hasDragonTigerHistory = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.MakeWagersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.MakeWagersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    wagersList: jspb.Message.toObjectList(msg.getWagersList(),
    proto.sands.live_dealer.spoke.SeatWager.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.MakeWagersRequest}
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.MakeWagersRequest;
  return proto.sands.live_dealer.spoke.MakeWagersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.MakeWagersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.MakeWagersRequest}
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.SeatWager;
      reader.readMessage(value,proto.sands.live_dealer.spoke.SeatWager.deserializeBinaryFromReader);
      msg.addWagers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.MakeWagersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.MakeWagersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.SeatWager.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.MakeWagersRequest} returns this
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.MakeWagersRequest} returns this
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SeatWager wagers = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.SeatWager>}
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.getWagersList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.SeatWager>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.SeatWager, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.SeatWager>} value
 * @return {!proto.sands.live_dealer.spoke.MakeWagersRequest} returns this
*/
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.setWagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.SeatWager=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.SeatWager}
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.addWagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.SeatWager, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.MakeWagersRequest} returns this
 */
proto.sands.live_dealer.spoke.MakeWagersRequest.prototype.clearWagersList = function() {
  return this.setWagersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.SeatWager.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.SeatWager} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SeatWager.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    wagerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.SeatWager}
 */
proto.sands.live_dealer.spoke.SeatWager.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.SeatWager;
  return proto.sands.live_dealer.spoke.SeatWager.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.SeatWager} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.SeatWager}
 */
proto.sands.live_dealer.spoke.SeatWager.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeatNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWagerName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.SeatWager.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.SeatWager} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SeatWager.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWagerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 seat_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.SeatWager} returns this
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string wager_name = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.getWagerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SeatWager} returns this
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.setWagerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 amount = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.SeatWager} returns this
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SeatWager} returns this
 */
proto.sands.live_dealer.spoke.SeatWager.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.MakeWagersReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.MakeWagersReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.MakeWagersReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.MakeWagersReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    activeWagersList: jspb.Message.toObjectList(msg.getActiveWagersList(),
    spoke_game_pb.ActiveWager.toObject, includeInstance),
    serverTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    insufficientFunds: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.MakeWagersReply;
  return proto.sands.live_dealer.spoke.MakeWagersReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.MakeWagersReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new spoke_game_pb.ActiveWager;
      reader.readMessage(value,spoke_game_pb.ActiveWager.deserializeBinaryFromReader);
      msg.addActiveWagers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsufficientFunds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.MakeWagersReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.MakeWagersReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.MakeWagersReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getActiveWagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      spoke_game_pb.ActiveWager.serializeBinaryToWriter
    );
  }
  f = message.getServerTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getInsufficientFunds();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply} returns this
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply} returns this
*/
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply} returns this
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ActiveWager active_wagers = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.ActiveWager>}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.getActiveWagersList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ActiveWager>} */ (
    jspb.Message.getRepeatedWrapperField(this, spoke_game_pb.ActiveWager, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ActiveWager>} value
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply} returns this
*/
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.setActiveWagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ActiveWager=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ActiveWager}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.addActiveWagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.ActiveWager, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply} returns this
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.clearActiveWagersList = function() {
  return this.setActiveWagersList([]);
};


/**
 * optional int64 server_time = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.getServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply} returns this
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.setServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool insufficient_funds = 5;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.getInsufficientFunds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.MakeWagersReply} returns this
 */
proto.sands.live_dealer.spoke.MakeWagersReply.prototype.setInsufficientFunds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.MakeChoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.MakeChoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    step: jspb.Message.getFieldWithDefault(msg, 3, 0),
    action: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceRequest}
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.MakeChoiceRequest;
  return proto.sands.live_dealer.spoke.MakeChoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.MakeChoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceRequest}
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.MakeChoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.MakeChoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStep();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceRequest} returns this
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceRequest} returns this
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 step = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceRequest} returns this
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.setStep = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceRequest} returns this
 */
proto.sands.live_dealer.spoke.MakeChoiceRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.MakeChoiceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.MakeChoiceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    insufficientFunds: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceReply}
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.MakeChoiceReply;
  return proto.sands.live_dealer.spoke.MakeChoiceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.MakeChoiceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceReply}
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsufficientFunds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.MakeChoiceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.MakeChoiceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getInsufficientFunds();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceReply} returns this
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceReply} returns this
*/
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceReply} returns this
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool insufficient_funds = 3;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.getInsufficientFunds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.MakeChoiceReply} returns this
 */
proto.sands.live_dealer.spoke.MakeChoiceReply.prototype.setInsufficientFunds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetWagerHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetWagerHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryRequest}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetWagerHistoryRequest;
  return proto.sands.live_dealer.spoke.GetWagerHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetWagerHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryRequest}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageNum(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetWagerHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetWagerHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageNum();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryRequest} returns this
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 page_num = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.prototype.getPageNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryRequest} returns this
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.prototype.setPageNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 per_page = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryRequest} returns this
 */
proto.sands.live_dealer.spoke.GetWagerHistoryRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetWagerHistoryReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    wagersList: jspb.Message.toObjectList(msg.getWagersList(),
    proto.sands.live_dealer.spoke.WagerAndResolution.toObject, includeInstance),
    serverTime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryReply}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetWagerHistoryReply;
  return proto.sands.live_dealer.spoke.GetWagerHistoryReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryReply}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.WagerAndResolution;
      reader.readMessage(value,proto.sands.live_dealer.spoke.WagerAndResolution.deserializeBinaryFromReader);
      msg.addWagers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetWagerHistoryReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getWagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.WagerAndResolution.serializeBinaryToWriter
    );
  }
  f = message.getServerTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} returns this
*/
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated WagerAndResolution wagers = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.WagerAndResolution>}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.getWagersList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.WagerAndResolution>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.WagerAndResolution, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.WagerAndResolution>} value
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} returns this
*/
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.setWagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.WagerAndResolution=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.WagerAndResolution}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.addWagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.WagerAndResolution, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.clearWagersList = function() {
  return this.setWagersList([]);
};


/**
 * optional int64 server_time = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.getServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetWagerHistoryReply} returns this
 */
proto.sands.live_dealer.spoke.GetWagerHistoryReply.prototype.setServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.WagerAndResolution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.WagerAndResolution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.WagerAndResolution.toObject = function(includeInstance, msg) {
  var f, obj = {
    playId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    wager: (f = msg.getWager()) && spoke_game_pb.ActiveWager.toObject(includeInstance, f),
    resolution: (f = msg.getResolution()) && spoke_game_pb.Resolution.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.WagerAndResolution}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.WagerAndResolution;
  return proto.sands.live_dealer.spoke.WagerAndResolution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.WagerAndResolution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.WagerAndResolution}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 2:
      var value = new spoke_game_pb.ActiveWager;
      reader.readMessage(value,spoke_game_pb.ActiveWager.deserializeBinaryFromReader);
      msg.setWager(value);
      break;
    case 3:
      var value = new spoke_game_pb.Resolution;
      reader.readMessage(value,spoke_game_pb.Resolution.deserializeBinaryFromReader);
      msg.setResolution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.WagerAndResolution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.WagerAndResolution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.WagerAndResolution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getWager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_game_pb.ActiveWager.serializeBinaryToWriter
    );
  }
  f = message.getResolution();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spoke_game_pb.Resolution.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 play_id = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.WagerAndResolution} returns this
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ActiveWager wager = 2;
 * @return {?proto.sands.live_dealer.spoke.ActiveWager}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.getWager = function() {
  return /** @type{?proto.sands.live_dealer.spoke.ActiveWager} */ (
    jspb.Message.getWrapperField(this, spoke_game_pb.ActiveWager, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.ActiveWager|undefined} value
 * @return {!proto.sands.live_dealer.spoke.WagerAndResolution} returns this
*/
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.setWager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.WagerAndResolution} returns this
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.clearWager = function() {
  return this.setWager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.hasWager = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Resolution resolution = 3;
 * @return {?proto.sands.live_dealer.spoke.Resolution}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.getResolution = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Resolution} */ (
    jspb.Message.getWrapperField(this, spoke_game_pb.Resolution, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Resolution|undefined} value
 * @return {!proto.sands.live_dealer.spoke.WagerAndResolution} returns this
*/
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.setResolution = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.WagerAndResolution} returns this
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.clearResolution = function() {
  return this.setResolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.WagerAndResolution.prototype.hasResolution = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetPlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetPlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetPlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetPlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetPlayRequest}
 */
proto.sands.live_dealer.spoke.GetPlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetPlayRequest;
  return proto.sands.live_dealer.spoke.GetPlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetPlayRequest}
 */
proto.sands.live_dealer.spoke.GetPlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetPlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetPlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetPlayRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetPlayRequest} returns this
 */
proto.sands.live_dealer.spoke.GetPlayRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetPlayRequest.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetPlayRequest} returns this
 */
proto.sands.live_dealer.spoke.GetPlayRequest.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetPlayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetPlayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetPlayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    play: (f = msg.getPlay()) && spoke_game_pb.Play.toObject(includeInstance, f),
    hubServerTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    serverTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply}
 */
proto.sands.live_dealer.spoke.GetPlayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetPlayReply;
  return proto.sands.live_dealer.spoke.GetPlayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetPlayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply}
 */
proto.sands.live_dealer.spoke.GetPlayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new spoke_game_pb.Play;
      reader.readMessage(value,spoke_game_pb.Play.deserializeBinaryFromReader);
      msg.setPlay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHubServerTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetPlayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetPlayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetPlayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getPlay();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spoke_game_pb.Play.serializeBinaryToWriter
    );
  }
  f = message.getHubServerTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getServerTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply} returns this
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply} returns this
*/
proto.sands.live_dealer.spoke.GetPlayReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply} returns this
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Play play = 3;
 * @return {?proto.sands.live_dealer.spoke.Play}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.getPlay = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Play} */ (
    jspb.Message.getWrapperField(this, spoke_game_pb.Play, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Play|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply} returns this
*/
proto.sands.live_dealer.spoke.GetPlayReply.prototype.setPlay = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply} returns this
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.clearPlay = function() {
  return this.setPlay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.hasPlay = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 hub_server_time = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.getHubServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply} returns this
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.setHubServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 server_time = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.getServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetPlayReply} returns this
 */
proto.sands.live_dealer.spoke.GetPlayReply.prototype.setServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetTablePlayDataReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    playId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deviceState: jspb.Message.getFieldWithDefault(msg, 4, ""),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    spoke_game_pb.PlaySeatData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetTablePlayDataReply;
  return proto.sands.live_dealer.spoke.GetTablePlayDataReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceState(value);
      break;
    case 5:
      var value = new spoke_game_pb.PlaySeatData;
      reader.readMessage(value,spoke_game_pb.PlaySeatData.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetTablePlayDataReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getDeviceState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      spoke_game_pb.PlaySeatData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} returns this
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} returns this
*/
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} returns this
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 play_id = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} returns this
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string device_state = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.getDeviceState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} returns this
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.setDeviceState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated PlaySeatData seats = 5;
 * @return {!Array<!proto.sands.live_dealer.spoke.PlaySeatData>}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.PlaySeatData>} */ (
    jspb.Message.getRepeatedWrapperField(this, spoke_game_pb.PlaySeatData, 5));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.PlaySeatData>} value
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} returns this
*/
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.PlaySeatData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.PlaySeatData}
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sands.live_dealer.spoke.PlaySeatData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.GetTablePlayDataReply} returns this
 */
proto.sands.live_dealer.spoke.GetTablePlayDataReply.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.NewPlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.NewPlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.NewPlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.NewPlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.NewPlayRequest}
 */
proto.sands.live_dealer.spoke.NewPlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.NewPlayRequest;
  return proto.sands.live_dealer.spoke.NewPlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.NewPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.NewPlayRequest}
 */
proto.sands.live_dealer.spoke.NewPlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.NewPlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.NewPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.NewPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.NewPlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.NewPlayRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.NewPlayRequest} returns this
 */
proto.sands.live_dealer.spoke.NewPlayRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.NewPlayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.NewPlayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.NewPlayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.NewPlayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.NewPlayReply}
 */
proto.sands.live_dealer.spoke.NewPlayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.NewPlayReply;
  return proto.sands.live_dealer.spoke.NewPlayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.NewPlayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.NewPlayReply}
 */
proto.sands.live_dealer.spoke.NewPlayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.NewPlayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.NewPlayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.NewPlayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.NewPlayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.NewPlayReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.NewPlayReply} returns this
 */
proto.sands.live_dealer.spoke.NewPlayReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.NewPlayReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.NewPlayReply} returns this
*/
proto.sands.live_dealer.spoke.NewPlayReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.NewPlayReply} returns this
 */
proto.sands.live_dealer.spoke.NewPlayReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.NewPlayReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RunPlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RunPlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RunPlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RunPlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RunPlayRequest}
 */
proto.sands.live_dealer.spoke.RunPlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RunPlayRequest;
  return proto.sands.live_dealer.spoke.RunPlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RunPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RunPlayRequest}
 */
proto.sands.live_dealer.spoke.RunPlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RunPlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RunPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RunPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RunPlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RunPlayRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RunPlayRequest} returns this
 */
proto.sands.live_dealer.spoke.RunPlayRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RunPlayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RunPlayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RunPlayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RunPlayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RunPlayReply}
 */
proto.sands.live_dealer.spoke.RunPlayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RunPlayReply;
  return proto.sands.live_dealer.spoke.RunPlayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RunPlayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RunPlayReply}
 */
proto.sands.live_dealer.spoke.RunPlayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RunPlayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RunPlayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RunPlayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RunPlayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.RunPlayReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.RunPlayReply} returns this
 */
proto.sands.live_dealer.spoke.RunPlayReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.RunPlayReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.RunPlayReply} returns this
*/
proto.sands.live_dealer.spoke.RunPlayReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.RunPlayReply} returns this
 */
proto.sands.live_dealer.spoke.RunPlayReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.RunPlayReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest;
  return proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest} returns this
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DealtInputRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DealtInputRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DealtInputRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealtInputRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    input: (f = msg.getInput()) && proto.sands.live_dealer.spoke.DealtInput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DealtInputRequest}
 */
proto.sands.live_dealer.spoke.DealtInputRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DealtInputRequest;
  return proto.sands.live_dealer.spoke.DealtInputRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DealtInputRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DealtInputRequest}
 */
proto.sands.live_dealer.spoke.DealtInputRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.DealtInput;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DealtInput.deserializeBinaryFromReader);
      msg.setInput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DealtInputRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DealtInputRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DealtInputRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealtInputRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInput();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.DealtInput.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DealtInputRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DealtInputRequest} returns this
 */
proto.sands.live_dealer.spoke.DealtInputRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DealtInput input = 2;
 * @return {?proto.sands.live_dealer.spoke.DealtInput}
 */
proto.sands.live_dealer.spoke.DealtInputRequest.prototype.getInput = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DealtInput} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DealtInput, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DealtInput|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DealtInputRequest} returns this
*/
proto.sands.live_dealer.spoke.DealtInputRequest.prototype.setInput = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DealtInputRequest} returns this
 */
proto.sands.live_dealer.spoke.DealtInputRequest.prototype.clearInput = function() {
  return this.setInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DealtInputRequest.prototype.hasInput = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.DealtInput.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DealtInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DealtInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealtInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inputNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    request: jspb.Message.getFieldWithDefault(msg, 3, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DealtInput}
 */
proto.sands.live_dealer.spoke.DealtInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DealtInput;
  return proto.sands.live_dealer.spoke.DealtInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DealtInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DealtInput}
 */
proto.sands.live_dealer.spoke.DealtInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInputNum(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DealtInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DealtInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealtInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInputNum();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DealtInput} returns this
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 input_num = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.getInputNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DealtInput} returns this
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.setInputNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string request = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DealtInput} returns this
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string values = 4;
 * @return {!Array<string>}
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sands.live_dealer.spoke.DealtInput} returns this
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DealtInput} returns this
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DealtInput} returns this
 */
proto.sands.live_dealer.spoke.DealtInput.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.sands.live_dealer.spoke.DealtRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply;
  return proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.DealtRequest;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DealtRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.DealtRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply} returns this
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply} returns this
*/
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply} returns this
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated DealtRequest requests = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.DealtRequest>}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DealtRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DealtRequest, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DealtRequest>} value
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply} returns this
*/
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DealtRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DealtRequest}
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.DealtRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply} returns this
 */
proto.sands.live_dealer.spoke.GetDealerDeviceRequestsReply.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DealtRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DealtRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DealtRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealtRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inputNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    request: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DealtRequest}
 */
proto.sands.live_dealer.spoke.DealtRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DealtRequest;
  return proto.sands.live_dealer.spoke.DealtRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DealtRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DealtRequest}
 */
proto.sands.live_dealer.spoke.DealtRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInputNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DealtRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DealtRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DealtRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealtRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInputNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 input_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DealtRequest.prototype.getInputNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DealtRequest} returns this
 */
proto.sands.live_dealer.spoke.DealtRequest.prototype.setInputNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string request = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DealtRequest.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DealtRequest} returns this
 */
proto.sands.live_dealer.spoke.DealtRequest.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 request_count = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DealtRequest.prototype.getRequestCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DealtRequest} returns this
 */
proto.sands.live_dealer.spoke.DealtRequest.prototype.setRequestCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DealerDeviceStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DealerDeviceStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceState: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateRequest}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DealerDeviceStateRequest;
  return proto.sands.live_dealer.spoke.DealerDeviceStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DealerDeviceStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateRequest}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DealerDeviceStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DealerDeviceStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateRequest} returns this
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_state = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.prototype.getDeviceState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateRequest} returns this
 */
proto.sands.live_dealer.spoke.DealerDeviceStateRequest.prototype.setDeviceState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DealerDeviceStateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DealerDeviceStateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateReply}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DealerDeviceStateReply;
  return proto.sands.live_dealer.spoke.DealerDeviceStateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DealerDeviceStateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateReply}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DealerDeviceStateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DealerDeviceStateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateReply} returns this
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateReply} returns this
*/
proto.sands.live_dealer.spoke.DealerDeviceStateReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DealerDeviceStateReply} returns this
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DealerDeviceStateReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.TestDepositMoneyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.TestDepositMoneyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.TestDepositMoneyRequest}
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.TestDepositMoneyRequest;
  return proto.sands.live_dealer.spoke.TestDepositMoneyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.TestDepositMoneyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.TestDepositMoneyRequest}
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.TestDepositMoneyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.TestDepositMoneyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.TestDepositMoneyRequest} returns this
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.TestDepositMoneyRequest} returns this
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.TestDepositMoneyRequest} returns this
 */
proto.sands.live_dealer.spoke.TestDepositMoneyRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.EmailRegisterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.EmailRegisterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    preferencesMap: (f = msg.getPreferencesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.EmailRegisterRequest}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.EmailRegisterRequest;
  return proto.sands.live_dealer.spoke.EmailRegisterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.EmailRegisterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.EmailRegisterRequest}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = msg.getPreferencesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.EmailRegisterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.EmailRegisterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPreferencesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.EmailRegisterRequest} returns this
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.EmailRegisterRequest} returns this
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.EmailRegisterRequest} returns this
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.EmailRegisterRequest} returns this
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> preferences = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.getPreferencesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sands.live_dealer.spoke.EmailRegisterRequest} returns this
 */
proto.sands.live_dealer.spoke.EmailRegisterRequest.prototype.clearPreferencesMap = function() {
  this.getPreferencesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.LoginRequest}
 */
proto.sands.live_dealer.spoke.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.LoginRequest;
  return proto.sands.live_dealer.spoke.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.LoginRequest}
 */
proto.sands.live_dealer.spoke.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.LoginRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.LoginRequest} returns this
 */
proto.sands.live_dealer.spoke.LoginRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.LoginRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.LoginRequest} returns this
 */
proto.sands.live_dealer.spoke.LoginRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.LoginReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.LoginReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.LoginReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    playerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    token: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.LoginReply}
 */
proto.sands.live_dealer.spoke.LoginReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.LoginReply;
  return proto.sands.live_dealer.spoke.LoginReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.LoginReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.LoginReply}
 */
proto.sands.live_dealer.spoke.LoginReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.LoginReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.LoginReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.LoginReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.LoginReply} returns this
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.LoginReply} returns this
*/
proto.sands.live_dealer.spoke.LoginReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.LoginReply} returns this
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 player_id = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.LoginReply} returns this
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.LoginReply} returns this
 */
proto.sands.live_dealer.spoke.LoginReply.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetSidebetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetSidebetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    collection: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sidebetdescriptor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetSidebetRequest}
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetSidebetRequest;
  return proto.sands.live_dealer.spoke.GetSidebetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetSidebetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetSidebetRequest}
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollection(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSidebetdescriptor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetSidebetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetSidebetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCollection();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSidebetdescriptor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetSidebetRequest} returns this
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string collection = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.getCollection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetSidebetRequest} returns this
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.setCollection = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SideBetDescriptor = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.getSidebetdescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetSidebetRequest} returns this
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.setSidebetdescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 version = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetSidebetRequest} returns this
 */
proto.sands.live_dealer.spoke.GetSidebetRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetSidebetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetSidebetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetSidebetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    sidebet: (f = msg.getSidebet()) && proto.sands.live_dealer.spoke.Sidebet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetSidebetReply}
 */
proto.sands.live_dealer.spoke.GetSidebetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetSidebetReply;
  return proto.sands.live_dealer.spoke.GetSidebetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetSidebetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetSidebetReply}
 */
proto.sands.live_dealer.spoke.GetSidebetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.Sidebet;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Sidebet.deserializeBinaryFromReader);
      msg.setSidebet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetSidebetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetSidebetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetSidebetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getSidebet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.Sidebet.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetSidebetReply} returns this
*/
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetSidebetReply} returns this
 */
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Sidebet sidebet = 2;
 * @return {?proto.sands.live_dealer.spoke.Sidebet}
 */
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.getSidebet = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Sidebet} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.Sidebet, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Sidebet|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetSidebetReply} returns this
*/
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.setSidebet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetSidebetReply} returns this
 */
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.clearSidebet = function() {
  return this.setSidebet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetSidebetReply.prototype.hasSidebet = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetPaytableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetPaytableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    collection: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sidebetdescriptor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paytabledescriptor: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetPaytableRequest}
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetPaytableRequest;
  return proto.sands.live_dealer.spoke.GetPaytableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetPaytableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetPaytableRequest}
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollection(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSidebetdescriptor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaytabledescriptor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetPaytableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetPaytableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCollection();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSidebetdescriptor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaytabledescriptor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetPaytableRequest} returns this
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string collection = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.getCollection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetPaytableRequest} returns this
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.setCollection = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sideBetDescriptor = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.getSidebetdescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetPaytableRequest} returns this
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.setSidebetdescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string paytableDescriptor = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.getPaytabledescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetPaytableRequest} returns this
 */
proto.sands.live_dealer.spoke.GetPaytableRequest.prototype.setPaytabledescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetPaytableReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetPaytableReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetPaytableReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    paytable: (f = msg.getPaytable()) && proto.sands.live_dealer.spoke.Paytable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetPaytableReply}
 */
proto.sands.live_dealer.spoke.GetPaytableReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetPaytableReply;
  return proto.sands.live_dealer.spoke.GetPaytableReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetPaytableReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetPaytableReply}
 */
proto.sands.live_dealer.spoke.GetPaytableReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.Paytable;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Paytable.deserializeBinaryFromReader);
      msg.setPaytable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetPaytableReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetPaytableReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetPaytableReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getPaytable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.Paytable.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetPaytableReply} returns this
*/
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetPaytableReply} returns this
 */
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Paytable paytable = 2;
 * @return {?proto.sands.live_dealer.spoke.Paytable}
 */
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.getPaytable = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Paytable} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.Paytable, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Paytable|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetPaytableReply} returns this
*/
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.setPaytable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetPaytableReply} returns this
 */
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.clearPaytable = function() {
  return this.setPaytable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetPaytableReply.prototype.hasPaytable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Sidebet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Sidebet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Sidebet.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    collection: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rowsMap: (f = msg.getRowsMap()) ? f.toObject(includeInstance, proto.sands.live_dealer.spoke.Sidebet.SidebetRows.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Sidebet}
 */
proto.sands.live_dealer.spoke.Sidebet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Sidebet;
  return proto.sands.live_dealer.spoke.Sidebet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Sidebet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Sidebet}
 */
proto.sands.live_dealer.spoke.Sidebet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollection(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 4:
      var value = msg.getRowsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.sands.live_dealer.spoke.Sidebet.SidebetRows.deserializeBinaryFromReader, "", new proto.sands.live_dealer.spoke.Sidebet.SidebetRows());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Sidebet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Sidebet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Sidebet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCollection();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRowsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.sands.live_dealer.spoke.Sidebet.SidebetRows.serializeBinaryToWriter);
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Sidebet.SidebetRows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Sidebet.SidebetRows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.sands.live_dealer.spoke.SidebetRow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Sidebet.SidebetRows}
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Sidebet.SidebetRows;
  return proto.sands.live_dealer.spoke.Sidebet.SidebetRows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Sidebet.SidebetRows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Sidebet.SidebetRows}
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.SidebetRow;
      reader.readMessage(value,proto.sands.live_dealer.spoke.SidebetRow.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Sidebet.SidebetRows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Sidebet.SidebetRows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.SidebetRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SidebetRow rows = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.SidebetRow>}
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.SidebetRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.SidebetRow, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.SidebetRow>} value
 * @return {!proto.sands.live_dealer.spoke.Sidebet.SidebetRows} returns this
*/
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.SidebetRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.SidebetRow}
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.SidebetRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Sidebet.SidebetRows} returns this
 */
proto.sands.live_dealer.spoke.Sidebet.SidebetRows.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string Descriptor = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.getDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Sidebet} returns this
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.setDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Collection = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.getCollection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Sidebet} returns this
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.setCollection = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 Version = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Sidebet} returns this
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, SidebetRows> Rows = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.sands.live_dealer.spoke.Sidebet.SidebetRows>}
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.getRowsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.sands.live_dealer.spoke.Sidebet.SidebetRows>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.sands.live_dealer.spoke.Sidebet.SidebetRows));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sands.live_dealer.spoke.Sidebet} returns this
 */
proto.sands.live_dealer.spoke.Sidebet.prototype.clearRowsMap = function() {
  this.getRowsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.SidebetRow.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.SidebetRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.SidebetRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SidebetRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    group: jspb.Message.getFieldWithDefault(msg, 2, ""),
    playercardlimiter: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dealercardlimiter: jspb.Message.getFieldWithDefault(msg, 4, 0),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    google_protobuf_any_pb.Any.toObject, includeInstance),
    conditionsorList: jspb.Message.toObjectList(msg.getConditionsorList(),
    proto.sands.live_dealer.spoke.SidebetRow.ConditionList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.SidebetRow}
 */
proto.sands.live_dealer.spoke.SidebetRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.SidebetRow;
  return proto.sands.live_dealer.spoke.SidebetRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.SidebetRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.SidebetRow}
 */
proto.sands.live_dealer.spoke.SidebetRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercardlimiter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDealercardlimiter(value);
      break;
    case 5:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 6:
      var value = new proto.sands.live_dealer.spoke.SidebetRow.ConditionList;
      reader.readMessage(value,proto.sands.live_dealer.spoke.SidebetRow.ConditionList.deserializeBinaryFromReader);
      msg.addConditionsor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.SidebetRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.SidebetRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SidebetRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlayercardlimiter();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDealercardlimiter();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getConditionsorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.sands.live_dealer.spoke.SidebetRow.ConditionList.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.SidebetRow.ConditionList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    google_protobuf_any_pb.Any.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList}
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.SidebetRow.ConditionList;
  return proto.sands.live_dealer.spoke.SidebetRow.ConditionList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList}
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.SidebetRow.ConditionList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * repeated google.protobuf.Any items = 1;
 * @return {!Array<!proto.google.protobuf.Any>}
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Any>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_any_pb.Any, 1));
};


/**
 * @param {!Array<!proto.google.protobuf.Any>} value
 * @return {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList} returns this
*/
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.protobuf.Any=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Any}
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.Any, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList} returns this
 */
proto.sands.live_dealer.spoke.SidebetRow.ConditionList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string Descriptor = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.getDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SidebetRow} returns this
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.setDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Group = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SidebetRow} returns this
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 PlayerCardLimiter = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.getPlayercardlimiter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.SidebetRow} returns this
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.setPlayercardlimiter = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 DealerCardLimiter = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.getDealercardlimiter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.SidebetRow} returns this
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.setDealercardlimiter = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated google.protobuf.Any Conditions = 5;
 * @return {!Array<!proto.google.protobuf.Any>}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Any>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_any_pb.Any, 5));
};


/**
 * @param {!Array<!proto.google.protobuf.Any>} value
 * @return {!proto.sands.live_dealer.spoke.SidebetRow} returns this
*/
proto.sands.live_dealer.spoke.SidebetRow.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.protobuf.Any=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Any}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.Any, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.SidebetRow} returns this
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * repeated ConditionList ConditionsOr = 6;
 * @return {!Array<!proto.sands.live_dealer.spoke.SidebetRow.ConditionList>}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.getConditionsorList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.SidebetRow.ConditionList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.SidebetRow.ConditionList, 6));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.SidebetRow.ConditionList>} value
 * @return {!proto.sands.live_dealer.spoke.SidebetRow} returns this
*/
proto.sands.live_dealer.spoke.SidebetRow.prototype.setConditionsorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.SidebetRow.ConditionList}
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.addConditionsor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sands.live_dealer.spoke.SidebetRow.ConditionList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.SidebetRow} returns this
 */
proto.sands.live_dealer.spoke.SidebetRow.prototype.clearConditionsorList = function() {
  return this.setConditionsorList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.Paytable.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Paytable.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Paytable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Paytable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Paytable.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    collection: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sidebetdescriptor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sidebetversionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    rowsMap: (f = msg.getRowsMap()) ? f.toObject(includeInstance, proto.sands.live_dealer.spoke.Paytable.PaytableRows.toObject) : [],
    rtpsMap: (f = msg.getRtpsMap()) ? f.toObject(includeInstance, proto.sands.live_dealer.spoke.Rtp.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Paytable}
 */
proto.sands.live_dealer.spoke.Paytable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Paytable;
  return proto.sands.live_dealer.spoke.Paytable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Paytable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Paytable}
 */
proto.sands.live_dealer.spoke.Paytable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollection(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSidebetdescriptor(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSidebetversions(values[i]);
      }
      break;
    case 5:
      var value = msg.getRowsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.sands.live_dealer.spoke.Paytable.PaytableRows.deserializeBinaryFromReader, "", new proto.sands.live_dealer.spoke.Paytable.PaytableRows());
         });
      break;
    case 6:
      var value = msg.getRtpsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.sands.live_dealer.spoke.Rtp.deserializeBinaryFromReader, "", new proto.sands.live_dealer.spoke.Rtp());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Paytable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Paytable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Paytable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Paytable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCollection();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSidebetdescriptor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSidebetversionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getRowsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.sands.live_dealer.spoke.Paytable.PaytableRows.serializeBinaryToWriter);
  }
  f = message.getRtpsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.sands.live_dealer.spoke.Rtp.serializeBinaryToWriter);
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Paytable.PaytableRows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Paytable.PaytableRows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.sands.live_dealer.spoke.PaytableRow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Paytable.PaytableRows}
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Paytable.PaytableRows;
  return proto.sands.live_dealer.spoke.Paytable.PaytableRows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Paytable.PaytableRows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Paytable.PaytableRows}
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.PaytableRow;
      reader.readMessage(value,proto.sands.live_dealer.spoke.PaytableRow.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Paytable.PaytableRows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Paytable.PaytableRows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.PaytableRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PaytableRow rows = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.PaytableRow>}
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.PaytableRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.PaytableRow, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.PaytableRow>} value
 * @return {!proto.sands.live_dealer.spoke.Paytable.PaytableRows} returns this
*/
proto.sands.live_dealer.spoke.Paytable.PaytableRows.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.PaytableRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.PaytableRow}
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.PaytableRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Paytable.PaytableRows} returns this
 */
proto.sands.live_dealer.spoke.Paytable.PaytableRows.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string Descriptor = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Paytable.prototype.getDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Paytable} returns this
 */
proto.sands.live_dealer.spoke.Paytable.prototype.setDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Collection = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Paytable.prototype.getCollection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Paytable} returns this
 */
proto.sands.live_dealer.spoke.Paytable.prototype.setCollection = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SideBetDescriptor = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Paytable.prototype.getSidebetdescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Paytable} returns this
 */
proto.sands.live_dealer.spoke.Paytable.prototype.setSidebetdescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 SideBetVersions = 4;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.Paytable.prototype.getSidebetversionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.Paytable} returns this
 */
proto.sands.live_dealer.spoke.Paytable.prototype.setSidebetversionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Paytable} returns this
 */
proto.sands.live_dealer.spoke.Paytable.prototype.addSidebetversions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Paytable} returns this
 */
proto.sands.live_dealer.spoke.Paytable.prototype.clearSidebetversionsList = function() {
  return this.setSidebetversionsList([]);
};


/**
 * map<string, PaytableRows> Rows = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.sands.live_dealer.spoke.Paytable.PaytableRows>}
 */
proto.sands.live_dealer.spoke.Paytable.prototype.getRowsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.sands.live_dealer.spoke.Paytable.PaytableRows>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.sands.live_dealer.spoke.Paytable.PaytableRows));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sands.live_dealer.spoke.Paytable} returns this
 */
proto.sands.live_dealer.spoke.Paytable.prototype.clearRowsMap = function() {
  this.getRowsMap().clear();
  return this;};


/**
 * map<string, Rtp> Rtps = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.sands.live_dealer.spoke.Rtp>}
 */
proto.sands.live_dealer.spoke.Paytable.prototype.getRtpsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.sands.live_dealer.spoke.Rtp>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.sands.live_dealer.spoke.Rtp));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sands.live_dealer.spoke.Paytable} returns this
 */
proto.sands.live_dealer.spoke.Paytable.prototype.clearRtpsMap = function() {
  this.getRtpsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.PaytableRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.PaytableRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PaytableRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    group: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    push: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.PaytableRow}
 */
proto.sands.live_dealer.spoke.PaytableRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.PaytableRow;
  return proto.sands.live_dealer.spoke.PaytableRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.PaytableRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.PaytableRow}
 */
proto.sands.live_dealer.spoke.PaytableRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPush(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.PaytableRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.PaytableRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PaytableRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getPush();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string Descriptor = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.getDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.PaytableRow} returns this
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.setDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Group = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.PaytableRow} returns this
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 Amount = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.PaytableRow} returns this
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool Push = 4;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.getPush = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.PaytableRow} returns this
 */
proto.sands.live_dealer.spoke.PaytableRow.prototype.setPush = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Rtp.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Rtp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Rtp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Rtp.toObject = function(includeInstance, msg) {
  var f, obj = {
    details: jspb.Message.getFieldWithDefault(msg, 1, ""),
    percentage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Rtp}
 */
proto.sands.live_dealer.spoke.Rtp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Rtp;
  return proto.sands.live_dealer.spoke.Rtp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Rtp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Rtp}
 */
proto.sands.live_dealer.spoke.Rtp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Rtp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Rtp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Rtp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Rtp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetails();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string Details = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Rtp.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Rtp} returns this
 */
proto.sands.live_dealer.spoke.Rtp.prototype.setDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 Percentage = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Rtp.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Rtp} returns this
 */
proto.sands.live_dealer.spoke.Rtp.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.BaseCondition.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BaseCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BaseCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaseCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardselection: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cardsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    stringsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    valuesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.BaseCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BaseCondition;
  return proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BaseCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardselection(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCards(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addStrings(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BaseCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardselection();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getStringsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};


/**
 * optional string cardSelection = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.getCardselection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.setCardselection = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string cards = 2;
 * @return {!Array<string>}
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.getCardsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.setCardsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.addCards = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * repeated string strings = 3;
 * @return {!Array<string>}
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.getStringsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.setStringsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.addStrings = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.clearStringsList = function() {
  return this.setStringsList([]);
};


/**
 * repeated int32 values = 4;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaseCondition} returns this
 */
proto.sands.live_dealer.spoke.BaseCondition.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BaccaratDefaultRules.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BaccaratDefaultRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BaccaratDefaultRules;
  return proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BaccaratDefaultRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BaccaratDefaultRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BaccaratDefaultRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ConditionCard.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ConditionCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ConditionCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ConditionCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    rank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    suit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ConditionCard}
 */
proto.sands.live_dealer.spoke.ConditionCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ConditionCard;
  return proto.sands.live_dealer.spoke.ConditionCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ConditionCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ConditionCard}
 */
proto.sands.live_dealer.spoke.ConditionCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSuit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ConditionCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ConditionCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ConditionCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ConditionCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSuit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 Rank = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ConditionCard.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ConditionCard} returns this
 */
proto.sands.live_dealer.spoke.ConditionCard.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 Suit = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ConditionCard.prototype.getSuit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ConditionCard} returns this
 */
proto.sands.live_dealer.spoke.ConditionCard.prototype.setSuit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd}
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd;
  return proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd}
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaccaratDefaultRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd} returns this
*/
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd} returns this
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BaccaratDefaultRules rules = 2;
 * @return {?proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.getRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratDefaultRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratDefaultRules, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratDefaultRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd} returns this
*/
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd} returns this
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.SBCBaccaratEvenOdd.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBaccaratHandWins.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBaccaratHandWins;
  return proto.sands.live_dealer.spoke.sbcBaccaratHandWins.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaccaratDefaultRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBaccaratHandWins.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BaccaratDefaultRules rules = 2;
 * @return {?proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.getRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratDefaultRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratDefaultRules, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratDefaultRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWins} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWins.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy;
  return proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaccaratDefaultRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BaccaratDefaultRules rules = 2;
 * @return {?proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.getRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratDefaultRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratDefaultRules, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratDefaultRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsBy.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore;
  return proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaccaratDefaultRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BaccaratDefaultRules rules = 2;
 * @return {?proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.getRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratDefaultRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratDefaultRules, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratDefaultRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratHandWinsByOrMore.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal}
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBaccaratPointTotal;
  return proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal}
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaccaratDefaultRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BaccaratDefaultRules rules = 2;
 * @return {?proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.getRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratDefaultRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratDefaultRules, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratDefaultRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratPointTotal} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratPointTotal.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBaccaratTie.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratTie} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratTie}
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBaccaratTie;
  return proto.sands.live_dealer.spoke.sbcBaccaratTie.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratTie} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratTie}
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaccaratDefaultRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBaccaratTie.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratTie} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratTie} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratTie} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BaccaratDefaultRules rules = 2;
 * @return {?proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.getRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratDefaultRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratDefaultRules, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratDefaultRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratTie} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratTie} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratTie.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && proto.sands.live_dealer.spoke.BaccaratDefaultRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie}
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie;
  return proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie}
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaccaratDefaultRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratDefaultRules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaccaratDefaultRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BaccaratDefaultRules rules = 2;
 * @return {?proto.sands.live_dealer.spoke.BaccaratDefaultRules}
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.getRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratDefaultRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratDefaultRules, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratDefaultRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie} returns this
*/
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie} returns this
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBaccaratWinOrTie.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBlackjack.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBlackjack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBlackjack.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjack}
 */
proto.sands.live_dealer.spoke.sbcBlackjack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBlackjack;
  return proto.sands.live_dealer.spoke.sbcBlackjack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjack}
 */
proto.sands.live_dealer.spoke.sbcBlackjack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBlackjack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBlackjack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBlackjack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBlackjack.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjack} returns this
*/
proto.sands.live_dealer.spoke.sbcBlackjack.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjack} returns this
 */
proto.sands.live_dealer.spoke.sbcBlackjack.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBlackjack.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBlackjackBust.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackBust} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackBust}
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBlackjackBust;
  return proto.sands.live_dealer.spoke.sbcBlackjackBust.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackBust} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackBust}
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBlackjackBust.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackBust} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackBust} returns this
*/
proto.sands.live_dealer.spoke.sbcBlackjackBust.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackBust} returns this
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBlackjackBust.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotal}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBlackjackPointTotal;
  return proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotal}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotal} returns this
*/
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotal} returns this
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotal.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand;
  return proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand} returns this
*/
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand} returns this
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcBlackjackPointTotalAsHand.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcCardColor.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcCardColor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcCardColor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcCardColor.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcCardColor}
 */
proto.sands.live_dealer.spoke.sbcCardColor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcCardColor;
  return proto.sands.live_dealer.spoke.sbcCardColor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcCardColor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcCardColor}
 */
proto.sands.live_dealer.spoke.sbcCardColor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcCardColor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcCardColor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcCardColor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcCardColor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcCardColor.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcCardColor} returns this
*/
proto.sands.live_dealer.spoke.sbcCardColor.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcCardColor} returns this
 */
proto.sands.live_dealer.spoke.sbcCardColor.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcCardColor.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcHandLengthEquals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcHandLengthEquals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcHandLengthEquals}
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcHandLengthEquals;
  return proto.sands.live_dealer.spoke.sbcHandLengthEquals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcHandLengthEquals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcHandLengthEquals}
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcHandLengthEquals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcHandLengthEquals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandLengthEquals} returns this
*/
proto.sands.live_dealer.spoke.sbcHandLengthEquals.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcHandLengthEquals} returns this
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcHandLengthEquals.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcHandLengthSame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcHandLengthSame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcHandLengthSame}
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcHandLengthSame;
  return proto.sands.live_dealer.spoke.sbcHandLengthSame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcHandLengthSame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcHandLengthSame}
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcHandLengthSame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcHandLengthSame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandLengthSame} returns this
*/
proto.sands.live_dealer.spoke.sbcHandLengthSame.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcHandLengthSame} returns this
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcHandLengthSame.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcHandMatch.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcHandMatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandMatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    handtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatch}
 */
proto.sands.live_dealer.spoke.sbcHandMatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcHandMatch;
  return proto.sands.live_dealer.spoke.sbcHandMatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatch}
 */
proto.sands.live_dealer.spoke.sbcHandMatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHandtype(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcHandMatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcHandMatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandMatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandtype();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 handType = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.sbcHandMatch.prototype.getHandtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatch} returns this
 */
proto.sands.live_dealer.spoke.sbcHandMatch.prototype.setHandtype = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcHandMatch.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatch} returns this
*/
proto.sands.live_dealer.spoke.sbcHandMatch.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatch} returns this
 */
proto.sands.live_dealer.spoke.sbcHandMatch.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcHandMatch.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.toObject = function(includeInstance, msg) {
  var f, obj = {
    handtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetter}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcHandMatchOrBetter;
  return proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetter}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHandtype(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandtype();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 handType = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.prototype.getHandtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetter} returns this
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.prototype.setHandtype = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetter} returns this
*/
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetter} returns this
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetter.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.toObject = function(includeInstance, msg) {
  var f, obj = {
    handtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pokercardrank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked;
  return proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHandtype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPokercardrank(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandtype();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPokercardrank();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 handType = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.getHandtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked} returns this
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.setHandtype = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 pokerCardRank = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.getPokercardrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked} returns this
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.setPokercardrank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional BaseCondition base = 3;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked} returns this
*/
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked} returns this
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcHandMatchOrBetterRanked.prototype.hasBase = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.sbcMatchCards.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcMatchCards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMatchCards.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamingcardsList: jspb.Message.toObjectList(msg.getGamingcardsList(),
    proto.sands.live_dealer.spoke.ConditionCard.toObject, includeInstance),
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCards}
 */
proto.sands.live_dealer.spoke.sbcMatchCards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcMatchCards;
  return proto.sands.live_dealer.spoke.sbcMatchCards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCards}
 */
proto.sands.live_dealer.spoke.sbcMatchCards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.ConditionCard;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ConditionCard.deserializeBinaryFromReader);
      msg.addGamingcards(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcMatchCards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMatchCards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamingcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.ConditionCard.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionCard gamingCards = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.ConditionCard>}
 */
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.getGamingcardsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ConditionCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.ConditionCard, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ConditionCard>} value
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCards} returns this
*/
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.setGamingcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ConditionCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ConditionCard}
 */
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.addGamingcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.ConditionCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCards} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.clearGamingcardsList = function() {
  return this.setGamingcardsList([]);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCards} returns this
*/
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCards} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcMatchCards.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcMatchCardsRank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsRank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.toObject = function(includeInstance, msg) {
  var f, obj = {
    ranksList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsRank}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcMatchCardsRank;
  return proto.sands.live_dealer.spoke.sbcMatchCardsRank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsRank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsRank}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRanks(values[i]);
      }
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcMatchCardsRank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsRank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRanksList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int32 ranks = 1;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.getRanksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsRank} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.setRanksList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsRank} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.addRanks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsRank} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.clearRanksList = function() {
  return this.setRanksList([]);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsRank} returns this
*/
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsRank} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsRank.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcMatchCardsWithin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamingcardsList: jspb.Message.toObjectList(msg.getGamingcardsList(),
    proto.sands.live_dealer.spoke.ConditionCard.toObject, includeInstance),
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcMatchCardsWithin;
  return proto.sands.live_dealer.spoke.sbcMatchCardsWithin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.ConditionCard;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ConditionCard.deserializeBinaryFromReader);
      msg.addGamingcards(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcMatchCardsWithin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamingcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.ConditionCard.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionCard gamingCards = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.ConditionCard>}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.getGamingcardsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ConditionCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.ConditionCard, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ConditionCard>} value
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin} returns this
*/
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.setGamingcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ConditionCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ConditionCard}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.addGamingcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.ConditionCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.clearGamingcardsList = function() {
  return this.setGamingcardsList([]);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin} returns this
*/
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithin} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithin.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamingcardsList: jspb.Message.toObjectList(msg.getGamingcardsList(),
    proto.sands.live_dealer.spoke.ConditionCard.toObject, includeInstance),
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle;
  return proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.ConditionCard;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ConditionCard.deserializeBinaryFromReader);
      msg.addGamingcards(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamingcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.ConditionCard.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionCard gamingCards = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.ConditionCard>}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.getGamingcardsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ConditionCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.ConditionCard, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ConditionCard>} value
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle} returns this
*/
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.setGamingcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ConditionCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ConditionCard}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.addGamingcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.ConditionCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.clearGamingcardsList = function() {
  return this.setGamingcardsList([]);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle} returns this
*/
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle} returns this
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcMatchCardsWithinSingle.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcMultiple.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcMultiple} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMultiple.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardrank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    multiple: jspb.Message.getFieldWithDefault(msg, 2, 0),
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcMultiple}
 */
proto.sands.live_dealer.spoke.sbcMultiple.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcMultiple;
  return proto.sands.live_dealer.spoke.sbcMultiple.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcMultiple} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcMultiple}
 */
proto.sands.live_dealer.spoke.sbcMultiple.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCardrank(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultiple(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcMultiple.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcMultiple} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMultiple.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardrank();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMultiple();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 cardRank = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.getCardrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.sbcMultiple} returns this
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.setCardrank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 multiple = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.getMultiple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.sbcMultiple} returns this
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.setMultiple = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional BaseCondition base = 3;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcMultiple} returns this
*/
proto.sands.live_dealer.spoke.sbcMultiple.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcMultiple} returns this
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcMultiple.prototype.hasBase = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcMultipleSuited.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcMultipleSuited} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardrank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cardsuitsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    multiple: jspb.Message.getFieldWithDefault(msg, 3, 0),
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcMultipleSuited;
  return proto.sands.live_dealer.spoke.sbcMultipleSuited.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcMultipleSuited} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCardrank(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCardsuits(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultiple(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcMultipleSuited.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcMultipleSuited} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardrank();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCardsuitsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getMultiple();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 cardRank = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.getCardrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.setCardrank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 cardSuits = 2;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.getCardsuitsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.setCardsuitsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.addCardsuits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.clearCardsuitsList = function() {
  return this.setCardsuitsList([]);
};


/**
 * optional int32 multiple = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.getMultiple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.setMultiple = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional BaseCondition base = 4;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited} returns this
*/
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcMultipleSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcMultipleSuited.prototype.hasBase = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcPair.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcPair}
 */
proto.sands.live_dealer.spoke.sbcPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcPair;
  return proto.sands.live_dealer.spoke.sbcPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcPair}
 */
proto.sands.live_dealer.spoke.sbcPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcPair.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcPair} returns this
*/
proto.sands.live_dealer.spoke.sbcPair.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcPair} returns this
 */
proto.sands.live_dealer.spoke.sbcPair.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcPair.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcPairEither.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcPairEither.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcPairEither} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPairEither.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcPairEither}
 */
proto.sands.live_dealer.spoke.sbcPairEither.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcPairEither;
  return proto.sands.live_dealer.spoke.sbcPairEither.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcPairEither} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcPairEither}
 */
proto.sands.live_dealer.spoke.sbcPairEither.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcPairEither.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcPairEither.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcPairEither} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPairEither.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcPairEither.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcPairEither} returns this
*/
proto.sands.live_dealer.spoke.sbcPairEither.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcPairEither} returns this
 */
proto.sands.live_dealer.spoke.sbcPairEither.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcPairEither.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcPairMatchRank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcPairMatchRank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.toObject = function(includeInstance, msg) {
  var f, obj = {
    ranksList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchRank}
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcPairMatchRank;
  return proto.sands.live_dealer.spoke.sbcPairMatchRank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcPairMatchRank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchRank}
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRanks(values[i]);
      }
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcPairMatchRank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcPairMatchRank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRanksList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int32 ranks = 1;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.getRanksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchRank} returns this
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.setRanksList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchRank} returns this
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.addRanks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchRank} returns this
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.clearRanksList = function() {
  return this.setRanksList([]);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchRank} returns this
*/
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchRank} returns this
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcPairMatchRank.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcPairMatchSuit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcPairMatchSuit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.toObject = function(includeInstance, msg) {
  var f, obj = {
    suitsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchSuit}
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcPairMatchSuit;
  return proto.sands.live_dealer.spoke.sbcPairMatchSuit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcPairMatchSuit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchSuit}
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSuits(values[i]);
      }
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcPairMatchSuit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcPairMatchSuit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuitsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int32 suits = 1;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.getSuitsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchSuit} returns this
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.setSuitsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchSuit} returns this
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.addSuits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchSuit} returns this
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.clearSuitsList = function() {
  return this.setSuitsList([]);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchSuit} returns this
*/
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcPairMatchSuit} returns this
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcPairMatchSuit.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcPairSame.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcPairSame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcPairSame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPairSame.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcPairSame}
 */
proto.sands.live_dealer.spoke.sbcPairSame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcPairSame;
  return proto.sands.live_dealer.spoke.sbcPairSame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcPairSame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcPairSame}
 */
proto.sands.live_dealer.spoke.sbcPairSame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcPairSame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcPairSame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcPairSame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcPairSame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseCondition base = 1;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcPairSame.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcPairSame} returns this
*/
proto.sands.live_dealer.spoke.sbcPairSame.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcPairSame} returns this
 */
proto.sands.live_dealer.spoke.sbcPairSame.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcPairSame.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.sbcSuited.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.sbcSuited.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.sbcSuited} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcSuited.toObject = function(includeInstance, msg) {
  var f, obj = {
    suitsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    base: (f = msg.getBase()) && proto.sands.live_dealer.spoke.BaseCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.sbcSuited}
 */
proto.sands.live_dealer.spoke.sbcSuited.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.sbcSuited;
  return proto.sands.live_dealer.spoke.sbcSuited.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.sbcSuited} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.sbcSuited}
 */
proto.sands.live_dealer.spoke.sbcSuited.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSuits(values[i]);
      }
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaseCondition;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaseCondition.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.sbcSuited.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.sbcSuited} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.sbcSuited.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuitsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaseCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int32 suits = 1;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.getSuitsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.sbcSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.setSuitsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.sbcSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.addSuits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.sbcSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.clearSuitsList = function() {
  return this.setSuitsList([]);
};


/**
 * optional BaseCondition base = 2;
 * @return {?proto.sands.live_dealer.spoke.BaseCondition}
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.getBase = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaseCondition} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaseCondition, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaseCondition|undefined} value
 * @return {!proto.sands.live_dealer.spoke.sbcSuited} returns this
*/
proto.sands.live_dealer.spoke.sbcSuited.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.sbcSuited} returns this
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.sbcSuited.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.FutureActionSetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.FutureActionSetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    futureAction: (f = msg.getFutureAction()) && spoke_game_pb.FutureAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetRequest}
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.FutureActionSetRequest;
  return proto.sands.live_dealer.spoke.FutureActionSetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.FutureActionSetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetRequest}
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new spoke_game_pb.FutureAction;
      reader.readMessage(value,spoke_game_pb.FutureAction.deserializeBinaryFromReader);
      msg.setFutureAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.FutureActionSetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.FutureActionSetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFutureAction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_game_pb.FutureAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetRequest} returns this
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FutureAction future_action = 2;
 * @return {?proto.sands.live_dealer.spoke.FutureAction}
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.prototype.getFutureAction = function() {
  return /** @type{?proto.sands.live_dealer.spoke.FutureAction} */ (
    jspb.Message.getWrapperField(this, spoke_game_pb.FutureAction, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.FutureAction|undefined} value
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetRequest} returns this
*/
proto.sands.live_dealer.spoke.FutureActionSetRequest.prototype.setFutureAction = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetRequest} returns this
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.prototype.clearFutureAction = function() {
  return this.setFutureAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.FutureActionSetRequest.prototype.hasFutureAction = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.FutureActionSetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.FutureActionSetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f),
    futureActionsList: jspb.Message.toObjectList(msg.getFutureActionsList(),
    spoke_game_pb.FutureAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetReply}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.FutureActionSetReply;
  return proto.sands.live_dealer.spoke.FutureActionSetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.FutureActionSetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetReply}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new spoke_game_pb.FutureAction;
      reader.readMessage(value,spoke_game_pb.FutureAction.deserializeBinaryFromReader);
      msg.addFutureActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.FutureActionSetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.FutureActionSetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getFutureActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      spoke_game_pb.FutureAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetReply} returns this
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetReply} returns this
*/
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetReply} returns this
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FutureAction future_actions = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.FutureAction>}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.getFutureActionsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.FutureAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, spoke_game_pb.FutureAction, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.FutureAction>} value
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetReply} returns this
*/
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.setFutureActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.FutureAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.FutureAction}
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.addFutureActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.FutureAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.FutureActionSetReply} returns this
 */
proto.sands.live_dealer.spoke.FutureActionSetReply.prototype.clearFutureActionsList = function() {
  return this.setFutureActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.FavoriteBet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.FavoriteBet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.FavoriteBet.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hash: jspb.Message.getFieldWithDefault(msg, 2, ""),
    betName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    wagersMap: (f = msg.getWagersMap()) ? f.toObject(includeInstance, undefined) : [],
    betTotal: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedTime: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet}
 */
proto.sands.live_dealer.spoke.FavoriteBet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.FavoriteBet;
  return proto.sands.live_dealer.spoke.FavoriteBet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.FavoriteBet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet}
 */
proto.sands.live_dealer.spoke.FavoriteBet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetName(value);
      break;
    case 4:
      var value = msg.getWagersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBetTotal(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.FavoriteBet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.FavoriteBet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.FavoriteBet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBetName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWagersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getBetTotal();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUpdatedTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet} returns this
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hash = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet} returns this
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bet_name = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.getBetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet} returns this
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.setBetName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, int64> wagers = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.getWagersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet} returns this
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.clearWagersMap = function() {
  this.getWagersMap().clear();
  return this;};


/**
 * optional int64 bet_total = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.getBetTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet} returns this
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.setBetTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet} returns this
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 created_time = 7;
 * @return {number}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.getCreatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet} returns this
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 updated_time = 8;
 * @return {number}
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.getUpdatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet} returns this
 */
proto.sands.live_dealer.spoke.FavoriteBet.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.AddFavoriteBetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    favoriteBet: (f = msg.getFavoriteBet()) && proto.sands.live_dealer.spoke.FavoriteBet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.AddFavoriteBetRequest;
  return proto.sands.live_dealer.spoke.AddFavoriteBetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.FavoriteBet;
      reader.readMessage(value,proto.sands.live_dealer.spoke.FavoriteBet.deserializeBinaryFromReader);
      msg.setFavoriteBet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.AddFavoriteBetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFavoriteBet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.FavoriteBet.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FavoriteBet favorite_bet = 3;
 * @return {?proto.sands.live_dealer.spoke.FavoriteBet}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.getFavoriteBet = function() {
  return /** @type{?proto.sands.live_dealer.spoke.FavoriteBet} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.FavoriteBet, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.FavoriteBet|undefined} value
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest} returns this
*/
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.setFavoriteBet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.clearFavoriteBet = function() {
  return this.setFavoriteBet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetRequest.prototype.hasFavoriteBet = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.AddFavoriteBetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rulesId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    applied: (f = msg.getApplied()) && proto.sands.live_dealer.spoke.FavoriteBet.toObject(includeInstance, f),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.AddFavoriteBetResponse;
  return proto.sands.live_dealer.spoke.AddFavoriteBetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.FavoriteBet;
      reader.readMessage(value,proto.sands.live_dealer.spoke.FavoriteBet.deserializeBinaryFromReader);
      msg.setApplied(value);
      break;
    case 5:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.AddFavoriteBetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApplied();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.FavoriteBet.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint64 player_id = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string rules_id = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FavoriteBet applied = 4;
 * @return {?proto.sands.live_dealer.spoke.FavoriteBet}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.getApplied = function() {
  return /** @type{?proto.sands.live_dealer.spoke.FavoriteBet} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.FavoriteBet, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.FavoriteBet|undefined} value
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} returns this
*/
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.setApplied = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.clearApplied = function() {
  return this.setApplied(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.hasApplied = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Error error = 5;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 5));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} returns this
*/
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.AddFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.AddFavoriteBetResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetFavoriteBetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsRequest}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetFavoriteBetsRequest;
  return proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetFavoriteBetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsRequest}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetFavoriteBetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsRequest} returns this
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsRequest} returns this
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsRequest.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rulesId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    favoriteBetsList: jspb.Message.toObjectList(msg.getFavoriteBetsList(),
    proto.sands.live_dealer.spoke.FavoriteBet.toObject, includeInstance),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GetFavoriteBetsResponse;
  return proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.FavoriteBet;
      reader.readMessage(value,proto.sands.live_dealer.spoke.FavoriteBet.deserializeBinaryFromReader);
      msg.addFavoriteBets(value);
      break;
    case 6:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFavoriteBetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.FavoriteBet.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} returns this
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint64 player_id = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} returns this
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string rules_id = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} returns this
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FavoriteBet favorite_bets = 4;
 * @return {!Array<!proto.sands.live_dealer.spoke.FavoriteBet>}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.getFavoriteBetsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.FavoriteBet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.FavoriteBet, 4));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.FavoriteBet>} value
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} returns this
*/
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.setFavoriteBetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.FavoriteBet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.FavoriteBet}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.addFavoriteBets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sands.live_dealer.spoke.FavoriteBet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} returns this
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.clearFavoriteBetsList = function() {
  return this.setFavoriteBetsList([]);
};


/**
 * optional Error error = 6;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 6));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} returns this
*/
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GetFavoriteBetsResponse} returns this
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GetFavoriteBetsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bet: (f = msg.getBet()) && proto.sands.live_dealer.spoke.FavoriteBet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest;
  return proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.FavoriteBet;
      reader.readMessage(value,proto.sands.live_dealer.spoke.FavoriteBet.deserializeBinaryFromReader);
      msg.setBet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBet();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.FavoriteBet.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FavoriteBet bet = 4;
 * @return {?proto.sands.live_dealer.spoke.FavoriteBet}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.getBet = function() {
  return /** @type{?proto.sands.live_dealer.spoke.FavoriteBet} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.FavoriteBet, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.FavoriteBet|undefined} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest} returns this
*/
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.setBet = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.clearBet = function() {
  return this.setBet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetRequest.prototype.hasBet = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rulesId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    applied: (f = msg.getApplied()) && proto.sands.live_dealer.spoke.FavoriteBet.toObject(includeInstance, f),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse;
  return proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.FavoriteBet;
      reader.readMessage(value,proto.sands.live_dealer.spoke.FavoriteBet.deserializeBinaryFromReader);
      msg.setApplied(value);
      break;
    case 5:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApplied();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.FavoriteBet.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint64 player_id = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string rules_id = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FavoriteBet applied = 4;
 * @return {?proto.sands.live_dealer.spoke.FavoriteBet}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.getApplied = function() {
  return /** @type{?proto.sands.live_dealer.spoke.FavoriteBet} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.FavoriteBet, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.FavoriteBet|undefined} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} returns this
*/
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.setApplied = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.clearApplied = function() {
  return this.setApplied(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.hasApplied = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Error error = 5;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 5));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} returns this
*/
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.UpdateFavoriteBetResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest;
  return proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest} returns this
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rulesId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    error: (f = msg.getError()) && spoke_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse;
  return proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 6:
      var value = new spoke_error_pb.Error;
      reader.readMessage(value,spoke_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spoke_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint64 player_id = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string rules_id = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Error error = 6;
 * @return {?proto.sands.live_dealer.spoke.Error}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.getError = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Error} */ (
    jspb.Message.getWrapperField(this, spoke_error_pb.Error, 6));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Error|undefined} value
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} returns this
*/
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse} returns this
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.RemoveFavoriteBetResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};


goog.object.extend(exports, proto.sands.live_dealer.spoke);
