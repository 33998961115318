/**********************************************************************************************************************
 * Stores and manages table history (specifically for Dragon Tiger).
 *********************************************************************************************************************/
import clamp from 'lodash/clamp';
import { RpcTableHistoryStore } from 'server/lib-rpc';
import { RpcTableHistoryStoreMobXKeys } from 'server/lib-rpc';
import { ITableService } from 'server/lib-rpc';
import {
	DragonTigerBigRoadEntryData,
	DragonTigerBigRoadStreakData,
	DragonTigerDerivedRoadEntryData,
	DragonTigerDerivedRoadStreakData,
	DragonTigerPlaySummaryData,
	DragonTigerPossibleDerivedProgressData,
	TableHistoryData,
} from 'server/lib-rpc';
import { action, computed, makeObservable, observable, toJS } from 'helpers/mobx';

interface IDragonTigerTableHistoryStoreOpts {
	data?: Maybe<TableHistoryData>;
}

type BeadPlateItem = DragonTigerPlaySummaryData;
type BeadPlate = BeadPlateItem[];

type ToJsonResult = {
	tableId: string;
	playId: number;
	beadPlate: BeadPlate;
	bigRoad: DragonTigerBigRoadStreakData[];
	bigEyeBoy: DragonTigerDerivedRoadStreakData[];
	cockroachPig: DragonTigerDerivedRoadStreakData[];
	smallRoad: DragonTigerDerivedRoadStreakData[];
	ifDragonNext: Nullable<DragonTigerPossibleDerivedProgressData>;
	ifTigerNext: Nullable<DragonTigerPossibleDerivedProgressData>;
	ifPlayerNext: Nullable<DragonTigerPossibleDerivedProgressData>;
	ifBankerNext: Nullable<DragonTigerPossibleDerivedProgressData>;
};

class DragonTigerTableHistoryStore extends RpcTableHistoryStore {
	/**
	 * CONSTRUCTOR.
	 *
	 * @param service
	 * @param opts
	 */
	constructor(service: ITableService, opts?: Maybe<IDragonTigerTableHistoryStoreOpts>) {
		opts = opts || {};

		super(service);

		// Note: We cannot use `makeAutoObservable` because this class extends a parent class. So we are declaring the
		// annotations right here instead. We can probably make this better in the future by carefully applying observables
		// on the parent class or via some other technique.. but for now, just roll with it.
		makeObservable<DragonTigerTableHistoryStore, RpcTableHistoryStoreMobXKeys>(this, {
			// ---- Inherited from DataStore --------------------------------------------------------------------------------

			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			isPopulated: computed,
			lastUpdatedTs: computed,

			// Actions
			populate: action,
			setData: action,

			// ---- Inherited from RpcTableStore ----------------------------------------------------------------------------

			_playId: observable,
			playId: computed,
			tableId: computed,
			onAfterPopulate: false,

			// ---- Local ---------------------------------------------------------------------------------------------------

			beadPlate: computed,
			bigRoad: computed,
			bigEyeBoy: computed,
			cockroachPig: computed,
			smallRoad: computed,
			ifDragonNext: computed,
			ifTigerNext: computed,
			ifPlayerNext: computed,
			ifBankerNext: computed,

			counts: computed,
			beadPlateGrid: computed,
			bigRoadGrid: computed,
			smallRoadGrid: computed,
			bigEyeBoyGrid: computed,
			cockroachPigGrid: computed,
			toJson: false,

			// Actions
			clear: action,
		});

		opts.data && this.setData(opts.data);
	}

	public get beadPlate(): BeadPlate {
		return this.dragonTigerHistory?.beadPlateList ?? [];
	}

	public get bigRoad(): DragonTigerBigRoadStreakData[] {
		return this.dragonTigerHistory?.bigRoadList ?? [];
	}

	public get bigEyeBoy(): DragonTigerDerivedRoadStreakData[] {
		return this.dragonTigerHistory?.bigEyeBoyList ?? [];
	}

	public get cockroachPig(): DragonTigerDerivedRoadStreakData[] {
		return this.dragonTigerHistory?.cockroachPigList ?? [];
	}

	public get smallRoad(): DragonTigerDerivedRoadStreakData[] {
		return this.dragonTigerHistory?.smallRoadList ?? [];
	}

	public get ifDragonNext(): Nullable<DragonTigerPossibleDerivedProgressData> {
		return this.dragonTigerHistory?.ifDragonNext ?? null;
	}

	public get ifTigerNext(): Nullable<DragonTigerPossibleDerivedProgressData> {
		return this.dragonTigerHistory?.ifTigerNext ?? null;
	}

	public get ifPlayerNext(): Nullable<DragonTigerPossibleDerivedProgressData> {
		return this.ifDragonNext;
	}

	public get ifBankerNext(): Nullable<DragonTigerPossibleDerivedProgressData> {
		return this.ifTigerNext;
	}

	public get counts() {
		const output: Record<string, number> = {
			cat: 0,
			dragon: 0,
			tie: 0,
			natural: 0,
		};

		this.beadPlate.forEach((summary) => {
			output[summary.winner]++;
			summary.winner !== 'tie' && ++output.natural;
		});

		return output;
	}

	public get beadPlateGrid() {
		const gridHeight: number = 6;
		let gridWidth: number = 1;

		const newGrid = (width: number) => Array<BeadPlateItem[]>(width);
		const newColumn = (height: number) => Array<BeadPlateItem>(height);

		// Make a fixed grid of visible items
		const grid = newGrid(gridWidth);
		const outputVis = [...grid].map(() => {
			return [...newColumn(gridHeight)];
		});

		this.beadPlate.every((b: BeadPlateItem, i: number) => {
			const x = Math.floor(i / gridHeight);
			const y = i % gridHeight;

			// Create a new column if needed.
			if (x >= gridWidth) {
				outputVis.push([...newColumn(gridHeight)]);
				gridWidth++;
			}

			outputVis[x][y] = b;

			return true; // Continue
		});

		return outputVis;
	}

	public get bigRoadGrid() {
		let xMod = 0;
		const gridHeight = 6;
		const gridWidth = 1; // More like a min-width as cols will be added as needed.

		// const newGrid = (width: number) => Array<BigRoadEntryData[]>(width);
		// const newColumn = (height: number) => Array<BigRoadEntryData>(height);
		const newGrid = (width: number) => Array<DragonTigerBigRoadEntryData[]>(width);
		const newColumn = (height: number) => Array<DragonTigerBigRoadEntryData>(height);

		const grid = newGrid(gridWidth);
		const outputVis = [...grid].map(() => {
			return [...newColumn(gridHeight)];
		});

		this.bigRoad.forEach(({ streakList }, i) => {
			// Create a new column if needed.
			if (outputVis.length - 1 < i + xMod) {
				outputVis.push([...newColumn(gridHeight)]);
			}

			let maxHeight = outputVis[i + xMod].findIndex((i) => i !== undefined) - 1;
			if (maxHeight === -2) {
				// Not found
				maxHeight = gridHeight - 1;
			}

			streakList.forEach((s, q) => {
				const y = clamp(q, maxHeight);
				let x = i + xMod;

				if (q > maxHeight) {
					// If our max height is zero we need to start by moving laterally
					if (maxHeight === 0) {
						// Move one to the right
						x++;
						// And move all future streaks to the right
						xMod++;
					} else {
						// Move laterally thanks to being at max height
						x += q - maxHeight;
					}
				}

				// Create a new column if needed for tail.
				if (outputVis[x] === undefined) {
					outputVis.push([...newColumn(gridHeight)]);
				}

				if (outputVis[x][y] !== undefined) {
					console.error(
						'Placement would result in overwriting a cell in scoreboard: skipping',
						toJS(outputVis[x][y]),
						x,
						y,
						toJS(s)
					);
				} else {
					outputVis[x][y] = s;
				}
			});
		});

		return outputVis;
	}

	public get smallRoadGrid() {
		let xMod = 0;
		const gridHeight = 6;
		const gridWidth = 1; // More like a min-width as cols will be added as needed.

		// const newGrid = (width: number) => Array<DerivedRoadEntryData[]>(width);
		// const newColumn = (height: number) => Array<DerivedRoadEntryData>(height);
		const newGrid = (width: number) => Array<DragonTigerDerivedRoadEntryData[]>(width);
		const newColumn = (height: number) => Array<DragonTigerDerivedRoadEntryData>(height);

		const grid = newGrid(gridWidth);
		const outputVis = [...grid].map(() => {
			return [...newColumn(gridHeight)];
		});

		this.smallRoad.forEach(({ streakList }, i) => {
			// Create a new column if needed.
			if (outputVis.length - 1 < i + xMod) {
				outputVis.push([...newColumn(gridHeight)]);
			}

			let maxHeight = outputVis[i + xMod].findIndex((i) => i !== undefined) - 1;
			if (maxHeight === -2) {
				// Not found
				maxHeight = gridHeight - 1;
			}

			streakList.forEach((s, q) => {
				const y = clamp(q, maxHeight);
				let x = i + xMod;

				if (q > maxHeight) {
					// If our max height is zero we need to start by moving laterally
					if (maxHeight === 0) {
						// Move one to the right
						x++;
						// And move all future streaks to the right
						xMod++;
					} else {
						// Move laterally thanks to being at max height
						x += q - maxHeight;
					}
				}

				// Create a new column if needed for tail.
				if (outputVis[x] === undefined) {
					outputVis.push([...newColumn(gridHeight)]);
				}
				if (outputVis[x][y] !== undefined) {
					console.error('overwriting cell in scoreboard', toJS(outputVis[x][y]), x, y, toJS(s));
				} else {
					// @ts-ignore
					outputVis[x][y] = s;
				}
			});
		});

		return outputVis;
	}

	public get bigEyeBoyGrid() {
		let xMod = 0;
		const gridHeight = 6;
		const gridWidth = 1; // More like a min-width as cols will be added as needed.

		// const newGrid = (width: number) => Array<DerivedRoadEntryData[]>(width);
		// const newColumn = (height: number) => Array<DerivedRoadEntryData>(height);
		const newGrid = (width: number) => Array<DragonTigerDerivedRoadEntryData[]>(width);
		const newColumn = (height: number) => Array<DragonTigerDerivedRoadEntryData>(height);

		const grid = newGrid(gridWidth);
		const outputVis = [...grid].map(() => {
			return [...newColumn(gridHeight)];
		});

		this.bigEyeBoy.forEach(({ streakList }, i) => {
			// Create a new column if needed.
			if (outputVis.length - 1 < i + xMod) {
				outputVis.push([...newColumn(gridHeight)]);
			}

			let maxHeight = outputVis[i + xMod].findIndex((i) => i !== undefined) - 1;
			if (maxHeight === -2) {
				// Not found
				maxHeight = gridHeight - 1;
			}

			streakList.forEach((s, q) => {
				const y = clamp(q, maxHeight);
				let x = i + xMod;

				if (q > maxHeight) {
					// If our max height is zero we need to start by moving laterally
					if (maxHeight === 0) {
						// Move one to the right
						x++;
						// And move all future streaks to the right
						xMod++;
					} else {
						// Move laterally thanks to being at max height
						x += q - maxHeight;
					}
				}

				// Create a new column if needed for tail.
				if (outputVis[x] === undefined) {
					outputVis.push([...newColumn(gridHeight)]);
				}

				if (outputVis[x][y] !== undefined) {
					console.error('overwriting cell in scoreboard', toJS(outputVis[x][y]), x, y, toJS(s));
				} else {
					outputVis[x][y] = s;
				}
			});
		});

		return outputVis;
	}

	public get cockroachPigGrid() {
		let xMod = 0;
		const gridHeight = 6;
		const gridWidth = 1; // More like a min-width as cols will be added as needed.

		// const newGrid = (width: number) => Array<DerivedRoadEntryData[]>(width);
		// const newColumn = (height: number) => Array<DerivedRoadEntryData>(height);
		const newGrid = (width: number) => Array<DragonTigerDerivedRoadEntryData[]>(width);
		const newColumn = (height: number) => Array<DragonTigerDerivedRoadEntryData>(height);

		const grid = newGrid(gridWidth);
		const outputVis = [...grid].map(() => {
			return [...newColumn(gridHeight)];
		});

		this.cockroachPig.forEach(({ streakList }, i) => {
			// Create a new column if needed.
			if (outputVis.length - 1 < i + xMod) {
				outputVis.push([...newColumn(gridHeight)]);
			}

			let maxHeight = outputVis[i + xMod].findIndex((i) => i !== undefined) - 1;
			if (maxHeight === -2) {
				// Not found
				maxHeight = gridHeight - 1;
			}

			streakList.forEach((s, q) => {
				const y = clamp(q, maxHeight);
				let x = i + xMod;

				if (q > maxHeight) {
					// If our max height is zero we need to start by moving laterally
					if (maxHeight === 0) {
						// Move one to the right
						x++;
						// And move all future streaks to the right
						xMod++;
					} else {
						// Move laterally thanks to being at max height
						x += q - maxHeight;
					}
				}

				// Create a new column if needed for tail.
				if (outputVis[x] === undefined) {
					outputVis.push([...newColumn(gridHeight)]);
				}
				if (outputVis[x][y] !== undefined) {
					console.error('overwriting cell in scoreboard', toJS(outputVis[x][y]), x, y, toJS(s));
				} else {
					outputVis[x][y] = s;
				}
			});
		});

		return outputVis;
	}

	public toJson(): ToJsonResult {
		return {
			tableId: this.tableId,
			playId: this.playId,
			beadPlate: toJS(this.beadPlate),
			bigRoad: toJS(this.bigRoad),
			bigEyeBoy: toJS(this.bigEyeBoy),
			cockroachPig: toJS(this.cockroachPig),
			smallRoad: toJS(this.smallRoad),
			ifDragonNext: toJS(this.ifDragonNext),
			ifTigerNext: toJS(this.ifTigerNext),
			ifPlayerNext: toJS(this.ifPlayerNext),
			ifBankerNext: toJS(this.ifBankerNext),
		};
	}

	public clear(): void {
		super.clear();
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { DragonTigerTableHistoryStore as default };
export { DragonTigerTableHistoryStore };
