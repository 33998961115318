/**********************************************************************************************************************
 * Handles dealer stream subscriptions and various resulting data flow.
 *********************************************************************************************************************/
import { DealerStreamKey as StreamKey } from './constants';
import { HubDealerHudData, TableData, TableSeatsPlayData } from './lib-rpc';
import { streamManager } from './lib-rpc';
import { getDealerStores } from './store';

const setupDealerHudStreamSubscriptions = () => {
	streamManager.subscribe<HubDealerHudData>(StreamKey.DealerHudStream, {
		onData: (data: HubDealerHudData) => {
			const { dealerHudStore } = getDealerStores();
			data.success && data.table != null && dealerHudStore.setData(data);
		},
	});
};

const setupDealerTableStreamSubscriptions = () => {
	streamManager.subscribe<TableData>(StreamKey.DealerTableStream, {
		onData: (data: TableData) => {
			const { dealerTableStore, dealerPlayStore } = getDealerStores();
			if (!data.success && data.table == null) {
				return;
			}

			dealerTableStore.setData(data);
			dealerPlayStore.populateFromTable(data);
		},
	});
};

const setupTableSeatsPlayStreamSubscriptions = () => {
	streamManager.subscribe<TableSeatsPlayData>(StreamKey.DealerTableSeatsPlayStream, {
		onData: (data: TableSeatsPlayData) => {
			const { dealerTableSeatsPlayStore } = getDealerStores();
			data.success && dealerTableSeatsPlayStore.setData(data);
		},
	});
};

// ---- Export --------------------------------------------------------------------------------------------------------

let _isInitialized = false;

const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	if (!streamManager.hasStreams()) {
		return false;
	}

	setupDealerHudStreamSubscriptions();
	setupDealerTableStreamSubscriptions();
	setupTableSeatsPlayStreamSubscriptions();

	_isInitialized = true;

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initDealerStreamSubscriptions };
