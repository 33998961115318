import { clearMultipleDealerStores, getDealerStores as getStores, DealerStoreKey as StoreKey } from '../store';
import { DealerStreamKey as StreamKey, streamManager } from '../streams';
import { debug } from './debug';

let _currentTableId: string = '';

interface ISetDealerTableOpts {
	// When TRUE will start the dealer HUD stream.
	startHudStream?: Maybe<boolean>;
	// When TRUE will start the dealer table stream.
	startTableStream?: Maybe<boolean>;
	// When TRUE will start the dealer table seats play stream.
	startTableSeatsPlayStream?: Maybe<boolean>;
}

/**
 * Sets the current active table for the game. This will ensure data state is correct and that the relevant streams
 * are managed.
 *
 * @param tableId   Dealer table ID to make active.
 * @param opts      Various options.
 * @returns
 */
const setActiveDealerTable = async (tableId: string, opts?: Maybe<ISetDealerTableOpts>): Promise<boolean> => {
	// Do nothing if the table ID is not changing
	if (tableId === _currentTableId) {
		return false;
	}

	return await setDealerTable(tableId, opts);
};

const setDealerTable = async (tableId: string, opts?: Maybe<ISetDealerTableOpts>): Promise<boolean> => {
	const { startHudStream = true, startTableStream = true, startTableSeatsPlayStream = true } = opts ?? {};

	debug.info('Props:', 'setDealerTable', { tableId, opts });

	const { dealerHudStore, dealerTableSeatsPlayStore } = getStores();

	streamManager.stopMultiple([
		StreamKey.DealerHudStream,
		StreamKey.DealerTableStream,
		StreamKey.DealerTableSeatsPlayStream,
	]);

	clearMultipleDealerStores([
		StoreKey.DealerPlayStore,
		StoreKey.DealerTableStore,
		StoreKey.DealerTableSeatsPlayStore,
		StoreKey.DealerRulesStore,
		StoreKey.DealerTableHistoryStore,
	]);

	// Unsetting the table
	if (tableId === '') {
		_currentTableId = '';
		dealerHudStore.clear();
		return true;
	}

	if (dealerHudStore.tableId !== tableId) {
		// debug.info(`Attempting to populate dealer hud store for table ID '${tableId}'`, 'setDealerTable');

		await dealerHudStore.populate(tableId);

		if (!dealerHudStore.isPopulated || dealerHudStore.tableId !== tableId) {
			debug.warn(
				`Unable to populate dealer hud store using table ID '${tableId}'. Is the table ID valid?`,
				'setDealerTable'
			);

			_currentTableId = '';
			dealerHudStore.clear();
			return false;
		}
	}

	// Populate the dealer table seats play store (if necessary)
	if (dealerTableSeatsPlayStore.tableId !== tableId) {
		// debug.info(`Attempting to populate dealer table seats play store for table ID '${tableId}'`, 'setDealerTable');

		await dealerTableSeatsPlayStore.populate(tableId);

		if (!dealerTableSeatsPlayStore.isPopulated || dealerTableSeatsPlayStore.tableId !== tableId) {
			debug.warn(`Unable to populate dealer table seats play store using play ID '${tableId}'`, 'setDealerTable');

			dealerTableSeatsPlayStore.clear();
			return false;
		}
	}

	_currentTableId = dealerHudStore.tableId;

	if (startHudStream && streamManager.isEnabled(StreamKey.DealerHudStream)) {
		// debug.info(`Attempting to start the dealer hud stream for table ID '${tableId}'`, 'setDealerTable');
		streamManager.start(StreamKey.DealerHudStream, { tableId });
	}

	if (startTableStream && streamManager.isEnabled(StreamKey.DealerTableStream)) {
		// debug.info(`Attempting to start the dealer table stream for table ID '${tableId}'`, 'setDealerTable');
		streamManager.start(StreamKey.DealerTableStream, { tableId });
	}

	if (startTableSeatsPlayStream && streamManager.isEnabled(StreamKey.DealerTableSeatsPlayStream)) {
		// debug.info(`Attempting to start the dealer table seats play stream for table ID '${tableId}'`, 'setDealerTable');
		streamManager.start(StreamKey.DealerTableSeatsPlayStream, { tableId });
	}

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { setActiveDealerTable, setDealerTable };
