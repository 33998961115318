import { IStoreBase } from './types';

interface IDataStore<RpcDataType> extends IStoreBase {
	data: Nullable<RpcDataType>;
	populate: (...args: any[]) => void;
}

abstract class DataStore<ServiceType, RpcDataType> implements IDataStore<RpcDataType> {
	// Service associated with this store. Needed in order to call `populate`.
	protected service: Nullable<ServiceType> = null;

	// The underlying data inside this store.
	protected _data: Nullable<RpcDataType> = null;

	// The unix timestamp (UTC) for when the data in this store was last updated.
	protected _lastUpdatedTs: number = 0;

	/**
	 * CONSTRUCTOR.
	 *
	 * @param  service  Service associated with this store. Needed in order to call `populate`.
	 */
	constructor(service?: Maybe<ServiceType>) {
		this.service = service ?? null;
	}

	/**
	 * GET/SET. The underlying data inside this store.
	 */
	public get data(): Nullable<RpcDataType> {
		return this._data;
	}
	public set data(value: Nullable<RpcDataType>) {
		this._data = value;
		this._lastUpdatedTs = Date.now();
	}

	/**
	 * @returns TRUE if this store is populated with data.
	 */
	public get isPopulated(): boolean {
		return this.data != null;
	}

	/**
	 * @returns The unix timestamp (UTC) for when the data in this store was last updated.
	 */
	public get lastUpdatedTs() {
		return this._lastUpdatedTs;
	}

	/**
	 * Action. Set the current data.
	 */
	public setData(value: Nullable<RpcDataType>) {
		this.data = value;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		this.setData(null);
		this._lastUpdatedTs = 0;
	}

	/**
	 * Populate this store by calling the service associated with this store.
	 *
	 * @returns TRUE if we were able to populate this store using data.
	 */
	public async populate(...optionalArgs: unknown[]): Promise<boolean> {
		if (!this.service) {
			this.debugError('Unable to populate, no service was specified', 'populate');

			return false;
		}

		try {
			const data = await this.fetchPopulateData(...optionalArgs);
			this.setData(data);
		} catch (err) {
			this.debugError('Unable to populate due to an error:', 'populate', { error: err as Error });

			return false;
		}

		return true;
	}

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected abstract fetchPopulateData(...optionalArgs: unknown[]): Promise<Nullable<RpcDataType>>;

	/**
	 * The actual type of the class extending this. Used for debug messages.
	 */
	protected get className(): string {
		return this.constructor.name ?? 'DataStore';
	}

	/**
	 * Creates a formatted debug message for the specified message and method.
	 */
	protected debugMsg(msg: string, method?: Maybe<string>): string {
		if (msg === '') {
			return '';
		}

		const prefix = `[${this.className}]: ${method ? `${method} - ` : ''}`;

		return `${prefix}${msg}`;
	}

	protected debugError(msg: string, method?: Maybe<string>, ...args: unknown[]): void {
		console.error(this.debugMsg(msg, method), ...args);
	}

	protected debugWarn(msg: string, method?: Maybe<string>, ...args: unknown[]): void {
		console.warn(this.debugMsg(msg, method), ...args);
	}

	protected debugInfo(msg: string, method?: Maybe<string>, ...args: unknown[]): void {
		console.log(this.debugMsg(msg, method), ...args);
	}
}

export { DataStore as default };
export { DataStore };

export type { IDataStore };
