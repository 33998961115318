/**********************************************************************************************************************
 * Stores and manages Dealer HUD related data (from the HUB back-office).
 *********************************************************************************************************************/
import { RpcDealerHudStore } from 'server/lib-rpc';
import { RpcDealerHudStoreMobXKeys } from 'server/lib-rpc';
import { IHubBackOfficeService } from 'server/lib-rpc';
import { action, computed, makeObservable, observable } from 'helpers/mobx';

class DealerHudStore extends RpcDealerHudStore {
	/**
	 * CONSTRUCTOR.
	 *
	 * @param  service  RPC service to use when calling `populate`.
	 */
	constructor(service: IHubBackOfficeService) {
		super(service);

		// Note: We cannot use `makeAutoObservable` because this class extends a parent class. So we are declaring the
		// annotations right here instead. We can probably make this better in the future by carefully applying observables
		// on the parent class or via some other technique.. but for now, just roll with it.
		makeObservable<DealerHudStore, RpcDealerHudStoreMobXKeys>(this, {
			// ---- Inherited from DataStore --------------------------------------------------------------------------------
			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			isPopulated: computed,
			lastUpdatedTs: computed,

			// Actions
			populate: action,
			setData: action,

			// ---- Inherited from RpcDealerHudStore ------------------------------------------------------------------------

			play: computed,
			table: computed,
			tableId: computed,
			tableKey: computed, // DEPRECATED
			spaces: computed,
			dealer: computed,
			player: computed,
			nextStep: computed,
			activeSpace: computed,
			deviceKey: computed,
			tableState: computed,
			playState: computed,

			// Actions
			clear: action,
		});
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { DealerHudStore as default };
export { DealerHudStore };
