import { SoundEffects } from './constants';
import { EffectOpts } from './types';

const SoundEffectDefaultOptions = new Map<string, EffectOpts>([
	[SoundEffects.STEREO_POSITION, { position: 0.0 }],
	[SoundEffects.STEREO_TRANSITION, { startPosition: 0.0, endPosition: 0.0 }],
	[SoundEffects.FAR_LEFT, { position: -1.0 }],
	[SoundEffects.FAR_RIGHT, { position: 1.0 }],
	[SoundEffects.PAN_LEFT_RIGHT, { startPosition: -1.0, endPosition: 1.0 }],
	[SoundEffects.PAN_RIGHT_LEFT, { startPosition: 1.0, endPosition: -1.0 }],
	[SoundEffects.PAN_CENTER_LEFT, { startPosition: 0.0, endPosition: 1.0 }],
	[SoundEffects.PAN_CENTER_RIGHT, { startPosition: 0.0, endPosition: -1.0 }],
]);

export { SoundEffects, SoundEffectDefaultOptions };
