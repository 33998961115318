import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { entries } from '../../helpers/object';
import { IStream, IStreamRequestProps, IStreamState, IStreamSubscriber } from './types/stream';

interface IGenericStream extends IStream {
	start: (props?: any) => boolean;
	restart: (props?: any) => boolean;
	subscribe: (subscriber: IStreamSubscriber<unknown>, opts?: { throwError?: boolean }) => void;
	unsubscribe: (subscriber: IStreamSubscriber<unknown>) => boolean;
	unsubscribeAll: () => void;
	readonly currentState: IStreamState;
}

type StreamCollection = Map<string, IGenericStream>;
type MultipleOpsStreamRequests = Record<string, Maybe<IStreamRequestProps>>;
type MultipleOpsStreams = string[] | [string, Maybe<IStreamRequestProps>][] | MultipleOpsStreamRequests;
type MultipleOpsResults = Record<string, boolean>;

class StreamManager {
	// Collection of streams currently registered with this stream manager
	protected streams: StreamCollection = new Map<string, IGenericStream>();

	/**
	 * CONSTRUCTOR.
	 *
	 * @param  streams  Optionally register the specified streams at the same time we instantiate.
	 */
	constructor(streams?: Maybe<StreamCollection>) {
		this.streams = streams ?? this.streams;
	}

	/**
	 * Returns TRUE if a stream with the specified key has been registered.
	 *
	 * @param    streamKey  Unique stream key.
	 * @returns  TRUE if the stream manager has the specified stream.
	 */
	public hasStream = (streamKey: string): boolean => {
		return this.getStream(streamKey) != null;
	};

	/**
	 * Gets a registered stream by key.
	 *
	 * @param   streamKey  Unique stream key.
	 */
	public getStream = (streamKey: string): Nullable<IGenericStream> => {
		streamKey = this.validateStreamKey(streamKey, { method: 'getStream', throwError: false });

		return (streamKey && this.streams.get(streamKey)) || null;
	};

	/**
	 * @returns  An array of the currently registered stream keys.
	 */
	public list(): string[] {
		return Array.from(this.streams.keys());
	}

	/**
	 * Count of all currently registered streams.
	 */
	public get count(): number {
		return this.streams.size;
	}

	/**
	 * @returns TRUE if this stream manager has any registered streams.
	 */
	public hasStreams(): boolean {
		return this.count > 0;
	}

	/**
	 * Registers a stream with this stream manager.
	 *
	 * @param   streamKey  Unique stream key.
	 * @param   stream     The stream to register.
	 * @throws  Error      When the stream key is invalid or is not registered.
	 */
	public registerStream(streamKey: string, stream: IGenericStream, opts?: { throwError?: boolean }): boolean {
		const { throwError = true } = opts || {};

		streamKey = this.validateStreamKey(streamKey, { method: 'registerStream', throwError });
		if (streamKey === '') {
			return false;
		}

		if (this.streams.has(streamKey)) {
			const message = this.debugMsg(`Stream with key ${streamKey} already registered`, 'registerStream');
			if (throwError) {
				throw new Error(message);
			}
			console.error(message);

			return false;
		}

		this.streams.set(streamKey, stream);

		return true;
	}

	/**
	 * Unregisters a stream from this stream manager.
	 *
	 * @param   streamKey  Unique stream key.
	 * @throws  Error      When the stream key is invalid or is not registered.
	 */
	public unregisterStream(streamKey: string, opts?: { throwError?: boolean }): boolean {
		const { throwError = true } = opts || {};

		streamKey = this.validateStreamKey(streamKey, { method: 'unregisterStream', throwError });
		if (streamKey === '') {
			return false;
		}

		if (!this.streams.has(streamKey)) {
			const message = this.debugMsg(`Stream with key ${streamKey} is not registered`, 'unregisterStream');
			if (throwError) {
				throw new Error(message);
			}
			console.error(message);

			return false;
		}

		this.stop(streamKey);
		this.streams.delete(streamKey);

		return true;
	}

	/**
	 * Attempts to unregister the specified streams - using the specified props.
	 *
	 * @param   streams  The streams to be affected by this operation.
	 * @returns A record of the start operation results - for each of the specified streams.
	 */
	public unregisterMultiple(streams: string[]): MultipleOpsResults {
		const result: MultipleOpsResults = {};

		streams.forEach((streamKey) => {
			result[streamKey] = this.unregisterStream(streamKey, { throwError: false });
		});

		return result;
	}

	/**
	 * Attempts to unregister all of the currently registered streams.
	 *
	 * @returns A record of the unregister operation results - for each of the specified streams.
	 */
	public unregisterAll(): MultipleOpsResults {
		return this.unregisterMultiple(this.list());
	}

	/**
	 * Subscribe to events on the specified registered stream.
	 *
	 * @param   streamKey   Unique stream key.
	 * @param   subscriber  Set of callbacks to execute when the relevant stream actions occur (eg. onData, onError, etc).
	 * @throws  Error       When the stream key is invalid or is not registered.
	 */
	public subscribe<StreamDataType>(streamKey: string, subscriber: IStreamSubscriber<StreamDataType>) {
		const stream = this.validateStreamRegistered(streamKey, { method: 'subscribeToStream' });

		stream && stream.subscribe(subscriber);
	}

	/**
	 * Unsubscribes the specified subscriber from the specified registered stream.
	 *
	 * @param   streamKey   Unique stream key.
	 * @param   subscriber  Set of callbacks to execute when the relevant stream actions occur (eg. onData, onError, etc).
	 * @throws  Error       When the stream key is invalid or is not registered.
	 */
	public unsubscribe<StreamDataType>(streamKey: string, subscriber: IStreamSubscriber<StreamDataType>) {
		const stream = this.validateStreamRegistered(streamKey, { method: 'unsubscribe' });

		stream && stream.unsubscribe(subscriber);
	}

	/**
	 * Unsubscribes all subscribers from the specified registered stream.
	 *
	 * @param   streamKey  Unique stream key.
	 * @throws  Error      When the stream key is invalid or is not registered.
	 */
	public unsubscribeAllForStream(streamKey: string): void {
		const stream = this.validateStreamRegistered(streamKey, { method: 'unsubscribeAllForStream' });

		stream && stream.unsubscribeAll();
	}

	/**
	 * Attempts to start the specified registered stream.
	 *
	 * @param    streamKey  Unique stream key.
	 * @throws   Error      When the stream key is invalid or is not registered.
	 * @returns  TRUE if the attempt to start the stream succeeded. Note that this does NOT mean the stream actually
	 *           started and received data - you must subscribe to the stream to know that.
	 */
	public start(streamKey: string, props?: Maybe<IStreamRequestProps>, opts?: { throwError?: boolean }) {
		const stream = this.validateStreamRegistered(streamKey, { method: 'start', throwError: opts?.throwError });

		return stream && !stream.isActive ? stream.start(props) : false;
	}

	/**
	 * Attempts to restart the specified registered stream.
	 *
	 * @param    streamKey  Unique stream key.
	 * @throws   Error      When the stream key is invalid or is not registered.
	 * @returns  TRUE if the attempt to restart the stream succeeded. Note that this does NOT mean the stream
	 *           actually re-connected and received data - you must subscribe to the stream to know that.
	 */
	public restart(streamKey: string, props?: Maybe<IStreamRequestProps>, opts?: { throwError?: boolean }) {
		const stream = this.validateStreamRegistered(streamKey, { method: 'restart', throwError: opts?.throwError });

		return stream ? stream.restart(props) : false;
	}

	/**
	 * Attempts to stops the specified registered stream.
	 *
	 * @param   streamKey  Unique stream key.
	 * @throws  Error      When the stream key is invalid or is not registered.
	 * @returns TRUE if the attempt to stop the stream succeeded.
	 */
	public stop(streamKey: string, opts?: { throwError?: boolean }): boolean {
		const stream = this.validateStreamRegistered(streamKey, { method: 'stop', throwError: opts?.throwError });

		return stream ? stream.stop() : false;
	}

	/**
	 * @param   streamKey  Unique stream key.
	 * @throws  Error      When the stream key is invalid or is not registered.
	 * @returns TRUE if the specified stream is currently active.
	 */
	public isActive(streamKey: string, opts?: { throwError?: boolean }): boolean {
		const stream = this.validateStreamRegistered(streamKey, { method: 'isActive', throwError: opts?.throwError });

		return stream ? stream.isActive : false;
	}

	/**
	 * @param   streamKey  Unique stream key.
	 * @throws  Error      When the stream key is invalid or is not registered.
	 * @returns TRUE if the specified stream is currently enabled.
	 */
	public isEnabled(streamKey: string, opts?: { throwError?: boolean }): boolean {
		const stream = this.validateStreamRegistered(streamKey, { method: 'isEnabled', throwError: opts?.throwError });

		return stream ? stream.isEnabled : false;
	}

	/**
	 * Enables/disables the specified stream.
	 *
	 * @param   streamKey  Unique stream key.
	 * @param   enabled    Enabled state.
	 * @throws  Error      When the stream key is invalid or is not registered.
	 * @returns TRUE if the attempt to enable/disable the stream succeeded.
	 */
	public setEnabled(streamKey: string, enabled: boolean, opts?: { throwError?: boolean }): boolean {
		const stream = this.validateStreamRegistered(streamKey, { method: 'setEnabled', throwError: opts?.throwError });

		if (!stream) {
			return false;
		}

		stream.isEnabled = enabled;

		return true;
	}

	/**
	 * Attempts to start all the specified streams - using the specified props.
	 *
	 * @param   streams  The streams to be affected by this operation.
	 * @returns A record of the start operation results - for each of the specified streams.
	 */
	public startMultiple(streams: MultipleOpsStreams): MultipleOpsResults {
		const result: MultipleOpsResults = {};
		const iterator = this.resolveMultipleOpsStreamsIterator(streams);

		iterator.forEach(([streamKey, props]) => {
			result[streamKey] = this.start(streamKey, props, { throwError: false });
		});

		return result;
	}

	/**
	 * Attempts to restart all the specified streams - using the specified props.
	 *
	 * @param   streams  The streams to be affected by this operation.
	 * @returns A record of the restart operation results - for each of the specified streams.
	 */
	public restartMultiple(streams: MultipleOpsStreams): MultipleOpsResults {
		const result: MultipleOpsResults = {};
		const iterator = this.resolveMultipleOpsStreamsIterator(streams);

		iterator.forEach(([streamKey, props]) => {
			result[streamKey] = this.restart(streamKey, props, { throwError: false });
		});

		return result;
	}

	/**
	 * Attempts to stop all the specified streams.
	 *
	 * @param   streams  The streams to be affected by this operation.
	 * @returns A record of the stop operation results - for each of the specified streams.
	 */
	public stopMultiple(streams: MultipleOpsStreams): MultipleOpsResults {
		const result: MultipleOpsResults = {};
		const iterator = this.resolveMultipleOpsStreamsIterator(streams);

		iterator.forEach(([streamKey]) => {
			result[streamKey] = this.stop(streamKey, { throwError: false });
		});

		return result;
	}

	/**
	 * Attempts to enable all the specified streams.
	 *
	 * @param   streams  The streams to be affected by this operation.
	 * @returns A record of the enable operation results - for each of the specified streams.
	 */
	public enableMultiple(streams: MultipleOpsStreams): MultipleOpsResults {
		return this.setMultipleEnabled(streams, true);
	}

	/**
	 * Attempts to disable all the specified streams.
	 *
	 * @param   streams  The streams to be affected by this operation.
	 * @returns A record of the disable operation results - for each of the specified streams.
	 */
	public disableMultiple(streams: MultipleOpsStreams): MultipleOpsResults {
		return this.setMultipleEnabled(streams, false);
	}

	/**
	 * Attempts to stop all of the currently registered streams.
	 *
	 * @returns A record of the stop operation results - for each of the specified streams.
	 */
	public stopAll(): MultipleOpsResults {
		return this.stopMultiple(this.list());
	}

	/**
	 * Attempts to start all of the currently registered streams with the last props they each started with.
	 *
	 * @returns A record of the start operation results - for each of the specified streams.
	 */
	public startAll(): MultipleOpsResults {
		return this.startMultiple(this.list());
	}

	/**
	 * Attempts to restart all of the currently registered streams with the last props they each started with.
	 *
	 * @returns A record of the restart operation results - for each of the specified streams.
	 */
	public restartAll(): MultipleOpsResults {
		return this.restartMultiple(this.list());
	}

	/**
	 * Attempts to enable all of the currently registered streams.
	 *
	 * @returns A record of the enable operation results - for each of the specified streams.
	 */
	public enableAll(): MultipleOpsResults {
		return this.enableMultiple(this.list());
	}

	/**
	 * Attempts to disable all of the currently registered streams.
	 *
	 * @returns A record of the disable operation results - for each of the specified streams.
	 */
	public disableAll(): MultipleOpsResults {
		return this.disableMultiple(this.list());
	}

	/**
	 * Clear/reset this stream manager instance.
	 */
	public clear() {
		if (this.hasStreams()) {
			this.unregisterAll();
		}

		this.streams = new Map<string, IGenericStream>();
	}

	/**
	 * Resolves the entries to iterate through for the multiple operation methods (eg. startMultiple).
	 *
	 * @param   streams  The streams to be affected by this operation.
	 * @returns An `entries` type iterator of [streamKey, props]
	 */
	protected resolveMultipleOpsStreamsIterator = (streams: MultipleOpsStreams) => {
		let result: [string, Maybe<IStreamRequestProps>][] = [];

		if (isArray(streams)) {
			streams.forEach((stream: string | [string, Maybe<IStreamRequestProps>]) => {
				if (isString(stream) && stream !== '') {
					result.push([stream, null]);
				} else if (isArray(stream) && stream.length > 0 && stream[0] !== '') {
					result.push(stream);
				}
			});
		} else {
			result = entries(streams).filter((stream) => stream[0] !== '');
		}

		return result;
	};

	/**
	 * Validates and resolves the specified stream key.
	 *
	 * @throws  Error  When `throwError` option is TRUE and the stream key is empty.
	 * @returns The applied stream key we resolved during validation.
	 */
	protected validateStreamKey = (
		streamKey: string,
		opts?: { throwError?: boolean; method?: Maybe<string> }
	): string => {
		streamKey = streamKey.toLowerCase();

		const { method = null, throwError = false } = opts || {};

		if (streamKey === '') {
			const message = this.debugMsg('Stream key is empty', method);
			if (throwError) {
				throw new Error(message);
			}
			console.error(message);

			return '';
		}

		return streamKey;
	};

	/**
	 * Attempts to enable/disable all the specified streams.
	 *
	 * @param   streams  The streams to be affected by this operation.
	 * @returns A record of the set operation results - for each of the specified streams.
	 */
	protected setMultipleEnabled(streams: MultipleOpsStreams, enabled: boolean): MultipleOpsResults {
		const result: MultipleOpsResults = {};
		const iterator = this.resolveMultipleOpsStreamsIterator(streams);

		iterator.forEach(([streamKey]) => {
			result[streamKey] = this.setEnabled(streamKey, enabled, { throwError: false });
		});

		return result;
	}

	/**
	 * Validates that the specified stream key is a registered stream.
	 *
	 * @throws   Error  When `throwError` option is TRUE and the stream key is invalid.
	 * @throws   Error  When `throwError` option is TRUE and the stream has not been registered.
	 * @returns  The resolved stream object, or NULL if not valid/registered.
	 */
	protected validateStreamRegistered = (
		streamKey: string,
		opts?: { throwError?: boolean; method?: Maybe<string> }
	): Nullable<IGenericStream> => {
		const { method = null, throwError = true } = opts || {};

		streamKey = this.validateStreamKey(streamKey, { method, throwError });

		const stream = streamKey != '' ? this.getStream(streamKey) : null;

		if (!stream) {
			const message = this.debugMsg(`Stream with key '${streamKey}' has not been registered`, method);
			if (throwError) {
				throw new Error(message);
			}
			console.error(message);

			return null;
		}

		return stream;
	};

	/**
	 * Creates a formatted debug message for the specified message and method.
	 */
	protected debugMsg(msg: string, method?: Maybe<string>): string {
		if (msg === '') {
			return '';
		}

		const prefix = `[StreamManager]: ${method ? `${method} - ` : ''}`;

		return `${prefix}${msg}`;
	}
}

const instance = new StreamManager();

export { instance as default };
export { instance as Instance, StreamManager };
