/**********************************************************************************************************************
 * Creates and initializes the various dealer data stores that underpin this application.
 *********************************************************************************************************************/
import { IHubBackOfficeService, IRulesService, IStoreBase, ITableSeatsPlayService, ITableService } from '../lib-rpc';
import { getBackOfficeService, getGameService } from '../services';
import { DealerStoreKey, DealerStoreKeys } from './constants';
import {
	DealerHudStore,
	DragonTigerTableHistoryStore,
	PlayStore,
	RulesStore,
	TableSeatsPlayStore,
	TableStore,
} from './storeClasses/index.mainStores';
import { ITableStoreOpts } from './storeClasses/TableStore';

const newRulesStore = (service?: Maybe<IRulesService>): RulesStore => {
	return new RulesStore(service ?? getGameService());
};

const newTableStore = (service?: Maybe<ITableService>, opts?: Maybe<ITableStoreOpts>): TableStore => {
	return new TableStore(service ?? getGameService(), opts);
};

const newTableSeatsPlayStore = (service?: Maybe<ITableSeatsPlayService>): TableSeatsPlayStore => {
	return new TableSeatsPlayStore(service ?? getGameService());
};

const newPlayStore = (service?: Maybe<ITableService>): PlayStore => {
	return new PlayStore(service ?? getGameService());
};

const newDealerHudStore = (service?: Maybe<IHubBackOfficeService>): DealerHudStore => {
	return new DealerHudStore(service ?? getBackOfficeService());
};

const newTableHistoryStore = (service?: Maybe<ITableService>): DragonTigerTableHistoryStore => {
	return new DragonTigerTableHistoryStore(service ?? getGameService());
};

// ----- Store instances ----------------------------------------------------------------------------------------------

let _dealerHudStore: DealerHudStore;
let _dealerTableStore: TableStore;
let _dealerPlayStore: PlayStore;
let _dealerRulesStore: RulesStore;
let _dealerTableSeatsPlayStore: TableSeatsPlayStore;
let _dealerTableHistoryStore: DragonTigerTableHistoryStore;

// ---- Initialize ----------------------------------------------------------------------------------------------------

let _isInitialized = false;

const makeStores = () => {
	_dealerHudStore = newDealerHudStore();
	_dealerPlayStore = newPlayStore();
	_dealerRulesStore = newRulesStore();
	_dealerTableSeatsPlayStore = newTableSeatsPlayStore();
	_dealerTableHistoryStore = newTableHistoryStore();

	_dealerTableStore = newTableStore(null, {
		isOnDataUpdateEnabled: false,
		rulesStore: _dealerRulesStore,
		playStore: _dealerPlayStore,
	});
};

const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	makeStores();

	_isInitialized = true;

	return true;
};

// ---- Utility -------------------------------------------------------------------------------------------------------

const getDealerHudStore = (): DealerHudStore => _dealerHudStore;
const getDealerPlayStore = (): PlayStore => _dealerPlayStore;
const getDealerRulesStore = (): RulesStore => _dealerRulesStore;
const getDealerTableSeatsPlayStore = (): TableSeatsPlayStore => _dealerTableSeatsPlayStore;
const getDealerTableStore = (): TableStore => _dealerTableStore;
const getDealerTableHistoryStore = (): DragonTigerTableHistoryStore => _dealerTableHistoryStore;

const setDealerHudStore = (val: DealerHudStore) => (_dealerHudStore = val);
const setDealerPlayStore = (val: PlayStore) => (_dealerPlayStore = val);
const setDealerRulesStore = (val: RulesStore) => (_dealerRulesStore = val);
const setDealerTableSeatsPlayStore = (val: TableSeatsPlayStore) => (_dealerTableSeatsPlayStore = val);
const setDealerTableStore = (val: TableStore) => (_dealerTableStore = val);
const setDealerTableHistoryStore = (val: DragonTigerTableHistoryStore) => (_dealerTableHistoryStore = val);

const clearMultipleStores = (storeKeys: DealerStoreKey[]) => {
	storeKeys.forEach((storeKey: DealerStoreKey) => clearStoreByKey(storeKey));
};

const clearAllStores = () => clearMultipleStores(DealerStoreKeys);

const clearStoreByKey = (storeKey: DealerStoreKey): boolean => {
	const store = getStoreByKey(storeKey);
	if (store == null) {
		return false;
	}

	store.clear();

	return true;
};

const getStoreByKey = (storeKey: DealerStoreKey): Nullable<IStoreBase> => {
	switch (storeKey) {
		case DealerStoreKey.DealerHudStore:
			return _dealerHudStore;
		case DealerStoreKey.DealerPlayStore:
			return _dealerPlayStore;
		case DealerStoreKey.DealerRulesStore:
			return _dealerRulesStore;
		case DealerStoreKey.DealerTableStore:
			return _dealerTableStore;
		case DealerStoreKey.DealerTableSeatsPlayStore:
			return _dealerTableSeatsPlayStore;
		case DealerStoreKey.DealerTableHistoryStore:
			return _dealerTableHistoryStore;
		default:
			return null;
	}
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initDealerStores };
export {
	getDealerHudStore,
	getDealerPlayStore,
	getDealerRulesStore,
	getDealerTableSeatsPlayStore,
	getDealerTableStore,
	getDealerTableHistoryStore,
};
export {
	setDealerHudStore,
	setDealerPlayStore,
	setDealerRulesStore,
	setDealerTableSeatsPlayStore,
	setDealerTableStore,
	setDealerTableHistoryStore,
};
export {
	getStoreByKey as getDealerStoreByKey,
	clearMultipleStores as clearMultipleDealerStores,
	clearAllStores as clearAllDealerStores,
};
