import { IDDRumSessionConfig } from './types';

const DEFAULT_DD_SITE = 'datadoghq.com';
const NOT_SET = 'not_set';

enum SDKInitState {
	NOT_INITIALIZED = '',
	INITIALIZING = 'INITIALIZING',
	AVAILABLE = 'AVAILABLE',
	NOT_AVAILABLE = 'NOT_AVAILABLE',
}

// These must be the same across RUM and Browser Logging
const DDRumCommonSessionConfig: IDDRumSessionConfig = {
	trackSessionAcrossSubdomains: false,
	useSecureSessionCookie: false,
	useCrossSiteSessionCookie: false,
};

export { DDRumCommonSessionConfig, DEFAULT_DD_SITE, NOT_SET, SDKInitState };
export type { IDDRumSessionConfig };
