import { WagerName } from 'Constants';
import { getPlaceWagerSoundKey, IPlayMethodOptions, SoundManager } from 'helpers/sounds';

const playSound = (soundKey: string, opts?: Maybe<IPlayMethodOptions>): boolean => {
	return SoundManager.play(soundKey, opts);
};

const playPlaceWagerSound = (betName: string, amount: number) => {
	const soundKey = getPlaceWagerSoundKey(betName as WagerName, amount);
	playSound(soundKey);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { playSound, playPlaceWagerSound };
