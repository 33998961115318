/**********************************************************************************************************************
 * Stores and manages data regarding user-controlled game settings.
 *
 * Note: This will be used as the base parent class for the same store inside of the game clients.
 *********************************************************************************************************************/
import toInteger from 'lodash/toInteger';
import { IStoreBase } from './types';

interface ISettings {
	// Video performance (0 = quality, 1 = bandwidth)
	videoSetting: number;
	// Dealer volume % (percentage between 0.0 to 1.0)
	dealerVolume: number;
	// Game volume % (percentage between 0.0 to 1.0)
	gameVolume: number;
	// Ambient volume % (percentage between 0.0 to 1.0)
	ambientVolume: number;
	// Chat enabled (TRUE/FALSE)
	isChatEnabled: boolean;
	// Chat filter enabled (TRUE/FALSE)
	isChatFilterEnabled: boolean;
}

const defaultSettings = (): ISettings => {
	return {
		videoSetting: 0,
		dealerVolume: 0.5,
		gameVolume: 0.3,
		ambientVolume: 0.1,
		isChatEnabled: true,
		isChatFilterEnabled: true,
	};
};

class SettingsStore implements IStoreBase {
	// The underlying data inside this store.
	protected _data: ISettings;

	// The unix timestamp (UTC) for when the data in this store was last updated.
	protected _lastUpdatedTs: number = 0;

	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		this._data = defaultSettings();
	}

	/**
	 * The underlying data inside this store.
	 */
	public get data(): ISettings {
		return this._data;
	}
	public set data(value: ISettings) {
		this._data = value;
		this._lastUpdatedTs = Date.now();
	}

	/**
	 * @returns The unix timestamp (UTC) for when the data in this store was last updated.
	 */
	public get lastUpdatedTs() {
		return this._lastUpdatedTs;
	}

	/**
	 * Video setting (0|1)
	 */
	public get videoSetting(): number {
		return this._data.videoSetting;
	}
	public set videoSetting(value: number) {
		value = Math.max(toInteger(value), 0);

		if (![0, 1].includes(value)) {
			throw new Error('[SettingsStore]: videoSettting - Value must be be 0 or 1');
		}

		this._data.videoSetting = value;
		this.setUpdatedTs();
	}

	/**
	 * Dealer volume % (percentage between 0.0 to 1.0)
	 */
	public get dealerVolume(): number {
		return this._data.dealerVolume;
	}
	public set dealerVolume(value: number) {
		value = Math.max(value, 0.0);
		if (value > 1.0) {
			throw new Error('[SettingsStore]: dealerVolume - Value must be between 0 and 1');
		}

		this._data.dealerVolume = value;
		this.setUpdatedTs();
	}

	/**
	 * Game volume % (percentage between 0.0 to 1.0)
	 */
	public get gameVolume(): number {
		return this._data.gameVolume;
	}
	public set gameVolume(value: number) {
		value = Math.max(value, 0.0);
		if (value > 1.0) {
			throw new Error('[SettingsStore]: gameVolume - Value must be between 0 and 1');
		}

		this._data.gameVolume = value;
		this.setUpdatedTs();
	}

	/**
	 * Ambient volume % (percentage between 0.0 to 1.0)
	 */
	public get ambientVolume(): number {
		return this._data.ambientVolume;
	}
	public set ambientVolume(value: number) {
		value = Math.max(value, 0.0);
		if (value > 1.0) {
			throw new Error('[SettingsStore]: ambientVolume - Value must be between 0 and 1');
		}

		this._data.ambientVolume = value;
		this.setUpdatedTs();
	}

	/**
	 * Chat enabled (TRUE/FALSE)
	 */
	public get isChatEnabled(): boolean {
		return this._data.isChatEnabled;
	}
	public set isChatEnabled(value: boolean) {
		this._data.isChatEnabled = value;
		this.setUpdatedTs();
	}

	/**
	 * Chat filter enabled (TRUE/FALSE)
	 */
	public get isChatFilterEnabled(): boolean {
		return this._data.isChatFilterEnabled;
	}
	public set isChatFilterEnabled(value: boolean) {
		this._data.isChatFilterEnabled = value;
		this.setUpdatedTs();
	}

	/**
	 * Action. Toggles the `isChatEnabled` setting.
	 */
	public toggleChatEnabled = () => (this.isChatEnabled = !this.isChatEnabled);

	/**
	 * Action. Toggles the `isChatFilterEnabled` setting.
	 */
	public toggleChatFilterEnabled = () => (this.isChatFilterEnabled = !this.isChatFilterEnabled);

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		this._data = defaultSettings();
		this._lastUpdatedTs = 0;
	}

	/**
	 * Action. Set the last updated timestamp to now.
	 */
	protected setUpdatedTs() {
		this._lastUpdatedTs = Date.now();
	}
}

export { SettingsStore as default };
export { SettingsStore };
export type { ISettings };
