// source: hub_game.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.com.sands.live_dealer.hub.BaccaratRules', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.BlackjackRules', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.Composite', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.CompositeSpace', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DealtInput', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DealtRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DealtValue', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.DragonTigerRules', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GameVariantRules', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.HubAction', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.Play', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.PlaySeat', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.PlayState', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.RouletteRules', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.RouletteWheelStop', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.Rules', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.SpokeInput', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.StepValue', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.Table', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.TableSeat', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.ThreeCardPokerRules', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.Rules.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.Rules.displayName = 'proto.com.sands.live_dealer.hub.Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GameVariantRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GameVariantRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GameVariantRules.displayName = 'proto.com.sands.live_dealer.hub.GameVariantRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.Table.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.Table.displayName = 'proto.com.sands.live_dealer.hub.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.TableSeat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.TableSeat.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.TableSeat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.TableSeat.displayName = 'proto.com.sands.live_dealer.hub.TableSeat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.Play = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.Play.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.Play, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.Play.displayName = 'proto.com.sands.live_dealer.hub.Play';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.PlayState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.PlayState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.PlayState.displayName = 'proto.com.sands.live_dealer.hub.PlayState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.Composite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.Composite.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.Composite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.Composite.displayName = 'proto.com.sands.live_dealer.hub.Composite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.CompositeSpace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.CompositeSpace.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.CompositeSpace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.CompositeSpace.displayName = 'proto.com.sands.live_dealer.hub.CompositeSpace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.StepValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.StepValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.StepValue.displayName = 'proto.com.sands.live_dealer.hub.StepValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.PlaySeat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.PlaySeat.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.PlaySeat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.PlaySeat.displayName = 'proto.com.sands.live_dealer.hub.PlaySeat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.SpokeInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.SpokeInput.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.SpokeInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.SpokeInput.displayName = 'proto.com.sands.live_dealer.hub.SpokeInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.HubAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.HubAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.HubAction.displayName = 'proto.com.sands.live_dealer.hub.HubAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DealtRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DealtRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DealtRequest.displayName = 'proto.com.sands.live_dealer.hub.DealtRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DealtInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.DealtInput.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DealtInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DealtInput.displayName = 'proto.com.sands.live_dealer.hub.DealtInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DealtValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DealtValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DealtValue.displayName = 'proto.com.sands.live_dealer.hub.DealtValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.BaccaratRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.BaccaratRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.BaccaratRules.displayName = 'proto.com.sands.live_dealer.hub.BaccaratRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.DragonTigerRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.DragonTigerRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.DragonTigerRules.displayName = 'proto.com.sands.live_dealer.hub.DragonTigerRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.RouletteRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.RouletteRules.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.RouletteRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.RouletteRules.displayName = 'proto.com.sands.live_dealer.hub.RouletteRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.RouletteWheelStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.RouletteWheelStop.displayName = 'proto.com.sands.live_dealer.hub.RouletteWheelStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.BlackjackRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.BlackjackRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.BlackjackRules.displayName = 'proto.com.sands.live_dealer.hub.BlackjackRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.ThreeCardPokerRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.ThreeCardPokerRules.displayName = 'proto.com.sands.live_dealer.hub.ThreeCardPokerRules';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.Rules.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    game: jspb.Message.getFieldWithDefault(msg, 2, ""),
    variant: jspb.Message.getFieldWithDefault(msg, 3, ""),
    seating: jspb.Message.getFieldWithDefault(msg, 4, ""),
    runRequiresWager: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    trackShoes: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    validValuesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    gameVariantRules: (f = msg.getGameVariantRules()) && proto.com.sands.live_dealer.hub.GameVariantRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.Rules}
 */
proto.com.sands.live_dealer.hub.Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.Rules;
  return proto.com.sands.live_dealer.hub.Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.Rules}
 */
proto.com.sands.live_dealer.hub.Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariant(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeating(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunRequiresWager(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrackShoes(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addValidValues(value);
      break;
    case 8:
      var value = new proto.com.sands.live_dealer.hub.GameVariantRules;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.GameVariantRules.deserializeBinaryFromReader);
      msg.setGameVariantRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVariant();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeating();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRunRequiresWager();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTrackShoes();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getValidValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getGameVariantRules();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.com.sands.live_dealer.hub.GameVariantRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string variant = 3;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.getVariant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.setVariant = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string seating = 4;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.getSeating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.setSeating = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool run_requires_wager = 5;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.getRunRequiresWager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.setRunRequiresWager = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool track_shoes = 6;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.getTrackShoes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.setTrackShoes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated string valid_values = 7;
 * @return {!Array<string>}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.getValidValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.setValidValuesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.addValidValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.clearValidValuesList = function() {
  return this.setValidValuesList([]);
};


/**
 * optional GameVariantRules game_variant_rules = 8;
 * @return {?proto.com.sands.live_dealer.hub.GameVariantRules}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.getGameVariantRules = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.GameVariantRules} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.GameVariantRules, 8));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.GameVariantRules|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
*/
proto.com.sands.live_dealer.hub.Rules.prototype.setGameVariantRules = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.Rules} returns this
 */
proto.com.sands.live_dealer.hub.Rules.prototype.clearGameVariantRules = function() {
  return this.setGameVariantRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.Rules.prototype.hasGameVariantRules = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GameVariantRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GameVariantRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GameVariantRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    baccarat: (f = msg.getBaccarat()) && proto.com.sands.live_dealer.hub.BaccaratRules.toObject(includeInstance, f),
    roulette: (f = msg.getRoulette()) && proto.com.sands.live_dealer.hub.RouletteRules.toObject(includeInstance, f),
    blackjack: (f = msg.getBlackjack()) && proto.com.sands.live_dealer.hub.BlackjackRules.toObject(includeInstance, f),
    threeCardPoker: (f = msg.getThreeCardPoker()) && proto.com.sands.live_dealer.hub.ThreeCardPokerRules.toObject(includeInstance, f),
    dragonTiger: (f = msg.getDragonTiger()) && proto.com.sands.live_dealer.hub.DragonTigerRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GameVariantRules;
  return proto.com.sands.live_dealer.hub.GameVariantRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GameVariantRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.sands.live_dealer.hub.BaccaratRules;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.BaccaratRules.deserializeBinaryFromReader);
      msg.setBaccarat(value);
      break;
    case 2:
      var value = new proto.com.sands.live_dealer.hub.RouletteRules;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.RouletteRules.deserializeBinaryFromReader);
      msg.setRoulette(value);
      break;
    case 3:
      var value = new proto.com.sands.live_dealer.hub.BlackjackRules;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.BlackjackRules.deserializeBinaryFromReader);
      msg.setBlackjack(value);
      break;
    case 4:
      var value = new proto.com.sands.live_dealer.hub.ThreeCardPokerRules;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.ThreeCardPokerRules.deserializeBinaryFromReader);
      msg.setThreeCardPoker(value);
      break;
    case 5:
      var value = new proto.com.sands.live_dealer.hub.DragonTigerRules;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.DragonTigerRules.deserializeBinaryFromReader);
      msg.setDragonTiger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GameVariantRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GameVariantRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GameVariantRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaccarat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.sands.live_dealer.hub.BaccaratRules.serializeBinaryToWriter
    );
  }
  f = message.getRoulette();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.sands.live_dealer.hub.RouletteRules.serializeBinaryToWriter
    );
  }
  f = message.getBlackjack();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.sands.live_dealer.hub.BlackjackRules.serializeBinaryToWriter
    );
  }
  f = message.getThreeCardPoker();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.sands.live_dealer.hub.ThreeCardPokerRules.serializeBinaryToWriter
    );
  }
  f = message.getDragonTiger();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.sands.live_dealer.hub.DragonTigerRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaccaratRules baccarat = 1;
 * @return {?proto.com.sands.live_dealer.hub.BaccaratRules}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.getBaccarat = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.BaccaratRules} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.BaccaratRules, 1));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.BaccaratRules|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
*/
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.setBaccarat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.clearBaccarat = function() {
  return this.setBaccarat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.hasBaccarat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RouletteRules roulette = 2;
 * @return {?proto.com.sands.live_dealer.hub.RouletteRules}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.getRoulette = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.RouletteRules} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.RouletteRules, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.RouletteRules|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
*/
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.setRoulette = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.clearRoulette = function() {
  return this.setRoulette(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.hasRoulette = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BlackjackRules blackjack = 3;
 * @return {?proto.com.sands.live_dealer.hub.BlackjackRules}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.getBlackjack = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.BlackjackRules} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.BlackjackRules, 3));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.BlackjackRules|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
*/
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.setBlackjack = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.clearBlackjack = function() {
  return this.setBlackjack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.hasBlackjack = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ThreeCardPokerRules three_card_poker = 4;
 * @return {?proto.com.sands.live_dealer.hub.ThreeCardPokerRules}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.getThreeCardPoker = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.ThreeCardPokerRules} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.ThreeCardPokerRules, 4));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.ThreeCardPokerRules|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
*/
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.setThreeCardPoker = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.clearThreeCardPoker = function() {
  return this.setThreeCardPoker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.hasThreeCardPoker = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DragonTigerRules dragon_tiger = 5;
 * @return {?proto.com.sands.live_dealer.hub.DragonTigerRules}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.getDragonTiger = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.DragonTigerRules} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.DragonTigerRules, 5));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.DragonTigerRules|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
*/
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.setDragonTiger = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GameVariantRules} returns this
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.clearDragonTiger = function() {
  return this.setDragonTiger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GameVariantRules.prototype.hasDragonTiger = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.Table.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    defaultName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    seatCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deviceKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stateNum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    playId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    autoRunDelaySec: jspb.Message.getFieldWithDefault(msg, 9, 0),
    actionTimeoutSec: jspb.Message.getFieldWithDefault(msg, 10, 0),
    shoeNum: jspb.Message.getFieldWithDefault(msg, 11, 0),
    shoePlayNum: jspb.Message.getFieldWithDefault(msg, 12, 0),
    spokesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.com.sands.live_dealer.hub.TableSeat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.Table}
 */
proto.com.sands.live_dealer.hub.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.Table;
  return proto.com.sands.live_dealer.hub.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.Table}
 */
proto.com.sands.live_dealer.hub.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStateNum(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAutoRunDelaySec(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActionTimeoutSec(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShoeNum(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShoePlayNum(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSpokes(value);
      break;
    case 14:
      var value = new proto.com.sands.live_dealer.hub.TableSeat;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.TableSeat.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDefaultName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeatCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStateNum();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getAutoRunDelaySec();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getActionTimeoutSec();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getShoeNum();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getShoePlayNum();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getSpokesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.com.sands.live_dealer.hub.TableSeat.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string default_name = 3;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getDefaultName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setDefaultName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 seat_count = 4;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getSeatCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setSeatCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string device_key = 5;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 state_num = 7;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getStateNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setStateNum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 play_id = 8;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 auto_run_delay_sec = 9;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getAutoRunDelaySec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setAutoRunDelaySec = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 action_timeout_sec = 10;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getActionTimeoutSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setActionTimeoutSec = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 shoe_num = 11;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getShoeNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setShoeNum = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 shoe_play_num = 12;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getShoePlayNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setShoePlayNum = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated string spokes = 13;
 * @return {!Array<string>}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getSpokesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.setSpokesList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.addSpokes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.clearSpokesList = function() {
  return this.setSpokesList([]);
};


/**
 * repeated TableSeat seats = 14;
 * @return {!Array<!proto.com.sands.live_dealer.hub.TableSeat>}
 */
proto.com.sands.live_dealer.hub.Table.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.TableSeat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.sands.live_dealer.hub.TableSeat, 14));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.TableSeat>} value
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
*/
proto.com.sands.live_dealer.hub.Table.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.TableSeat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.TableSeat}
 */
proto.com.sands.live_dealer.hub.Table.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.com.sands.live_dealer.hub.TableSeat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.Table} returns this
 */
proto.com.sands.live_dealer.hub.Table.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.TableSeat.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.TableSeat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.TableSeat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.TableSeat.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    claimed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    spokesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    displayName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.TableSeat}
 */
proto.com.sands.live_dealer.hub.TableSeat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.TableSeat;
  return proto.com.sands.live_dealer.hub.TableSeat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.TableSeat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.TableSeat}
 */
proto.com.sands.live_dealer.hub.TableSeat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatNum(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClaimed(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSpokes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.TableSeat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.TableSeat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.TableSeat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getClaimed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSpokesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.TableSeat} returns this
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 seat_num = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.TableSeat} returns this
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool claimed = 3;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.getClaimed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.TableSeat} returns this
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.setClaimed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string spokes = 4;
 * @return {!Array<string>}
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.getSpokesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.sands.live_dealer.hub.TableSeat} returns this
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.setSpokesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.TableSeat} returns this
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.addSpokes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.TableSeat} returns this
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.clearSpokesList = function() {
  return this.setSpokesList([]);
};


/**
 * optional string display_name = 5;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.TableSeat} returns this
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image_url = 6;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.TableSeat} returns this
 */
proto.com.sands.live_dealer.hub.TableSeat.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.Play.repeatedFields_ = [7,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.Play.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.Play.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.Play} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.Play.toObject = function(includeInstance, msg) {
  var f, obj = {
    playId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    game: jspb.Message.getFieldWithDefault(msg, 3, ""),
    variant: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    seatCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    spokesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    state: (f = msg.getState()) && proto.com.sands.live_dealer.hub.PlayState.toObject(includeInstance, f),
    stateNum: jspb.Message.getFieldWithDefault(msg, 9, 0),
    spokeState: jspb.Message.getFieldWithDefault(msg, 10, ""),
    activeSeatNum: jspb.Message.getFieldWithDefault(msg, 11, 0),
    lastSeatNum: jspb.Message.getFieldWithDefault(msg, 12, 0),
    autoRunTime: jspb.Message.getFieldWithDefault(msg, 13, 0),
    shoeNum: jspb.Message.getFieldWithDefault(msg, 14, 0),
    shoePlayNum: jspb.Message.getFieldWithDefault(msg, 15, 0),
    composite: (f = msg.getComposite()) && proto.com.sands.live_dealer.hub.Composite.toObject(includeInstance, f),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.com.sands.live_dealer.hub.PlaySeat.toObject, includeInstance),
    listeningFor: (f = msg.getListeningFor()) && proto.com.sands.live_dealer.hub.SpokeInput.toObject(includeInstance, f),
    waitingFor: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    traceId: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.Play}
 */
proto.com.sands.live_dealer.hub.Play.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.Play;
  return proto.com.sands.live_dealer.hub.Play.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.Play} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.Play}
 */
proto.com.sands.live_dealer.hub.Play.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariant(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatCount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSpokes(value);
      break;
    case 8:
      var value = new proto.com.sands.live_dealer.hub.PlayState;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.PlayState.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStateNum(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeState(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveSeatNum(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSeatNum(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAutoRunTime(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShoeNum(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShoePlayNum(value);
      break;
    case 16:
      var value = new proto.com.sands.live_dealer.hub.Composite;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.Composite.deserializeBinaryFromReader);
      msg.setComposite(value);
      break;
    case 17:
      var value = new proto.com.sands.live_dealer.hub.PlaySeat;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.PlaySeat.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    case 18:
      var value = new proto.com.sands.live_dealer.hub.SpokeInput;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.SpokeInput.deserializeBinaryFromReader);
      msg.setListeningFor(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitingFor(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTraceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.Play.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.Play.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.Play} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.Play.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVariant();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSeatCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSpokesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.com.sands.live_dealer.hub.PlayState.serializeBinaryToWriter
    );
  }
  f = message.getStateNum();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSpokeState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getActiveSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getLastSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getAutoRunTime();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getShoeNum();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getShoePlayNum();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getComposite();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.com.sands.live_dealer.hub.Composite.serializeBinaryToWriter
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.com.sands.live_dealer.hub.PlaySeat.serializeBinaryToWriter
    );
  }
  f = message.getListeningFor();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.com.sands.live_dealer.hub.SpokeInput.serializeBinaryToWriter
    );
  }
  f = message.getWaitingFor();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getTraceId();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
};


/**
 * optional uint64 play_id = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string game = 3;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string variant = 4;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getVariant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setVariant = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string table_id = 5;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 seat_count = 6;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getSeatCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setSeatCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string spokes = 7;
 * @return {!Array<string>}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getSpokesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setSpokesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.addSpokes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.clearSpokesList = function() {
  return this.setSpokesList([]);
};


/**
 * optional PlayState state = 8;
 * @return {?proto.com.sands.live_dealer.hub.PlayState}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getState = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.PlayState} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.PlayState, 8));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.PlayState|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
*/
proto.com.sands.live_dealer.hub.Play.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.Play.prototype.hasState = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 state_num = 9;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getStateNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setStateNum = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string spoke_state = 10;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getSpokeState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setSpokeState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 active_seat_num = 11;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getActiveSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setActiveSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 last_seat_num = 12;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getLastSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setLastSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 auto_run_time = 13;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getAutoRunTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setAutoRunTime = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 shoe_num = 14;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getShoeNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setShoeNum = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 shoe_play_num = 15;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getShoePlayNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setShoePlayNum = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional Composite composite = 16;
 * @return {?proto.com.sands.live_dealer.hub.Composite}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getComposite = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Composite} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.Composite, 16));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Composite|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
*/
proto.com.sands.live_dealer.hub.Play.prototype.setComposite = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.clearComposite = function() {
  return this.setComposite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.Play.prototype.hasComposite = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated PlaySeat seats = 17;
 * @return {!Array<!proto.com.sands.live_dealer.hub.PlaySeat>}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.PlaySeat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.sands.live_dealer.hub.PlaySeat, 17));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.PlaySeat>} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
*/
proto.com.sands.live_dealer.hub.Play.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.PlaySeat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat}
 */
proto.com.sands.live_dealer.hub.Play.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.com.sands.live_dealer.hub.PlaySeat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};


/**
 * optional SpokeInput listening_for = 18;
 * @return {?proto.com.sands.live_dealer.hub.SpokeInput}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getListeningFor = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.SpokeInput} */ (
    jspb.Message.getWrapperField(this, proto.com.sands.live_dealer.hub.SpokeInput, 18));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.SpokeInput|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
*/
proto.com.sands.live_dealer.hub.Play.prototype.setListeningFor = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.clearListeningFor = function() {
  return this.setListeningFor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.Play.prototype.hasListeningFor = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool waiting_for = 19;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getWaitingFor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setWaitingFor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional uint64 trace_id = 20;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Play.prototype.getTraceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Play} returns this
 */
proto.com.sands.live_dealer.hub.Play.prototype.setTraceId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.PlayState.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.PlayState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.PlayState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.PlayState.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    substate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.PlayState}
 */
proto.com.sands.live_dealer.hub.PlayState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.PlayState;
  return proto.com.sands.live_dealer.hub.PlayState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.PlayState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.PlayState}
 */
proto.com.sands.live_dealer.hub.PlayState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.PlayState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.PlayState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.PlayState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.PlayState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubstate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.PlayState.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.PlayState} returns this
 */
proto.com.sands.live_dealer.hub.PlayState.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string substate = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.PlayState.prototype.getSubstate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.PlayState} returns this
 */
proto.com.sands.live_dealer.hub.PlayState.prototype.setSubstate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.Composite.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.Composite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.Composite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.Composite.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spacesList: jspb.Message.toObjectList(msg.getSpacesList(),
    proto.com.sands.live_dealer.hub.CompositeSpace.toObject, includeInstance),
    burnedList: jspb.Message.toObjectList(msg.getBurnedList(),
    proto.com.sands.live_dealer.hub.StepValue.toObject, includeInstance),
    nonexistentList: jspb.Message.toObjectList(msg.getNonexistentList(),
    proto.com.sands.live_dealer.hub.StepValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.Composite}
 */
proto.com.sands.live_dealer.hub.Composite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.Composite;
  return proto.com.sands.live_dealer.hub.Composite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.Composite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.Composite}
 */
proto.com.sands.live_dealer.hub.Composite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStepNum(value);
      break;
    case 2:
      var value = new proto.com.sands.live_dealer.hub.CompositeSpace;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.CompositeSpace.deserializeBinaryFromReader);
      msg.addSpaces(value);
      break;
    case 3:
      var value = new proto.com.sands.live_dealer.hub.StepValue;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.StepValue.deserializeBinaryFromReader);
      msg.addBurned(value);
      break;
    case 4:
      var value = new proto.com.sands.live_dealer.hub.StepValue;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.StepValue.deserializeBinaryFromReader);
      msg.addNonexistent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.Composite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.Composite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.Composite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSpacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.sands.live_dealer.hub.CompositeSpace.serializeBinaryToWriter
    );
  }
  f = message.getBurnedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.sands.live_dealer.hub.StepValue.serializeBinaryToWriter
    );
  }
  f = message.getNonexistentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.sands.live_dealer.hub.StepValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 step_num = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.getStepNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.Composite} returns this
 */
proto.com.sands.live_dealer.hub.Composite.prototype.setStepNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated CompositeSpace spaces = 2;
 * @return {!Array<!proto.com.sands.live_dealer.hub.CompositeSpace>}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.getSpacesList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.CompositeSpace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.sands.live_dealer.hub.CompositeSpace, 2));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.CompositeSpace>} value
 * @return {!proto.com.sands.live_dealer.hub.Composite} returns this
*/
proto.com.sands.live_dealer.hub.Composite.prototype.setSpacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.CompositeSpace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.CompositeSpace}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.addSpaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.sands.live_dealer.hub.CompositeSpace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.Composite} returns this
 */
proto.com.sands.live_dealer.hub.Composite.prototype.clearSpacesList = function() {
  return this.setSpacesList([]);
};


/**
 * repeated StepValue burned = 3;
 * @return {!Array<!proto.com.sands.live_dealer.hub.StepValue>}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.getBurnedList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.StepValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.sands.live_dealer.hub.StepValue, 3));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.StepValue>} value
 * @return {!proto.com.sands.live_dealer.hub.Composite} returns this
*/
proto.com.sands.live_dealer.hub.Composite.prototype.setBurnedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.StepValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.StepValue}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.addBurned = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.sands.live_dealer.hub.StepValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.Composite} returns this
 */
proto.com.sands.live_dealer.hub.Composite.prototype.clearBurnedList = function() {
  return this.setBurnedList([]);
};


/**
 * repeated StepValue nonexistent = 4;
 * @return {!Array<!proto.com.sands.live_dealer.hub.StepValue>}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.getNonexistentList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.StepValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.sands.live_dealer.hub.StepValue, 4));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.StepValue>} value
 * @return {!proto.com.sands.live_dealer.hub.Composite} returns this
*/
proto.com.sands.live_dealer.hub.Composite.prototype.setNonexistentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.StepValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.StepValue}
 */
proto.com.sands.live_dealer.hub.Composite.prototype.addNonexistent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.sands.live_dealer.hub.StepValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.Composite} returns this
 */
proto.com.sands.live_dealer.hub.Composite.prototype.clearNonexistentList = function() {
  return this.setNonexistentList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.CompositeSpace.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.CompositeSpace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.CompositeSpace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.CompositeSpace.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spaceI: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.com.sands.live_dealer.hub.StepValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.CompositeSpace}
 */
proto.com.sands.live_dealer.hub.CompositeSpace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.CompositeSpace;
  return proto.com.sands.live_dealer.hub.CompositeSpace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.CompositeSpace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.CompositeSpace}
 */
proto.com.sands.live_dealer.hub.CompositeSpace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatNum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSpaceI(value);
      break;
    case 3:
      var value = new proto.com.sands.live_dealer.hub.StepValue;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.StepValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.CompositeSpace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.CompositeSpace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.CompositeSpace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSpaceI();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.sands.live_dealer.hub.StepValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 seat_num = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.CompositeSpace} returns this
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 space_i = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.getSpaceI = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.CompositeSpace} returns this
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.setSpaceI = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated StepValue values = 3;
 * @return {!Array<!proto.com.sands.live_dealer.hub.StepValue>}
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.StepValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.sands.live_dealer.hub.StepValue, 3));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.StepValue>} value
 * @return {!proto.com.sands.live_dealer.hub.CompositeSpace} returns this
*/
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.StepValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.StepValue}
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.sands.live_dealer.hub.StepValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.CompositeSpace} returns this
 */
proto.com.sands.live_dealer.hub.CompositeSpace.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.StepValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.StepValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.StepValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    revealed: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pending: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.StepValue}
 */
proto.com.sands.live_dealer.hub.StepValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.StepValue;
  return proto.com.sands.live_dealer.hub.StepValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.StepValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.StepValue}
 */
proto.com.sands.live_dealer.hub.StepValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStepNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevealed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.StepValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.StepValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.StepValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRevealed();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPending();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 step_num = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.getStepNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.StepValue} returns this
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.setStepNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.StepValue} returns this
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 revealed = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.getRevealed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.StepValue} returns this
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.setRevealed = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool pending = 4;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.getPending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.StepValue} returns this
 */
proto.com.sands.live_dealer.hub.StepValue.prototype.setPending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.PlaySeat.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.PlaySeat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.PlaySeat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.PlaySeat.toObject = function(includeInstance, msg) {
  var f, obj = {
    playId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seatNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    claimed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    spokesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    displayName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat}
 */
proto.com.sands.live_dealer.hub.PlaySeat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.PlaySeat;
  return proto.com.sands.live_dealer.hub.PlaySeat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.PlaySeat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat}
 */
proto.com.sands.live_dealer.hub.PlaySeat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatNum(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClaimed(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSpokes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.PlaySeat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.PlaySeat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.PlaySeat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getClaimed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSpokesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint64 play_id = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat} returns this
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 seat_num = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat} returns this
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool claimed = 3;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.getClaimed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat} returns this
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.setClaimed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string spokes = 4;
 * @return {!Array<string>}
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.getSpokesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat} returns this
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.setSpokesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat} returns this
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.addSpokes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat} returns this
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.clearSpokesList = function() {
  return this.setSpokesList([]);
};


/**
 * optional string display_name = 5;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat} returns this
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image_url = 6;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.PlaySeat} returns this
 */
proto.com.sands.live_dealer.hub.PlaySeat.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.SpokeInput.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.SpokeInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.SpokeInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.SpokeInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    spoke: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inputNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seatNum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.com.sands.live_dealer.hub.HubAction.toObject, includeInstance),
    priority: jspb.Message.getFieldWithDefault(msg, 6, 0),
    uniqueId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput}
 */
proto.com.sands.live_dealer.hub.SpokeInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.SpokeInput;
  return proto.com.sands.live_dealer.hub.SpokeInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.SpokeInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput}
 */
proto.com.sands.live_dealer.hub.SpokeInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpoke(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInputNum(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatNum(value);
      break;
    case 5:
      var value = new proto.com.sands.live_dealer.hub.HubAction;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.HubAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.SpokeInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.SpokeInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.SpokeInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpoke();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getInputNum();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.sands.live_dealer.hub.HubAction.serializeBinaryToWriter
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUniqueId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string spoke = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.getSpoke = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.setSpoke = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 input_num = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.getInputNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.setInputNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 seat_num = 4;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated HubAction actions = 5;
 * @return {!Array<!proto.com.sands.live_dealer.hub.HubAction>}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.HubAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.sands.live_dealer.hub.HubAction, 5));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.HubAction>} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput} returns this
*/
proto.com.sands.live_dealer.hub.SpokeInput.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.HubAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.HubAction}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.sands.live_dealer.hub.HubAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional int64 priority = 6;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string unique_id = 7;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.getUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInput} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInput.prototype.setUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.HubAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.HubAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.HubAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    inputNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    request: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seatNum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    seatSpaceI: jspb.Message.getFieldWithDefault(msg, 5, 0),
    spokeState: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.HubAction}
 */
proto.com.sands.live_dealer.hub.HubAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.HubAction;
  return proto.com.sands.live_dealer.hub.HubAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.HubAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.HubAction}
 */
proto.com.sands.live_dealer.hub.HubAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInputNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatNum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatSpaceI(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.HubAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.HubAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.HubAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInputNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSeatSpaceI();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSpokeState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 input_num = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.getInputNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.HubAction} returns this
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.setInputNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string request = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.HubAction} returns this
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 request_count = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.getRequestCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.HubAction} returns this
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.setRequestCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 seat_num = 4;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.HubAction} returns this
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 seat_space_i = 5;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.getSeatSpaceI = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.HubAction} returns this
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.setSeatSpaceI = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string spoke_state = 6;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.getSpokeState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.HubAction} returns this
 */
proto.com.sands.live_dealer.hub.HubAction.prototype.setSpokeState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DealtRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DealtRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DealtRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inputNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    request: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seatNum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    seatSpaceI: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DealtRequest}
 */
proto.com.sands.live_dealer.hub.DealtRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DealtRequest;
  return proto.com.sands.live_dealer.hub.DealtRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DealtRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DealtRequest}
 */
proto.com.sands.live_dealer.hub.DealtRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInputNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatNum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatSpaceI(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DealtRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DealtRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DealtRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInputNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSeatSpaceI();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 input_num = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.getInputNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DealtRequest} returns this
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.setInputNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string request = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.DealtRequest} returns this
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 request_count = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.getRequestCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DealtRequest} returns this
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.setRequestCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 seat_num = 4;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DealtRequest} returns this
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 seat_space_i = 5;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.getSeatSpaceI = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DealtRequest} returns this
 */
proto.com.sands.live_dealer.hub.DealtRequest.prototype.setSeatSpaceI = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.DealtInput.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DealtInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DealtInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DealtInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inputNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    request: jspb.Message.getFieldWithDefault(msg, 3, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DealtInput}
 */
proto.com.sands.live_dealer.hub.DealtInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DealtInput;
  return proto.com.sands.live_dealer.hub.DealtInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DealtInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DealtInput}
 */
proto.com.sands.live_dealer.hub.DealtInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInputNum(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DealtInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DealtInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DealtInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInputNum();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.DealtInput} returns this
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 input_num = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.getInputNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DealtInput} returns this
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.setInputNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string request = 3;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.DealtInput} returns this
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string values = 4;
 * @return {!Array<string>}
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.sands.live_dealer.hub.DealtInput} returns this
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.DealtInput} returns this
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.DealtInput} returns this
 */
proto.com.sands.live_dealer.hub.DealtInput.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DealtValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DealtValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DealtValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DealtValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealtNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DealtValue}
 */
proto.com.sands.live_dealer.hub.DealtValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DealtValue;
  return proto.com.sands.live_dealer.hub.DealtValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DealtValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DealtValue}
 */
proto.com.sands.live_dealer.hub.DealtValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDealtNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DealtValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DealtValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DealtValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DealtValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealtNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 dealt_num = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DealtValue.prototype.getDealtNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DealtValue} returns this
 */
proto.com.sands.live_dealer.hub.DealtValue.prototype.setDealtNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.DealtValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.DealtValue} returns this
 */
proto.com.sands.live_dealer.hub.DealtValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool hidden = 3;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.DealtValue.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.DealtValue} returns this
 */
proto.com.sands.live_dealer.hub.DealtValue.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.BaccaratRules.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.BaccaratRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.BaccaratRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.BaccaratRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    decks: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.BaccaratRules}
 */
proto.com.sands.live_dealer.hub.BaccaratRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.BaccaratRules;
  return proto.com.sands.live_dealer.hub.BaccaratRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.BaccaratRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.BaccaratRules}
 */
proto.com.sands.live_dealer.hub.BaccaratRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.BaccaratRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.BaccaratRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.BaccaratRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.BaccaratRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDecks();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 decks = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.BaccaratRules.prototype.getDecks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.BaccaratRules} returns this
 */
proto.com.sands.live_dealer.hub.BaccaratRules.prototype.setDecks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.DragonTigerRules.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.DragonTigerRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.DragonTigerRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DragonTigerRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    decks: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.DragonTigerRules}
 */
proto.com.sands.live_dealer.hub.DragonTigerRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.DragonTigerRules;
  return proto.com.sands.live_dealer.hub.DragonTigerRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.DragonTigerRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.DragonTigerRules}
 */
proto.com.sands.live_dealer.hub.DragonTigerRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.DragonTigerRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.DragonTigerRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.DragonTigerRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.DragonTigerRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDecks();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 decks = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.DragonTigerRules.prototype.getDecks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.DragonTigerRules} returns this
 */
proto.com.sands.live_dealer.hub.DragonTigerRules.prototype.setDecks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.RouletteRules.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.RouletteRules.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.RouletteRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.RouletteRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.RouletteRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    wheelList: jspb.Message.toObjectList(msg.getWheelList(),
    proto.com.sands.live_dealer.hub.RouletteWheelStop.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.RouletteRules}
 */
proto.com.sands.live_dealer.hub.RouletteRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.RouletteRules;
  return proto.com.sands.live_dealer.hub.RouletteRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.RouletteRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.RouletteRules}
 */
proto.com.sands.live_dealer.hub.RouletteRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.sands.live_dealer.hub.RouletteWheelStop;
      reader.readMessage(value,proto.com.sands.live_dealer.hub.RouletteWheelStop.deserializeBinaryFromReader);
      msg.addWheel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.RouletteRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.RouletteRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.RouletteRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.RouletteRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWheelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.sands.live_dealer.hub.RouletteWheelStop.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RouletteWheelStop wheel = 1;
 * @return {!Array<!proto.com.sands.live_dealer.hub.RouletteWheelStop>}
 */
proto.com.sands.live_dealer.hub.RouletteRules.prototype.getWheelList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.RouletteWheelStop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.sands.live_dealer.hub.RouletteWheelStop, 1));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.RouletteWheelStop>} value
 * @return {!proto.com.sands.live_dealer.hub.RouletteRules} returns this
*/
proto.com.sands.live_dealer.hub.RouletteRules.prototype.setWheelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.RouletteWheelStop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.RouletteWheelStop}
 */
proto.com.sands.live_dealer.hub.RouletteRules.prototype.addWheel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.sands.live_dealer.hub.RouletteWheelStop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.RouletteRules} returns this
 */
proto.com.sands.live_dealer.hub.RouletteRules.prototype.clearWheelList = function() {
  return this.setWheelList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.RouletteWheelStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.RouletteWheelStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stopNum: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.RouletteWheelStop}
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.RouletteWheelStop;
  return proto.com.sands.live_dealer.hub.RouletteWheelStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.RouletteWheelStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.RouletteWheelStop}
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStopNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.RouletteWheelStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.RouletteWheelStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStopNum();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.RouletteWheelStop} returns this
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.RouletteWheelStop} returns this
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 stop_num = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.prototype.getStopNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.RouletteWheelStop} returns this
 */
proto.com.sands.live_dealer.hub.RouletteWheelStop.prototype.setStopNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.BlackjackRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.BlackjackRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.BlackjackRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    decks: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hitRules: jspb.Message.getFieldWithDefault(msg, 2, ""),
    splitRules: jspb.Message.getFieldWithDefault(msg, 3, ""),
    splitPairRules: jspb.Message.getFieldWithDefault(msg, 4, ""),
    splitAcesOnce: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    doubleDownRules: jspb.Message.getFieldWithDefault(msg, 6, ""),
    doubleDownAfterSplit: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    doubleDownForLess: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    surrenderRules: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.BlackjackRules;
  return proto.com.sands.live_dealer.hub.BlackjackRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.BlackjackRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDecks(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHitRules(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSplitRules(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSplitPairRules(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSplitAcesOnce(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoubleDownRules(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoubleDownAfterSplit(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoubleDownForLess(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurrenderRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.BlackjackRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.BlackjackRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.BlackjackRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDecks();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHitRules();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSplitRules();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSplitPairRules();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSplitAcesOnce();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDoubleDownRules();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDoubleDownAfterSplit();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDoubleDownForLess();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSurrenderRules();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 decks = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getDecks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setDecks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hit_rules = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getHitRules = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setHitRules = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string split_rules = 3;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getSplitRules = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setSplitRules = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string split_pair_rules = 4;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getSplitPairRules = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setSplitPairRules = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool split_aces_once = 5;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getSplitAcesOnce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setSplitAcesOnce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string double_down_rules = 6;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getDoubleDownRules = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setDoubleDownRules = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool double_down_after_split = 7;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getDoubleDownAfterSplit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setDoubleDownAfterSplit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool double_down_for_less = 8;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getDoubleDownForLess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setDoubleDownForLess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string surrender_rules = 9;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.getSurrenderRules = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.BlackjackRules} returns this
 */
proto.com.sands.live_dealer.hub.BlackjackRules.prototype.setSurrenderRules = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.ThreeCardPokerRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.ThreeCardPokerRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    automatedDealer: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.ThreeCardPokerRules}
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.ThreeCardPokerRules;
  return proto.com.sands.live_dealer.hub.ThreeCardPokerRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.ThreeCardPokerRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.ThreeCardPokerRules}
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomatedDealer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.ThreeCardPokerRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.ThreeCardPokerRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomatedDealer();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool automated_dealer = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules.prototype.getAutomatedDealer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.ThreeCardPokerRules} returns this
 */
proto.com.sands.live_dealer.hub.ThreeCardPokerRules.prototype.setAutomatedDealer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.com.sands.live_dealer.hub);
