import isFunction from 'lodash/isFunction';
import { ICancelablePromise, NewCancelablePromise } from '../../helpers/promise';
import { Client } from './Client';
import {
	IClientResult,
	IHandleUnaryResponseMethodOptions,
	IRpcServiceError,
	IRpcServiceResponse,
	IRpcServiceUnaryCall,
	IUnaryMethodOptions,
	RpcPromiseExecutor,
	RpcPromiseRejector,
	RpcPromiseResolver,
	RpcServiceUnaryMethod,
	RpcServiceUnaryResponseHandler,
} from './types/client';

/**
 * Generic unary RPC client. It doesn't do a whole lot, but it reduces some complexity and allows us to hook in global
 * metadata and interceptors if we choose to.
 */
class UnaryClient<RpcClientType> extends Client<RpcClientType> {
	// The client is tied to a specific RPC client (eg. SpokeClient)
	constructor(rpcClient: RpcClientType) {
		super(rpcClient);
	}

	/**
	 * Make the specified unary call to the RPC client and return a promise that resolves when valid data is returned or
	 * rejects when an error occurs.
	 *
	 * @param rpcMethod  The RPC method to call on the RPC client.
	 * @param request    The request object to pass to the RPC method.
	 * @param opts       Additional options.
	 * @returns A promise that will resolve/reject with the result of the unary RPC call.
	 *   - Successful responses resolve the promise with:
	 *     { success: true, data: RPC_RESPONSE_DATA }
	 *   - Errors reject with promise with data (example):
	 *     { success: false, error: { message: "This is a sample error message", code: 1 }
	 */
	public unary = async <RequestType, ResponseType extends IRpcServiceResponse>(
		rpcMethod: typeof this.rpcClientProps,
		request: RequestType,
		opts?: IUnaryMethodOptions
	): Promise<IClientResult<ResponseType>> => {
		const rpcMethodFn = this.getRpcMethodFn<RequestType, ResponseType>(rpcMethod as string);

		if (rpcMethodFn == null) {
			throw new Error('RPC method unavailable for client');
		}

		const executor: RpcPromiseExecutor<ResponseType> = (
			resolve: RpcPromiseResolver<ResponseType>,
			reject: RpcPromiseRejector
		) => {
			const handleResponse: RpcServiceUnaryResponseHandler<ResponseType> = (error, response) => {
				this.handleUnaryResponse<ResponseType>(resolve, reject, error, response);
			};

			try {
				rpcMethodFn(request, opts?.meta, handleResponse);
			} catch (e) {
				const err = e as Error;
				handleResponse({ message: JSON.stringify(err), code: 0 }, null);
			}
		};

		return new Promise<IClientResult<ResponseType>>(executor);
	};

	/**
	 * Version of `unary` that will attempt to return the object type instead of the raw RPC data. Will returns the `data`
	 * prop as NULL if the `toObject()` conversion is not possible.
	 *
	 * @param rpcMethod  The RPC method to call on the RPC client.
	 * @param request    The request object to pass to the RPC method.
	 * @param opts       Additional options.
	 * @returns A promise that will resolve/reject with the result of the unary RPC call.
	 *   - Successful responses resolve the promise with:
	 *     { success: true, data: RPC_RESPONSE.AsObject }
	 *   - Errors reject with promise with data (example):
	 *     { success: false, error: { message: "This is a sample error message", code: 1 }
	 */
	public unaryAsObject = async <RequestType, ResponseType extends IRpcServiceResponse, ObjectType>(
		rpcMethod: typeof this.rpcClientProps,
		request: RequestType,
		opts?: IUnaryMethodOptions
	): Promise<IClientResult<ObjectType>> => {
		const promise = this.unary<RequestType, ResponseType>(rpcMethod, request, opts);

		return promise.then((reply) => {
			let data: Nullable<ObjectType> = null;

			if (isFunction(reply.data?.toObject)) {
				data = reply.data?.toObject() as ObjectType;
			}

			return { ...reply, data };
		});
	};

	/**
	 * Version of `unary` that can be cancelled via a cancellable promise. The unary call will get be cancelled and
	 * the inner promise will be rejected when calling the `cancel` method on the returned object.
	 *
	 * @param rpcMethod The RPC method to call on the RPC client.
	 * @param request   The request object to pass to the RPC method.
	 * @param opts      Additional options.
	 * @returns Cancelable promise wrapper containing a promise that will resolve with the result of unary RPC call.
	 *   - Successful responses resolve the promise with:
	 *     { success: true, data: RPC_RESPONSE_DATA }
	 *   - Errors reject with promise with data (example):
	 *     { success: false, error: { message: "This is a sample error message", code: 1 }
	 */
	public cancelableUnary = <RequestType, ResponseType extends IRpcServiceResponse>(
		rpcMethod: typeof this.rpcClientProps,
		request: RequestType,
		opts?: IUnaryMethodOptions
	): ICancelablePromise<IClientResult<ResponseType>> => {
		const rpcMethodFn = this.getRpcMethodFn<RequestType, ResponseType>(rpcMethod as string);

		if (rpcMethodFn == null) {
			throw new Error('RPC method unavailable for client');
		}

		let rpcUnaryCall: Nullable<IRpcServiceUnaryCall> = null;

		let isCanceled = false;
		let resolve: RpcPromiseResolver<ResponseType>;
		let reject: RpcPromiseRejector;

		const handleResponse: RpcServiceUnaryResponseHandler<ResponseType> = (error, response) => {
			this.handleUnaryResponse<ResponseType>(resolve, reject, error, response, { isCanceled });
		};

		const executor: RpcPromiseExecutor<ResponseType> = (
			_resolve: RpcPromiseResolver<ResponseType>,
			_reject: RpcPromiseRejector
		) => {
			resolve = _resolve;
			reject = _reject;

			try {
				rpcUnaryCall = rpcMethodFn(request, opts?.meta, handleResponse);
			} catch (e) {
				const err = e as Error;
				handleResponse({ message: JSON.stringify(err), code: -1 }, null);
			}
		};

		const promise = new Promise(executor);

		const onCancel = () => {
			isCanceled = true;
			if (rpcUnaryCall) {
				rpcUnaryCall.cancel();
				reject && reject(this.makeErrorResult<ResponseType>({ message: 'Unary call cancelled' }));
			}
		};

		const cp = NewCancelablePromise(promise, onCancel);

		return cp;
	};

	/**
	 * Processes the unary response and resolves/rejects with the correct data props.
	 *
	 * @param resolve  Resolver method.
	 * @param reject   Rejector method.
	 * @param error    Error data from the RPC method call.
	 * @param response Response data from the RPC method call.
	 * @param opts       Additional options.
	 */
	protected handleUnaryResponse = <ResponseType extends IRpcServiceResponse>(
		resolve: RpcPromiseResolver<ResponseType>,
		reject: RpcPromiseRejector,
		error: Nullable<IRpcServiceError>,
		response: Nullable<ResponseType>,
		opts?: IHandleUnaryResponseMethodOptions
	): void => {
		if (opts?.isCanceled) {
			const err = this.makeErrorResult<ResponseType>({ message: 'Unary call cancelled' });
			reject(err);
			return;
		}

		if (error) {
			const err = this.makeErrorResult<ResponseType>({ error });
			reject(err);
			return;
		}

		if (!response) {
			const err = this.makeErrorResult<ResponseType>({ message: 'Invalid RPC response' });
			reject(err);
			return;
		}

		if (isFunction(response.getError)) {
			const errorData = this.extractReplyError(response.getError());

			if (errorData != null) {
				const err = this.makeErrorResult<ResponseType>({ error: errorData });
				reject(err);
				return;
			}
		}

		resolve({ success: true, data: response });
	};

	/**
	 * Dynamically gets a bound version of the named RPC unary method from the RPC client (if it exists).
	 *
	 * @param   rpcMethodName  Name of the method on the RPC client.
	 * @returns A bound version of the method, or NULL if not found.
	 */
	protected getRpcMethodFn = <RequestType, ResponseType>(
		rpcMethodName: string
	): Nullable<RpcServiceUnaryMethod<RequestType, ResponseType>> => {
		const rpcMethod = super.getRpcMethodFn(rpcMethodName);

		if (rpcMethod == null) {
			return null;
		}

		return rpcMethod as RpcServiceUnaryMethod<RequestType, ResponseType>;
	};
}

export { UnaryClient as default };
export { UnaryClient };
