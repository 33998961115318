/**********************************************************************************************************************
 * Stores and manages data regarding the current authenticated user/player.
 *********************************************************************************************************************/
import { SelfData } from 'server/lib-rpc';
import { IUserService } from 'server/lib-rpc';
import { RpcUserStoreMobXKeys } from 'server/lib-rpc';
import { RpcUserStore } from 'server/lib-rpc';
import { DEFAULT_CURRENCY_SYMBOL, getCurrencySymbolForCode } from 'helpers/currency';
import { action, computed, makeObservable, observable } from 'helpers/mobx';

class UserStore extends RpcUserStore {
	constructor(service: IUserService, opts?: Maybe<{ data?: Maybe<SelfData> }>) {
		opts = opts || {};

		super(service);

		// Note: We cannot use `makeAutoObservable` because this class extends a parent class. So we are declaring the
		// annotations right here instead. We can probably make this better in the future by carefully applying observables
		// on the parent class or via some other technique.. but for now, just roll with it.
		makeObservable<UserStore, RpcUserStoreMobXKeys>(this, {
			// ---- Inherited from DataStore --------------------------------------------------------------------------------

			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			isPopulated: computed,
			lastUpdatedTs: computed,

			// Actions
			populate: action,
			setData: action,

			// ---- Inherited from RpcUserStore -----------------------------------------------------------------------------

			_balancesData: observable,
			playerId: computed,
			balancesList: computed,
			balancesLookup: computed,
			profile: computed,
			isLoggedIn: computed,
			balanceAmount: computed,
			balanceCurrency: computed,

			// Actions
			decreaseBalance: action,
			increaseBalance: action,

			// Non-annotated
			getBalanceDataForCurrency: false,

			// ---- Local ---------------------------------------------------------------------------------------------------

			currencyCode: computed,
			currencySymbol: computed,

			// Actions
			clear: action,
		});

		opts.data && (this.data = opts.data);
	}

	/**
	 * TODO: Make this go away once we have WalletManager working.
	 */
	public get currencyCode(): string {
		return this.balanceCurrency;
	}

	/**
	 * TODO: Make this go away once we have WalletManager working.
	 */
	public get currencySymbol(): Nullable<string> {
		return getCurrencySymbolForCode(this.balanceCurrency) || DEFAULT_CURRENCY_SYMBOL;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { UserStore as default };
export { UserStore };
