/**********************************************************************************************************************
 * Stores and manages data regarding the current table data.
 *********************************************************************************************************************/
import { GameState } from 'Constants';
import { min } from 'lodash';
import delay from 'lodash/delay';
import max from 'lodash/max';
import { rebet, sendCurrentBetsThrottled } from 'server/actions';
import { ActiveWagerData, TableData } from 'server/lib-rpc';
import { ITableService } from 'server/lib-rpc';
import { RpcTableStoreMobXKeys } from 'server/lib-rpc';
import { RpcTableStore } from 'server/lib-rpc';
import { getPlayStore, getRulesStore } from 'server/store';
import { action, computed, makeObservable, observable } from 'helpers/mobx';
import { DragonTigerTableHistoryStore } from './DragonTigerTableHistoryStore';
import { PlayStore } from './PlayStore';
import { RulesStore } from './RulesStore';

type StorePrivateProps =
	| '_playStore'
	| '_rulesStore'
	| '_tableHistoryStore'
	| '_isOnDataUpdateEnabled'
	| 'play'
	| 'rules'
	| 'onDataUpdate'
	| 'onAfterDataUpdate';
type StoreMobXKeys = RpcTableStoreMobXKeys | StorePrivateProps;

interface ITableStoreOpts {
	data?: Maybe<TableData>;
	playStore?: Maybe<PlayStore>;
	rulesStore?: Maybe<RulesStore>;
	tableHistoryStore?: Maybe<DragonTigerTableHistoryStore>;
	isOnDataUpdateEnabled?: Maybe<boolean>;
}

interface IGameMessage {
	style: string;
	message: string;
	duration?: number;
	priority?: number;
}

class TableStore extends RpcTableStore {
	/**
	 * Store instances
	 */
	protected _playStore: Nullable<PlayStore> = null;
	protected _rulesStore: Nullable<RulesStore> = null;
	protected _tableHistoryStore: Nullable<DragonTigerTableHistoryStore> = null;

	/**
	 * When TRUE will run the `onDataUpdate` method after data is updated.
	 */
	protected _isOnDataUpdateEnabled: boolean = true;

	/**
	 * TODO: Describe me
	 */
	public autoplay = 0;

	/**
	 * TODO: Describe me
	 */
	public highlightAutoplay = false;

	/**
	 * TODO: Describe me
	 */
	public min = 0;

	/**
	 * TODO: Describe me
	 */
	public max = 1000000;

	/**
	 * TODO: Describe me
	 */
	public isRebetDirty = true;

	/**
	 * TODO: Describe me
	 */
	public rebetWagers: ActiveWagerData[] = [];

	/**
	 * TODO: Describe me
	 */
	public isBetsInitialized = false;

	/**
	 * TRUE if bets are being sent to the server.
	 * TODO: Scott - I don't like this AT ALL. The BetManager should do this in the future.
	 */
	public isSendingBets = false;

	/**
	 * Queue of messages.
	 * TODO: This probably belongs in the game store or in it's own GameMessageManager
	 */
	public messagesQueue: IGameMessage[] = [];

	/**
	 * CONSTRUCTOR.
	 *
	 * @param service
	 * @param opts
	 */
	constructor(service: ITableService, opts?: Maybe<ITableStoreOpts>) {
		opts = opts || {};

		super(service);

		this._playStore = opts.playStore ?? null;
		this._rulesStore = opts.rulesStore ?? null;
		this._tableHistoryStore = opts.tableHistoryStore ?? null;
		this._isOnDataUpdateEnabled = opts.isOnDataUpdateEnabled ?? true;

		// Note: We cannot use `makeAutoObservable` because this class extends a parent class. So we are declaring the
		// annotations right here instead. We can probably make this better in the future by carefully applying observables
		// on the parent class or via some other technique.. but for now, just roll with it.
		makeObservable<TableStore, StoreMobXKeys>(this, {
			// ---- Inherited from DataStore --------------------------------------------------------------------------------

			_data: observable,
			_lastUpdatedTs: observable,
			data: computed,
			isPopulated: computed,
			lastUpdatedTs: computed,

			// Actions
			populate: action,
			setData: action,

			// ---- Inherited from RpcTableStore ----------------------------------------------------------------------------

			tableData: computed,
			tableId: computed,
			tableKey: computed, // DEPRECATED
			state: computed,
			game: computed,
			availableChoicesList: computed,
			availableWagers: computed,
			canPlaceBets: computed,
			seatCount: computed,
			seatsList: computed,
			placeBetsTimeAllowed: computed,
			shoeNumber: computed,

			// ---- Local ---------------------------------------------------------------------------------------------------

			_playStore: false,
			_rulesStore: false,
			_tableHistoryStore: false,
			_isOnDataUpdateEnabled: false,
			play: computed,
			rules: computed,
			totalPlayers: computed,
			minBet: computed,
			maxBet: computed,
			autoplay: observable,
			highlightAutoplay: observable,
			min: observable,
			max: observable,
			isRebetDirty: observable,
			rebetWagers: observable,
			isBetsInitialized: observable,
			messagesQueue: observable,
			isSendingBets: observable,

			// Actions
			clear: action,
			sendBets: action,
			onDataUpdate: action,
			onAfterDataUpdate: action,
			messageSeen: action,
			addMessage: action,
		});

		opts.data && this.setData(opts.data);
	}

	// TODO: Use live server data...
	public get totalPlayers(): number {
		return 0;
	}

	// TODO: This should probably use RulesStore.min or RulesStore should use this version?
	public get minBet(): number {
		return min(this.rules.wagerRulesList.map((w) => w.minBet)) || 0;
	}

	// TODO: This should probably use RulesStore.max or RulesStore should use this version?
	public get maxBet(): number {
		return max(this.rules.wagerRulesList.map((w) => w.maxBet)) || Infinity;
	}

	/**
	 * PlayStore proxy - for internal use only, components should call that store directly.
	 */
	protected get play(): PlayStore {
		return this._playStore ?? getPlayStore();
	}

	/**
	 * RulesStore proxy - for internal use only, components should call that store directly.
	 */
	protected get rules(): RulesStore {
		return this._rulesStore ?? getRulesStore();
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
		this.play.clear();
	}

	/**
	 * Action. Sends the current bets to the server.
	 */
	public sendBets = () => {
		sendCurrentBetsThrottled()?.then((result) => {
			if (result.success) {
				this.isRebetDirty = true;
			}
		});
	};

	/**
	 * Action. Override the parent store `setData` action.
	 */
	public setData(data: Nullable<TableData>): void {
		super.setData(data);
		data != null && this._isOnDataUpdateEnabled && this.onDataUpdate(data);
	}

	/**
	 * Action. Marks the messge as seen.
	 */
	public messageSeen = () => {
		this.messagesQueue = this.messagesQueue.splice(1);
	};

	/**
	 * Action. Add a message to the queue.
	 */
	public addMessage(style: string, message: string, duration?: number, priority?: number) {
		this.messagesQueue.push({
			style,
			message,
			duration,
			priority,
		});
	}

	/**
	 * Action. Called immediately when the store receives new data.
	 *
	 * This is responsible for populating:
	 * - HistoryStore
	 * - PlayStore
	 */
	protected onDataUpdate = (data: TableData) => {
		const tableGameData = data.table ?? null;

		// Reject bad data
		if (!data.success || tableGameData == null) {
			return;
		}

		const currentPlay = this.play;
		const { state: currentPlayState } = currentPlay;
		const newPlay = tableGameData.play ?? null;
		const newPlayState = newPlay?.state ?? '';

		// Save rebet wagers
		if (currentPlayState === GameState.INIT && newPlayState !== GameState.INIT) {
			this.rebetWagers = [...currentPlay.activeWagers.filter((w) => w.amount > 0)];
			this.isRebetDirty = false;
		}

		// TODO: Rob - I am not sure why I had to start doing this. It used to just happen as part of the
		// change from one playID to another.  Maybe I am missing an important step with the new lib
		if (newPlay && this.play.id !== newPlay.playId) {
			this.play.clearBets();
		}

		// Run autoplay
		if (
			this.autoplay > 0 &&
			this.rebetWagers.length > 0 &&
			currentPlayState !== GameState.INIT &&
			newPlayState === GameState.INIT
		) {
			this.highlightAutoplay = true;
			this.autoplay -= 1;
			delay(() => rebet(), 1000);
		}

		// Set the new play data on the PlayStore
		this.play.setData(newPlay);

		delay(() => this.onAfterDataUpdate(), 50);
	};

	/**
	 * Action. Called shortly after the store receives new data.
	 */
	protected onAfterDataUpdate = () => {
		const play = this.play;
		const { bets, activeWagers } = play;

		// After the play is updated check that the wagers are aligned.
		if (this.isBetsInitialized || activeWagers.length === 0) {
			return;
		}

		activeWagers.forEach((w: ActiveWagerData) => {
			if (w.amount <= 0) {
				return;
			}
			play.addToBetHistory(w.wagerName, 0);
			bets[w.wagerName] = w.amount;
		});

		this.isBetsInitialized = true;
	};
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { TableStore as default };
export { TableStore };
export type { IGameMessage, ITableStoreOpts };
