import isFunction from 'lodash/isFunction';
import { AuthSession, IAuthSessionProvider } from '../../../client/core/AuthSession';
import { IRpcServiceRequest } from '../types/client';

const authenticateRequest = <RequestType>(
	request: RequestType,
	token?: string,
	opts?: { checkSession?: IAuthSessionProvider; checkDefaultSession?: boolean; emptyTokenThrowsError?: boolean }
): RequestType => {
	token = token ?? '';

	const { checkSession = null, checkDefaultSession = false, emptyTokenThrowsError = true } = opts || {};
	const req = request as RequestType & IRpcServiceRequest;

	const shouldSetToken: boolean = req.setToken != null && isFunction(req.setToken);
	if (!shouldSetToken) {
		return request;
	}

	token = token === '' && checkSession != null ? checkSession.token : token;
	token = token === '' && checkDefaultSession ? AuthSession.token : token;

	// If we should set the token but we do not have it, throw an error, because any service request that accepts the
	// token will actually NEED the token. This behavior can be disabled via options.
	if (token === '') {
		const msg = 'Request requires valid authentication token';
		if (emptyTokenThrowsError) {
			throw new Error(msg);
		}

		console.error(msg);
	}

	req.setToken && req.setToken(token);

	return req;
};

const getDefaultAuthSession = () => AuthSession;

export { getDefaultAuthSession, authenticateRequest };
export type { IAuthSessionProvider };
