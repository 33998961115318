const DEFAULT_GAME_KEY = 'dragontiger';
const DEFAULT_GAME_NAME = 'Fast-Action Dragon Tiger';

const GameState = {
	INIT: 'initial',
	RUN: 'running',
	RES: 'resolved',
	COMP: 'completed',
};

enum ResolutionType {
	NOT_SET = '',
	RESOLUTION = 'resolution',
	PAYOUT = 'payout',
	PUSH = 'push',
}

enum TableState {
	NOT_SET = '',
	OPEN = 'open',
	CLOSED = 'closed',
	PLAY = 'play',
}

interface IWagerDetails {
	name: string;
	label: string;
}

/**
 * Wager name/key values.
 */
enum WagerName {
	BANKER = 'cat',
	TIGER = 'cat',
	PLAYER = 'dra',
	DRAGON = 'dra',
	TIE = 'tie',
	SUITED_TIE = 'suited_tie',
	DRAGON_EVEN = 'dragon_even',
	DRAGON_ODD = 'dragon_odd',
	TIGER_EVEN = 'tiger_even',
	TIGER_ODD = 'tiger_odd',
	DRAGON_BLACK = 'dragon_black',
	DRAGON_RED = 'dragon_red',
	TIGER_BLACK = 'tiger_black',
	TIGER_RED = 'tiger_red',
	DRAGON_BIG = 'dragon_big',
	DRAGON_SMALL = 'dragon_small',
	TIGER_BIG = 'tiger_big',
	TIGER_SMALL = 'tiger_small',

	// Baccarat: Still here to avoid errors in unused configs
	PAIR_PLAYER = 'pair_player',
	PAIR_BANKER = 'pair_banker',
	DRAGON_BONUS_BANKER = 'dragon_bonus_banker',
	DRAGON_BONUS_PLAYER = 'dragon_bonus_player',
	LUCKY6 = 'lucky_6',
	NOBLE7 = 'noble_7',
	OPULENT8 = 'opulent_8',
	FORTUNA_PLAYER = 'fortunas_bonus_player',
	FORTUNA_BANKER = 'fortunas_bonus_banker',
}

/**
 * Banker commission types
 */
enum BankerCommissionType {
	STANDARD = 'standard_commission',
	BANKER_6 = 'banker_6',
	THREE_CARD_7 = 'three_card_7',
}

/**
 * Payline descriptor values
 */
enum PaylineDescriptors {
	NONE = 'none',
	WIN = 'win',
	TIE = 'tie',
	TIE_LOSE_HALF = 'tie_lose_half',
	WIN_WITH_6 = 'win_with_six',
	WIN_WITH_THREE_CARD_7 = 'win_with_three_card_7',
}

type WagerKey =
	| 'BANKER'
	| 'PLAYER'
	| 'TIE'
	| 'PAIR_PLAYER'
	| 'PAIR_BANKER'
	| 'DRAGON_BONUS_BANKER'
	| 'DRAGON_BONUS_PLAYER'
	| 'LUCKY6'
	| 'NOBLE7'
	| 'OPULENT8'
	| 'TIGER_BIG'
	| 'TIGER_SMALL'
	| 'FORTUNA_PLAYER'
	| 'FORTUNA_BANKER';

const WagerDetails: Record<WagerKey, IWagerDetails> = {
	BANKER: { name: WagerName.BANKER, label: 'Tiger' },
	PLAYER: { name: WagerName.PLAYER, label: 'Dragon' },
	TIE: { name: WagerName.TIE, label: 'Tie' },
	PAIR_PLAYER: { name: WagerName.PAIR_PLAYER, label: 'Player Pair' },
	PAIR_BANKER: { name: WagerName.PAIR_BANKER, label: 'Banker Pair' },
	DRAGON_BONUS_BANKER: { name: WagerName.DRAGON_BONUS_BANKER, label: 'Dragon Bonus Banker' },
	DRAGON_BONUS_PLAYER: { name: WagerName.DRAGON_BONUS_PLAYER, label: 'Dragon Bonus Player' },
	LUCKY6: { name: WagerName.LUCKY6, label: 'Lucky 6' },
	NOBLE7: { name: WagerName.NOBLE7, label: 'Noble 7' },
	OPULENT8: { name: WagerName.OPULENT8, label: 'Opulent 8' },
	TIGER_BIG: { name: WagerName.TIGER_BIG, label: 'Tiger Big' },
	TIGER_SMALL: { name: WagerName.TIGER_SMALL, label: 'Tiger Small' },
	FORTUNA_PLAYER: { name: WagerName.FORTUNA_PLAYER, label: 'Fortuna Bonus Player' },
	FORTUNA_BANKER: { name: WagerName.FORTUNA_BANKER, label: 'Fortuna Bonus Banker' },
};

const WagerDetailsByName: Map<string, IWagerDetails> = new Map([
	[WagerName.BANKER, WagerDetails.BANKER],
	[WagerName.PLAYER, WagerDetails.PLAYER],
	[WagerName.TIE, WagerDetails.TIE],
	[WagerName.PAIR_PLAYER, WagerDetails.PAIR_PLAYER],
	[WagerName.PAIR_BANKER, WagerDetails.PAIR_BANKER],
	[WagerName.DRAGON_BONUS_BANKER, WagerDetails.DRAGON_BONUS_BANKER],
	[WagerName.DRAGON_BONUS_PLAYER, WagerDetails.DRAGON_BONUS_PLAYER],
	[WagerName.LUCKY6, WagerDetails.LUCKY6],
	[WagerName.NOBLE7, WagerDetails.NOBLE7],
	[WagerName.OPULENT8, WagerDetails.OPULENT8],
	[WagerName.TIGER_BIG, WagerDetails.TIGER_BIG],
	[WagerName.TIGER_SMALL, WagerDetails.TIGER_SMALL],
	[WagerName.FORTUNA_PLAYER, WagerDetails.FORTUNA_PLAYER],
	[WagerName.FORTUNA_BANKER, WagerDetails.FORTUNA_BANKER],
]);

interface IWagerGridPosition {
	x: number;
	y: number;
}

const WagerGridPositions = new Map<WagerName, IWagerGridPosition>([
	[WagerName.PAIR_PLAYER, { x: 1, y: 1 }],
	[WagerName.FORTUNA_PLAYER, { x: 1, y: 2 }],
	[WagerName.TIGER_SMALL, { x: 1, y: 3 }],
	[WagerName.PLAYER, { x: 2, y: 1 }],
	[WagerName.LUCKY6, { x: 2, y: 2 }],
	[WagerName.TIE, { x: 3, y: 1 }],
	[WagerName.NOBLE7, { x: 3, y: 2 }],
	[WagerName.BANKER, { x: 4, y: 1 }],
	[WagerName.OPULENT8, { x: 4, y: 2 }],
	[WagerName.PAIR_BANKER, { x: 5, y: 1 }],
	[WagerName.FORTUNA_BANKER, { x: 5, y: 2 }],
	[WagerName.TIGER_BIG, { x: 5, y: 3 }],
]);

// ---- Export --------------------------------------------------------------------------------------------------------

export { DEFAULT_GAME_KEY, DEFAULT_GAME_NAME };
export {
	BankerCommissionType,
	GameState,
	PaylineDescriptors,
	ResolutionType,
	TableState,
	WagerDetails,
	WagerName,
	WagerDetailsByName,
	WagerGridPositions,
};
