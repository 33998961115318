import { Service } from '../../client/core/Service';
import { IServiceOptions, ServiceResult } from '../../client/core/types/service';
import { SpokeClient } from '../../client/rpc/clients/spoke';
import { EmailRegisterReply, GetSelfReply, LoginReply } from '../../client/rpc/replies/spoke';
import { EmailRegisterRequest, GetSelfRequest, LoginRequest } from '../../client/rpc/requests/spoke';
import { EmailRegisterData, SelfData } from '../../client/rpc/types/spoke';
import { IUserService } from './types';

class UserService extends Service<SpokeClient> implements IUserService {
	constructor(url: string, opts?: IServiceOptions) {
		super(url, opts);
		this.client = this.createUnaryClient(SpokeClient, opts?.grpcOptions);
	}

	public emailRegister = async (
		email: string,
		pass: string,
		displayName?: Maybe<string>,
		imgURL?: Maybe<string>,
		willSetSessionToken?: boolean
	): Promise<ServiceResult<EmailRegisterData>> => {
		willSetSessionToken = willSetSessionToken ?? true;

		const request = new EmailRegisterRequest();
		request.setEmail(email);
		request.setPassword(pass);
		request.setDisplayName(displayName || 'User');
		request.setImageUrl(imgURL ?? '');

		const promise = this.unaryAsObject<typeof request, EmailRegisterReply, EmailRegisterData>('emailRegister', request);

		return promise.then((reply) => {
			if (reply.success && willSetSessionToken) {
				const token = reply.data?.token;
				token && (this.token = token);
			}

			return reply;
		});
	};

	public registerAndLogin = async (
		email: string,
		pass: string,
		displayName?: Maybe<string>,
		imgURL?: Maybe<string>,
		willSetSessionToken?: boolean
	): Promise<SelfData> => {
		willSetSessionToken = willSetSessionToken ?? true;

		const request = new EmailRegisterRequest();
		request.setEmail(email);
		request.setPassword(pass);
		request.setDisplayName(displayName || 'User');
		request.setImageUrl(imgURL ?? '');

		const promise = this.unary<typeof request, EmailRegisterReply>('emailRegister', request);

		return promise.then((reply) => {
			if (reply.success && willSetSessionToken) {
				const token = reply.data?.getToken();
				token && (this.token = token);
			}

			return this.getSelf();
		});
	};

	public login = async (email: string, pass: string, willSetSessionToken?: boolean): Promise<SelfData> => {
		willSetSessionToken = willSetSessionToken ?? true;

		const request = new LoginRequest();
		request.setEmail(email);
		request.setPassword(pass);

		const promise = this.unary<typeof request, LoginReply>('login', request);

		return promise.then((reply) => {
			if (reply.success && willSetSessionToken) {
				const token = reply.data?.getToken();
				token && (this.token = token);
			}

			return this.getSelf();
		});
	};

	public getSelf = async (): Promise<SelfData> => {
		const request = new GetSelfRequest();
		request.setToken(this.token);

		let result = new GetSelfReply().toObject();

		try {
			const reply = await this.unaryAsObject<typeof request, GetSelfReply, SelfData>('getSelf', request);
			reply.success && (result = reply.data ?? result);
		} catch (e) {
			// NOTHING
		}

		return result;
	};
}

export { UserService as default };
export { UserService };
