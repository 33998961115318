/**********************************************************************************************************************
 * Store-related constants
 *********************************************************************************************************************/

/**
 * Keys used for dealer store management and actions
 */
enum DealerStoreKey {
	DealerHudStore = 'dealer-hud-store',
	DealerPlayStore = 'dealer-play-store',
	DealerRulesStore = 'dealer-rules-store',
	DealerTableSeatsPlayStore = 'dealer-table-seats-play-store',
	DealerTableStore = 'dealer-table-store',
	DealerTableHistoryStore = 'dealer-table-history-store',
}

/**
 * Keys used for player store management and actions
 */
enum StoreKey {
	FavoriteBetsStore = 'favorite-bets-store',
	GameHistoryStore = 'game-history-store',
	TableHistoryStore = 'table-history-store',
	PlayStore = 'play-store',
	RulesStore = 'rules-store',
	SettingsStore = 'settings-store',
	TableSeatsPlayStore = 'table-seats-play-store',
	TableStore = 'table-store',
	UserStore = 'user-store',
}

/**
 * All the dealer store keys in an array
 */
const DealerStoreKeys = [
	DealerStoreKey.DealerHudStore,
	DealerStoreKey.DealerPlayStore,
	DealerStoreKey.DealerRulesStore,
	DealerStoreKey.DealerTableStore,
	DealerStoreKey.DealerTableSeatsPlayStore,
	DealerStoreKey.DealerTableHistoryStore,
];

/**
 * All the store keys in an array
 */
const StoreKeys = [
	StoreKey.FavoriteBetsStore,
	StoreKey.GameHistoryStore,
	StoreKey.TableHistoryStore,
	StoreKey.PlayStore,
	StoreKey.RulesStore,
	StoreKey.SettingsStore,
	StoreKey.TableSeatsPlayStore,
	StoreKey.TableStore,
	StoreKey.UserStore,
];

// ---- Export --------------------------------------------------------------------------------------------------------

export { DealerStoreKey, StoreKey, DealerStoreKeys, StoreKeys };
