import { i18n } from 'i18n';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import delay from 'lodash/delay';
import { getGameService } from 'server/services';
import { getStores } from 'server/store';
import { Bets, IServiceErrorResult } from 'server/types';
import { DEFAULT_CURRENCY_CODE } from 'helpers/currency';
import { toJS } from 'helpers/mobx';
import { entries } from 'helpers/object';
import { MakeWagersData, MakeWagersRequest, SeatWager } from '../lib-rpc';
import { debug } from './debug';
import { playPlaceWagerSound, playSound } from './sounds';

let betsChangedWhileSending = false;
let sendTimeout: number;
/**
 * TODO: For these actions we need to do the following:
 *  1. Do a better job of checking if the bets can be placed prior to sending them.
 *  2. Surface any server errors as UI popups so we don't wonder why things aren't working.
 */

/**
 * Sends the specified bets/wagers to the server via the RPC method `makeWagers`.
 *
 * @param tableId   The table ID the bets/wagers are for.
 * @param bets      The bets/wagers to send.
 * @returns         The data returned by `makeWagers`.
 */
const sendBetsToServer = async (tableId: string, bets: Bets): Promise<MakeWagersData> => {
	debug.info('Called:', 'sendBetsToServer', { tableId, bets: toJS(bets) });

	const defaultResult = {
		success: false,
		serverTime: 0,
		activeWagersList: [],
		insufficientFunds: false,
		error: undefined,
	};

	const { playStore, tableStore } = getStores();
	const currencyCode = tableStore.currency || DEFAULT_CURRENCY_CODE;

	// Yuck... We should not do this.
	if (tableStore.isSendingBets) {
		window.clearTimeout(sendTimeout);
		sendTimeout = window.setTimeout(tableStore.sendBets, 250);
		return defaultResult;
	}

	const betData = { ...bets };

	const svc = getGameService();
	const request = new MakeWagersRequest();

	const wagersList: SeatWager[] = Object.keys(betData).map((wagerName) => {
		const amount = betData[wagerName];
		const wager = new SeatWager();

		wager.setAmount(amount);
		wager.setWagerName(wagerName);
		wager.setSeatNum(1);
		wager.setCurrency(currencyCode.toLowerCase());

		return wager;
	});

	request.setWagersList(wagersList);
	request.setTableId(tableId);

	let result: MakeWagersData = { ...defaultResult };

	const promise = svc.sendWagers(request);

	promise
		.catch((e: unknown) => {
			const svcErrorResult = e as IServiceErrorResult;
			const svcError = svcErrorResult.error ?? null;

			debug.error('Error:', 'sendBetsToServer', { e, svcError });

			if (betsChangedWhileSending) {
				// See if the new bet amounts will work.
				sendCurrentBets();
			} else {
				// Revert to the last values from the server
				playStore.revertWagersToServer();
				tableStore.addMessage('error', i18n.t(svcError?.message ?? 'Unknown error'), 1000);
			}
		})
		.finally(() => {
			tableStore.isSendingBets = false;
			betsChangedWhileSending = false;
		});

	try {
		// Send the wagers
		const response = await promise;
		const data = response?.data ?? null;

		if (!response.success) {
			throw new Error(response.error?.message ?? 'Unknown error');
		}

		if (data == null) {
			throw new Error('Invalid data');
		}

		if (!data?.success) {
			throw new Error(data.error?.message ?? 'Unknown error');
		}

		result = data;
	} catch (e: unknown) {
		const err = e as Error;
		debug.warn('Error:', 'sendBetsToServer', err);

		result.success = false;
		result.error = { code: -1, message: err.message };
	}

	return result;
};

/**
 * Gets the current bets from the play store and sends them to the server.
 *
 * @returns The data returned by `makeWagers`.
 */
const sendCurrentBets = async (): Promise<MakeWagersData> => {
	debug.info('Called', 'sendCurrentBets');

	const { playStore, tableStore } = getStores();
	const { tableId } = tableStore;
	const { bets } = playStore;

	const result = await sendBetsToServer(tableId, bets);
	debug.info('Bets were sent. Result:', 'sendCurrentBets', result);

	return result;
};

/**
 * Throttled version of `sendCurrentBets`.
 */
const sendCurrentBetsThrottled = debounce(sendCurrentBets, 500, { trailing: true });

interface IPlaceBetOpts {
	noSend?: boolean;
	playSound?: boolean;
	isAutoPlay?: boolean;
}

/**
 * Places a single bet for the specified bet name and amount.
 */
const placeBet = (betName: string, amount: number, opts?: Maybe<IPlaceBetOpts>): void => {
	const { noSend = false, playSound = true } = opts ?? {};

	const isAutoPlay = opts && opts.isAutoPlay;

	debug.info('Called:', 'placeBet', { betName, amount, opts });

	const { playStore, tableStore, rulesStore } = getStores();
	const { canPlaceBets } = tableStore;
	const { bets } = playStore;
	const { wagerRulesList } = rulesStore;

	if (!canPlaceBets) {
		return;
	}

	// Not positive if this is correct, but the variantRules doesn't contain related data.
	const rules = wagerRulesList.find((w) => w.wagerName === betName);
	const { minBet = 0, maxBet = Number.MAX_SAFE_INTEGER } = rules ?? {};

	const currentAmt = bets[betName] || 0;
	const nextAmt = clamp(currentAmt + amount, minBet, maxBet);

	if (playSound) {
		playPlaceWagerSound(betName, currentAmt);
	}

	if (currentAmt === nextAmt) {
		debug.info('Wager amount did not change. Likely due to bet limit.', 'placeBet', { currentAmt, nextAmt });
		return;
	}

	betsChangedWhileSending = true;

	playStore.addToBetHistory(betName, currentAmt);
	bets[betName] = nextAmt;

	if (!isAutoPlay && tableStore.autoplay > 0) {
		tableStore.autoplay = 0;
	}

	// Send bets now if specified
	!noSend && tableStore.sendBets();
};

interface IPlaceBetsOpts extends IPlaceBetOpts {
	noClear?: boolean;
}

/**
 * Places multiple bets at once.
 */
const placeBets = (bets: Bets, opts?: Maybe<IPlaceBetsOpts>): void => {
	const { noClear = false, noSend = false, playSound = false } = opts ?? {};

	debug.info('Called:', 'placeBets', { bets, noClear, noSend, playSound });

	const { playStore, tableStore } = getStores();
	!noClear && playStore.clearBets();

	const betEntries = entries(bets);
	betEntries.forEach(([betName, betAmount]) => {
		placeBet(betName, betAmount, { playSound, noSend: true });
	});

	!noSend && tableStore.sendBets();
};

/**
 * Undo the last bet made (according to history) and sends to the server.
 */
const undoLastBet = () => {
	debug.info('Called:', 'undoLastBet');

	const { playStore, tableStore } = getStores();
	const { canPlaceBets } = tableStore;
	const { betHistory, bets } = playStore;

	if (!canPlaceBets) {
		return;
	}

	playSound('chip_remove');

	if (betHistory.length === 0) {
		clearCurrentBets();
		return;
	}

	// Restore the previous bet
	const previous = betHistory.pop();
	previous && (bets[previous[0]] = previous[1]);

	// Send to the server
	tableStore.sendBets();
};

/**
 * Doubles all the current bets and sends to the server.
 */
const doubleCurrentBets = () => {
	debug.info('Called:', 'doubleCurrentBets');

	const { playStore, tableStore } = getStores();
	const { hasBets } = playStore;

	if (!hasBets) {
		return;
	}

	Object.keys(playStore.bets).forEach((wagerName) => {
		const amount = playStore.bets[wagerName] ?? 0;
		if (amount > 0) {
			placeBet(wagerName, amount, { noSend: true });
		}
	});

	tableStore.sendBets();
};

/**
 * Clears all the current bets and sends to the server.
 */
const clearCurrentBets = (noSend?: boolean) => {
	noSend = noSend ?? false;

	const { playStore, tableStore } = getStores();
	const { hasBets } = playStore;
	const { canPlaceBets } = tableStore;

	debug.info('Called:', 'clearCurrentBets', { noSend, hasBets, canPlaceBets });

	if (!canPlaceBets || !hasBets) {
		return;
	}

	playSound('chip_remove');

	playStore.clearBets();
	playStore.clearBetHistory();
	!noSend && tableStore.sendBets();
};

/**
 * Rebets the last wager that was made.
 */
const rebet = (isAutoPlay = false) => {
	const incrementMs = 200;
	let delayMs = -incrementMs;

	const { tableStore } = getStores();
	const { rebetWagers } = tableStore;

	if (rebetWagers.length === 0) {
		return;
	}

	rebetWagers.forEach((w) => {
		if (w.amount > 0) {
			delayMs += incrementMs;
			delay(() => placeBet(w.wagerName, w.amount, { noSend: true, isAutoPlay: isAutoPlay }), delayMs);
		}
	});

	delay(() => {
		tableStore.sendBets();
		tableStore.highlightAutoplay = false;
	}, delayMs + incrementMs);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export {
	doubleCurrentBets,
	clearCurrentBets,
	placeBet,
	placeBets,
	rebet,
	sendCurrentBets,
	sendCurrentBetsThrottled,
	undoLastBet,
};
