/**********************************************************************************************************************
 * Server related constants.
 *********************************************************************************************************************/
/**
 * Prefix used for the default name of new favorite bets
 */
const NEW_FAVORITE_BET_PREFIX = 'My Favorite Bet #';

enum DealerStreamKey {
	DealerHudStream = 'dealer-hud-stream',
	DealerTableStream = 'dealer-table-stream',
	DealerTableSeatsPlayStream = 'dealer-table-seats-play-stream',
}

/**
 * Keys used for registering and managing player streams with the stream manager
 */
enum StreamKey {
	TableSeatsPlayStream = 'table-seats-play-stream',
	TableStream = 'table-stream',
	UserStream = 'user-stream',
}

enum ServerErrorCodes {
	INSUFFICIENT_FUNDS = 10050027,
	SESSION_NOT_FOUND = 10010010,
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { NEW_FAVORITE_BET_PREFIX };
export { DealerStreamKey, StreamKey, ServerErrorCodes };
