/**********************************************************************************************************************
 * Login related selectors
 *********************************************************************************************************************/
import { SelfData } from '../lib-rpc/types';
import { getSession } from '../session';
import { getUserStore } from '../store';

interface IExistingLoginData {
	isLoggedIn: boolean;
	token: string;
	userData: Nullable<SelfData>;
}

/**
 *
 * @returns TRUE if the user is logged-in.
 */
const isLoggedIn = (): boolean => {
	return resolveExistingLogin().isLoggedIn;
};

/**
 * Determines various data about the current user/player login state.
 */
const resolveExistingLogin = (): IExistingLoginData => {
	const userStore = getUserStore();
	const session = getSession();

	const defaults = { isLoggedIn: false, token: '', userData: null };

	if (userStore.isLoggedIn && session.hasToken()) {
		return { ...defaults, ...{ isLoggedIn: true, token: session.token, userData: userStore.data } };
	}

	return defaults;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { resolveExistingLogin, isLoggedIn };
