import {
	getI18n as getI18nLib,
	Internationalization,
	loadI18n as loadI18nLib,
} from '@sandsb2b/areax-client-library/dist/i18n';

let i18n: Internationalization = getI18nLib();

const loadI18n = async (): Promise<boolean> => {
	const loaded = await loadI18nLib('en', { i18nInstance: i18n, loadCoreLang: true });

	if (loaded) {
		i18n = getI18nLib();
	}

	return loaded;
};

const getI18n = (): Internationalization => i18n;

export { loadI18n, i18n, getI18n };
