/*********************************************************************************************************************
 * This module exports methods to initialize and utilize the DataDog browser logging capabilities.
 *********************************************************************************************************************/
import { Logger as DebugLogger } from '../helpers/debug';
import { DDRumCommonSessionConfig, DEFAULT_DD_SITE, NOT_SET, SDKInitState } from './common';
import Config from './config';
import { BrowserLogsInitOptions, DDBrowserLogsInitConfiguration, DDBrowserLogsSDK } from './types';

// Debug logging
const debug = new DebugLogger('DD.BrowserLogs');

type WhenAvailResolver = (value: DDBrowserLogsSDK | PromiseLike<DDBrowserLogsSDK>) => void;
type WhenAvailRejector = (reason?: unknown) => void;

interface IState {
	// Initialization state
	initState: SDKInitState;
	// Availability promise
	whenAvailable: Nullable<Promise<DDBrowserLogsSDK>>;
	// DataDog browser logs SDK instance
	sdk: Nullable<DDBrowserLogsSDK>;
}

/**
 * Current state props
 */
const state: IState = {
	initState: SDKInitState.NOT_INITIALIZED,
	whenAvailable: null,
	sdk: null,
};

/**
 * @returns Default configuration to use for DataDog browser logging SDK.
 */
const getDefaultSDKInitConfig = (): DDBrowserLogsInitConfiguration => {
	return {
		// Common session configuration values
		...DDRumCommonSessionConfig,
		// A Datadog client token (required)
		clientToken: '',
		// The Datadog site parameter of your organization.
		site: Config.site || DEFAULT_DD_SITE,
		// The service name for your application. Follows the tag syntax requirements.
		service: Config.serviceName || NOT_SET,
		// The application’s environment, for example: prod, pre-prod, and staging. Follows the tag syntax requirements.
		env: Config.environment || NOT_SET,
		// Specify a version number to identify the deployed version of your application in Datadog
		version: Config.appVersion || NOT_SET,
		// The percentage of sessions to track: 100 for all, 0 for none. Only tracked sessions send RUM events.
		sampleRate: 100,
		// Telemetry data (such as errors and debug logs) about SDK execution is sent to Datadog in order to detect and
		// solve potential issues. Set this option to 0 to opt out from telemetry collection.
		telemetrySampleRate: 20,
		// Initialization fails silently if the RUM Browser SDK is already initialized on the page.
		silentMultipleInit: false,
		// Optional proxy URL, for example: https://www.proxy.com/path. For more information, see the full proxy setup guide.
		proxyUrl: undefined,
		// Allows altering or discarding of the log data prior to sending it to DataDog. Use this to protected sensitive data.
		beforeSend: undefined,
		// When TRUE will forward console.error logs, uncaught exceptions and network errors to Datadog.
		forwardErrorsToLogs: true,
		// Forward logs from console.* to Datadog. Use "all" to forward everything or an array of console API names to forward only a subset.
		forwardConsoleLogs: 'all',
		// Forward reports from the Reporting API to Datadog. Use "all" to forward everything or an array of report types to forward only a subset.
		forwardReports: 'all',
	};
};

/**
 * Returns any runtime override configuration set on `DD_CONFIG` in global scope.
 */
const getRuntimeOverrides = (): BrowserLogsInitOptions => {
	return (window?.DD_CONFIG || {}) as BrowserLogsInitOptions;
};

/**
 * Creates the configuration object used to initialize the SDK.
 */
const getSDKInitConfig = (
	clientToken: string,
	opts?: Maybe<BrowserLogsInitOptions>
): DDBrowserLogsInitConfiguration => {
	return {
		...getDefaultSDKInitConfig(),
		...getRuntimeOverrides(),
		...(opts || {}),
		clientToken,
	};
};

/**
 * Dynamically imports the SDK.
 */
const importSDK = async (): Promise<DDBrowserLogsSDK> => {
	return (await import('@datadog/browser-logs')).datadogLogs;
};

/**
 * @returns TRUE if this feature is enabled
 */
const isEnabled = (): boolean => Config.enableBrowserLogs;

/**
 * @returns TRUE if the DataDog browser logging SDK is initialized and ready for use.
 */
const isAvailable = (): boolean => isEnabled() && state.initState === SDKInitState.AVAILABLE;

/**
 * @returns The SDK instance if available, otherwise NULL.
 */
const getSDK = (): Nullable<DDBrowserLogsSDK> => (isAvailable() && state.sdk) || null;

/**
 * @returns The logger instance from the SDK if available, otherwise NULL.
 */
const getLogger = () => (isAvailable() ? state.sdk?.logger : null) || null;

/**
 * State update callbacks
 */
const onInitializing = () => {
	debug.info('Initializing');
	state.initState = SDKInitState.INITIALIZING;
};
const onAvailable = () => {
	debug.info('Browser logging available for use');
	state.initState = SDKInitState.AVAILABLE;
};
const onNotAvailable = (reason?: unknown) => {
	debug.info('Browser logging is NOT available', null, { reason });
	state.initState = SDKInitState.NOT_AVAILABLE;
};

/**
 * Called to resolve the availability promise and flag the state as AVAILABLE.
 */
let setAvailable: WhenAvailResolver = () => onAvailable();

/**
 * Called to reject the availability promise and flag the state as UNAVAILABLE.
 */
let setNotAvailable: WhenAvailRejector = (reason) => onNotAvailable(reason);

/**
 * Executor for the `whenAvailable` promise.
 */
const whenAvailableExecutor = (resolve: WhenAvailResolver, reject: WhenAvailRejector): void => {
	setAvailable = (value) => {
		onAvailable();
		resolve(value);
	};

	setNotAvailable = (reason) => {
		onNotAvailable(reason);
		reject(reason);
	};

	// Immediately reject if the feature is not enabled
	if (!isEnabled()) {
		setNotAvailable('Feature is not enabled');
		return;
	}

	// Immediately resolve if the SDK is already available
	if (isAvailable() && state.sdk != null) {
		setAvailable(state.sdk);
		return;
	}
};

/**
 * @returns A promise that resolves when the SDK becomes available for use. Otherwise it will be rejected.
 */
const whenAvailable = async (): Promise<DDBrowserLogsSDK> => {
	if (state.whenAvailable != null) {
		return state.whenAvailable;
	}

	if (isAvailable() && state.sdk) {
		return Promise.resolve<DDBrowserLogsSDK>(state.sdk);
	}

	return !isEnabled() ? Promise.reject('Feature is not enabled') : Promise.reject('Unavailable');
};

/**
 * Initializes the SDK.
 *
 * @param    clientToken  Required. Client token to use when initializing the SDK.
 * @param    opts         Other initialization options. Use this to override to defaults.
 * @returns  TRUE if initialization is successful.
 */
const initialize = async (clientToken: string, opts?: Maybe<BrowserLogsInitOptions>) => {
	// Do nothing if this feature is not enabled
	if (!isEnabled()) {
		debug.warn('Unable to initialize. Feature is not enabled.', 'initialize');
		return false;
	}

	// Do nothing if we are not in the uninitialized state
	if (state.initState !== SDKInitState.NOT_INITIALIZED) {
		debug.warn(`Unable to initialize. Current init state is: ${state.initState}.`, 'initialize');
		return true;
	}

	// Create the availability promise
	state.whenAvailable = new Promise<DDBrowserLogsSDK>(whenAvailableExecutor);

	// Resolve the configuration to use
	const config = getSDKInitConfig(clientToken, opts);

	// Require an client token to proceed
	if (config.clientToken === '') {
		const message = 'Client Token must be specified';
		debug.error(message, 'initialize');
		setNotAvailable(message);
		return false;
	}

	try {
		// Dynamic import the SDK
		const sdk = await importSDK();

		// Initialize
		onInitializing();
		sdk.init(config);

		debug.info('Initialized with config:', 'initialize', { ...config, ...{ clientToken: '******' } });

		// Called when the SDK has finished initializing and is ready for use
		sdk.onReady(() => {
			setAvailable(sdk);
			state.sdk = sdk;
		});
	} catch (e) {
		const err = e as Error;
		const message = err.message;
		debug.error(`Error: ${message}`, 'initialize');
		setNotAvailable(message);
	}

	return true;
};

export { getLogger, getSDK, initialize, isAvailable, isEnabled, whenAvailable, getRuntimeOverrides };
export type { BrowserLogsInitOptions };
