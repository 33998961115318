/**********************************************************************************************************************
 * Initialization and registration of the various RPC playerstreams with the stream manager.
 *********************************************************************************************************************/
import { Config } from 'helpers/config';
import { DebugLogger } from 'helpers/debug';
import { StreamKey } from './constants';
import { IStreamOptions, SelfData, TableData, TableSeatsPlayData } from './lib-rpc';
import { TableDataStream, TableSeatsPlayDataStream, UserDataStream } from './lib-rpc';
import { streamManager } from './lib-rpc';
import { getSession } from './session';
import { StreamConstructor, ValidStreamType } from './types';

const debug = new DebugLogger('Streams');

const newStream = <StreamType>(
	Stream: StreamConstructor<StreamType>,
	url?: Maybe<string>,
	opts?: Maybe<IStreamOptions>
): StreamType => {
	opts = { ...(opts ?? {}) };

	url = url ?? '';
	if (url === '') {
		throw new Error(`Cannot create stream: Empty stream URL`);
	}

	opts.session = opts.session ?? getSession();

	return new Stream(url, opts);
};

const newUserStream = (props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IStreamOptions> }>): UserDataStream => {
	props = props ?? {};
	const url = props.url || Config.serverURI;

	return newStream<UserDataStream>(UserDataStream, url, props.opts);
};

const newTableSeatsPlayStream = (
	props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IStreamOptions> }>
): TableSeatsPlayDataStream => {
	props = props ?? {};
	const url = props.url || Config.serverURI;

	return newStream<TableSeatsPlayDataStream>(TableSeatsPlayDataStream, url, props.opts);
};

const newTableStream = (props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IStreamOptions> }>): TableDataStream => {
	props = props ?? {};
	const url = props.url || Config.serverURI;

	return newStream<TableDataStream>(TableDataStream, url, props.opts);
};

const registerStream = (streamKey: string, stream: ValidStreamType) => {
	if (streamKey === '') {
		throw new Error(`Stream key must be specified`);
	}

	if (streamManager.hasStream(streamKey)) {
		streamManager.unregisterStream(streamKey);
	}

	streamManager.registerStream(streamKey, stream);
};

const registerUserStream = (stream: UserDataStream, streamKey?: string) => {
	streamKey = streamKey || StreamKey.UserStream;
	registerStream(streamKey, stream);

	const prefix = `UserStream('${streamKey}')`;

	// Debug output subscription
	streamManager.subscribe<SelfData>(streamKey, {
		onStart: () => debug.info('Stream started', `${prefix}.onStart`),
		onStop: (reason?: string) => debug.info('Stream stopped:', `${prefix}.onStop`, { reason: reason }),
		onData: (data: SelfData) => debug.info('Stream data:', `${prefix}.onData`, data),
		onError: (error) => debug.info('Stream error:', `${prefix}.onError`, error),
	});
};

const registerTableStream = (stream: TableDataStream, streamKey?: string) => {
	streamKey = streamKey || StreamKey.TableStream;
	registerStream(streamKey, stream);

	const prefix = `TableStream('${streamKey}')`;

	// Debug output subscription
	streamManager.subscribe<TableData>(streamKey, {
		onStart: () => debug.info('Stream started', `${prefix}.onStart`),
		onStop: (reason?: string) => debug.info('Stream stopped:', `${prefix}.onStop`, { reason: reason }),
		onData: (data: TableData) => debug.info('Stream data:', `${prefix}.onData`, data),
		onError: (error) => debug.info('Stream error:', `${prefix}.onError`, error),
	});
};

const registerTableSeatsPlayStream = (stream: TableSeatsPlayDataStream, streamKey?: string) => {
	streamKey = streamKey || StreamKey.TableSeatsPlayStream;
	registerStream(streamKey, stream);

	const prefix = `TableSeatsPlayStream('${streamKey}')`;

	// Debug output subscription
	streamManager.subscribe<TableSeatsPlayData>(streamKey, {
		onStart: () => debug.info('Stream started', `${prefix}.onStart`),
		onStop: (reason?: string) => debug.info('Stream stopped:', `${prefix}.onStop`, { reason: reason }),
		onData: (data: TableSeatsPlayData) => debug.info('Stream data:', `${prefix}.onData`, data),
		onError: (error) => debug.info('Stream error:', `${prefix}.onError`, error),
	});
};

// ---- Stream instances ----------------------------------------------------------------------------------------------

let _userStream: UserDataStream;
let _tableStream: TableDataStream;
let _tableSeatsPlayStream: TableSeatsPlayDataStream;

// ---- Initialize ----------------------------------------------------------------------------------------------------

let _isInitialized = false;

const makeStreams = () => {
	_userStream = newUserStream();
	_tableStream = newTableStream();
	_tableSeatsPlayStream = newTableSeatsPlayStream();

	registerUserStream(_userStream);
	registerTableStream(_tableStream);
	registerTableSeatsPlayStream(_tableSeatsPlayStream);
};

const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	if (streamManager.hasStreams()) {
		streamManager.clear();
	}

	makeStreams();

	_isInitialized = true;

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

// Note that we don't export the stream instances because we want to use the StreamManager everywhere to manage them
export { initialize as initStreams };

// Dealer
export * from './streams.dealer';

export { streamManager } from './lib-rpc';
export { DealerStreamKey, StreamKey } from './constants';
