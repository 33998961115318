import { getCurrency as lookupLocaleCurrency } from 'locale-currency';
import memoize from 'memoize-one';
import { createLocaleFormatter, getBrowserLocaleString } from './locale';

enum CurrencyCode {
	USD = 'USD',
	VIRTUAL_CHIP = 'CHIP-VIR',
}

const DEFAULT_CURRENCY_CODE = CurrencyCode.USD;
const DEFAULT_CURRENCY_SYMBOL = '$';

interface IFormatCurrencyOpts {
	precision?: Maybe<number>;
	locale?: Maybe<string>;
	currencyCode?: Maybe<string>;
}

/**
 * Formats the given amount with the specified currency / locale options.
 *
 * @returns The formatted currency.
 */
const formatCurrency = (amount: number, opts?: Maybe<IFormatCurrencyOpts>): string => {
	opts = opts ?? {};

	const locale = opts.locale || getBrowserLocaleString();
	const currencyCode = opts.currencyCode || getBrowserCurrencyCode() || DEFAULT_CURRENCY_CODE;
	const precision = opts.precision ?? 2;

	const config: Intl.NumberFormatOptions = {
		style: 'currency',
		currency: currencyCode,
		minimumFractionDigits: precision,
		maximumFractionDigits: precision,
	};

	const formatter = createLocaleFormatter(locale, config);
	const formatted = formatter.format(amount);

	return formatted.replace(/\s/g, ' ').replace('FRE', '❍').trim(); // ❍ 🎰 🎲
};

/**
 * @returns The currency code for the specified locale. eg. 'en-US' will return currency code 'USD'.
 */
const getLocaleCurrencyCode = memoize((locale: string): Nullable<string> => {
	return lookupLocaleCurrency(locale) || null;
});

/**
 * @returns The browser's currency code for it's current locale.
 */
const getBrowserCurrencyCode = () => getLocaleCurrencyCode(getBrowserLocaleString());

/**
 * @returns The currency symbol (eg. $) for the specified locale and currency code. Code will default to the
 *          default currency code for the locale. eg. Locale 'en-US' defaults to currency code 'USD'.
 */
const getLocaleCurrencySymbol = (locale: string, currencyCode?: Maybe<string>): string => {
	currencyCode = currencyCode || getLocaleCurrencyCode(locale) || DEFAULT_CURRENCY_CODE;

	try {
		return (0)
			.toLocaleString(locale, {
				style: 'currency',
				currency: currencyCode,
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
			.replace(/\d/g, '')
			.trim();
	} catch (err) {
		return '';
	}
};

/**
 * @returns The browser's currency symbol for it's current locale.
 */
const getBrowserCurrencySymbol = () => getLocaleCurrencySymbol(getBrowserLocaleString());

/**
 * @returns The currency symbol for the specified currency code (and optional locale).
 */
const getCurrencySymbolForCode = (currencyCode: string, locale?: Maybe<string>): string => {
	locale = locale || getBrowserLocaleString();

	return getLocaleCurrencySymbol(locale, currencyCode);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { DEFAULT_CURRENCY_CODE, DEFAULT_CURRENCY_SYMBOL, CurrencyCode };
export { getLocaleCurrencyCode, getBrowserCurrencyCode };
export { getLocaleCurrencySymbol, getBrowserCurrencySymbol };
export { getCurrencySymbolForCode };
export { formatCurrency };
