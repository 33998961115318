import { getStores } from '../store';
import { debug } from './debug';
import { updateTableHistory } from './tableHistory';

let _currentPlayId: number = 0;

interface ISetPlayOpts {
	startStream?: Maybe<boolean>;
}

const setActivePlay = async (playId: number, opts?: Maybe<ISetPlayOpts>): Promise<void> => {
	if (playId === _currentPlayId) {
		return;
	}

	debug.info('Props:', 'setActivePlay', { playId, opts });

	await setPlay(playId, opts);
};

const setPlay = async (playId: number, opts?: Maybe<ISetPlayOpts>): Promise<void> => {
	const { tableStore, playStore, tableHistoryStore } = getStores();
	const tableId = tableStore.tableId;

	// Unsetting the play
	if (playId === 0) {
		_currentPlayId = 0;
		playStore.clear();
		return;
	}

	// Populate the play store (if necessary)
	if (playStore.playId !== playId) {
		// debug.info(`Attempting to populate play store for play ID '${playId}'`, 'setPlay');

		await playStore.populate(playId);

		if (!playStore.isPopulated || playStore.playId !== playId) {
			debug.warn(`Unable to populate play store using play ID '${playId}'`, 'setPlay');

			_currentPlayId = 0;
			playStore.clear();
			return;
		}
	}

	// Re-populate the table history store (if necessary)
	if (tableHistoryStore.playId !== playId) {
		const updated = await updateTableHistory(tableId, playId);

		if (!updated) {
			tableHistoryStore.clear();
		}
	}

	_currentPlayId = playStore.playId;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { setActivePlay };
