import { Service } from '../../client/core/Service';
import { IServiceOptions } from '../../client/core/types/service';
import { HubBackOfficeClient } from '../../client/rpc/clients/hub';
import { HubDealerHudReply } from '../../client/rpc/replies/hub';
import { HubDealerHudRequest } from '../../client/rpc/requests/hub';
import { HubDealerHudData } from '../../client/rpc/types/hub';
import { IHubBackOfficeService } from './types';

class HubBackOfficeService extends Service<HubBackOfficeClient> implements IHubBackOfficeService {
	constructor(url: string, opts?: IServiceOptions) {
		super(url, opts);
		this.client = this.createUnaryClient(HubBackOfficeClient, opts?.grpcOptions);
	}

	public getHud = async (tableId: string) => {
		const request = new HubDealerHudRequest();
		request.setTableId(tableId);

		return this.unaryAsObject<typeof request, HubDealerHudReply, HubDealerHudData>('getDealerHud', request);
	};
}

export { HubBackOfficeService as default };
export { HubBackOfficeService };
