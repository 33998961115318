import throttle from 'lodash/throttle';
import { getStores } from '../store';
import { debug } from './debug';

const _updateTableHistory = async (tableId: string, playId: number): Promise<boolean> => {
	const { tableHistoryStore } = getStores();

	// debug.info(
	// 	`Attempting to populate table history using table ID '${tableId}' and play ID ${playId}`,
	// 	'updateTableHistory'
	// );

	await tableHistoryStore.populate(tableId, playId);

	if (!tableHistoryStore.isPopulated || tableHistoryStore.tableId !== tableId || tableHistoryStore.playId !== playId) {
		debug.warn(
			`Unable to populate table history using table ID '${tableId}' and play ID ${playId}`,
			'updateTableHistory'
		);
		return false;
	}

	return true;
};

const updateTableHistoryThrottled = throttle(_updateTableHistory, 1000, { leading: false, trailing: true });

// ---- Export --------------------------------------------------------------------------------------------------------

export { updateTableHistoryThrottled as updateTableHistory };
