import { Service } from '../../client/core/Service';
import { IServiceOptions } from '../../client/core/types/service';
import { HubDealingDeviceClient } from '../../client/rpc/clients/hub';
import { HubDealtInput } from '../../client/rpc/data/hub';
import {
	HubDeviceDealtInputReply,
	HubDeviceGetRequestsReply,
	HubDeviceRunPlayReply,
} from '../../client/rpc/replies/hub';
import {
	HubDeviceDealtInputRequest,
	HubDeviceGetRequestsRequest,
	HubDeviceRunPlayRequest,
} from '../../client/rpc/requests/hub';
import { HubDeviceRequestsData } from '../../client/rpc/types/hub';
import { IHubDeviceService } from './types';

class HubDeviceService extends Service<HubDealingDeviceClient> implements IHubDeviceService {
	constructor(url: string, opts?: IServiceOptions) {
		super(url, opts);
		this.client = this.createUnaryClient(HubDealingDeviceClient, opts?.grpcOptions);
	}

	public runPlay = (deviceKey: string) => {
		const request = new HubDeviceRunPlayRequest();
		request.setDeviceKey(deviceKey);

		return this.unary<typeof request, HubDeviceRunPlayReply>('deviceRunPlay', request);
	};

	public getDealerDeviceRequests = async (deviceKey: string) => {
		const request = new HubDeviceGetRequestsRequest();
		request.setDeviceKey(deviceKey);

		return this.unaryAsObject<typeof request, HubDeviceGetRequestsReply, HubDeviceRequestsData>(
			'getDealerDeviceRequests',
			request
		);
	};

	public dealtInput = (deviceKey: string, inputNum: number, requestType: string, values: string[]) => {
		const request = new HubDeviceDealtInputRequest();
		const input = new HubDealtInput();

		input.setDeviceKey(deviceKey);
		input.setInputNum(inputNum);
		input.setRequest(requestType);
		input.setValuesList(values);

		request.setDeviceKey(deviceKey);
		request.setInput(input);

		return this.unary<typeof request, HubDeviceDealtInputReply>('dealtInput', request);
	};
}

export { HubDeviceService as default };
export { HubDeviceService };
