/**
 * This module will determine what themes are available for loading.
 */
import isArray from 'lodash/isArray';
import Config from 'helpers/config';
import { getHashData } from 'helpers/hashData';
import { Theme } from './constants';

// These are used if no themes come from config/env
const defaultThemes: string[] = [Theme.SANDS, Theme.TWO_BET, Theme.GLI1, Theme.GLI2, Theme.GLI3];

// Base themes
const baseThemes: string[] = [Theme.BASE];

const filterThemes = (arr: string[], removeThese: string[]): string[] => {
	if (arr.length === 0) {
		return arr;
	}

	const toRemove = new Set(removeThese);

	return arr.filter((t) => !toRemove.has(t));
};

const getConfigThemes = (): string[] => {
	return Config.themes.slice();
};

const getHashDataThemes = (): string[] => {
	const hashDataThemes = getHashData().themes;

	return isArray(hashDataThemes) ? hashDataThemes : [hashDataThemes];
};

let availableThemes: Nullable<string[]> = null;

const resolveAvailableThemes = () => {
	const configThemes = getConfigThemes();
	const hashDataThemes = getHashDataThemes();

	let addThemes: string[] = [];

	if (hashDataThemes.length > 0) {
		addThemes = hashDataThemes;
	} else if (configThemes.length > 0) {
		addThemes = configThemes;
	} else {
		addThemes = defaultThemes.slice();
	}

	addThemes = filterThemes(addThemes, baseThemes);

	return baseThemes.concat(addThemes);
};

const getAvailableThemes = () => {
	if (availableThemes == null) {
		availableThemes = resolveAvailableThemes();
	}

	return availableThemes;
};

const hasAvailableTheme = (name: string) => {
	return availableThemes && availableThemes.includes(name);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { getAvailableThemes, hasAvailableTheme };
