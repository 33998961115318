/**********************************************************************************************************************
 * Stores and manages data for the current table play.
 *
 * Note: This will be used as the base parent class for the same store inside of the game clients.
 *********************************************************************************************************************/
import isArray from 'lodash/isArray';
import { ActiveWagerData, PlayStatesData, ResolutionData, TableData, TablePlayData } from '../client/rpc/types/spoke';
import { ITableService } from '../client/service/types';
import { Bets, GameState, ResolutionType } from '../constants';
import { DataStore } from './DataStore';

type ResolutionsList = ResolutionData[];

interface ILookupResolutionsOpts {
	resolutions?: Maybe<ResolutionsList>;
	filterByType?: Maybe<string | string[]>;
	filterByName?: Maybe<string | string[]>;
	filterZeroAmounts?: Maybe<boolean>;
}

interface ILookupResolutionsResult {
	size: number;
	list: ResolutionsList;
	nameList: string[];
	typeList: string[];
	nameLookup: Map<string, ResolutionData>;
	typeLookup: Map<string, ResolutionData>;
	totalAmount: number;
	totalAmountFiltered: number;
}

class PlayStore extends DataStore<ITableService, TablePlayData> {
	/**
	 * @returns The unique play ID
	 */
	public get playId(): number {
		return this.data?.playId || 0;
	}

	/**
	 * Alias for `playId`
	 */
	public get id(): number {
		return this.playId;
	}

	/**
	 * @returns The play state value.
	 */
	public get state(): string {
		return this.data?.state ?? '';
	}

	/**
	 * @returns Play state data for the various games.
	 */
	public get playStates(): PlayStatesData {
		return this.data?.playStates ?? {};
	}

	/**
	 * @returns List of current active/effective player wagers.
	 */
	public get activeWagers(): ActiveWagerData[] {
		return this.data?.effectivePlayerWagersList ?? [];
	}

	/**
	 * @returns The total amount of all active wagers.
	 */
	public get activeWagersTotal(): number {
		const reducerFn = (total: number, current: ActiveWagerData) => total + current.amount;

		return this.activeWagers.reduce(reducerFn, 0) ?? 0;
	}

	public get activeWagersDirty(): boolean {
		return this.activeWagers.findIndex((w) => w.wagerState === 'ini') >= 0;
	}

	/**
	 * @returns TRUE if any active wager has an amount greater than zero.
	 */
	public get hasWagers(): boolean {
		return this.activeWagers.findIndex((w) => w.amount > 0) > -1;
	}

	/**
	 * @returns TRUE if the server is still processing wagers.
	 */
	public get serverHasUnprocessedWagers(): boolean {
		return this.data?.unprocessedWagers || false;
	}

	/**
	 * @returns List of current resolutions.
	 */
	public get resolutions(): ResolutionsList {
		return this.data?.resolutionsList ?? [];
	}

	/**
	 * @returns Total resolutions amount.
	 */
	public get resolutionsTotalAmount(): number {
		const lookupData = this.lookupResolutions();

		return lookupData.totalAmount;
	}

	/**
	 * List of current side-bet resolutions.
	 */
	public get sideBetResolutions(): ResolutionsList {
		return this.sideBetResolutionData.list;
	}

	/**
	 * Total of side-bet resolutions.
	 */
	public get sideBetResolutionsTotal(): number {
		return this.sideBetResolutionData.total;
	}

	protected get sideBetResolutionData(): { list: ResolutionsList; total: number } {
		const resolutions = this.resolutions.slice();
		const list: ResolutionsList = [];
		let total: number = 0;

		resolutions.forEach((r: ResolutionData) => {
			if (r.resolutionName === Bets.WAGER) {
				return;
			}

			const amount = r.amount > 0 ? r.amount : 0;

			list.push(r);
			total += amount;
		});

		return { list, total };
	}

	/**
	 * @returns TRUE if the current play state is the RESOLVED or COMPLETED state - ie. The play is fully resolved.
	 */
	public get isResolved(): boolean {
		return this.state === GameState.RES || this.state === GameState.COMP;
	}

	/**
	 * The start time of the current play betting round.
	 */
	public get autoRunTime(): number {
		return this.data?.autoRunTime ?? 0;
	}
	public get roundStartTime(): number {
		return this.autoRunTime;
	}

	/**
	 * The duration of the current play betting sround.
	 */
	public get actionTimeoutTime(): number {
		return this.data?.actionTimeoutTime ?? 0;
	}
	public get roundTimerDuration(): number {
		return this.actionTimeoutTime;
	}

	/**
	 * ???
	 */
	public get shoePlayNum(): number {
		return this.data?.shoePlayNum ?? 0;
	}

	/**
	 * @returns Current active hand.
	 */
	public get activeHandNum(): number {
		return this.data?.activeHandNum ?? 0;
	}
	public get activeHandIndex() {
		return this.activeHandNum === 0 ? -1 : this.activeHandNum - 1;
	}

	public get wagerResolution() {
		return this.resolutions.find((value: ResolutionData) => value.wagerName === Bets.WAGER) ?? null;
	}

	public get wagerResolutionWinAmount() {
		return this.wagerResolution?.amount ?? 0;
	}

	public get wagerResolutionIsPush() {
		return this.wagerResolution?.resolutionType === ResolutionType.PUSH;
	}

	public get insuranceResolution() {
		return this.resolutions.find((value: ResolutionData) => value.wagerName === Bets.INSURANCE) ?? null;
	}

	/**
	 * @returns Resolution lookup data. Allows easy filtering and mapping of resolutions data.
	 */
	public lookupResolutions(opts?: Maybe<ILookupResolutionsOpts>): ILookupResolutionsResult {
		const resolutions = opts?.resolutions ?? this.resolutions.slice();

		const { filterByType = null, filterByName = null, filterZeroAmounts = false } = opts ?? {};
		let typeFilter: string[] = [];
		let nameFilter: string[] = [];

		if (filterByType != null) {
			typeFilter = !isArray(filterByType) && filterByType !== '' ? [filterByType] : (filterByType as string[]);
		}
		if (filterByName != null) {
			nameFilter = !isArray(filterByName) && filterByName !== '' ? [filterByName] : (filterByName as string[]);
		}

		const list: ResolutionsList = [];
		const nameList: Array<string> = [];
		const typeList: Array<string> = [];
		const nameLookup = new Map<string, ResolutionData>();
		const typeLookup = new Map<string, ResolutionData>();
		let totalAmount: number = 0;
		let totalAmountFiltered: number = 0;

		resolutions.forEach((r) => {
			const type = r.resolutionType;
			const name = r.resolutionName;
			const amount = r.amount >= 0 ? r.amount : 0;
			totalAmount += amount;

			if (filterZeroAmounts && amount === 0) {
				return;
			}

			if (typeFilter.length > 0 && !typeFilter.includes(type)) {
				return;
			}
			if (nameFilter.length > 0 && !nameFilter.includes(name)) {
				return;
			}

			totalAmountFiltered += amount;

			list.push(r);
			nameList.push(name);
			typeList.push(type);
			nameLookup.set(name, r);
			typeLookup.set(type, r);
		});

		return {
			size: list.length,
			list,
			nameList,
			typeList,
			nameLookup,
			typeLookup,
			totalAmount,
			totalAmountFiltered,
		};
	}

	public findResolutionsTotal(opts?: Maybe<ILookupResolutionsOpts>): number {
		const lookupData = this.lookupResolutions(opts);

		return lookupData.totalAmountFiltered;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}

	// ---- Populate ----------------------------------------------------------------------------------------------------

	/**
	 * Action. Populates the store using the specified table data. This works since the play store is effectively a
	 * subset of the table data.
	 */
	public populateFromTable(data: TableData) {
		this.setData(data.table?.play ?? null);
	}

	/**
	 * Action. Populates the store (via unary RPC service call) using the specified play ID.
	 */
	public async populate(playId: number): Promise<boolean> {
		if (playId < 0) {
			this.debugError('A valid play id must be specified', 'populate');
		}

		return super.populate(playId);
	}

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(playId: number): Promise<Nullable<TablePlayData>> {
		if (!this.service) {
			this.debugError('No service was specified', 'fetchPopulateData');
			return null;
		}

		if (playId < 0) {
			this.debugError('A valid play id must be specified', 'fetchPopulateData');
		}

		return (await this.service.getTablePlay(playId)).data?.play ?? null;
	}
}

export { PlayStore as default };
export { PlayStore };

export type { ILookupResolutionsOpts, ILookupResolutionsResult, ResolutionsList };
