import trimEnd from 'lodash/trimEnd';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { DebugLogger } from 'helpers/debug';
import { ILoadedTheme, IThemeModule, LoadedThemeList, LoadedThemes, ThemedComponents } from './types';

interface IThemeManagerOptions {
	// Default theme path applied when loading the themes (when a specific theme path is not provided)
	themePath?: Optional<string>;
	// Whether debug output is enabled or not
	debugEnabled?: boolean;
}

type ThemePaths = { [key: string]: Nullable<string> };

interface IAddedThemes {
	// Full paths to each theme
	paths: ThemePaths;
	// The theme names that were added in the exact order they were added (affects loading).
	namesOrdered: string[];
}

interface ILoadedThemes {
	// Theme data per theme name
	items: LoadedThemes;
	// List of loaded theme data (in the order they were loaded)
	list: LoadedThemeList;
	// Names of the loaded themes in the order they were loaded
	names: string[];
}

interface IAppliedThemes extends ILoadedThemes {
	components: ThemedComponents;
}

const getAddedThemesDefaults = (): IAddedThemes => {
	return {
		paths: {},
		namesOrdered: [],
	};
};

const getLoadedThemesDefaults = (): ILoadedThemes => {
	return {
		items: {},
		list: [],
		names: [],
	};
};

const getAppliedThemesDefaults = (): IAppliedThemes => {
	return {
		items: {},
		list: [],
		names: [],
		components: {},
	};
};

const getThemeManagerOptionDefaults = (): IThemeManagerOptions => {
	return {
		debugEnabled: false,
		themePath: '.',
	};
};

class ThemeManager {
	// Options to use for this theme manager instance
	protected options: IThemeManagerOptions = getThemeManagerOptionDefaults();

	// Themes added to the manager - not necessarily loaded.
	protected added: IAddedThemes = getAddedThemesDefaults();

	// Themes currently loaded by the manager - not necessarily applied.
	protected loaded: ILoadedThemes = getLoadedThemesDefaults();

	// Themes currently applied (affects the result of `getAppliedComponents`)
	protected applied: IAppliedThemes = getAppliedThemesDefaults();

	// Debug logger instance used for logging
	protected logger: DebugLogger = new DebugLogger('ThemeManager');

	/**
	 * CONSTRUCTOR
	 * @param opts
	 */
	constructor(opts?: Maybe<IThemeManagerOptions>) {
		this.options = { ...this.options, ...(opts || {}) };
		this.options.themePath = trimEnd(this.options.themePath, '/ ') || this.options.themePath;

		if (!this.isDebugEnabled) {
			this.logger.silent = true;
		}
	}

	/**
	 * Add the specified themes to this manager. You can add the same theme name more than once if desired.
	 * Adding themes does NOT load them - you need to call `load`.
	 *
	 * @param  names  Array of theme names to add.
	 * @param  opts   Optional. Options to apply when adding the themes (eg. the path to use)
	 */
	public addThemes = (names: string[], opts?: { path?: Maybe<string> }): void => {
		const path = trimEnd(opts?.path ?? '', '/ ') || this.themePath || '';

		names.forEach((name) => {
			this.added.paths[name] = `${path}/${name}`;
			this.added.namesOrdered.push(name);
		});
	};

	/**
	 * Removes specified themes from this manager. This will re-calculate applied.
	 *
	 * @param  names  Array of theme names to remove.
	 */
	public removeThemes = (names: string[]): number => {
		if (names.length === 0) {
			return 0;
		}

		let removed = 0;

		names.forEach((name) => {
			if (this.removeTheme(name)) {
				removed++;
			}
		});

		if (removed > 0) {
			this.applyThemes();
		}

		return removed;
	};

	/**
	 * Removes all currently added themes from this manager. This will re-calculate applied.
	 */
	public removeAllThemes = (): number => {
		return this.removeThemes(this.added.namesOrdered);
	};

	/**
	 * Loads the themes that have been added to this manager. This will re-calculate applied.
	 */
	public load = async (): Promise<ILoadedThemes> => {
		const loadPaths = { ...this.added.paths };
		const loadNames = Object.keys(loadPaths);

		const loaded = await this.loadThemes(loadNames, loadPaths);
		this.loaded.items = { ...this.loaded.items, ...loaded.items };
		this.loaded.list = uniqBy(this.loaded.list.concat(loaded.list), 'name');
		this.loaded.names = uniq(this.loaded.names.concat(loaded.names));

		// Note that it's OK to apply the same theme twice
		const applyNames = this.added.namesOrdered.slice();
		this.applyThemes(applyNames);

		return this.loaded;
	};

	/**
	 * Clears this manager.
	 */
	public clear = () => {
		this.added = getAddedThemesDefaults();
		this.loaded = getLoadedThemesDefaults();
		this.applied = getAppliedThemesDefaults();
	};

	/**
	 * Throws away all current managed themes and then loads & applies the specified themes.
	 */
	public resetWith = async (names: string[], opts?: { path?: Maybe<string> }): Promise<IAppliedThemes> => {
		this.clear();

		this.addThemes(names, { path: opts?.path });
		await this.load();
		this.applyThemes();

		return this.applied;
	};

	/**
	 * Re-calculate the current applied themes according to the specified names (order matters). Themes must be
	 * already loaded in order to successfully apply them.
	 *
	 * @param    names  Array of theme names to apply.
	 * @returns  Details about which themes are now currently applied.
	 */
	public applyThemes = (names?: string[]): IAppliedThemes => {
		names = names ?? this.added.namesOrdered;
		this.applied = this.newAppliedThemes(names);

		return this.applied;
	};

	/**
	 * @param    name  The unique theme name.
	 * @returns  TRUE if the specified theme name is currently loaded. Loaded does NOT mean applied.
	 */
	public hasLoadedTheme = (name: string): boolean => {
		return this.loaded.items[name] != null;
	};

	public getLoaded = () => {
		return { ...this.loaded };
	};

	/**
	 * @returns Dictionary of currently applied themed components.
	 */
	public getAppliedComponents = () => {
		return { ...this.applied.components };
	};

	/**
	 * @param    name  The unique theme name.
	 * @returns  TRUE if the specified theme name is currently loaded.
	 */
	public hasAppliedTheme = (name: string) => {
		return this.applied.items[name] != null;
	};

	protected get themePath(): string {
		return this.options.themePath ?? '.';
	}

	protected get isDebugEnabled(): boolean {
		return this.options.debugEnabled ?? false;
	}

	/**
	 * Returns new applied data given the specified theme names.
	 *
	 * @param names
	 * @returns
	 */
	protected newAppliedThemes = (names: string[]): IAppliedThemes => {
		const applied: IAppliedThemes = getAppliedThemesDefaults();

		if (names.length === 0) {
			return applied;
		}

		names.forEach((name) => {
			const theme = this.loaded.items[name] ?? null;
			if (theme == null) {
				this.debug.warn(`Theme '${name}' is not currently loaded`, 'newAppliedThemes');
				return;
			}

			applied.components = { ...applied.components, ...(theme.components ?? {}) };

			const appliedTheme = { ...theme };
			applied.items[name] = appliedTheme;
			applied.list.push(appliedTheme);
			applied.names.push(name);
		});

		return applied;
	};

	/**
	 * Load the specified themes in the order specified by the `names` array.
	 */
	/**
	 *
	 * @param names
	 * @param paths
	 * @returns
	 */
	protected loadThemes = async (names: string[], paths: ThemePaths): Promise<ILoadedThemes> => {
		const loaded: ILoadedThemes = getLoadedThemesDefaults();

		if (names.length === 0) {
			return loaded;
		}

		this.debug.info(`Attempting to load specified themes:`, 'loadThemes', names.join(' | '));

		const promises: Promise<ILoadedTheme>[] = [];

		names.forEach((name) => {
			// Already loaded
			if (this.loaded.items[name] != null) {
				this.debug.info(`Theme '${name}' is already loaded`, 'loadThemes');
				return;
			}

			let themePath = paths[name] ?? '';
			themePath = themePath !== '' ? themePath : `${this.themePath}/${name}`;

			promises.push(this.loadTheme(name, themePath));
		});

		// Attempt to load all the theme modules
		const loadedThemes = await Promise.all(promises);

		loadedThemes.forEach((theme: ILoadedTheme) => {
			if (theme.components == null) {
				return;
			}

			loaded.items[theme.name] = theme;
			loaded.list.push(theme);
			loaded.names.push(theme.name);
		});

		this.debug.info('Themes actually loaded:', 'loadThemes', loaded.names.join(' | '), { ...loaded });

		return loaded;
	};

	/**
	 * Loads the specified theme by name and path.
	 *
	 * @param name       Unique theme name.
	 * @param themePath  Full path to the theme.
	 * @returns The loaded theme data.
	 */
	protected loadTheme = async (name: string, themePath: string): Promise<ILoadedTheme> => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const module: IThemeModule = (await import(`${themePath}`)) ?? { default: null };
			const components: ThemedComponents = module.default ?? null;

			if (components == null) {
				throw new Error(`Theme '${name}' does not export any components`);
			}

			const theme: ILoadedTheme = { name, components, error: null };
			this.debug.info(`Loaded theme '${name}':`, 'loadTheme', { ...theme });

			return theme;
		} catch (e) {
			const err = e as Error;
			this.debug.warn(`Failed to load theme '${name}':`, 'loadTheme', err.message);

			return { name, components: null, error: err };
		}
	};

	protected removeLoaded = (name: string): ILoadedThemes => {
		if (name === '' || !(name in this.loaded.items)) {
			return this.loaded;
		}

		const loaded = { ...this.loaded };

		const i1 = loaded.names.indexOf(name);
		i1 >= 0 && loaded.names.splice(i1, 1);

		const i2 = loaded.list.findIndex((item) => item.name === name);
		i2 >= 0 && loaded.list.splice(i2, 1);

		delete loaded.items[name];

		return loaded;
	};

	protected removeAdded = (name: string): IAddedThemes => {
		if (name === '' || !(name in this.added.paths)) {
			return this.added;
		}

		const added = { ...this.added };

		const i = added.namesOrdered.indexOf(name);
		i >= 0 && added.namesOrdered.splice(i, 1);

		delete added.paths[name];

		return added;
	};

	protected removeTheme = (name: string): boolean => {
		if (name === '' || !(name in this.added.paths)) {
			return false;
		}

		this.added = this.removeAdded(name);
		this.loaded = this.removeLoaded(name);

		return true;
	};

	protected get debug(): DebugLogger {
		return this.logger;
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { ThemeManager as default };
export { ThemeManager };
