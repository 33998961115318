enum CurrencyCode {
	USD = 'USD',
	VIRTUAL_CHIP = 'CHIP-VIR',
}

const DEFAULT_CURRENCY_CODE = CurrencyCode.USD;
const DEFAULT_CURRENCY_SYMBOL = '$';

// ---- Export --------------------------------------------------------------------------------------------------------

export { CurrencyCode };
export { DEFAULT_CURRENCY_CODE, DEFAULT_CURRENCY_SYMBOL };
