/**********************************************************************************************************************
 * Main game store. Holds game state data that is not tied to RPC data.
 *********************************************************************************************************************/
import { DEFAULT_GAME_KEY, DEFAULT_GAME_NAME } from 'Constants';
import { getAvailableThemes, Theme } from 'themes';
import Config from 'helpers/config';
import { makeAutoObservable } from 'helpers/mobx';
import { getTableStore } from '../stores';

interface IGameData {
	gameKey: string;
	gameName: string;
	showStatsInPlay: boolean;
	showStatsOnBet: boolean;
	videoPlaying: boolean;
	loadComplete: boolean;
	selectedChipAmt: number;
	themes: string[];
}

const defaultGameData = (): IGameData => {
	return {
		gameKey: DEFAULT_GAME_KEY,
		gameName: DEFAULT_GAME_NAME,
		showStatsInPlay: false,
		showStatsOnBet: false,
		videoPlaying: false,
		loadComplete: false,
		selectedChipAmt: 500,
		themes: [],
	};
};

class GameStore {
	protected _data: IGameData;
	protected _lastUpdatedTs: number = 0;

	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		makeAutoObservable(this);

		this._data = defaultGameData();
		this.themes = getAvailableThemes();

		// TODO: Make some better way to update the themes by the spoke.
		if (Config.spoke === 'spoke-b') {
			this.themes.push(Theme.TWO_BET);
		}

		// Get the version from the version.json if it exists.
		fetch('/version.json?time=' + Date.now().toString())
			.then((r) => r.json())
			.then((versionData: PlainObject) => {
				console.log('Version', versionData);
				this.version = versionData.GITHUB_REF_NAME as string;
			});
	}

	/**
	 * Build Tag Version
	 */
	version: string = '';

	/**
	 * Game Key
	 */
	public get gameKey(): string {
		return this._data.gameKey;
	}
	public set gameKey(val: string) {
		this._data.gameKey = val;
		this.setUpdatedTs();
	}

	/**
	 * Game Display Name/Title
	 */
	public get gameName(): string {
		return this._data.gameName;
	}
	public set gameName(val: string) {
		this._data.gameName = val;
		this.setUpdatedTs();
	}

	/**
	 * TRUE if we should show the play stats
	 */
	public get showStatsInPlay(): boolean {
		return this._data.showStatsInPlay;
	}
	public set showStatsInPlay(val: boolean) {
		this._data.showStatsInPlay = val;
		this.setUpdatedTs();
	}

	/**
	 * TRUE if we should show the bet stats
	 */
	public get showStatsOnBet(): boolean {
		return this._data.showStatsOnBet;
	}
	public set showStatsOnBet(val: boolean) {
		this._data.showStatsOnBet = val;
		this.setUpdatedTs();
	}

	/**
	 * TRUE if the video is currently playing
	 */
	public get videoPlaying(): boolean {
		return this._data.videoPlaying;
	}
	public set videoPlaying(val: boolean) {
		this._data.videoPlaying = val;
		this.setUpdatedTs();
	}

	/**
	 * TRUE if the application is fully loaded.
	 */
	public get loadComplete(): boolean {
		return this._data.loadComplete;
	}
	public set loadComplete(val: boolean) {
		this._data.loadComplete = val;
		this.setUpdatedTs();
	}

	/**
	 * Current selected chip amount
	 */
	public get selectedChipAmt(): number {
		return this._data.selectedChipAmt;
	}
	public set selectedChipAmt(val: number) {
		this._data.selectedChipAmt = val;
		this.setUpdatedTs();
	}

	/**
	 * Available themes
	 */
	public get themes(): string[] {
		return this._data.themes;
	}
	public set themes(val: string[]) {
		this._data.themes = val;
		this.setUpdatedTs();
	}

	/**
	 * The underlying data inside this store.
	 */
	public get data(): IGameData {
		return this._data;
	}

	/**
	 * Returns the unix timestamp (UTC) for when the data in this store was last updated.
	 */
	public get lastUpdatedTs() {
		return this._lastUpdatedTs;
	}

	/**
	 * Toggles the stats ON/OFF.
	 */
	public toggleStats() {
		const tableStore = getTableStore();
		if (tableStore.canPlaceBets) {
			this.showStatsOnBet = !this.showStatsOnBet;
		} else {
			this.showStatsInPlay = !this.showStatsInPlay;
		}
	}

	protected setUpdatedTs() {
		this._lastUpdatedTs = Date.now();
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { GameStore as default };
export { GameStore };
