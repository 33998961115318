import { IClientResult, IError, RpcOptions } from './client';
import { IAuthSessionProvider } from './session';

type StreamResult<T> = IClientResult<T>;
type IStreamError = IError;

interface IStreamSubscriber<T> {
	onStart?: () => void;
	onStop?: (reason?: string) => void;
	onData?: (data: T) => void;
	onError?: (e: IStreamError) => void;
}

type StreamSubscribers<T> = IStreamSubscriber<T>[];

interface IAutoRestartInputProps {
	// When TRUE the auto restart feature will be enabled.
	enabled?: boolean;
	// Base delay between restart attempts.
	delayMs?: number;
	// Maximum number of restart attempts (per cycle) before we give up
	maxAttempts?: number;
	// When TRUE the stream is allowed to restart when an error occurs.
	restartOnError?: boolean;
	// When TRUE will use a linear exponential backoff when calculating the delay between attempts
	applyExponentialBackoff?: boolean;
}

interface IStreamOptions {
	session?: Maybe<IAuthSessionProvider>;
	grpcOptions?: RpcOptions;
	autoRestart?: Maybe<IAutoRestartInputProps>;
	isEnabled?: boolean;
}

interface IStreamRequestProps {
	tableId?: string;
	playId?: number;
	userId?: string;
}

interface IStream {
	token: string;
	session: IAuthSessionProvider;
	readonly isActive: boolean;
	isEnabled: boolean;
	stop: (reason?: string) => boolean;
}

enum StreamStatus {
	// Initial state
	NOT_STARTED = '',
	// Stream is starting
	STARTING = 'STARTING',
	// Stream is restarting
	RESTARTING = 'RESTARTING',
	// Stream is started and running
	RUNNING = 'RUNNING',
	// Stream was stopped due to an end notification
	STOPPED_ENDED = 'STOPPED_ENDED',
	// Stream was stopped due to a cancel notification
	STOPPED_CANCELLED = 'STOPPED_CANCELLED',
	// Stream was stopped due to an error
	STOPPED_ERROR = 'STOPPED_ERROR',
	// Stream was stopped due calling the `stop` method
	STOPPED = 'STOPPED',
}

interface IStreamState {
	isEnabled?: boolean;
	isActive?: boolean;
	status?: StreamStatus;
	message?: string;
}

interface IValidateStartResult<StreamRequestProps> extends IStreamRequestProps {
	isValid: boolean;
	requestProps: Maybe<StreamRequestProps>;
}

export { StreamStatus };
export type {
	StreamResult,
	IStream,
	IStreamSubscriber,
	StreamSubscribers,
	IStreamOptions,
	IStreamRequestProps,
	IStreamState,
	IValidateStartResult,
	IStreamError,
	IAutoRestartInputProps,
};
