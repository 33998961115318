// source: chat_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var chat_error_pb = require('./chat_error_pb.js');
goog.object.extend(proto, chat_error_pb);
goog.exportSymbol('proto.com.sands.live_dealer.chat.ChatMessage', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.chat.JoinTableReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.chat.JoinTableRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.chat.LeaveTableReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.chat.LeaveTableRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.chat.SendMessageReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.chat.StreamChatsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.chat.JoinTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.chat.JoinTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.chat.JoinTableRequest.displayName = 'proto.com.sands.live_dealer.chat.JoinTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.chat.JoinTableReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.chat.JoinTableReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.chat.JoinTableReply.displayName = 'proto.com.sands.live_dealer.chat.JoinTableReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.chat.ChatMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.chat.ChatMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.chat.ChatMessage.displayName = 'proto.com.sands.live_dealer.chat.ChatMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.chat.SendMessageReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.chat.SendMessageReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.chat.SendMessageReply.displayName = 'proto.com.sands.live_dealer.chat.SendMessageReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.chat.StreamChatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.chat.StreamChatsRequest.displayName = 'proto.com.sands.live_dealer.chat.StreamChatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.chat.LeaveTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.chat.LeaveTableRequest.displayName = 'proto.com.sands.live_dealer.chat.LeaveTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.chat.LeaveTableReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.chat.LeaveTableReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.chat.LeaveTableReply.displayName = 'proto.com.sands.live_dealer.chat.LeaveTableReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.chat.JoinTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.chat.JoinTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.chat.JoinTableRequest}
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.chat.JoinTableRequest;
  return proto.com.sands.live_dealer.chat.JoinTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.chat.JoinTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.chat.JoinTableRequest}
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.chat.JoinTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.chat.JoinTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.JoinTableRequest} returns this
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.JoinTableRequest} returns this
 */
proto.com.sands.live_dealer.chat.JoinTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.chat.JoinTableReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.chat.JoinTableReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.chat.JoinTableReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.JoinTableReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && chat_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.chat.JoinTableReply}
 */
proto.com.sands.live_dealer.chat.JoinTableReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.chat.JoinTableReply;
  return proto.com.sands.live_dealer.chat.JoinTableReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.chat.JoinTableReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.chat.JoinTableReply}
 */
proto.com.sands.live_dealer.chat.JoinTableReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new chat_error_pb.Error;
      reader.readMessage(value,chat_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.chat.JoinTableReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.chat.JoinTableReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.chat.JoinTableReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.JoinTableReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      chat_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.com.sands.live_dealer.chat.Error}
 */
proto.com.sands.live_dealer.chat.JoinTableReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.chat.Error} */ (
    jspb.Message.getWrapperField(this, chat_error_pb.Error, 1));
};


/**
 * @param {?proto.com.sands.live_dealer.chat.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.chat.JoinTableReply} returns this
*/
proto.com.sands.live_dealer.chat.JoinTableReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.chat.JoinTableReply} returns this
 */
proto.com.sands.live_dealer.chat.JoinTableReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.chat.JoinTableReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.chat.ChatMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.chat.ChatMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.ChatMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isDirect: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    senderId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    recipientId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    body: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.chat.ChatMessage}
 */
proto.com.sands.live_dealer.chat.ChatMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.chat.ChatMessage;
  return proto.com.sands.live_dealer.chat.ChatMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.chat.ChatMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.chat.ChatMessage}
 */
proto.com.sands.live_dealer.chat.ChatMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDirect(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipientId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.chat.ChatMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.chat.ChatMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.ChatMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsDirect();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRecipientId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 time = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.chat.ChatMessage} returns this
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.getMessageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.ChatMessage} returns this
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_direct = 3;
 * @return {boolean}
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.getIsDirect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.chat.ChatMessage} returns this
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.setIsDirect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string sender_id = 4;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.ChatMessage} returns this
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string recipient_id = 5;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.getRecipientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.ChatMessage} returns this
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.setRecipientId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string body = 6;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.ChatMessage} returns this
 */
proto.com.sands.live_dealer.chat.ChatMessage.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.chat.SendMessageReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.chat.SendMessageReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.chat.SendMessageReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.SendMessageReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && chat_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.chat.SendMessageReply}
 */
proto.com.sands.live_dealer.chat.SendMessageReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.chat.SendMessageReply;
  return proto.com.sands.live_dealer.chat.SendMessageReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.chat.SendMessageReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.chat.SendMessageReply}
 */
proto.com.sands.live_dealer.chat.SendMessageReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new chat_error_pb.Error;
      reader.readMessage(value,chat_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.chat.SendMessageReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.chat.SendMessageReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.chat.SendMessageReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.SendMessageReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      chat_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.com.sands.live_dealer.chat.Error}
 */
proto.com.sands.live_dealer.chat.SendMessageReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.chat.Error} */ (
    jspb.Message.getWrapperField(this, chat_error_pb.Error, 1));
};


/**
 * @param {?proto.com.sands.live_dealer.chat.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.chat.SendMessageReply} returns this
*/
proto.com.sands.live_dealer.chat.SendMessageReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.chat.SendMessageReply} returns this
 */
proto.com.sands.live_dealer.chat.SendMessageReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.chat.SendMessageReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.chat.StreamChatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.chat.StreamChatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.chat.StreamChatsRequest}
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.chat.StreamChatsRequest;
  return proto.com.sands.live_dealer.chat.StreamChatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.chat.StreamChatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.chat.StreamChatsRequest}
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.chat.StreamChatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.chat.StreamChatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.StreamChatsRequest} returns this
 */
proto.com.sands.live_dealer.chat.StreamChatsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.chat.LeaveTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.chat.LeaveTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.chat.LeaveTableRequest}
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.chat.LeaveTableRequest;
  return proto.com.sands.live_dealer.chat.LeaveTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.chat.LeaveTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.chat.LeaveTableRequest}
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.chat.LeaveTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.chat.LeaveTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.LeaveTableRequest} returns this
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.chat.LeaveTableRequest} returns this
 */
proto.com.sands.live_dealer.chat.LeaveTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.chat.LeaveTableReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.chat.LeaveTableReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && chat_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.chat.LeaveTableReply}
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.chat.LeaveTableReply;
  return proto.com.sands.live_dealer.chat.LeaveTableReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.chat.LeaveTableReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.chat.LeaveTableReply}
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new chat_error_pb.Error;
      reader.readMessage(value,chat_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.chat.LeaveTableReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.chat.LeaveTableReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      chat_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.com.sands.live_dealer.chat.Error}
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.chat.Error} */ (
    jspb.Message.getWrapperField(this, chat_error_pb.Error, 1));
};


/**
 * @param {?proto.com.sands.live_dealer.chat.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.chat.LeaveTableReply} returns this
*/
proto.com.sands.live_dealer.chat.LeaveTableReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.chat.LeaveTableReply} returns this
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.chat.LeaveTableReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.com.sands.live_dealer.chat);
