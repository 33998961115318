import {
	ISoundManagerOptions,
	PixiSoundAdapter,
	SoundEffects,
	SoundManager,
} from '@sandsb2b/areax-client-library/dist/media';
import { WagerGridPositions, WagerName } from '../Constants';

const soundAdapter = new PixiSoundAdapter();
const manager = new SoundManager(soundAdapter);

const opts: ISoundManagerOptions = { assetBaseUrl: '/sound' };
const init = manager.initialize(opts);

// TODO: Only load sounds if they are needed. Don't load them here in a helper.
init.then(() => {
	manager.addMultiple([
		// ---- Bet Low (when a wager position has no wager/chip on it) ---------------------------------------------------

		// COL #1: Far-left
		{
			key: 'core_bet_low_COL1',
			url: 'core_bet_low.mp3',
			volume: 1.0,
			effects: [{ key: SoundEffects.STEREO_POSITION, options: { position: -0.9 } }],
		},
		// COL #2: Mid-left
		{
			key: 'core_bet_low_COL2',
			url: 'core_bet_low.mp3',
			volume: 0.9,
			effects: [{ key: SoundEffects.STEREO_POSITION, options: { position: -0.7 } }],
		},
		// COL #3: Center
		{ key: 'core_bet_low_COL3', url: 'core_bet_low.mp3', volume: 0.8 },
		// COL #4: Mid-right
		{
			key: 'core_bet_low_COL4',
			url: 'core_bet_low.mp3',
			volume: 0.9,
			effects: [{ key: SoundEffects.STEREO_POSITION, options: { position: 0.7 } }],
		},
		// COL #5: Far-right
		{
			key: 'core_bet_low_COL5',
			url: 'core_bet_low.mp3',
			volume: 1.0,
			effects: [{ key: SoundEffects.STEREO_POSITION, options: { position: 0.9 } }],
		},

		// ---- Bet Additional (when a wager position already has a wager/chip on it) -------------------------------------

		// COL #1: Far-left
		{
			key: 'core_bet_additional_COL1',
			url: 'core_bet_additional.mp3',
			volume: 1.0,
			effects: [{ key: SoundEffects.STEREO_POSITION, options: { position: -0.9 } }],
		},
		// COL #2: Mid-left
		{
			key: 'core_bet_additional_COL2',
			url: 'core_bet_additional.mp3',
			volume: 0.9,
			effects: [{ key: SoundEffects.STEREO_POSITION, options: { position: -0.7 } }],
		},
		// COL #3: Center
		{ key: 'core_bet_additional_COL3', url: 'core_bet_additional.mp3', volume: 0.8 },
		// COL #4: Mid-right
		{
			key: 'core_bet_additional_COL4',
			url: 'core_bet_additional.mp3',
			volume: 0.9,
			effects: [{ key: SoundEffects.STEREO_POSITION, options: { position: 0.7 } }],
		},
		// COL #5: Far-right
		{
			key: 'core_bet_additional_COL5',
			url: 'core_bet_additional.mp3',
			volume: 1.0,
			effects: [{ key: SoundEffects.STEREO_POSITION, options: { position: 0.9 } }],
		},

		// ---- Other -----------------------------------------------------------------------------------------------------

		// Played when a chip is removed from the table
		{ key: 'chip_remove', url: 'core_bet_cancelDisappear.mp3' },

		// Played when the dealer deals a card and the player sees it being placed
		{ key: 'card_flip', url: 'core_bet_cardFlip.mp3' },

		// Played when the player opens the chip selector
		{ key: 'chip_selector_open', url: 'core_chipSelector-open.mp3' },

		// Played when the player closes the chip selector
		{ key: 'chip_selector_close', url: 'core_chipSelector-close.mp3' },

		// Played as the win animation plays for a Win or Big Win at the end of the round
		{ key: 'big_win', url: 'core_reso_win-big.mp3' },
	]);

	manager.load();
});

const getWagerSpacialSoundPosition = (wagerName: WagerName): number => {
	const wagerPos = WagerGridPositions.get(wagerName) ?? null;
	if (wagerPos == null) {
		return 3;
	}

	return wagerPos.x;
};

const getPlaceWagerSoundKey = (wagerName: WagerName, currentAmt: number): string => {
	const prefix = currentAmt <= 0 ? 'core_bet_low' : 'core_bet_additional';
	const pos = getWagerSpacialSoundPosition(wagerName);

	return `${prefix}_COL${pos}`;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { manager as default };
export { manager as SoundManager };
export { getWagerSpacialSoundPosition, getPlaceWagerSoundKey };

export type { IPlayMethodOptions } from '@sandsb2b/areax-client-library/dist/media';
