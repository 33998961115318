import { DEFAULT_CURRENCY_CODE } from 'helpers/currency';
import { Service } from '../../client/core/Service';
import { IServiceOptions } from '../../client/core/types/service';
import { SpokeClient } from '../../client/rpc/clients/spoke';
import { NewPlayReply, TestDepositMoneyReply } from '../../client/rpc/replies/spoke';
import { NewPlayRequest, TestDepositMoneyRequest } from '../../client/rpc/requests/spoke';
import { IDevService } from './types';

/**
 * Service methods for development and testing purposes.
 * TODO: Have this code removed during the build process
 */
class DevService extends Service<SpokeClient> implements IDevService {
	constructor(url: string, opts?: IServiceOptions) {
		super(url, opts);
		this.client = this.createUnaryClient(SpokeClient, opts?.grpcOptions);
	}

	public newPlay = (tableId: string) => {
		const request = new NewPlayRequest();
		request.setTableId(tableId);

		return this.unary<typeof request, NewPlayReply>('newPlay', request);
	};

	public testDepositMoney = (amount: number, currencyCode?: string) => {
		currencyCode = currencyCode || DEFAULT_CURRENCY_CODE;

		const request = new TestDepositMoneyRequest();
		request.setAmount(amount);
		request.setCurrency(currencyCode);

		return this.unary<typeof request, TestDepositMoneyReply>('testDepositMoney', request);
	};
}

export { DevService as default };
export { DevService };
