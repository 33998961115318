import { getDealerStores as getStores } from '../store';
import { debug } from './debug';

let _currentPlayId: number = 0;

interface ISetPlayOpts {
	startStream?: Maybe<boolean>;
}

const setActiveDealerPlay = async (playId: number, opts?: Maybe<ISetPlayOpts>): Promise<void> => {
	if (playId === _currentPlayId) {
		return;
	}

	debug.info('Props:', 'setActiveDealerPlay', { playId, opts });

	await setDealerPlay(playId, opts);
};

const setDealerPlay = async (playId: number, opts?: Maybe<ISetPlayOpts>): Promise<void> => {
	const { dealerTableStore, dealerPlayStore, dealerTableHistoryStore } = getStores();

	// Unsetting the play
	if (playId === 0) {
		_currentPlayId = 0;
		dealerPlayStore.clear();
		dealerTableHistoryStore.clear();
		return;
	}

	// Populate the play store (if necessary)
	if (dealerPlayStore.playId !== playId) {
		// debug.info(`Attempting to populate dealer play store for play ID '${playId}'`, 'setDealerPlay');

		await dealerPlayStore.populate(playId);

		if (!dealerPlayStore.isPopulated || dealerPlayStore.playId !== playId) {
			debug.warn(`Unable to populate dealer play store using play ID '${playId}'`, 'setDealerPlay');

			_currentPlayId = 0;
			dealerPlayStore.clear();
			return;
		}
	}

	if (dealerTableHistoryStore.playId !== playId) {
		const tableId = dealerTableStore.tableId;

		// debug.info(`Attempting to populate dealer table history using table ID '${tableId}' and play ID ${playId}`, 'setDealerPlay');

		await dealerTableHistoryStore.populate(tableId, playId);

		if (!dealerTableHistoryStore.isPopulated || dealerTableHistoryStore.playId !== playId) {
			debug.warn(
				`Unable to populate dealer table history using table ID '${tableId}' and play ID ${playId}`,
				'setDealerPlay'
			);

			dealerTableHistoryStore.clear();
			return;
		}
	}

	_currentPlayId = dealerPlayStore.playId;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { setActiveDealerPlay };
