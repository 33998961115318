/**********************************************************************************************************************
 * Initialization and registration of the various RPC dealer streams with the stream manager.
 *********************************************************************************************************************/
import { Config } from 'helpers/config';
import { DebugLogger } from 'helpers/debug';
import { DealerStreamKey as StreamKey } from './constants';
import { HubDealerHudData, IStreamOptions, TableData, TableSeatsPlayData } from './lib-rpc';
import { HubDealerHudDataStream, TableDataStream, TableSeatsPlayDataStream } from './lib-rpc';
import { streamManager } from './lib-rpc';
import { getSession } from './session';
import { StreamConstructor, ValidStreamType } from './types';

const debug = new DebugLogger('Streams.Dealer');

const newStream = <StreamType>(
	Stream: StreamConstructor<StreamType>,
	url?: Maybe<string>,
	opts?: Maybe<IStreamOptions>
): StreamType => {
	opts = { ...(opts ?? {}) };

	url = url ?? '';
	if (url === '') {
		throw new Error(`Cannot create stream: Empty stream URL`);
	}

	opts.session = opts.session ?? getSession();

	return new Stream(url, opts);
};

const newDealerTableSeatsPlayStream = (
	props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IStreamOptions> }>
): TableSeatsPlayDataStream => {
	props = props ?? {};
	const url = props.url || Config.serverURI;

	return newStream<TableSeatsPlayDataStream>(TableSeatsPlayDataStream, url, props.opts);
};

const newDealerTableStream = (
	props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IStreamOptions> }>
): TableDataStream => {
	props = props ?? {};
	const url = props.url || Config.serverURI;

	return newStream<TableDataStream>(TableDataStream, url, props.opts);
};

const newDealerHudStream = (
	props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IStreamOptions> }>
): HubDealerHudDataStream => {
	props = props ?? {};
	const url = props.url || Config.backOfficeServerURI;

	return newStream<HubDealerHudDataStream>(HubDealerHudDataStream, url, props.opts);
};

const registerStream = (streamKey: string, stream: ValidStreamType) => {
	if (streamKey === '') {
		throw new Error(`Stream key must be specified`);
	}

	if (streamManager.hasStream(streamKey)) {
		streamManager.unregisterStream(streamKey);
	}

	streamManager.registerStream(streamKey, stream);
};

const registerDealerTableStream = (stream: TableDataStream, streamKey?: string) => {
	streamKey = streamKey || StreamKey.DealerTableStream;
	registerStream(streamKey, stream);

	const prefix = `DealerTableStream('${streamKey}')`;

	// Debug output subscription
	streamManager.subscribe<TableData>(streamKey, {
		onStart: () => debug.info('Stream started', `${prefix}.onStart`),
		onStop: (reason?: string) => debug.info('Stream stopped:', `${prefix}.onStop`, { reason: reason }),
		onData: (data: TableData) => debug.info('Stream data:', `${prefix}.onData`, data),
		onError: (error) => debug.info('Stream error:', `${prefix}.onError`, error),
	});
};

const registerDealerTableSeatsPlayStream = (stream: TableSeatsPlayDataStream, streamKey?: string) => {
	streamKey = streamKey || StreamKey.DealerTableSeatsPlayStream;
	registerStream(streamKey, stream);

	const prefix = `DealerTableSeatsPlayStream('${streamKey}')`;

	// Debug output subscription
	streamManager.subscribe<TableSeatsPlayData>(streamKey, {
		onStart: () => debug.info('Stream started', `${prefix}.onStart`),
		onStop: (reason?: string) => debug.info('Stream stopped:', `${prefix}.onStop`, { reason: reason }),
		onData: (data: TableSeatsPlayData) => debug.info('Stream data:', `${prefix}.onData`, data),
		onError: (error) => debug.info('Stream error:', `${prefix}.onError`, error),
	});
};

const registerDealerHudStream = (stream: HubDealerHudDataStream, streamKey?: string) => {
	streamKey = streamKey || StreamKey.DealerHudStream;
	registerStream(streamKey, stream);

	const prefix = `DealerHudStream('${streamKey}')`;

	// Debug output subscription
	streamManager.subscribe<HubDealerHudData>(streamKey, {
		onStart: () => debug.info('Stream started', `${prefix}.onStart`),
		onStop: (reason?: string) => debug.info('Stream stopped:', `${prefix}.onStop`, { reason: reason }),
		onData: (data: HubDealerHudData) => debug.info('Stream data:', `${prefix}.onData`, data),
		onError: (error) => debug.info('Stream error:', `${prefix}.onError`, error),
	});
};

// ---- Stream instances ----------------------------------------------------------------------------------------------

let _dealerTableStream: TableDataStream;
let _dealerTableSeatsPlayStream: TableSeatsPlayDataStream;
let _dealerHudStream: HubDealerHudDataStream;

// ---- Initialize ----------------------------------------------------------------------------------------------------

let _isInitialized = false;

const makeStreams = () => {
	_dealerTableStream = newDealerTableStream();
	_dealerTableSeatsPlayStream = newDealerTableSeatsPlayStream();
	_dealerHudStream = newDealerHudStream();

	registerDealerTableStream(_dealerTableStream);
	registerDealerTableSeatsPlayStream(_dealerTableSeatsPlayStream);
	registerDealerHudStream(_dealerHudStream);
};

const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	makeStreams();

	_isInitialized = true;

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

// Note that we don't export the stream instances because we want to use the StreamManager everywhere to manage them
export { initialize as initDealerStreams };
