/**********************************************************************************************************************
 * Stores and manages Dealer HUD related data (from the HUB back-office).
 *
 * Note: This will be used as the base parent class for the same store inside of the game clients.
 *********************************************************************************************************************/
import {
	HubCompositeData,
	HubCompositeSpaceData,
	HubDealerHudData,
	HubPlayData,
	HubStepValueData,
	HubTableData,
} from '../client/rpc/types/hub';
import { IHubBackOfficeService } from '../client/service/types';
import { DataStore } from './DataStore';

class DealerHudStore extends DataStore<IHubBackOfficeService, HubDealerHudData> {
	/**
	 * @returns Dealer play data.
	 */
	public get play(): Nullable<HubPlayData> {
		return this.data?.play ?? null;
	}

	/**
	 * @returns Dealer table data.
	 */
	public get table(): Nullable<HubTableData> {
		return this.data?.table ?? null;
	}

	/**
	 * @returns The unique table ID.
	 */
	public get tableId(): string {
		return this.table?.id ?? '';
	}

	/**
	 * DEPRECATED. Alias for `tableId`.
	 */
	public get tableKey(): string {
		console.warn('[DEPRECATED] Use of `tableKey` is deprecated. Use `tableId` instead.');
		return this.tableId;
	}

	/**
	 * @returns The play ID.
	 */
	public get playId(): number {
		return this.play?.playId ?? 0;
	}

	/**
	 * @returns The composite play data.
	 */
	public get composite(): Nullable<HubCompositeData> {
		return this.play?.composite ?? null;
	}

	/**
	 * @returns The table's device key
	 */
	public get deviceKey(): string {
		return this.table?.deviceKey ?? '';
	}

	/**
	 * @returns The table state value.
	 */
	public get tableState(): string {
		return this.table?.state ?? '';
	}

	/**
	 * @returns The play state value.
	 */
	public get playState(): string {
		return this.play?.state?.substate ?? '';
	}

	/**
	 * @returns List of available spaces from the composite play data.
	 */
	public get spaces(): HubCompositeSpaceData[] {
		return this.composite?.spacesList ?? [];
	}

	/**
	 * @returns The timestamp the betting phase will end and automatically proceed.
	 */
	public get autoRunTime(): number {
		return this.play?.autoRunTime ?? -1;
	}

	/**
	 * @returns TODO: ???
	 */
	public get actionTimeoutSec(): number {
		return this.table?.actionTimeoutSec ?? 0;
	}

	/**
	 * @returns TODO: ???
	 */
	public get autoRunDelaySec(): number {
		return this.table?.autoRunDelaySec ?? 0;
	}

	/**
	 * @returns The dealer step values.
	 */
	public get dealer(): HubStepValueData[] {
		const spaces = this.spaces.slice();

		if (spaces.length === 0) {
			return [];
		}

		return spaces[0].valuesList ?? [];
	}

	/**
	 * @returns The player step values.
	 */
	public get player(): HubStepValueData[] {
		const spaces = this.spaces.slice();

		if (spaces.length < 2) {
			return [];
		}

		return spaces[1].valuesList ?? [];
	}

	/**
	 * @returns The next space/step number.
	 */
	public get nextStep(): number {
		const defaultVal = Number.MAX_SAFE_INTEGER;
		const spaces = this.spaces.slice();

		if (spaces.length === 0) {
			return defaultVal;
		}

		for (let si = 0, sl = spaces.length; si < sl; si++) {
			const space = spaces[si];
			const values = space.valuesList ?? [];

			for (let vi = 0, vl = values.length; vi < vl; vi++) {
				const val = values[vi];
				if (val.value === '??') {
					return val.stepNum;
				}
			}
		}

		return defaultVal;
	}

	/**
	 * @returns The current space/step number.
	 */
	public get activeSpace(): number {
		const defaultVal = 1;
		const spaces = this.spaces.slice();

		if (spaces.length === 0) {
			return defaultVal;
		}

		for (let si = 0, sl = spaces.length; si < sl; si++) {
			const space = spaces[si];
			const values = space.valuesList ?? [];

			for (let vi = 0, vl = values.length; vi < vl; vi++) {
				const val = values[vi];
				if (val.value === '??') {
					return space.seatNum;
				}
			}
		}

		return defaultVal;
	}

	/**
	 * Action. Clear the store.
	 */
	public clear(): void {
		super.clear();
	}

	// ---- Populate ----------------------------------------------------------------------------------------------------

	/**
	 * Action. Populates the store (via unary RPC service call) using the specified table ID.
	 */
	public async populate(tableId: string): Promise<boolean> {
		if (tableId === '') {
			this.debugError('A valid table ID must be specified', 'populate');
		}

		return super.populate(tableId);
	}

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(tableId: string): Promise<Nullable<HubDealerHudData>> {
		if (!this.service) {
			this.debugError('No service was specified', 'fetchPopulateData');
			return null;
		}

		tableId = tableId ?? '';
		if (tableId === '') {
			this.debugError('A valid table ID must be specified', 'fetchPopulateData');
		}

		return (await this.service.getHud(tableId)).data ?? null;
	}
}

export { DealerHudStore as default };
export { DealerHudStore };
