/**********************************************************************************************************************
 * Application entrypoint
 *   - Initializes the application
 *   - Renders the root-level React component
 **********************************************************************************************************************/
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { loadI18n } from 'i18n';
import { initServer } from 'server';
import { appAutoLoginFromConfig } from 'server/actions';
import { setupAllTracing } from 'helpers/tracing';
import 'helpers/window';
import { reportWebVitals } from './reportWebVitals';
import './index.css';

const AppLazy = lazy(() => import('./App'));

/**
 * Creates a React application facade and returns a method to render. Allows late/conditional rendering.
 */
const createReactApp = () => {
	return {
		// Renders the React application into the DOM
		render: () => {
			ReactDOM.render(
				<React.StrictMode>
					<Suspense fallback={<div>Loading...</div>}>
						<AppLazy />
					</Suspense>
				</React.StrictMode>,
				document.getElementById('root')
			);
		},
	};
};

/**
 * Pre-app render initialization. This should be used to do things before the React app runs for the first time.
 *
 * @returns A promise that should resolve before the application should be allowed to load.
 */
const init = async () => {
	// Setup the server layer (services, streams, stores, etc)
	initServer();

	// Setup tracing & monitoring
	setupAllTracing();

	// Web vitals
	reportWebVitals(console.log);

	// Load i18n language translation
	await loadI18n();

	// Attempt to login (using config and/or stored token)
	await appAutoLoginFromConfig();
};

// Initialize
init().then(() => {
	const app = createReactApp();
	app.render();
});
