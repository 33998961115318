const DEFAULT_SPOKE_SERVER_TEMPLATE = 'https://{{SPOKE}}.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_DEVICE_SERVER_TEMPLATE = 'https://hub-device-web.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_BACKOFFICE_SERVER_TEMPLATE = 'https://hub-backoffice.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_CHAT_SERVER_TEMPLATE = 'https://chat-web.{{DEPLOYMENT}}.{{SERVER}}';
const DEFAULT_SPOKE = 'spoke-a';
const DEFAULT_DEPLOYMENT = 'dev';
const DEFAULT_SERVER_DOMAIN = 'areax.app';

export {
	DEFAULT_SPOKE_SERVER_TEMPLATE,
	DEFAULT_DEVICE_SERVER_TEMPLATE,
	DEFAULT_BACKOFFICE_SERVER_TEMPLATE,
	DEFAULT_CHAT_SERVER_TEMPLATE,
	DEFAULT_SPOKE,
	DEFAULT_DEPLOYMENT,
	DEFAULT_SERVER_DOMAIN,
};
