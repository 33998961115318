import {
	ChatMessage,
	JoinTableReply,
	JoinTableRequest,
	LeaveTableReply,
	LeaveTableRequest,
	SendMessageReply,
} from 'grpc/chat/chat_service_pb';
import { ChatServiceClient } from 'rpc/clients/chat';
import { JoinTableReplyData, LeaveTableReplyData, SendMessageReplyData } from 'types';
import { Service } from '../../client/core/Service';
import { IServiceOptions } from '../../client/core/types/service';
import { IChatService } from './types';

class ChatService extends Service<ChatServiceClient> implements IChatService {
	constructor(url: string, opts?: IServiceOptions) {
		super(url, opts);
		this.client = this.createUnaryClient(ChatServiceClient, opts?.grpcOptions);
	}

	public joinTable = async (userId: string, tableId: string) => {
		const request = new JoinTableRequest();
		request.setUserId(userId);
		request.setTableId(tableId);

		const promise = this.unaryAsObject<typeof request, JoinTableReply, JoinTableReplyData>('joinTable', request);
		return promise;
	};

	public leaveTable = async (userId: string, tableId: string) => {
		const request = new LeaveTableRequest();
		request.setUserId(userId);
		request.setTableId(tableId);

		const promise = this.unaryAsObject<typeof request, LeaveTableReply, LeaveTableReplyData>('leaveTable', request);
		return promise;
	};

	public sendMessageToTable = async (sender: string, tableId: string, message: string) => {
		const request = new ChatMessage();
		request.setSenderId(sender);
		request.setRecipientId(tableId);
		request.setBody(message);
		request.setTime(Math.floor(Date.now() / 1000));
		request.setIsDirect(false);

		const promise = this.unaryAsObject<typeof request, SendMessageReply, SendMessageReplyData>('sendMessage', request);
		return promise;
	};

	public sendDirectMessage = async (sender: string, recipient: string, message: string) => {
		const request = new ChatMessage();
		request.setSenderId(sender);
		request.setRecipientId(recipient);
		request.setBody(message);
		request.setTime(Math.floor(Date.now() / 1000));
		request.setIsDirect(true);

		const promise = this.unaryAsObject<typeof request, SendMessageReply, SendMessageReplyData>('sendMessage', request);
		return promise;
	};
}

export { ChatService as default };
export { ChatService };
