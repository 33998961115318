/*********************************************************************************************************************
 * This module exports a config adapter instance that is designed to read DD related config from process.env & config.
 *********************************************************************************************************************/
import { ConfigAdapterBase } from '../helpers/config/ConfigAdapterBase';

enum ConfigVars {
	DD_RUM_ENABLED = 'DD_RUM_ENABLED',
	DD_BROWSER_LOGS_ENABLED = 'DD_BROWSER_LOGS_ENABLED',
	DD_TRACE_ENABLED = 'DD_TRACE_ENABLED',
	DD_APPLICATION_ID = 'DD_APPLICATION_ID',
	DD_CLIENT_TOKEN = 'DD_CLIENT_TOKEN',
	DD_ENV = 'DD_ENV',
	DD_SERVICE = 'DD_SERVICE',
	DD_SITE = 'DD_SITE',
	DD_APP_VERSION = 'DD_APP_VERSION',
}

class ConfigAdapter extends ConfigAdapterBase {
	public get enableRum(): boolean {
		return this.getBoolVar(ConfigVars.DD_RUM_ENABLED);
	}

	public get enableBrowserLogs(): boolean {
		return this.getBoolVar(ConfigVars.DD_BROWSER_LOGS_ENABLED);
	}

	public get enableTrace(): boolean {
		return this.getBoolVar(ConfigVars.DD_TRACE_ENABLED);
	}

	public get applicationId(): string {
		return this.getStringVar(ConfigVars.DD_APPLICATION_ID);
	}

	public get clientToken(): string {
		return this.getStringVar(ConfigVars.DD_CLIENT_TOKEN);
	}

	public get environment(): string {
		return this.getStringVar(ConfigVars.DD_ENV);
	}

	public get serviceName(): string {
		return this.getStringVar(ConfigVars.DD_SERVICE);
	}

	public get site(): string {
		return this.getStringVar(ConfigVars.DD_SITE);
	}

	public get appVersion(): string {
		return this.getStringVar(ConfigVars.DD_APP_VERSION);
	}

	public get resolvedProps(): PlainObject {
		const allProps: PlainObject = {
			[ConfigVars.DD_RUM_ENABLED]: this.enableRum,
			[ConfigVars.DD_BROWSER_LOGS_ENABLED]: this.enableBrowserLogs,
			[ConfigVars.DD_TRACE_ENABLED]: this.enableTrace,
			[ConfigVars.DD_SITE]: this.site,
			[ConfigVars.DD_ENV]: this.environment,
			[ConfigVars.DD_SERVICE]: this.serviceName,
			[ConfigVars.DD_APP_VERSION]: this.appVersion,
			[ConfigVars.DD_APPLICATION_ID]: this.applicationId,
			[ConfigVars.DD_CLIENT_TOKEN]: this.clientToken,
		};

		Object.keys(allProps).forEach((k) => {
			const val = allProps[k] ?? null;
			if (val === '' || val == null) {
				delete allProps[k];
			}
		});

		return allProps;
	}
}

const instance = new ConfigAdapter();

export { instance as default };
export { instance as TracerConfig, ConfigAdapter as TracerConfigAdapter };
