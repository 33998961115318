import debounce from 'lodash/debounce';
import { Logger as DebugLogger } from '../helpers/debug';
import { setPreciseInterval } from '../helpers/preciseInterval';
import {
	EffectOpts,
	IPlayMethodOptions,
	ISoundAdapter,
	ISoundAsset,
	ISoundAssetEffect,
	IStereoPositionEffectOpts,
	IStereoTransitionEffectOpts,
	SoundAssetEffects,
} from './types';
import {
	PixiSound,
	PixiSoundFilter,
	PixiSoundFilters,
	PixiSoundLibrary,
	PixiSoundOptions,
	PixiSoundPlayOptions,
	PixiSoundType,
} from './types.pixi_sound';
import { SoundEffectDefaultOptions, SoundEffects } from './utils';

interface IPixiSoundAdapterOptions {
	// Optional injected library instance to use (instead of creating a new one).
	library?: Nullable<PixiSoundLibrary>;
}

// Debug logging
const debug = new DebugLogger('PixiSoundAdapter');

/**
 * Dynamic imports
 */
const importSoundLibrary = async () => (await import('@pixi/sound')).SoundLibrary;
const importSound = async () => (await import('@pixi/sound')).Sound;
const importFilters = async () => (await import('@pixi/sound')).filters;

/**
 * Imports and creates a library instance.
 */
const createSoundLibrary = async (): Promise<PixiSoundLibrary> => {
	const Library = await importSoundLibrary();

	return new Library();
};

/**
 * @returns Default options to use when creating the sound adapter.
 */
const getDefaultOptions = (): IPixiSoundAdapterOptions => {
	return {
		library: null,
	};
};

class PixiSoundAdapter implements ISoundAdapter {
	// Options used when configuring this sound adapter
	protected options: IPixiSoundAdapterOptions;

	// PixiSoundLibrary used to manage & play the sounds for the adapter
	protected library: Nullable<PixiSoundLibrary> = null;

	// Used to create new sounds
	protected soundCreator: Nullable<PixiSoundType> = null;

	// Used to create new filters
	protected filters: Nullable<PixiSoundFilters> = null;

	// TRUE when this sound adapter has been initialized
	protected isInitialized: boolean = false;

	/**
	 * CONSTRUCTOR.
	 *
	 * @param  opts  Optional. Options to use. Will default if not provided.
	 */
	public constructor(opts?: Maybe<IPixiSoundAdapterOptions>, init: boolean = true) {
		this.options = { ...getDefaultOptions(), ...opts };

		init && this.initialize(this.options);
	}

	/**
	 * Initializes the sound adapter.
	 *
	 * @param  opts  Optional. Options to use. Will default if not provided.
	 */
	public initialize = async (opts?: Maybe<IPixiSoundAdapterOptions>): Promise<boolean> => {
		if (this.isInitialized) {
			return false;
		}

		this.options = { ...getDefaultOptions(), ...opts };

		await this.initLibrary(this.options.library);
		await this.initSoundCreator();
		await this.initFilters();

		this.isInitialized = true;

		return true;
	};

	/**
	 * Attempts to add a sound to the adapter.
	 */
	public add = (soundKey: string, sound: PixiSound | PixiSoundOptions, opts?: ISoundAsset): boolean => {
		if (!this.library) {
			debug.error(`Library is not available`, 'add');
			return false;
		}

		if (this.has(soundKey)) {
			debug.warn(`Sound with key '${soundKey}' is already registered`, 'add');
			return false;
		}

		let newSound: PixiSound;

		// PixiSound instance, use as-is
		if (this.isPixiSound(sound)) {
			newSound = sound as PixiSound;
		}
		// Create the PixiSound instance
		else {
			const opts = sound as PixiSoundOptions;
			newSound = this.makeSound(opts) as PixiSound;
		}

		newSound = this.setupPixiSound(newSound, opts);

		this.library.add(soundKey, newSound);

		return true;
	};

	isPixiSound = (sound: PixiSound | PixiSoundOptions): boolean => {
		return 'media' in sound;
	};

	protected setupPixiSound = (sound: PixiSound, opts?: Maybe<ISoundAsset>): PixiSound => {
		const { volume = null, effects = [] } = opts || {};

		if (volume != null) {
			sound.volume = volume;
		}

		if (effects.length > 0) {
			const existingFilters = (sound.filters ?? []).slice();
			const filters = this.createFiltersFromEffects(effects, sound).concat(existingFilters);
			sound.filters = filters;
		}

		return sound;
	};

	/**
	 * Attempts to remove a sound from the adapter.
	 */
	public remove(soundKey: string): boolean {
		if (!this.library) {
			debug.error(`Library is not available`, 'remove');
			return false;
		}

		if (!this.has(soundKey)) {
			debug.warn(`Sound with key '${soundKey}' is not registered`, 'remove');
			return false;
		}

		this.library.remove(soundKey);

		return true;
	}

	/**
	 * @param   soundKey  Unique sound key.
	 * @returns TRUE if the specified sound is already registered with this adapter.
	 */
	public has = (soundKey: string): boolean => {
		return !!(soundKey !== '' && this.library && this.library.exists(soundKey));
	};

	/**
	 * @param   soundKey  Unique sound key.
	 * @returns The sound that is registered with this adapter, or NULL
	 */
	public get = (soundKey: string): Nullable<PixiSound> => {
		return (soundKey !== '' && this.library ? this.library.find(soundKey) : null) ?? null;
	};

	/**
	 * Play the specified sound with optional volume and effects.
	 *
	 * @param soundKey The unique key assigned to the sound.
	 */
	public play = (soundKey: string, opts?: Maybe<IPlayMethodOptions>): boolean => {
		if (!this.has(soundKey)) {
			debug.error(`Specified sound '${soundKey}' is not registered`, 'play');
			return false;
		}

		const sound = this.get(soundKey);
		const volume = this.resolveVolume(opts?.volume, sound?.volume);
		const effects = opts?.effects ?? [];

		let filters: PixiSoundFilter[] = [];

		if (effects.length > 0) {
			filters = sound ? this.createFiltersFromEffects(effects, sound) : [];
		}

		const playOpts: PixiSoundPlayOptions = {
			volume: volume,
			filters: filters.length > 0 ? filters : undefined,
		};

		return this.playSound(soundKey, playOpts);
	};

	/**
	 * Stop playing the specified sound.
	 *
	 * @param soundKey The unique key assigned to the sound.
	 */
	public stop(soundKey: string): boolean {
		if (!this.library) {
			debug.error(`Library is not available`, 'stop');
			return false;
		}

		if (!this.has(soundKey)) {
			debug.error(`Specified sound '${soundKey}' is not registered`, 'stop');
			return false;
		}

		this.library.stop(soundKey);

		return true;
	}

	protected playSound = (soundKey: string, opts?: Maybe<PixiSoundPlayOptions>): boolean => {
		if (!this.library) {
			debug.error(`Library is not available`, 'playSound');
			return false;
		}

		const sound: Nullable<PixiSound> = this.get(soundKey);

		if (!sound) {
			debug.error(`Specified sound '${soundKey}' is not registered`, 'playSound');
			return false;
		}

		this.library.play(soundKey, opts ?? undefined);
		this.preventSoundBacklog(sound);

		return true;
	};

	/**
	 * Workaround: If autoplay fails, whenever the player *eventually* clicks the screen, the queued sounds are played.
	 * This forces them to gracefully stop after their duration ends
	 */
	protected preventSoundBacklog = (sound: PixiSound) => {
		const stopSound = () => {
			sound.stop();
		};

		const stopSoundAfterComplete = debounce(stopSound, sound.duration * 1000, { leading: false, trailing: true });

		stopSoundAfterComplete();
	};

	protected createFiltersFromEffects = (effects: SoundAssetEffects, sound: PixiSound): PixiSoundFilter[] => {
		if (effects.length === 0) {
			return [] as PixiSoundFilter[];
		}

		const filters: PixiSoundFilter[] = [];

		effects.forEach((eff: ISoundAssetEffect) => {
			const filter = this.filterFromEffect(eff, sound);
			filter && filters.push(filter);
		});

		return filters;
	};

	protected filterFromEffect = (effect: ISoundAssetEffect, sound: PixiSound): Nullable<PixiSoundFilter> => {
		if (this.filters == null) {
			return null;
		}

		let effectKey = effect.key;
		const effectOpts: EffectOpts = { ...SoundEffectDefaultOptions.get(effectKey), ...(effect.options || {}) };

		if ([SoundEffects.FAR_LEFT, SoundEffects.FAR_RIGHT].includes(effectKey)) {
			effectKey = SoundEffects.STEREO_POSITION;
		} else if (
			[
				SoundEffects.PAN_LEFT_RIGHT,
				SoundEffects.PAN_RIGHT_LEFT,
				SoundEffects.PAN_CENTER_LEFT,
				SoundEffects.PAN_CENTER_RIGHT,
			].includes(effectKey)
		) {
			effectKey = SoundEffects.STEREO_TRANSITION;
		}

		if (effectKey === SoundEffects.STEREO_POSITION) {
			const opts = effectOpts as IStereoPositionEffectOpts;
			const pan = this.resolvePosition(opts.position);

			return new this.filters.StereoFilter(pan);
		}

		if (effectKey === SoundEffects.STEREO_TRANSITION) {
			const opts = effectOpts as IStereoTransitionEffectOpts;
			const panStart = this.resolvePosition(opts.startPosition);
			const panEnd = this.resolvePosition(opts.endPosition);

			const soundDurationMs = sound.duration * 1000;
			let durationMs = opts.durationMs ?? 0;
			durationMs = durationMs > 0 ? Math.min(durationMs, soundDurationMs) : soundDurationMs;

			if (panStart === panEnd || durationMs <= 0) {
				return null;
			}

			const panRangeSize = Math.abs(panEnd - panStart) * 100;
			const intervalMs = durationMs / panRangeSize;

			const filter = new this.filters.StereoFilter(panStart);

			setPreciseInterval(intervalMs, () => {
				filter.pan = filter.pan + 0.01;
			});

			return filter;
		}

		return null;
	};

	protected resolvePosition = (position: Maybe<number>, defaultPosition?: Maybe<number>) => {
		defaultPosition = defaultPosition ?? 0;
		position = position ?? defaultPosition;
		position = position > 1.0 ? 1.0 : position;
		position = position < -1.0 ? -1.0 : position;

		return position;
	};

	protected resolveVolume = (volume: Maybe<number>, defaultVolume?: Maybe<number>) => {
		defaultVolume = defaultVolume ?? 1.0;
		volume = volume ?? defaultVolume;
		volume = volume > 1.0 ? 1.0 : volume;
		volume = volume < 0 ? 0 : volume;

		return volume;
	};

	protected makeSound = (opts: PixiSoundOptions): Nullable<PixiSound> => {
		if (!this.soundCreator) {
			return null;
		}

		return this.soundCreator.from(opts);
	};

	/**
	 * Setup the SoundLibrary used to manage sound assets.
	 *
	 * @param library Optional. Injected SoundLibrary instance to use.
	 */
	protected initLibrary = async (library?: Maybe<PixiSoundLibrary>) => {
		try {
			if (library) {
				this.library = library;
			} else {
				this.library = await createSoundLibrary();
			}
		} catch (e) {
			const err = e as Error;
			debug.error('Error creating Pixi.SoundLibrary:', 'initLibrary', err);
		}
	};

	/**
	 * Initializes the sound creator used to make new sounds.
	 */
	protected initSoundCreator = async () => {
		try {
			this.soundCreator = await importSound();
		} catch (e) {
			const err = e as Error;
			debug.error('Error importing Pixi.Sound:', 'initSoundCreator', err);
		}
	};

	/**
	 * Initializes the filters used to make new filters.
	 */
	protected initFilters = async () => {
		try {
			this.filters = await importFilters();
		} catch (e) {
			const err = e as Error;
			debug.error('Error importing filters:', 'initFilters', err);
		}
	};
}

export { PixiSoundAdapter as default };
export { PixiSoundAdapter };
export type { IPixiSoundAdapterOptions };
