/**********************************************************************************************************************
 * Stores and manages table history
 *********************************************************************************************************************/
import {
	BaccaratHistoryData,
	DragonTigerHistoryData,
	PlaySummaryData,
	TableHistoryData,
} from '../client/rpc/types/spoke';
import { ITableService } from '../client/service/types';
import { DataStore } from './DataStore';

class TableHistoryStore extends DataStore<ITableService, TableHistoryData> {
	/**
	 * Current Play ID
	 */
	protected _playId: number = 0;

	/**
	 * @returns The unique table ID.
	 */
	public get tableId(): string {
		return this.data?.tableId ?? '';
	}

	/**
	 * Baccarat specific history
	 */
	public get baccaratHistory(): Nullable<BaccaratHistoryData> {
		return this.data?.baccaratHistory ?? null;
	}

	/**
	 * Dragon Tiger specific history.
	 */
	public get dragonTigerHistory(): Nullable<DragonTigerHistoryData> {
		return this.data?.dragonTigerHistory ?? null;
	}

	/**
	 * Array of play summaries.
	 */
	public get historyList(): PlaySummaryData[] {
		return this.data?.historyList ?? [];
	}

	/**
	 * Current Play ID - used as reference-only during populate
	 */
	public get playId(): number {
		return this._playId;
	}

	public clear(): void {
		super.clear();
	}

	// ---- Populate ----------------------------------------------------------------------------------------------------

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected onAfterPopulate(tableId: string, playId?: number) {
		this.debugInfo('Called with params:', 'onAfterPopulate', { tableId, playId });
	}

	/**
	 * Action. Populates the store (via unary RPC service call) using the specified table ID.
	 */
	public async populate(tableId: string, playId?: number): Promise<boolean> {
		if (tableId === '') {
			this.debugError('A valid table ID must be specified', 'populate');
		}

		if (playId != null) {
			this._playId = playId;
		}

		const success = await super.populate(tableId);

		if (success && this.data != null) {
			this.onAfterPopulate(tableId, playId);
		}

		return success;
	}

	/**
	 * Gets data from the associated service in order to populate the store.
	 *
	 * @returns The underlying data needed to populate this store.
	 */
	protected async fetchPopulateData(tableId: string): Promise<Nullable<TableHistoryData>> {
		if (!this.service) {
			this.debugError('No service was specified', 'fetchPopulateData');
			return null;
		}

		return (await this.service.getTableHistory(tableId)).data ?? null;
	}
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { TableHistoryStore as default };
export { TableHistoryStore };
