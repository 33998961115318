// source: back_office.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var hub_game_pb = require('./hub_game_pb.js');
goog.object.extend(proto, hub_game_pb);
var hub_error_pb = require('./hub_error_pb.js');
goog.object.extend(proto, hub_error_pb);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.DealerHudReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.DealerHudRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.OpenCloseTableReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.OpenCloseTableRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.PausePlayReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.PausePlayRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.UnpausePlayReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.UnpausePlayRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.displayName = 'proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.displayName = 'proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.displayName = 'proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.displayName = 'proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.DealerHudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.DealerHudRequest.displayName = 'proto.com.sands.live_dealer.back_office.DealerHudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.DealerHudReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.DealerHudReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.DealerHudReply.displayName = 'proto.com.sands.live_dealer.back_office.DealerHudReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.PausePlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.PausePlayRequest.displayName = 'proto.com.sands.live_dealer.back_office.PausePlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.PausePlayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.PausePlayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.PausePlayReply.displayName = 'proto.com.sands.live_dealer.back_office.PausePlayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.UnpausePlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.UnpausePlayRequest.displayName = 'proto.com.sands.live_dealer.back_office.UnpausePlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.UnpausePlayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.UnpausePlayReply.displayName = 'proto.com.sands.live_dealer.back_office.UnpausePlayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.displayName = 'proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.displayName = 'proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.OpenCloseTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.displayName = 'proto.com.sands.live_dealer.back_office.OpenCloseTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.back_office.OpenCloseTableReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.back_office.OpenCloseTableReply.displayName = 'proto.com.sands.live_dealer.back_office.OpenCloseTableReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rulesId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest;
  return proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string rules_id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesRequest.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && hub_game_pb.Rules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply;
  return proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Rules;
      reader.readMessage(value,hub_game_pb.Rules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hub_game_pb.Rules.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional com.sands.live_dealer.hub.Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply} returns this
*/
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional com.sands.live_dealer.hub.Rules rules = 3;
 * @return {?proto.com.sands.live_dealer.hub.Rules}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.getRules = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Rules} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.Rules, 3));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Rules|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply} returns this
*/
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesReply.prototype.hasRules = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rulesIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest;
  return proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRulesIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRulesIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string rules_ids = 1;
 * @return {!Array<string>}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.prototype.getRulesIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.prototype.setRulesIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.prototype.addRulesIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetRequest.prototype.clearRulesIdsList = function() {
  return this.setRulesIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    hub_game_pb.Rules.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply;
  return proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Rules;
      reader.readMessage(value,hub_game_pb.Rules.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hub_game_pb.Rules.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional com.sands.live_dealer.hub.Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply} returns this
*/
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated com.sands.live_dealer.hub.Rules rules = 3;
 * @return {!Array<!proto.com.sands.live_dealer.hub.Rules>}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.Rules>} */ (
    jspb.Message.getRepeatedWrapperField(this, hub_game_pb.Rules, 3));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.Rules>} value
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply} returns this
*/
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.Rules=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.Rules}
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.sands.live_dealer.hub.Rules, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply} returns this
 */
proto.com.sands.live_dealer.back_office.BackOfficeGetRulesSetReply.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.DealerHudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.DealerHudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudRequest}
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.DealerHudRequest;
  return proto.com.sands.live_dealer.back_office.DealerHudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.DealerHudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudRequest}
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.DealerHudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.DealerHudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudRequest} returns this
 */
proto.com.sands.live_dealer.back_office.DealerHudRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.DealerHudReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.DealerHudReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    table: (f = msg.getTable()) && hub_game_pb.Table.toObject(includeInstance, f),
    play: (f = msg.getPlay()) && hub_game_pb.Play.toObject(includeInstance, f),
    serverTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.DealerHudReply;
  return proto.com.sands.live_dealer.back_office.DealerHudReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.DealerHudReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Table;
      reader.readMessage(value,hub_game_pb.Table.deserializeBinaryFromReader);
      msg.setTable(value);
      break;
    case 4:
      var value = new hub_game_pb.Play;
      reader.readMessage(value,hub_game_pb.Play.deserializeBinaryFromReader);
      msg.setPlay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.DealerHudReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.DealerHudReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getTable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hub_game_pb.Table.serializeBinaryToWriter
    );
  }
  f = message.getPlay();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      hub_game_pb.Play.serializeBinaryToWriter
    );
  }
  f = message.getServerTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply} returns this
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional com.sands.live_dealer.hub.Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply} returns this
*/
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply} returns this
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional com.sands.live_dealer.hub.Table table = 3;
 * @return {?proto.com.sands.live_dealer.hub.Table}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.getTable = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Table} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.Table, 3));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Table|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply} returns this
*/
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.setTable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply} returns this
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.clearTable = function() {
  return this.setTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.hasTable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional com.sands.live_dealer.hub.Play play = 4;
 * @return {?proto.com.sands.live_dealer.hub.Play}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.getPlay = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Play} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.Play, 4));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Play|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply} returns this
*/
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.setPlay = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply} returns this
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.clearPlay = function() {
  return this.setPlay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.hasPlay = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 server_time = 5;
 * @return {number}
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.getServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.back_office.DealerHudReply} returns this
 */
proto.com.sands.live_dealer.back_office.DealerHudReply.prototype.setServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.PausePlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.PausePlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adminId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayRequest}
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.PausePlayRequest;
  return proto.com.sands.live_dealer.back_office.PausePlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.PausePlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayRequest}
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAdminId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.PausePlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.PausePlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdminId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 admin_id = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.prototype.getAdminId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayRequest} returns this
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.prototype.setAdminId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayRequest} returns this
 */
proto.com.sands.live_dealer.back_office.PausePlayRequest.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.PausePlayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.PausePlayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayReply}
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.PausePlayReply;
  return proto.com.sands.live_dealer.back_office.PausePlayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.PausePlayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayReply}
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.PausePlayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.PausePlayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayReply} returns this
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional com.sands.live_dealer.hub.Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayReply} returns this
*/
proto.com.sands.live_dealer.back_office.PausePlayReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.PausePlayReply} returns this
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.PausePlayReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.UnpausePlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.UnpausePlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adminId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayRequest}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.UnpausePlayRequest;
  return proto.com.sands.live_dealer.back_office.UnpausePlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.UnpausePlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayRequest}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAdminId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.UnpausePlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.UnpausePlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdminId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 admin_id = 1;
 * @return {number}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.prototype.getAdminId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayRequest} returns this
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.prototype.setAdminId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayRequest} returns this
 */
proto.com.sands.live_dealer.back_office.UnpausePlayRequest.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.UnpausePlayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.UnpausePlayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayReply}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.UnpausePlayReply;
  return proto.com.sands.live_dealer.back_office.UnpausePlayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.UnpausePlayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayReply}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.UnpausePlayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.UnpausePlayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayReply} returns this
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional com.sands.live_dealer.hub.Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayReply} returns this
*/
proto.com.sands.live_dealer.back_office.UnpausePlayReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.UnpausePlayReply} returns this
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.UnpausePlayReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest;
  return proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest} returns this
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest} returns this
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply;
  return proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply} returns this
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional com.sands.live_dealer.hub.Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply} returns this
*/
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply} returns this
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.UpdateDeviceTableReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.OpenCloseTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    open: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableRequest}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.OpenCloseTableRequest;
  return proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.OpenCloseTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableRequest}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.OpenCloseTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpen();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string table_id = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableRequest} returns this
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool open = 2;
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.prototype.getOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableRequest} returns this
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableRequest.prototype.setOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.back_office.OpenCloseTableReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.back_office.OpenCloseTableReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableReply}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.back_office.OpenCloseTableReply;
  return proto.com.sands.live_dealer.back_office.OpenCloseTableReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.back_office.OpenCloseTableReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableReply}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.back_office.OpenCloseTableReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.back_office.OpenCloseTableReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableReply} returns this
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional com.sands.live_dealer.hub.Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableReply} returns this
*/
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.back_office.OpenCloseTableReply} returns this
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.back_office.OpenCloseTableReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.com.sands.live_dealer.back_office);
