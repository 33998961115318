/**********************************************************************************************************************
 * Store-related utility methods.
 *********************************************************************************************************************/
import {
	getFavoriteBetsStore,
	getGameHistoryStore,
	getPlayStore,
	getRulesStore,
	getSettingsStore,
	getTableHistoryStore,
	getTableSeatsPlayStore,
	getTableStore,
	getUserStore,
} from './stores';
import {
	getDealerHudStore,
	getDealerPlayStore,
	getDealerRulesStore,
	getDealerTableHistoryStore,
	getDealerTableSeatsPlayStore,
	getDealerTableStore,
} from './stores.dealer';
import { getGameStore } from './stores.game';

const getDealerStores = () => ({
	dealerHudStore: getDealerHudStore(),
	dealerPlayStore: getDealerPlayStore(),
	dealerRulesStore: getDealerRulesStore(),
	dealerTableStore: getDealerTableStore(),
	dealerTableSeatsPlayStore: getDealerTableSeatsPlayStore(),
	dealerTableHistoryStore: getDealerTableHistoryStore(),
});

const getStores = () => ({
	favoriteBetsStore: getFavoriteBetsStore(),
	gameStore: getGameStore(),
	gameHistoryStore: getGameHistoryStore(),
	tableHistoryStore: getTableHistoryStore(),
	playStore: getPlayStore(),
	rulesStore: getRulesStore(),
	settingsStore: getSettingsStore(),
	tableStore: getTableStore(),
	tableSeatsPlayStore: getTableSeatsPlayStore(),
	userStore: getUserStore(),
});

// ---- Export --------------------------------------------------------------------------------------------------------

export { getDealerStores, getStores };
