// source: hub.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var hub_game_pb = require('./hub_game_pb.js');
goog.object.extend(proto, hub_game_pb);
var hub_error_pb = require('./hub_error_pb.js');
goog.object.extend(proto, hub_error_pb);
goog.exportSymbol('proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.ClaimSeatRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetPlayReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetPlayRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetRulesReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetRulesRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetRulesSetReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetRulesSetRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetTableReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetTableRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetTablesReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.GetTablesRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.NewPlayRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.RegisterSpokeReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.RegisterSpokeRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.SpokeInputRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.UnclaimSeatRequest', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.UpdateDeviceStateReply', null, global);
goog.exportSymbol('proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetRulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetRulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetRulesRequest.displayName = 'proto.com.sands.live_dealer.hub.GetRulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetRulesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetRulesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetRulesReply.displayName = 'proto.com.sands.live_dealer.hub.GetRulesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.GetRulesSetRequest.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetRulesSetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetRulesSetRequest.displayName = 'proto.com.sands.live_dealer.hub.GetRulesSetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.GetRulesSetReply.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetRulesSetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetRulesSetReply.displayName = 'proto.com.sands.live_dealer.hub.GetRulesSetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetTableRequest.displayName = 'proto.com.sands.live_dealer.hub.GetTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetTablesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetTablesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetTablesRequest.displayName = 'proto.com.sands.live_dealer.hub.GetTablesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.ClaimSeatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.ClaimSeatRequest.displayName = 'proto.com.sands.live_dealer.hub.ClaimSeatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.UnclaimSeatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.UnclaimSeatRequest.displayName = 'proto.com.sands.live_dealer.hub.UnclaimSeatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.NewPlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.NewPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.NewPlayRequest.displayName = 'proto.com.sands.live_dealer.hub.NewPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetTableReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetTableReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetTableReply.displayName = 'proto.com.sands.live_dealer.hub.GetTableReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetTablesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.GetTablesReply.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetTablesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetTablesReply.displayName = 'proto.com.sands.live_dealer.hub.GetTablesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetPlayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetPlayRequest.displayName = 'proto.com.sands.live_dealer.hub.GetPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.SpokeInputRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.SpokeInputRequest.displayName = 'proto.com.sands.live_dealer.hub.SpokeInputRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetPlayReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetPlayReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetPlayReply.displayName = 'proto.com.sands.live_dealer.hub.GetPlayReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.displayName = 'proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.UpdateDeviceStateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.displayName = 'proto.com.sands.live_dealer.hub.UpdateDeviceStateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.displayName = 'proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.displayName = 'proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.displayName = 'proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.displayName = 'proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.RegisterSpokeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.RegisterSpokeRequest.displayName = 'proto.com.sands.live_dealer.hub.RegisterSpokeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.RegisterSpokeReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.RegisterSpokeReply.displayName = 'proto.com.sands.live_dealer.hub.RegisterSpokeReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.displayName = 'proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.repeatedFields_, null);
};
goog.inherits(proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.displayName = 'proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetRulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetRulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesRequest}
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetRulesRequest;
  return proto.com.sands.live_dealer.hub.GetRulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetRulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesRequest}
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetRulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetRulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesRequest.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetRulesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetRulesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetRulesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && hub_game_pb.Rules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesReply}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetRulesReply;
  return proto.com.sands.live_dealer.hub.GetRulesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetRulesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesReply}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Rules;
      reader.readMessage(value,hub_game_pb.Rules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetRulesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetRulesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetRulesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hub_game_pb.Rules.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesReply} returns this
*/
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Rules rules = 3;
 * @return {?proto.com.sands.live_dealer.hub.Rules}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.getRules = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Rules} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.Rules, 3));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Rules|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesReply} returns this
*/
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetRulesReply.prototype.hasRules = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetRulesSetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetRulesSetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetRequest}
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetRulesSetRequest;
  return proto.com.sands.live_dealer.hub.GetRulesSetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetRulesSetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetRequest}
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRulesIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetRulesSetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetRulesSetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string rules_ids = 2;
 * @return {!Array<string>}
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.prototype.getRulesIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.prototype.setRulesIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.prototype.addRulesIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesSetRequest.prototype.clearRulesIdsList = function() {
  return this.setRulesIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetRulesSetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetRulesSetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    hub_game_pb.Rules.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetReply}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetRulesSetReply;
  return proto.com.sands.live_dealer.hub.GetRulesSetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetRulesSetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetReply}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Rules;
      reader.readMessage(value,hub_game_pb.Rules.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetRulesSetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetRulesSetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hub_game_pb.Rules.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetReply} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetReply} returns this
*/
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetReply} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Rules rules = 3;
 * @return {!Array<!proto.com.sands.live_dealer.hub.Rules>}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.Rules>} */ (
    jspb.Message.getRepeatedWrapperField(this, hub_game_pb.Rules, 3));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.Rules>} value
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetReply} returns this
*/
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.Rules=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.Rules}
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.sands.live_dealer.hub.Rules, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.GetRulesSetReply} returns this
 */
proto.com.sands.live_dealer.hub.GetRulesSetReply.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetTableRequest}
 */
proto.com.sands.live_dealer.hub.GetTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetTableRequest;
  return proto.com.sands.live_dealer.hub.GetTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetTableRequest}
 */
proto.com.sands.live_dealer.hub.GetTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetTableRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetTableRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetTableRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetTableRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetTableRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetTableRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetTablesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetTablesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetTablesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetTablesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetTablesRequest}
 */
proto.com.sands.live_dealer.hub.GetTablesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetTablesRequest;
  return proto.com.sands.live_dealer.hub.GetTablesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetTablesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetTablesRequest}
 */
proto.com.sands.live_dealer.hub.GetTablesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetTablesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetTablesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetTablesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetTablesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetTablesRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetTablesRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetTablesRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.ClaimSeatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.ClaimSeatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seatNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.ClaimSeatRequest}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.ClaimSeatRequest;
  return proto.com.sands.live_dealer.hub.ClaimSeatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.ClaimSeatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.ClaimSeatRequest}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeatNum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.ClaimSeatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.ClaimSeatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.ClaimSeatRequest} returns this
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.ClaimSeatRequest} returns this
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 seat_num = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.ClaimSeatRequest} returns this
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.ClaimSeatRequest} returns this
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.ClaimSeatRequest} returns this
 */
proto.com.sands.live_dealer.hub.ClaimSeatRequest.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.UnclaimSeatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.UnclaimSeatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seatNum: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.UnclaimSeatRequest}
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.UnclaimSeatRequest;
  return proto.com.sands.live_dealer.hub.UnclaimSeatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.UnclaimSeatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.UnclaimSeatRequest}
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeatNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.UnclaimSeatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.UnclaimSeatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.UnclaimSeatRequest} returns this
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.UnclaimSeatRequest} returns this
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 seat_num = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.UnclaimSeatRequest} returns this
 */
proto.com.sands.live_dealer.hub.UnclaimSeatRequest.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.NewPlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.NewPlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.NewPlayRequest}
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.NewPlayRequest;
  return proto.com.sands.live_dealer.hub.NewPlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.NewPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.NewPlayRequest}
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.NewPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.NewPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.NewPlayRequest} returns this
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_id = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.prototype.getTableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.NewPlayRequest} returns this
 */
proto.com.sands.live_dealer.hub.NewPlayRequest.prototype.setTableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetTableReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetTableReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetTableReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    table: (f = msg.getTable()) && hub_game_pb.Table.toObject(includeInstance, f),
    play: (f = msg.getPlay()) && hub_game_pb.Play.toObject(includeInstance, f),
    serverTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply}
 */
proto.com.sands.live_dealer.hub.GetTableReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetTableReply;
  return proto.com.sands.live_dealer.hub.GetTableReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetTableReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply}
 */
proto.com.sands.live_dealer.hub.GetTableReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Table;
      reader.readMessage(value,hub_game_pb.Table.deserializeBinaryFromReader);
      msg.setTable(value);
      break;
    case 4:
      var value = new hub_game_pb.Play;
      reader.readMessage(value,hub_game_pb.Play.deserializeBinaryFromReader);
      msg.setPlay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetTableReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetTableReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetTableReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getTable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hub_game_pb.Table.serializeBinaryToWriter
    );
  }
  f = message.getPlay();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      hub_game_pb.Play.serializeBinaryToWriter
    );
  }
  f = message.getServerTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply} returns this
*/
proto.com.sands.live_dealer.hub.GetTableReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Table table = 3;
 * @return {?proto.com.sands.live_dealer.hub.Table}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.getTable = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Table} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.Table, 3));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Table|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply} returns this
*/
proto.com.sands.live_dealer.hub.GetTableReply.prototype.setTable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.clearTable = function() {
  return this.setTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.hasTable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Play play = 4;
 * @return {?proto.com.sands.live_dealer.hub.Play}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.getPlay = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Play} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.Play, 4));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Play|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply} returns this
*/
proto.com.sands.live_dealer.hub.GetTableReply.prototype.setPlay = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.clearPlay = function() {
  return this.setPlay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.hasPlay = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 server_time = 5;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.getServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.GetTableReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTableReply.prototype.setServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.GetTablesReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetTablesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetTablesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetTablesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    tablesList: jspb.Message.toObjectList(msg.getTablesList(),
    hub_game_pb.Table.toObject, includeInstance),
    serverTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetTablesReply}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetTablesReply;
  return proto.com.sands.live_dealer.hub.GetTablesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetTablesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetTablesReply}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Table;
      reader.readMessage(value,hub_game_pb.Table.deserializeBinaryFromReader);
      msg.addTables(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetTablesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetTablesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetTablesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getTablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hub_game_pb.Table.serializeBinaryToWriter
    );
  }
  f = message.getServerTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetTablesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetTablesReply} returns this
*/
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetTablesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Table tables = 3;
 * @return {!Array<!proto.com.sands.live_dealer.hub.Table>}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.getTablesList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, hub_game_pb.Table, 3));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.Table>} value
 * @return {!proto.com.sands.live_dealer.hub.GetTablesReply} returns this
*/
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.setTablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.Table}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.addTables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.sands.live_dealer.hub.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.GetTablesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.clearTablesList = function() {
  return this.setTablesList([]);
};


/**
 * optional int64 server_time = 5;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.getServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.GetTablesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetTablesReply.prototype.setServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetPlayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetPlayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetPlayRequest}
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetPlayRequest;
  return proto.com.sands.live_dealer.hub.GetPlayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetPlayRequest}
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetPlayRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.GetPlayRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetPlayRequest.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.SpokeInputRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.SpokeInputRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    input: (f = msg.getInput()) && hub_game_pb.SpokeInput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.SpokeInputRequest}
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.SpokeInputRequest;
  return proto.com.sands.live_dealer.hub.SpokeInputRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.SpokeInputRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.SpokeInputRequest}
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 3:
      var value = new hub_game_pb.SpokeInput;
      reader.readMessage(value,hub_game_pb.SpokeInput.deserializeBinaryFromReader);
      msg.setInput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.SpokeInputRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.SpokeInputRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getInput();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hub_game_pb.SpokeInput.serializeBinaryToWriter
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInputRequest} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInputRequest} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SpokeInput input = 3;
 * @return {?proto.com.sands.live_dealer.hub.SpokeInput}
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.getInput = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.SpokeInput} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.SpokeInput, 3));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.SpokeInput|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.SpokeInputRequest} returns this
*/
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.setInput = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.SpokeInputRequest} returns this
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.clearInput = function() {
  return this.setInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.SpokeInputRequest.prototype.hasInput = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetPlayReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetPlayReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetPlayReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    play: (f = msg.getPlay()) && hub_game_pb.Play.toObject(includeInstance, f),
    serverTime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetPlayReply}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetPlayReply;
  return proto.com.sands.live_dealer.hub.GetPlayReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetPlayReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetPlayReply}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Play;
      reader.readMessage(value,hub_game_pb.Play.deserializeBinaryFromReader);
      msg.setPlay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetPlayReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetPlayReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetPlayReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getPlay();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hub_game_pb.Play.serializeBinaryToWriter
    );
  }
  f = message.getServerTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetPlayReply} returns this
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetPlayReply} returns this
*/
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetPlayReply} returns this
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Play play = 3;
 * @return {?proto.com.sands.live_dealer.hub.Play}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.getPlay = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Play} */ (
    jspb.Message.getWrapperField(this, hub_game_pb.Play, 3));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Play|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetPlayReply} returns this
*/
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.setPlay = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetPlayReply} returns this
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.clearPlay = function() {
  return this.setPlay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.hasPlay = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 server_time = 4;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.getServerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.GetPlayReply} returns this
 */
proto.com.sands.live_dealer.hub.GetPlayReply.prototype.setServerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceState: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest;
  return proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string device_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.prototype.getDeviceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest} returns this
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.prototype.setDeviceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_state = 2;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.prototype.getDeviceState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest} returns this
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateRequest.prototype.setDeviceState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceStateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateReply}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.UpdateDeviceStateReply;
  return proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceStateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateReply}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.UpdateDeviceStateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateReply} returns this
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateReply} returns this
*/
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.UpdateDeviceStateReply} returns this
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.UpdateDeviceStateReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest;
  return proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    playIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply;
  return proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlayIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getPlayIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} returns this
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} returns this
*/
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} returns this
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint64 play_ids = 3;
 * @return {!Array<number>}
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.getPlayIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} returns this
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.setPlayIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} returns this
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.addPlayIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply} returns this
 */
proto.com.sands.live_dealer.hub.GetIncompletePlayIdsReply.prototype.clearPlayIdsList = function() {
  return this.setPlayIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stateNum: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest;
  return proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStateNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStateNum();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest} returns this
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest} returns this
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 state_num = 3;
 * @return {number}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.prototype.getStateNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest} returns this
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateRequest.prototype.setStateNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply;
  return proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply} returns this
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply} returns this
*/
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply} returns this
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.AcknowledgePlayStateReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.RegisterSpokeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.RegisterSpokeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.RegisterSpokeRequest}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.RegisterSpokeRequest;
  return proto.com.sands.live_dealer.hub.RegisterSpokeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.RegisterSpokeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.RegisterSpokeRequest}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.RegisterSpokeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.RegisterSpokeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.RegisterSpokeRequest} returns this
 */
proto.com.sands.live_dealer.hub.RegisterSpokeRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.RegisterSpokeReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.RegisterSpokeReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.RegisterSpokeReply}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.RegisterSpokeReply;
  return proto.com.sands.live_dealer.hub.RegisterSpokeReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.RegisterSpokeReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.RegisterSpokeReply}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.RegisterSpokeReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.RegisterSpokeReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.RegisterSpokeReply} returns this
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.RegisterSpokeReply} returns this
*/
proto.com.sands.live_dealer.hub.RegisterSpokeReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.RegisterSpokeReply} returns this
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.RegisterSpokeReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spokeKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest;
  return proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpokeKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpokeKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string spoke_key = 1;
 * @return {string}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.prototype.getSpokeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest} returns this
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesRequest.prototype.setSpokeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && hub_error_pb.Error.toObject(includeInstance, f),
    tablesList: jspb.Message.toObjectList(msg.getTablesList(),
    hub_game_pb.Table.toObject, includeInstance),
    playsList: jspb.Message.toObjectList(msg.getPlaysList(),
    hub_game_pb.Play.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply;
  return proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new hub_error_pb.Error;
      reader.readMessage(value,hub_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new hub_game_pb.Table;
      reader.readMessage(value,hub_game_pb.Table.deserializeBinaryFromReader);
      msg.addTables(value);
      break;
    case 4:
      var value = new hub_game_pb.Play;
      reader.readMessage(value,hub_game_pb.Play.deserializeBinaryFromReader);
      msg.addPlays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hub_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getTablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hub_game_pb.Table.serializeBinaryToWriter
    );
  }
  f = message.getPlaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hub_game_pb.Play.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.com.sands.live_dealer.hub.Error}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.getError = function() {
  return /** @type{?proto.com.sands.live_dealer.hub.Error} */ (
    jspb.Message.getWrapperField(this, hub_error_pb.Error, 2));
};


/**
 * @param {?proto.com.sands.live_dealer.hub.Error|undefined} value
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} returns this
*/
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Table tables = 3;
 * @return {!Array<!proto.com.sands.live_dealer.hub.Table>}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.getTablesList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, hub_game_pb.Table, 3));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.Table>} value
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} returns this
*/
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.setTablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.Table}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.addTables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.sands.live_dealer.hub.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.clearTablesList = function() {
  return this.setTablesList([]);
};


/**
 * repeated Play plays = 4;
 * @return {!Array<!proto.com.sands.live_dealer.hub.Play>}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.getPlaysList = function() {
  return /** @type{!Array<!proto.com.sands.live_dealer.hub.Play>} */ (
    jspb.Message.getRepeatedWrapperField(this, hub_game_pb.Play, 4));
};


/**
 * @param {!Array<!proto.com.sands.live_dealer.hub.Play>} value
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} returns this
*/
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.setPlaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.sands.live_dealer.hub.Play=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.sands.live_dealer.hub.Play}
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.addPlays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.sands.live_dealer.hub.Play, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply} returns this
 */
proto.com.sands.live_dealer.hub.GetSpokeUpdatesReply.prototype.clearPlaysList = function() {
  return this.setPlaysList([]);
};


goog.object.extend(exports, proto.com.sands.live_dealer.hub);
