import trim from 'lodash/trim';

type HashDataValue = string[] | string;
type HashData = { [key: string]: HashDataValue };

const getHashStr = () => {
	return trim(globalThis.location.hash || '');
};

const defaultHashData = {
	themes: [],
};

const decodeHashData = (hashStr: string): HashData => {
	if (hashStr === '') {
		return { ...defaultHashData };
	}

	hashStr = hashStr.startsWith('#') ? hashStr.slice(1) : hashStr;

	const parts = hashStr.includes('=') ? hashStr.split('=') : [];
	if (parts.length === 0) {
		return { ...defaultHashData };
	}

	let key = '';
	const data: HashData = {};
	parts.forEach((str: string, i: number) => {
		if (i % 2) {
			const val: HashDataValue = str.includes('|') ? str.split('|') : str;
			data[key] = val ?? '';
		} else {
			key = str;
		}
	});

	return { ...defaultHashData, ...data };
};

const getHashData = () => {
	const hashStr = getHashStr();

	return decodeHashData(hashStr);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { decodeHashData, getHashStr, getHashData };
