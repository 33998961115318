/**********************************************************************************************************************
 * Initialization of the various RPC services used to make unary calls.
 *********************************************************************************************************************/
import { Config } from 'helpers/config';
import { IServiceOptions } from './lib-rpc';
import { DevService, GameService, HubBackOfficeService, HubDeviceService, UserService } from './lib-rpc';
import { getSession } from './session';
import { ServiceConstructor } from './types';

const newService = <ServiceType>(
	Service: ServiceConstructor<ServiceType>,
	url?: Maybe<string>,
	opts?: Maybe<IServiceOptions>
): ServiceType => {
	opts = { ...(opts ?? {}) };

	url = url ?? '';
	if (url === '') {
		throw new Error(`Cannot create service: Invalid service URL`);
	}

	opts.session = opts.session ?? getSession();

	return new Service(url, opts);
};

const newDevService = (props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IServiceOptions> }>): DevService => {
	props = props ?? {};
	const url = props.url || Config.serverURI;

	return newService<DevService>(DevService, url, props.opts);
};

const newUserService = (props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IServiceOptions> }>): UserService => {
	props = props ?? {};
	const url = props.url || Config.serverURI;

	return newService<UserService>(UserService, url, props.opts);
};

const newGameService = (props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IServiceOptions> }>): GameService => {
	props = props ?? {};
	const url = props.url || Config.serverURI;

	return newService<GameService>(GameService, url, props.opts);
};

const newDeviceService = (props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IServiceOptions> }>): HubDeviceService => {
	props = props ?? {};
	const url = props.url || Config.deviceServerURI;

	return newService<HubDeviceService>(HubDeviceService, url, props.opts);
};

const newBackOfficeService = (
	props?: Maybe<{ url?: Maybe<string>; opts?: Maybe<IServiceOptions> }>
): HubBackOfficeService => {
	props = props ?? {};
	const url = props.url || Config.backOfficeServerURI;

	return newService<HubBackOfficeService>(HubBackOfficeService, url, props.opts);
};

// ---- Service instances ---------------------------------------------------------------------------------------------

let _devService: DevService;
let _userService: UserService;
let _gameService: GameService;
let _deviceService: HubDeviceService;
let _backOfficeService: HubBackOfficeService;

// ---- Initialize ----------------------------------------------------------------------------------------------------

let _isInitialized = false;

const makeServices = () => {
	_devService = newDevService();
	_userService = newUserService();
	_gameService = newGameService();
	_deviceService = newDeviceService();
	_backOfficeService = newBackOfficeService();
};

const initialize = (): boolean => {
	if (_isInitialized) {
		return false;
	}

	makeServices();
	_isInitialized = true;

	return true;
};

// ---- Utility -------------------------------------------------------------------------------------------------------

const getDevService = (): DevService => _devService;
const getUserService = (): UserService => _userService;
const getGameService = (): GameService => _gameService;
const getDeviceService = (): HubDeviceService => _deviceService;
const getBackOfficeService = (): HubBackOfficeService => _backOfficeService;

const getServices = () => {
	return {
		devService: _devService,
		userService: _userService,
		gameService: _gameService,
		deviceService: _deviceService,
		backOfficeService: _backOfficeService,
	};
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initServices };
export { getDevService, getUserService, getGameService, getDeviceService, getBackOfficeService, getServices };
