// source: spoke_game.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.sands.live_dealer.spoke.ActiveWager', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.AvailableWager', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BaccaratHand', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BaccaratHistory', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BaccaratPlayState', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BaccaratPlaySummary', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BaccaratRules', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Balance', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BigRoadEntry', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BigRoadStreak', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BlackjackHand', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BlackjackPlayState', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BlackjackRules', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.BlackjackSeatHands', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Card', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Choice', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DTBigRoadEntry', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DTBigRoadStreak', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DTDerivedRoadEntry', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DTDerivedRoadStreak', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DTPossibleDerivedProgress', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DerivedRoadEntry', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DerivedRoadStreak', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DragonTigerCard', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DragonTigerHand', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DragonTigerHistory', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DragonTigerPlayState', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DragonTigerPlaySummary', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.DragonTigerRules', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.FutureAction', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.GameVariantPlaySummary', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Payline', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Play', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.PlaySeatData', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.PlayStates', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.PlaySummary', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.PossibleDerivedProgress', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Resolution', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RouletteFastAction', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RoulettePatternHit', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RoulettePlayState', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RoulettePlaySummary', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RouletteRules', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RouletteVariants', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RouletteWagerHits', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.RouletteWheelStop', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Rules', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Seat', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.SeatDataAction', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.SimpleTable', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.Table', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.ThreeCardPokerHand', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.ThreeCardPokerPlayState', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.ThreeCardPokerPlayer', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.ThreeCardPokerRules', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.ThreeCardPokerSeat', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.VariantRules', null, global);
goog.exportSymbol('proto.sands.live_dealer.spoke.WagerRule', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.Rules.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Rules.displayName = 'proto.sands.live_dealer.spoke.Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.WagerRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.WagerRule.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.WagerRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.WagerRule.displayName = 'proto.sands.live_dealer.spoke.WagerRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Payline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Payline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Payline.displayName = 'proto.sands.live_dealer.spoke.Payline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.VariantRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.VariantRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.VariantRules.displayName = 'proto.sands.live_dealer.spoke.VariantRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BlackjackRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.BlackjackRules.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BlackjackRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BlackjackRules.displayName = 'proto.sands.live_dealer.spoke.BlackjackRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RouletteRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.RouletteRules.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RouletteRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RouletteRules.displayName = 'proto.sands.live_dealer.spoke.RouletteRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RouletteWheelStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RouletteWheelStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RouletteWheelStop.displayName = 'proto.sands.live_dealer.spoke.RouletteWheelStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RouletteWagerHits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.RouletteWagerHits.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RouletteWagerHits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RouletteWagerHits.displayName = 'proto.sands.live_dealer.spoke.RouletteWagerHits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BaccaratRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BaccaratRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BaccaratRules.displayName = 'proto.sands.live_dealer.spoke.BaccaratRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DragonTigerRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DragonTigerRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DragonTigerRules.displayName = 'proto.sands.live_dealer.spoke.DragonTigerRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ThreeCardPokerRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ThreeCardPokerRules.displayName = 'proto.sands.live_dealer.spoke.ThreeCardPokerRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.Table.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Table.displayName = 'proto.sands.live_dealer.spoke.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Seat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Seat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Seat.displayName = 'proto.sands.live_dealer.spoke.Seat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.AvailableWager = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.AvailableWager, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.AvailableWager.displayName = 'proto.sands.live_dealer.spoke.AvailableWager';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ActiveWager = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ActiveWager, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ActiveWager.displayName = 'proto.sands.live_dealer.spoke.ActiveWager';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Resolution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Resolution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Resolution.displayName = 'proto.sands.live_dealer.spoke.Resolution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Choice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Choice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Choice.displayName = 'proto.sands.live_dealer.spoke.Choice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Balance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Balance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Balance.displayName = 'proto.sands.live_dealer.spoke.Balance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.SimpleTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.SimpleTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.SimpleTable.displayName = 'proto.sands.live_dealer.spoke.SimpleTable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Play = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.Play.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Play, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Play.displayName = 'proto.sands.live_dealer.spoke.Play';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.PlaySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.PlaySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.PlaySummary.displayName = 'proto.sands.live_dealer.spoke.PlaySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.GameVariantPlaySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.GameVariantPlaySummary.displayName = 'proto.sands.live_dealer.spoke.GameVariantPlaySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BaccaratPlaySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BaccaratPlaySummary.displayName = 'proto.sands.live_dealer.spoke.BaccaratPlaySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DragonTigerPlaySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DragonTigerPlaySummary.displayName = 'proto.sands.live_dealer.spoke.DragonTigerPlaySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RoulettePlaySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RoulettePlaySummary.displayName = 'proto.sands.live_dealer.spoke.RoulettePlaySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.PlaySeatData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.PlaySeatData.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.PlaySeatData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.PlaySeatData.displayName = 'proto.sands.live_dealer.spoke.PlaySeatData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.SeatDataAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.SeatDataAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.SeatDataAction.displayName = 'proto.sands.live_dealer.spoke.SeatDataAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.PlayStates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.PlayStates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.PlayStates.displayName = 'proto.sands.live_dealer.spoke.PlayStates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BaccaratPlayState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BaccaratPlayState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BaccaratPlayState.displayName = 'proto.sands.live_dealer.spoke.BaccaratPlayState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DragonTigerPlayState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DragonTigerPlayState.displayName = 'proto.sands.live_dealer.spoke.DragonTigerPlayState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DragonTigerHand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.DragonTigerHand.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DragonTigerHand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DragonTigerHand.displayName = 'proto.sands.live_dealer.spoke.DragonTigerHand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DragonTigerCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DragonTigerCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DragonTigerCard.displayName = 'proto.sands.live_dealer.spoke.DragonTigerCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BaccaratHand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.BaccaratHand.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BaccaratHand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BaccaratHand.displayName = 'proto.sands.live_dealer.spoke.BaccaratHand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BlackjackPlayState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.BlackjackPlayState.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BlackjackPlayState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BlackjackPlayState.displayName = 'proto.sands.live_dealer.spoke.BlackjackPlayState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.BlackjackSeatHands.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BlackjackSeatHands, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BlackjackSeatHands.displayName = 'proto.sands.live_dealer.spoke.BlackjackSeatHands';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BlackjackHand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.BlackjackHand.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BlackjackHand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BlackjackHand.displayName = 'proto.sands.live_dealer.spoke.BlackjackHand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RoulettePlayState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RoulettePlayState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RoulettePlayState.displayName = 'proto.sands.live_dealer.spoke.RoulettePlayState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RouletteVariants = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RouletteVariants, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RouletteVariants.displayName = 'proto.sands.live_dealer.spoke.RouletteVariants';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RouletteFastAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.RouletteFastAction.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RouletteFastAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RouletteFastAction.displayName = 'proto.sands.live_dealer.spoke.RouletteFastAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.RoulettePatternHit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.RoulettePatternHit.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.RoulettePatternHit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.RoulettePatternHit.displayName = 'proto.sands.live_dealer.spoke.RoulettePatternHit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ThreeCardPokerPlayState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.displayName = 'proto.sands.live_dealer.spoke.ThreeCardPokerPlayState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ThreeCardPokerSeat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ThreeCardPokerSeat.displayName = 'proto.sands.live_dealer.spoke.ThreeCardPokerSeat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.ThreeCardPokerHand.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ThreeCardPokerHand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ThreeCardPokerHand.displayName = 'proto.sands.live_dealer.spoke.ThreeCardPokerHand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.displayName = 'proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ThreeCardPokerPlayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.displayName = 'proto.sands.live_dealer.spoke.ThreeCardPokerPlayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.displayName = 'proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BaccaratHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.BaccaratHistory.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BaccaratHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BaccaratHistory.displayName = 'proto.sands.live_dealer.spoke.BaccaratHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BigRoadStreak = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.BigRoadStreak.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BigRoadStreak, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BigRoadStreak.displayName = 'proto.sands.live_dealer.spoke.BigRoadStreak';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.BigRoadEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.BigRoadEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.BigRoadEntry.displayName = 'proto.sands.live_dealer.spoke.BigRoadEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.PossibleDerivedProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.PossibleDerivedProgress.displayName = 'proto.sands.live_dealer.spoke.PossibleDerivedProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.DerivedRoadStreak.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DerivedRoadStreak, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DerivedRoadStreak.displayName = 'proto.sands.live_dealer.spoke.DerivedRoadStreak';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DerivedRoadEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DerivedRoadEntry.displayName = 'proto.sands.live_dealer.spoke.DerivedRoadEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DragonTigerHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.DragonTigerHistory.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DragonTigerHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DragonTigerHistory.displayName = 'proto.sands.live_dealer.spoke.DragonTigerHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.DTBigRoadStreak.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DTBigRoadStreak, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DTBigRoadStreak.displayName = 'proto.sands.live_dealer.spoke.DTBigRoadStreak';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DTBigRoadEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DTBigRoadEntry.displayName = 'proto.sands.live_dealer.spoke.DTBigRoadEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DTPossibleDerivedProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.displayName = 'proto.sands.live_dealer.spoke.DTPossibleDerivedProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sands.live_dealer.spoke.DTDerivedRoadStreak.repeatedFields_, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DTDerivedRoadStreak, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DTDerivedRoadStreak.displayName = 'proto.sands.live_dealer.spoke.DTDerivedRoadStreak';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.DTDerivedRoadEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.DTDerivedRoadEntry.displayName = 'proto.sands.live_dealer.spoke.DTDerivedRoadEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.Card = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.Card, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.Card.displayName = 'proto.sands.live_dealer.spoke.Card';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sands.live_dealer.spoke.FutureAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sands.live_dealer.spoke.FutureAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sands.live_dealer.spoke.FutureAction.displayName = 'proto.sands.live_dealer.spoke.FutureAction';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.Rules.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    game: jspb.Message.getFieldWithDefault(msg, 2, ""),
    variant: jspb.Message.getFieldWithDefault(msg, 3, ""),
    seating: jspb.Message.getFieldWithDefault(msg, 4, ""),
    runRequiresWager: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    variantRules: (f = msg.getVariantRules()) && proto.sands.live_dealer.spoke.VariantRules.toObject(includeInstance, f),
    wagerRulesList: jspb.Message.toObjectList(msg.getWagerRulesList(),
    proto.sands.live_dealer.spoke.WagerRule.toObject, includeInstance),
    wagerSet: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Rules}
 */
proto.sands.live_dealer.spoke.Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Rules;
  return proto.sands.live_dealer.spoke.Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Rules}
 */
proto.sands.live_dealer.spoke.Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariant(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeating(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunRequiresWager(value);
      break;
    case 6:
      var value = new proto.sands.live_dealer.spoke.VariantRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.VariantRules.deserializeBinaryFromReader);
      msg.setVariantRules(value);
      break;
    case 7:
      var value = new proto.sands.live_dealer.spoke.WagerRule;
      reader.readMessage(value,proto.sands.live_dealer.spoke.WagerRule.deserializeBinaryFromReader);
      msg.addWagerRules(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWagerSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVariant();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeating();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRunRequiresWager();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getVariantRules();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.sands.live_dealer.spoke.VariantRules.serializeBinaryToWriter
    );
  }
  f = message.getWagerRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.sands.live_dealer.spoke.WagerRule.serializeBinaryToWriter
    );
  }
  f = message.getWagerSet();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Rules.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
 */
proto.sands.live_dealer.spoke.Rules.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Rules.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
 */
proto.sands.live_dealer.spoke.Rules.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string variant = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Rules.prototype.getVariant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
 */
proto.sands.live_dealer.spoke.Rules.prototype.setVariant = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string seating = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Rules.prototype.getSeating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
 */
proto.sands.live_dealer.spoke.Rules.prototype.setSeating = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool run_requires_wager = 5;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Rules.prototype.getRunRequiresWager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
 */
proto.sands.live_dealer.spoke.Rules.prototype.setRunRequiresWager = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional VariantRules variant_rules = 6;
 * @return {?proto.sands.live_dealer.spoke.VariantRules}
 */
proto.sands.live_dealer.spoke.Rules.prototype.getVariantRules = function() {
  return /** @type{?proto.sands.live_dealer.spoke.VariantRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.VariantRules, 6));
};


/**
 * @param {?proto.sands.live_dealer.spoke.VariantRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
*/
proto.sands.live_dealer.spoke.Rules.prototype.setVariantRules = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
 */
proto.sands.live_dealer.spoke.Rules.prototype.clearVariantRules = function() {
  return this.setVariantRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Rules.prototype.hasVariantRules = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated WagerRule wager_rules = 7;
 * @return {!Array<!proto.sands.live_dealer.spoke.WagerRule>}
 */
proto.sands.live_dealer.spoke.Rules.prototype.getWagerRulesList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.WagerRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.WagerRule, 7));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.WagerRule>} value
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
*/
proto.sands.live_dealer.spoke.Rules.prototype.setWagerRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.WagerRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.WagerRule}
 */
proto.sands.live_dealer.spoke.Rules.prototype.addWagerRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.sands.live_dealer.spoke.WagerRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
 */
proto.sands.live_dealer.spoke.Rules.prototype.clearWagerRulesList = function() {
  return this.setWagerRulesList([]);
};


/**
 * optional string wager_set = 8;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Rules.prototype.getWagerSet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Rules} returns this
 */
proto.sands.live_dealer.spoke.Rules.prototype.setWagerSet = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.WagerRule.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.WagerRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.WagerRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.WagerRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    wagerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minBet: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxBet: jspb.Message.getFieldWithDefault(msg, 3, 0),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    paylinesList: jspb.Message.toObjectList(msg.getPaylinesList(),
    proto.sands.live_dealer.spoke.Payline.toObject, includeInstance),
    rtp: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.WagerRule}
 */
proto.sands.live_dealer.spoke.WagerRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.WagerRule;
  return proto.sands.live_dealer.spoke.WagerRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.WagerRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.WagerRule}
 */
proto.sands.live_dealer.spoke.WagerRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWagerName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinBet(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxBet(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.Payline;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Payline.deserializeBinaryFromReader);
      msg.addPaylines(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRtp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.WagerRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.WagerRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.WagerRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWagerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinBet();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMaxBet();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPaylinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.Payline.serializeBinaryToWriter
    );
  }
  f = message.getRtp();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional string wager_name = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.getWagerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.WagerRule} returns this
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.setWagerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 min_bet = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.getMinBet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.WagerRule} returns this
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.setMinBet = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 max_bet = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.getMaxBet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.WagerRule} returns this
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.setMaxBet = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool required = 4;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.WagerRule} returns this
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated Payline paylines = 5;
 * @return {!Array<!proto.sands.live_dealer.spoke.Payline>}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.getPaylinesList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Payline>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Payline, 5));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Payline>} value
 * @return {!proto.sands.live_dealer.spoke.WagerRule} returns this
*/
proto.sands.live_dealer.spoke.WagerRule.prototype.setPaylinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Payline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Payline}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.addPaylines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sands.live_dealer.spoke.Payline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.WagerRule} returns this
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.clearPaylinesList = function() {
  return this.setPaylinesList([]);
};


/**
 * optional uint64 Rtp = 6;
 * @return {number}
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.getRtp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.WagerRule} returns this
 */
proto.sands.live_dealer.spoke.WagerRule.prototype.setRtp = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Payline.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Payline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Payline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Payline.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    group: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    push: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Payline}
 */
proto.sands.live_dealer.spoke.Payline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Payline;
  return proto.sands.live_dealer.spoke.Payline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Payline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Payline}
 */
proto.sands.live_dealer.spoke.Payline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPush(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Payline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Payline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Payline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Payline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getPush();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string descriptor = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Payline.prototype.getDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Payline} returns this
 */
proto.sands.live_dealer.spoke.Payline.prototype.setDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Payline.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Payline} returns this
 */
proto.sands.live_dealer.spoke.Payline.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 amount = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Payline.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Payline} returns this
 */
proto.sands.live_dealer.spoke.Payline.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool push = 4;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Payline.prototype.getPush = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.Payline} returns this
 */
proto.sands.live_dealer.spoke.Payline.prototype.setPush = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.VariantRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.VariantRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.VariantRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    blackjack: (f = msg.getBlackjack()) && proto.sands.live_dealer.spoke.BlackjackRules.toObject(includeInstance, f),
    roulette: (f = msg.getRoulette()) && proto.sands.live_dealer.spoke.RouletteRules.toObject(includeInstance, f),
    baccarat: (f = msg.getBaccarat()) && proto.sands.live_dealer.spoke.BaccaratRules.toObject(includeInstance, f),
    threeCardPoker: (f = msg.getThreeCardPoker()) && proto.sands.live_dealer.spoke.ThreeCardPokerRules.toObject(includeInstance, f),
    dragonTiger: (f = msg.getDragonTiger()) && proto.sands.live_dealer.spoke.DragonTigerRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.VariantRules}
 */
proto.sands.live_dealer.spoke.VariantRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.VariantRules;
  return proto.sands.live_dealer.spoke.VariantRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.VariantRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.VariantRules}
 */
proto.sands.live_dealer.spoke.VariantRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BlackjackRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BlackjackRules.deserializeBinaryFromReader);
      msg.setBlackjack(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.RouletteRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.RouletteRules.deserializeBinaryFromReader);
      msg.setRoulette(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.BaccaratRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratRules.deserializeBinaryFromReader);
      msg.setBaccarat(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.ThreeCardPokerRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ThreeCardPokerRules.deserializeBinaryFromReader);
      msg.setThreeCardPoker(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.DragonTigerRules;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DragonTigerRules.deserializeBinaryFromReader);
      msg.setDragonTiger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.VariantRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.VariantRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.VariantRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlackjack();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BlackjackRules.serializeBinaryToWriter
    );
  }
  f = message.getRoulette();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.RouletteRules.serializeBinaryToWriter
    );
  }
  f = message.getBaccarat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.BaccaratRules.serializeBinaryToWriter
    );
  }
  f = message.getThreeCardPoker();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.ThreeCardPokerRules.serializeBinaryToWriter
    );
  }
  f = message.getDragonTiger();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.DragonTigerRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional BlackjackRules blackjack = 1;
 * @return {?proto.sands.live_dealer.spoke.BlackjackRules}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.getBlackjack = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BlackjackRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BlackjackRules, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BlackjackRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
*/
proto.sands.live_dealer.spoke.VariantRules.prototype.setBlackjack = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.clearBlackjack = function() {
  return this.setBlackjack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.hasBlackjack = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RouletteRules roulette = 2;
 * @return {?proto.sands.live_dealer.spoke.RouletteRules}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.getRoulette = function() {
  return /** @type{?proto.sands.live_dealer.spoke.RouletteRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.RouletteRules, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.RouletteRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
*/
proto.sands.live_dealer.spoke.VariantRules.prototype.setRoulette = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.clearRoulette = function() {
  return this.setRoulette(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.hasRoulette = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BaccaratRules baccarat = 3;
 * @return {?proto.sands.live_dealer.spoke.BaccaratRules}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.getBaccarat = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratRules, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
*/
proto.sands.live_dealer.spoke.VariantRules.prototype.setBaccarat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.clearBaccarat = function() {
  return this.setBaccarat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.hasBaccarat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ThreeCardPokerRules three_card_poker = 4;
 * @return {?proto.sands.live_dealer.spoke.ThreeCardPokerRules}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.getThreeCardPoker = function() {
  return /** @type{?proto.sands.live_dealer.spoke.ThreeCardPokerRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.ThreeCardPokerRules, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.ThreeCardPokerRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
*/
proto.sands.live_dealer.spoke.VariantRules.prototype.setThreeCardPoker = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.clearThreeCardPoker = function() {
  return this.setThreeCardPoker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.hasThreeCardPoker = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DragonTigerRules dragon_tiger = 5;
 * @return {?proto.sands.live_dealer.spoke.DragonTigerRules}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.getDragonTiger = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DragonTigerRules} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DragonTigerRules, 5));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DragonTigerRules|undefined} value
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
*/
proto.sands.live_dealer.spoke.VariantRules.prototype.setDragonTiger = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.VariantRules} returns this
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.clearDragonTiger = function() {
  return this.setDragonTiger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.VariantRules.prototype.hasDragonTiger = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.BlackjackRules.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BlackjackRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BlackjackRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BlackjackRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    variant: jspb.Message.getFieldWithDefault(msg, 1, ""),
    decks: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hubSeating: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hitRules: jspb.Message.getFieldWithDefault(msg, 4, ""),
    splitRules: jspb.Message.getFieldWithDefault(msg, 5, ""),
    insuranceEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    dealingRulesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules}
 */
proto.sands.live_dealer.spoke.BlackjackRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BlackjackRules;
  return proto.sands.live_dealer.spoke.BlackjackRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BlackjackRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules}
 */
proto.sands.live_dealer.spoke.BlackjackRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariant(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDecks(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHubSeating(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHitRules(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSplitRules(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsuranceEnabled(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDealingRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BlackjackRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BlackjackRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BlackjackRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariant();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDecks();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHubSeating();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHitRules();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSplitRules();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInsuranceEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDealingRulesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string variant = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.getVariant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.setVariant = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 decks = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.getDecks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.setDecks = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool hub_seating = 3;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.getHubSeating = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.setHubSeating = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string hit_rules = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.getHitRules = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.setHitRules = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string split_rules = 5;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.getSplitRules = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.setSplitRules = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool insurance_enabled = 6;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.getInsuranceEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.setInsuranceEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated string dealing_rules = 7;
 * @return {!Array<string>}
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.getDealingRulesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.setDealingRulesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.addDealingRules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BlackjackRules} returns this
 */
proto.sands.live_dealer.spoke.BlackjackRules.prototype.clearDealingRulesList = function() {
  return this.setDealingRulesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.RouletteRules.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RouletteRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RouletteRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    variant: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wheelStopsList: jspb.Message.toObjectList(msg.getWheelStopsList(),
    proto.sands.live_dealer.spoke.RouletteWheelStop.toObject, includeInstance),
    wagerHitsList: jspb.Message.toObjectList(msg.getWagerHitsList(),
    proto.sands.live_dealer.spoke.RouletteWagerHits.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RouletteRules}
 */
proto.sands.live_dealer.spoke.RouletteRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RouletteRules;
  return proto.sands.live_dealer.spoke.RouletteRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RouletteRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RouletteRules}
 */
proto.sands.live_dealer.spoke.RouletteRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariant(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.RouletteWheelStop;
      reader.readMessage(value,proto.sands.live_dealer.spoke.RouletteWheelStop.deserializeBinaryFromReader);
      msg.addWheelStops(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.RouletteWagerHits;
      reader.readMessage(value,proto.sands.live_dealer.spoke.RouletteWagerHits.deserializeBinaryFromReader);
      msg.addWagerHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RouletteRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RouletteRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariant();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWheelStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.RouletteWheelStop.serializeBinaryToWriter
    );
  }
  f = message.getWagerHitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.RouletteWagerHits.serializeBinaryToWriter
    );
  }
};


/**
 * optional string variant = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.getVariant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RouletteRules} returns this
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.setVariant = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RouletteWheelStop wheel_stops = 2;
 * @return {!Array<!proto.sands.live_dealer.spoke.RouletteWheelStop>}
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.getWheelStopsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.RouletteWheelStop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.RouletteWheelStop, 2));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.RouletteWheelStop>} value
 * @return {!proto.sands.live_dealer.spoke.RouletteRules} returns this
*/
proto.sands.live_dealer.spoke.RouletteRules.prototype.setWheelStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.RouletteWheelStop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.RouletteWheelStop}
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.addWheelStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sands.live_dealer.spoke.RouletteWheelStop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.RouletteRules} returns this
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.clearWheelStopsList = function() {
  return this.setWheelStopsList([]);
};


/**
 * repeated RouletteWagerHits wager_hits = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.RouletteWagerHits>}
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.getWagerHitsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.RouletteWagerHits>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.RouletteWagerHits, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.RouletteWagerHits>} value
 * @return {!proto.sands.live_dealer.spoke.RouletteRules} returns this
*/
proto.sands.live_dealer.spoke.RouletteRules.prototype.setWagerHitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.RouletteWagerHits=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits}
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.addWagerHits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.RouletteWagerHits, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.RouletteRules} returns this
 */
proto.sands.live_dealer.spoke.RouletteRules.prototype.clearWagerHitsList = function() {
  return this.setWagerHitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RouletteWheelStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RouletteWheelStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stopNum: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RouletteWheelStop}
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RouletteWheelStop;
  return proto.sands.live_dealer.spoke.RouletteWheelStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RouletteWheelStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RouletteWheelStop}
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStopNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RouletteWheelStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RouletteWheelStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStopNum();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RouletteWheelStop} returns this
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RouletteWheelStop} returns this
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 stop_num = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.prototype.getStopNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.RouletteWheelStop} returns this
 */
proto.sands.live_dealer.spoke.RouletteWheelStop.prototype.setStopNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RouletteWagerHits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RouletteWagerHits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hitsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    betType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    payMultiplier: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits}
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RouletteWagerHits;
  return proto.sands.live_dealer.spoke.RouletteWagerHits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RouletteWagerHits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits}
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addHits(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPayMultiplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RouletteWagerHits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RouletteWagerHits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHitsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBetType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPayMultiplier();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits} returns this
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string hits = 2;
 * @return {!Array<string>}
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.getHitsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits} returns this
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.setHitsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits} returns this
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.addHits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits} returns this
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.clearHitsList = function() {
  return this.setHitsList([]);
};


/**
 * optional string bet_type = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.getBetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits} returns this
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.setBetType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 pay_multiplier = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.getPayMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.RouletteWagerHits} returns this
 */
proto.sands.live_dealer.spoke.RouletteWagerHits.prototype.setPayMultiplier = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BaccaratRules.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BaccaratRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BaccaratRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    decks: jspb.Message.getFieldWithDefault(msg, 1, 0),
    commissionType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BaccaratRules}
 */
proto.sands.live_dealer.spoke.BaccaratRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BaccaratRules;
  return proto.sands.live_dealer.spoke.BaccaratRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BaccaratRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BaccaratRules}
 */
proto.sands.live_dealer.spoke.BaccaratRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDecks(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommissionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BaccaratRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BaccaratRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BaccaratRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDecks();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCommissionType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 decks = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BaccaratRules.prototype.getDecks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratRules} returns this
 */
proto.sands.live_dealer.spoke.BaccaratRules.prototype.setDecks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string commission_type = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BaccaratRules.prototype.getCommissionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratRules} returns this
 */
proto.sands.live_dealer.spoke.BaccaratRules.prototype.setCommissionType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DragonTigerRules.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DragonTigerRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DragonTigerRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    decks: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerRules}
 */
proto.sands.live_dealer.spoke.DragonTigerRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DragonTigerRules;
  return proto.sands.live_dealer.spoke.DragonTigerRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerRules}
 */
proto.sands.live_dealer.spoke.DragonTigerRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DragonTigerRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DragonTigerRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDecks();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 decks = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DragonTigerRules.prototype.getDecks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerRules} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerRules.prototype.setDecks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ThreeCardPokerRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    automatedDeal: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerRules}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ThreeCardPokerRules;
  return proto.sands.live_dealer.spoke.ThreeCardPokerRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerRules}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomatedDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ThreeCardPokerRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomatedDeal();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool automated_deal = 1;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules.prototype.getAutomatedDeal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerRules} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerRules.prototype.setAutomatedDeal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.Table.repeatedFields_ = [9,10,11,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    game: jspb.Message.getFieldWithDefault(msg, 4, ""),
    seatCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    openSeats: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tableState: jspb.Message.getFieldWithDefault(msg, 7, ""),
    play: (f = msg.getPlay()) && proto.sands.live_dealer.spoke.Play.toObject(includeInstance, f),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.sands.live_dealer.spoke.Seat.toObject, includeInstance),
    availableWagersList: jspb.Message.toObjectList(msg.getAvailableWagersList(),
    proto.sands.live_dealer.spoke.AvailableWager.toObject, includeInstance),
    choicesList: jspb.Message.toObjectList(msg.getChoicesList(),
    proto.sands.live_dealer.spoke.Choice.toObject, includeInstance),
    shoeNum: jspb.Message.getFieldWithDefault(msg, 12, 0),
    shoePlayNum: jspb.Message.getFieldWithDefault(msg, 13, 0),
    autoRunDelaySec: jspb.Message.getFieldWithDefault(msg, 14, 0),
    actionTimeoutSec: jspb.Message.getFieldWithDefault(msg, 15, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 16, ""),
    futureActionsList: jspb.Message.toObjectList(msg.getFutureActionsList(),
    proto.sands.live_dealer.spoke.FutureAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Table}
 */
proto.sands.live_dealer.spoke.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Table;
  return proto.sands.live_dealer.spoke.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Table}
 */
proto.sands.live_dealer.spoke.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRulesId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeatCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenSeats(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableState(value);
      break;
    case 8:
      var value = new proto.sands.live_dealer.spoke.Play;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Play.deserializeBinaryFromReader);
      msg.setPlay(value);
      break;
    case 9:
      var value = new proto.sands.live_dealer.spoke.Seat;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Seat.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    case 10:
      var value = new proto.sands.live_dealer.spoke.AvailableWager;
      reader.readMessage(value,proto.sands.live_dealer.spoke.AvailableWager.deserializeBinaryFromReader);
      msg.addAvailableWagers(value);
      break;
    case 11:
      var value = new proto.sands.live_dealer.spoke.Choice;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Choice.deserializeBinaryFromReader);
      msg.addChoices(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShoeNum(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShoePlayNum(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAutoRunDelaySec(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActionTimeoutSec(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 17:
      var value = new proto.sands.live_dealer.spoke.FutureAction;
      reader.readMessage(value,proto.sands.live_dealer.spoke.FutureAction.deserializeBinaryFromReader);
      msg.addFutureActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSeatCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOpenSeats();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTableState();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPlay();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.sands.live_dealer.spoke.Play.serializeBinaryToWriter
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.sands.live_dealer.spoke.Seat.serializeBinaryToWriter
    );
  }
  f = message.getAvailableWagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.sands.live_dealer.spoke.AvailableWager.serializeBinaryToWriter
    );
  }
  f = message.getChoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.sands.live_dealer.spoke.Choice.serializeBinaryToWriter
    );
  }
  f = message.getShoeNum();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getShoePlayNum();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getAutoRunDelaySec();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getActionTimeoutSec();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFutureActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.sands.live_dealer.spoke.FutureAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Table.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rules_id = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Table.prototype.getRulesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setRulesId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Table.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string game = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Table.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 seat_count = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Table.prototype.getSeatCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setSeatCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 open_seats = 6;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Table.prototype.getOpenSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setOpenSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string table_state = 7;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Table.prototype.getTableState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setTableState = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Play play = 8;
 * @return {?proto.sands.live_dealer.spoke.Play}
 */
proto.sands.live_dealer.spoke.Table.prototype.getPlay = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Play} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.Play, 8));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Play|undefined} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
*/
proto.sands.live_dealer.spoke.Table.prototype.setPlay = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.clearPlay = function() {
  return this.setPlay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Table.prototype.hasPlay = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Seat seats = 9;
 * @return {!Array<!proto.sands.live_dealer.spoke.Seat>}
 */
proto.sands.live_dealer.spoke.Table.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Seat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Seat, 9));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Seat>} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
*/
proto.sands.live_dealer.spoke.Table.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Seat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Seat}
 */
proto.sands.live_dealer.spoke.Table.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.sands.live_dealer.spoke.Seat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};


/**
 * repeated AvailableWager available_wagers = 10;
 * @return {!Array<!proto.sands.live_dealer.spoke.AvailableWager>}
 */
proto.sands.live_dealer.spoke.Table.prototype.getAvailableWagersList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.AvailableWager>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.AvailableWager, 10));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.AvailableWager>} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
*/
proto.sands.live_dealer.spoke.Table.prototype.setAvailableWagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.AvailableWager=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.AvailableWager}
 */
proto.sands.live_dealer.spoke.Table.prototype.addAvailableWagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.sands.live_dealer.spoke.AvailableWager, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.clearAvailableWagersList = function() {
  return this.setAvailableWagersList([]);
};


/**
 * repeated Choice choices = 11;
 * @return {!Array<!proto.sands.live_dealer.spoke.Choice>}
 */
proto.sands.live_dealer.spoke.Table.prototype.getChoicesList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Choice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Choice, 11));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Choice>} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
*/
proto.sands.live_dealer.spoke.Table.prototype.setChoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Choice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Choice}
 */
proto.sands.live_dealer.spoke.Table.prototype.addChoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.sands.live_dealer.spoke.Choice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.clearChoicesList = function() {
  return this.setChoicesList([]);
};


/**
 * optional int64 shoe_num = 12;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Table.prototype.getShoeNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setShoeNum = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 shoe_play_num = 13;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Table.prototype.getShoePlayNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setShoePlayNum = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 auto_run_delay_sec = 14;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Table.prototype.getAutoRunDelaySec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setAutoRunDelaySec = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint64 action_timeout_sec = 15;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Table.prototype.getActionTimeoutSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setActionTimeoutSec = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string currency = 16;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Table.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated FutureAction future_actions = 17;
 * @return {!Array<!proto.sands.live_dealer.spoke.FutureAction>}
 */
proto.sands.live_dealer.spoke.Table.prototype.getFutureActionsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.FutureAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.FutureAction, 17));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.FutureAction>} value
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
*/
proto.sands.live_dealer.spoke.Table.prototype.setFutureActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.FutureAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.FutureAction}
 */
proto.sands.live_dealer.spoke.Table.prototype.addFutureActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.sands.live_dealer.spoke.FutureAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Table} returns this
 */
proto.sands.live_dealer.spoke.Table.prototype.clearFutureActionsList = function() {
  return this.setFutureActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Seat.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Seat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Seat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Seat.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    open: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    playerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Seat}
 */
proto.sands.live_dealer.spoke.Seat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Seat;
  return proto.sands.live_dealer.spoke.Seat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Seat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Seat}
 */
proto.sands.live_dealer.spoke.Seat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeatNum(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpen(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Seat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Seat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Seat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Seat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOpen();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 seat_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Seat.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Seat} returns this
 */
proto.sands.live_dealer.spoke.Seat.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool open = 2;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Seat.prototype.getOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.Seat} returns this
 */
proto.sands.live_dealer.spoke.Seat.prototype.setOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional uint64 player_id = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Seat.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Seat} returns this
 */
proto.sands.live_dealer.spoke.Seat.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Seat.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Seat} returns this
 */
proto.sands.live_dealer.spoke.Seat.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Seat.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Seat} returns this
 */
proto.sands.live_dealer.spoke.Seat.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.AvailableWager.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.AvailableWager.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.AvailableWager} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.AvailableWager.toObject = function(includeInstance, msg) {
  var f, obj = {
    wagerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    min: jspb.Message.getFieldWithDefault(msg, 2, 0),
    max: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.AvailableWager}
 */
proto.sands.live_dealer.spoke.AvailableWager.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.AvailableWager;
  return proto.sands.live_dealer.spoke.AvailableWager.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.AvailableWager} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.AvailableWager}
 */
proto.sands.live_dealer.spoke.AvailableWager.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWagerName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.AvailableWager.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.AvailableWager.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.AvailableWager} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.AvailableWager.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWagerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string wager_name = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.AvailableWager.prototype.getWagerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.AvailableWager} returns this
 */
proto.sands.live_dealer.spoke.AvailableWager.prototype.setWagerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 min = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.AvailableWager.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.AvailableWager} returns this
 */
proto.sands.live_dealer.spoke.AvailableWager.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 max = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.AvailableWager.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.AvailableWager} returns this
 */
proto.sands.live_dealer.spoke.AvailableWager.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ActiveWager.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ActiveWager} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ActiveWager.toObject = function(includeInstance, msg) {
  var f, obj = {
    wagerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    wagerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    seatNum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    handNum: jspb.Message.getFieldWithDefault(msg, 8, 0),
    wagerState: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ActiveWager}
 */
proto.sands.live_dealer.spoke.ActiveWager.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ActiveWager;
  return proto.sands.live_dealer.spoke.ActiveWager.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ActiveWager} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ActiveWager}
 */
proto.sands.live_dealer.spoke.ActiveWager.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWagerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWagerName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeatNum(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHandNum(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setWagerState(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ActiveWager.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ActiveWager} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ActiveWager.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWagerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getWagerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getHandNum();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getWagerState();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional string wager_id = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getWagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setWagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 play_id = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 player_id = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string wager_name = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getWagerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setWagerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 amount = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 seat_num = 7;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 hand_num = 8;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getHandNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setHandNum = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string wager_state = 9;
 * @return {string}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getWagerState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setWagerState = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ActiveWager} returns this
 */
proto.sands.live_dealer.spoke.ActiveWager.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Resolution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Resolution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Resolution.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    wagerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resolutionType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    resolutionName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    playerId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    handNum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Resolution}
 */
proto.sands.live_dealer.spoke.Resolution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Resolution;
  return proto.sands.live_dealer.spoke.Resolution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Resolution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Resolution}
 */
proto.sands.live_dealer.spoke.Resolution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeatNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWagerName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResolutionType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResolutionName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHandNum(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Resolution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Resolution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Resolution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWagerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResolutionType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResolutionName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getHandNum();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional uint32 seat_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Resolution} returns this
 */
proto.sands.live_dealer.spoke.Resolution.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string wager_name = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.getWagerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Resolution} returns this
 */
proto.sands.live_dealer.spoke.Resolution.prototype.setWagerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resolution_type = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.getResolutionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Resolution} returns this
 */
proto.sands.live_dealer.spoke.Resolution.prototype.setResolutionType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string resolution_name = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.getResolutionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Resolution} returns this
 */
proto.sands.live_dealer.spoke.Resolution.prototype.setResolutionName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 amount = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Resolution} returns this
 */
proto.sands.live_dealer.spoke.Resolution.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 player_id = 6;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Resolution} returns this
 */
proto.sands.live_dealer.spoke.Resolution.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 hand_num = 7;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.getHandNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Resolution} returns this
 */
proto.sands.live_dealer.spoke.Resolution.prototype.setHandNum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string currency = 8;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Resolution.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Resolution} returns this
 */
proto.sands.live_dealer.spoke.Resolution.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Choice.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Choice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Choice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Choice.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, ""),
    step: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seatNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    handNum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Choice}
 */
proto.sands.live_dealer.spoke.Choice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Choice;
  return proto.sands.live_dealer.spoke.Choice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Choice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Choice}
 */
proto.sands.live_dealer.spoke.Choice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStep(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeatNum(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHandNum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Choice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Choice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Choice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Choice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStep();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getHandNum();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional string action = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Choice.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Choice} returns this
 */
proto.sands.live_dealer.spoke.Choice.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 step = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Choice.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Choice} returns this
 */
proto.sands.live_dealer.spoke.Choice.prototype.setStep = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 seat_num = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Choice.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Choice} returns this
 */
proto.sands.live_dealer.spoke.Choice.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 hand_num = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Choice.prototype.getHandNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Choice} returns this
 */
proto.sands.live_dealer.spoke.Choice.prototype.setHandNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 amount = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Choice.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Choice} returns this
 */
proto.sands.live_dealer.spoke.Choice.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Balance.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Balance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Balance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Balance.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    partitionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Balance}
 */
proto.sands.live_dealer.spoke.Balance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Balance;
  return proto.sands.live_dealer.spoke.Balance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Balance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Balance}
 */
proto.sands.live_dealer.spoke.Balance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPartitionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Balance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Balance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Balance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Balance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPartitionId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional uint64 player_id = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Balance.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Balance} returns this
 */
proto.sands.live_dealer.spoke.Balance.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 partition_id = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Balance.prototype.getPartitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Balance} returns this
 */
proto.sands.live_dealer.spoke.Balance.prototype.setPartitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Balance.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Balance} returns this
 */
proto.sands.live_dealer.spoke.Balance.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Balance.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Balance} returns this
 */
proto.sands.live_dealer.spoke.Balance.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.SimpleTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.SimpleTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SimpleTable.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    game: jspb.Message.getFieldWithDefault(msg, 3, ""),
    variant: jspb.Message.getFieldWithDefault(msg, 4, ""),
    seatCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    openSeats: jspb.Message.getFieldWithDefault(msg, 6, 0),
    open: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    backbettingAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    currency: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.SimpleTable}
 */
proto.sands.live_dealer.spoke.SimpleTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.SimpleTable;
  return proto.sands.live_dealer.spoke.SimpleTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.SimpleTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.SimpleTable}
 */
proto.sands.live_dealer.spoke.SimpleTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariant(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeatCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenSeats(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpen(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBackbettingAvailable(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.SimpleTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.SimpleTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SimpleTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVariant();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSeatCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOpenSeats();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOpen();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBackbettingAvailable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string game = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string variant = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getVariant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setVariant = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 seat_count = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getSeatCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setSeatCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 open_seats = 6;
 * @return {number}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getOpenSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setOpenSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool open = 7;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool backbetting_available = 8;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getBackbettingAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setBackbettingAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string currency = 9;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SimpleTable} returns this
 */
proto.sands.live_dealer.spoke.SimpleTable.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.Play.repeatedFields_ = [5,21,22,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Play.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Play.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Play} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Play.toObject = function(includeInstance, msg) {
  var f, obj = {
    playId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    game: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    playStates: (f = msg.getPlayStates()) && proto.sands.live_dealer.spoke.PlayStates.toObject(includeInstance, f),
    resolutionsList: jspb.Message.toObjectList(msg.getResolutionsList(),
    proto.sands.live_dealer.spoke.Resolution.toObject, includeInstance),
    startingBalance: (f = msg.getStartingBalance()) && proto.sands.live_dealer.spoke.Balance.toObject(includeInstance, f),
    endingBalance: (f = msg.getEndingBalance()) && proto.sands.live_dealer.spoke.Balance.toObject(includeInstance, f),
    autoRunTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    shoeNum: jspb.Message.getFieldWithDefault(msg, 9, 0),
    shoePlayNum: jspb.Message.getFieldWithDefault(msg, 10, 0),
    activeSeatNum: jspb.Message.getFieldWithDefault(msg, 11, 0),
    activeHandNum: jspb.Message.getFieldWithDefault(msg, 12, 0),
    unprocessedResolutions: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    actionTimeoutTime: jspb.Message.getFieldWithDefault(msg, 14, 0),
    voidedTime: jspb.Message.getFieldWithDefault(msg, 15, 0),
    voidedReason: jspb.Message.getFieldWithDefault(msg, 16, ""),
    voidedByAdminId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    completedTime: jspb.Message.getFieldWithDefault(msg, 18, 0),
    createdTime: jspb.Message.getFieldWithDefault(msg, 19, 0),
    updatedTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    effectivePlayWagersList: jspb.Message.toObjectList(msg.getEffectivePlayWagersList(),
    proto.sands.live_dealer.spoke.ActiveWager.toObject, includeInstance),
    effectivePlayerWagersList: jspb.Message.toObjectList(msg.getEffectivePlayerWagersList(),
    proto.sands.live_dealer.spoke.ActiveWager.toObject, includeInstance),
    failedPlayerWagersList: jspb.Message.toObjectList(msg.getFailedPlayerWagersList(),
    proto.sands.live_dealer.spoke.ActiveWager.toObject, includeInstance),
    unprocessedWagers: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    wagerAdjustmentCount: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Play}
 */
proto.sands.live_dealer.spoke.Play.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Play;
  return proto.sands.live_dealer.spoke.Play.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Play} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Play}
 */
proto.sands.live_dealer.spoke.Play.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.PlayStates;
      reader.readMessage(value,proto.sands.live_dealer.spoke.PlayStates.deserializeBinaryFromReader);
      msg.setPlayStates(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.Resolution;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Resolution.deserializeBinaryFromReader);
      msg.addResolutions(value);
      break;
    case 6:
      var value = new proto.sands.live_dealer.spoke.Balance;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Balance.deserializeBinaryFromReader);
      msg.setStartingBalance(value);
      break;
    case 7:
      var value = new proto.sands.live_dealer.spoke.Balance;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Balance.deserializeBinaryFromReader);
      msg.setEndingBalance(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAutoRunTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShoeNum(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShoePlayNum(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveSeatNum(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveHandNum(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnprocessedResolutions(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActionTimeoutTime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVoidedTime(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setVoidedReason(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVoidedByAdminId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedTime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedTime(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedTime(value);
      break;
    case 21:
      var value = new proto.sands.live_dealer.spoke.ActiveWager;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ActiveWager.deserializeBinaryFromReader);
      msg.addEffectivePlayWagers(value);
      break;
    case 22:
      var value = new proto.sands.live_dealer.spoke.ActiveWager;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ActiveWager.deserializeBinaryFromReader);
      msg.addEffectivePlayerWagers(value);
      break;
    case 23:
      var value = new proto.sands.live_dealer.spoke.ActiveWager;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ActiveWager.deserializeBinaryFromReader);
      msg.addFailedPlayerWagers(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnprocessedWagers(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWagerAdjustmentCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Play.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Play.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Play} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Play.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlayStates();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.PlayStates.serializeBinaryToWriter
    );
  }
  f = message.getResolutionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.Resolution.serializeBinaryToWriter
    );
  }
  f = message.getStartingBalance();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.sands.live_dealer.spoke.Balance.serializeBinaryToWriter
    );
  }
  f = message.getEndingBalance();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.sands.live_dealer.spoke.Balance.serializeBinaryToWriter
    );
  }
  f = message.getAutoRunTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getShoeNum();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getShoePlayNum();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getActiveSeatNum();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getActiveHandNum();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getUnprocessedResolutions();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getActionTimeoutTime();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getVoidedTime();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getVoidedReason();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getVoidedByAdminId();
  if (f !== 0) {
    writer.writeUint64(
      17,
      f
    );
  }
  f = message.getCompletedTime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getCreatedTime();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getUpdatedTime();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getEffectivePlayWagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.sands.live_dealer.spoke.ActiveWager.serializeBinaryToWriter
    );
  }
  f = message.getEffectivePlayerWagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.sands.live_dealer.spoke.ActiveWager.serializeBinaryToWriter
    );
  }
  f = message.getFailedPlayerWagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.sands.live_dealer.spoke.ActiveWager.serializeBinaryToWriter
    );
  }
  f = message.getUnprocessedWagers();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getWagerAdjustmentCount();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
};


/**
 * optional uint64 play_id = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string game = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Play.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Play.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PlayStates play_states = 4;
 * @return {?proto.sands.live_dealer.spoke.PlayStates}
 */
proto.sands.live_dealer.spoke.Play.prototype.getPlayStates = function() {
  return /** @type{?proto.sands.live_dealer.spoke.PlayStates} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.PlayStates, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.PlayStates|undefined} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
*/
proto.sands.live_dealer.spoke.Play.prototype.setPlayStates = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.clearPlayStates = function() {
  return this.setPlayStates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Play.prototype.hasPlayStates = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Resolution resolutions = 5;
 * @return {!Array<!proto.sands.live_dealer.spoke.Resolution>}
 */
proto.sands.live_dealer.spoke.Play.prototype.getResolutionsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Resolution>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Resolution, 5));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Resolution>} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
*/
proto.sands.live_dealer.spoke.Play.prototype.setResolutionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Resolution=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Resolution}
 */
proto.sands.live_dealer.spoke.Play.prototype.addResolutions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sands.live_dealer.spoke.Resolution, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.clearResolutionsList = function() {
  return this.setResolutionsList([]);
};


/**
 * optional Balance starting_balance = 6;
 * @return {?proto.sands.live_dealer.spoke.Balance}
 */
proto.sands.live_dealer.spoke.Play.prototype.getStartingBalance = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Balance} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.Balance, 6));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Balance|undefined} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
*/
proto.sands.live_dealer.spoke.Play.prototype.setStartingBalance = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.clearStartingBalance = function() {
  return this.setStartingBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Play.prototype.hasStartingBalance = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Balance ending_balance = 7;
 * @return {?proto.sands.live_dealer.spoke.Balance}
 */
proto.sands.live_dealer.spoke.Play.prototype.getEndingBalance = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Balance} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.Balance, 7));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Balance|undefined} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
*/
proto.sands.live_dealer.spoke.Play.prototype.setEndingBalance = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.clearEndingBalance = function() {
  return this.setEndingBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Play.prototype.hasEndingBalance = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 auto_run_time = 8;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getAutoRunTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setAutoRunTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 shoe_num = 9;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getShoeNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setShoeNum = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 shoe_play_num = 10;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getShoePlayNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setShoePlayNum = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 active_seat_num = 11;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getActiveSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setActiveSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 active_hand_num = 12;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getActiveHandNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setActiveHandNum = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool unprocessed_resolutions = 13;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Play.prototype.getUnprocessedResolutions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setUnprocessedResolutions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 action_timeout_time = 14;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getActionTimeoutTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setActionTimeoutTime = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 voided_time = 15;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getVoidedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setVoidedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string voided_reason = 16;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Play.prototype.getVoidedReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setVoidedReason = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional uint64 voided_by_admin_id = 17;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getVoidedByAdminId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setVoidedByAdminId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 completed_time = 18;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getCompletedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setCompletedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 created_time = 19;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getCreatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 updated_time = 20;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getUpdatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * repeated ActiveWager effective_play_wagers = 21;
 * @return {!Array<!proto.sands.live_dealer.spoke.ActiveWager>}
 */
proto.sands.live_dealer.spoke.Play.prototype.getEffectivePlayWagersList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ActiveWager>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.ActiveWager, 21));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ActiveWager>} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
*/
proto.sands.live_dealer.spoke.Play.prototype.setEffectivePlayWagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ActiveWager=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ActiveWager}
 */
proto.sands.live_dealer.spoke.Play.prototype.addEffectivePlayWagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.sands.live_dealer.spoke.ActiveWager, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.clearEffectivePlayWagersList = function() {
  return this.setEffectivePlayWagersList([]);
};


/**
 * repeated ActiveWager effective_player_wagers = 22;
 * @return {!Array<!proto.sands.live_dealer.spoke.ActiveWager>}
 */
proto.sands.live_dealer.spoke.Play.prototype.getEffectivePlayerWagersList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ActiveWager>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.ActiveWager, 22));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ActiveWager>} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
*/
proto.sands.live_dealer.spoke.Play.prototype.setEffectivePlayerWagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ActiveWager=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ActiveWager}
 */
proto.sands.live_dealer.spoke.Play.prototype.addEffectivePlayerWagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.sands.live_dealer.spoke.ActiveWager, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.clearEffectivePlayerWagersList = function() {
  return this.setEffectivePlayerWagersList([]);
};


/**
 * repeated ActiveWager failed_player_wagers = 23;
 * @return {!Array<!proto.sands.live_dealer.spoke.ActiveWager>}
 */
proto.sands.live_dealer.spoke.Play.prototype.getFailedPlayerWagersList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ActiveWager>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.ActiveWager, 23));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ActiveWager>} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
*/
proto.sands.live_dealer.spoke.Play.prototype.setFailedPlayerWagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ActiveWager=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ActiveWager}
 */
proto.sands.live_dealer.spoke.Play.prototype.addFailedPlayerWagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.sands.live_dealer.spoke.ActiveWager, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.clearFailedPlayerWagersList = function() {
  return this.setFailedPlayerWagersList([]);
};


/**
 * optional bool unprocessed_wagers = 24;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.Play.prototype.getUnprocessedWagers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setUnprocessedWagers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional int64 wager_adjustment_count = 25;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Play.prototype.getWagerAdjustmentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Play} returns this
 */
proto.sands.live_dealer.spoke.Play.prototype.setWagerAdjustmentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.PlaySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.PlaySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PlaySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    playId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    game: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shoeNum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    summary: (f = msg.getSummary()) && proto.sands.live_dealer.spoke.GameVariantPlaySummary.toObject(includeInstance, f),
    shoePlayNum: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.PlaySummary}
 */
proto.sands.live_dealer.spoke.PlaySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.PlaySummary;
  return proto.sands.live_dealer.spoke.PlaySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.PlaySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.PlaySummary}
 */
proto.sands.live_dealer.spoke.PlaySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setShoeNum(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.GameVariantPlaySummary;
      reader.readMessage(value,proto.sands.live_dealer.spoke.GameVariantPlaySummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setShoePlayNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.PlaySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.PlaySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PlaySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShoeNum();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.GameVariantPlaySummary.serializeBinaryToWriter
    );
  }
  f = message.getShoePlayNum();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional uint64 play_id = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.PlaySummary} returns this
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string game = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.PlaySummary} returns this
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.PlaySummary} returns this
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 shoe_num = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.getShoeNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.PlaySummary} returns this
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.setShoeNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional GameVariantPlaySummary summary = 5;
 * @return {?proto.sands.live_dealer.spoke.GameVariantPlaySummary}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.getSummary = function() {
  return /** @type{?proto.sands.live_dealer.spoke.GameVariantPlaySummary} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.GameVariantPlaySummary, 5));
};


/**
 * @param {?proto.sands.live_dealer.spoke.GameVariantPlaySummary|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PlaySummary} returns this
*/
proto.sands.live_dealer.spoke.PlaySummary.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PlaySummary} returns this
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 shoe_play_num = 6;
 * @return {number}
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.getShoePlayNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.PlaySummary} returns this
 */
proto.sands.live_dealer.spoke.PlaySummary.prototype.setShoePlayNum = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.GameVariantPlaySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    baccarat: (f = msg.getBaccarat()) && proto.sands.live_dealer.spoke.BaccaratPlaySummary.toObject(includeInstance, f),
    roulette: (f = msg.getRoulette()) && proto.sands.live_dealer.spoke.RoulettePlaySummary.toObject(includeInstance, f),
    dragonTiger: (f = msg.getDragonTiger()) && proto.sands.live_dealer.spoke.DragonTigerPlaySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.GameVariantPlaySummary}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.GameVariantPlaySummary;
  return proto.sands.live_dealer.spoke.GameVariantPlaySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.GameVariantPlaySummary}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaccaratPlaySummary;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratPlaySummary.deserializeBinaryFromReader);
      msg.setBaccarat(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.RoulettePlaySummary;
      reader.readMessage(value,proto.sands.live_dealer.spoke.RoulettePlaySummary.deserializeBinaryFromReader);
      msg.setRoulette(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.DragonTigerPlaySummary;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DragonTigerPlaySummary.deserializeBinaryFromReader);
      msg.setDragonTiger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.GameVariantPlaySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaccarat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaccaratPlaySummary.serializeBinaryToWriter
    );
  }
  f = message.getRoulette();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.RoulettePlaySummary.serializeBinaryToWriter
    );
  }
  f = message.getDragonTiger();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.DragonTigerPlaySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaccaratPlaySummary baccarat = 1;
 * @return {?proto.sands.live_dealer.spoke.BaccaratPlaySummary}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.getBaccarat = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratPlaySummary} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratPlaySummary, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratPlaySummary|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} returns this
*/
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.setBaccarat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.clearBaccarat = function() {
  return this.setBaccarat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.hasBaccarat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RoulettePlaySummary roulette = 2;
 * @return {?proto.sands.live_dealer.spoke.RoulettePlaySummary}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.getRoulette = function() {
  return /** @type{?proto.sands.live_dealer.spoke.RoulettePlaySummary} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.RoulettePlaySummary, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.RoulettePlaySummary|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} returns this
*/
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.setRoulette = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.clearRoulette = function() {
  return this.setRoulette(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.hasRoulette = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DragonTigerPlaySummary dragon_tiger = 3;
 * @return {?proto.sands.live_dealer.spoke.DragonTigerPlaySummary}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.getDragonTiger = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DragonTigerPlaySummary} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DragonTigerPlaySummary, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DragonTigerPlaySummary|undefined} value
 * @return {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} returns this
*/
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.setDragonTiger = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.GameVariantPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.clearDragonTiger = function() {
  return this.setDragonTiger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.GameVariantPlaySummary.prototype.hasDragonTiger = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BaccaratPlaySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BaccaratPlaySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    winner: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerNatural: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    bankerNatural: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    playerPair: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    bankerPair: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlaySummary}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BaccaratPlaySummary;
  return proto.sands.live_dealer.spoke.BaccaratPlaySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BaccaratPlaySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlaySummary}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWinner(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlayerNatural(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBankerNatural(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlayerPair(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBankerPair(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BaccaratPlaySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BaccaratPlaySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWinner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerNatural();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getBankerNatural();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPlayerPair();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBankerPair();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string winner = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.getWinner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.setWinner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool player_natural = 2;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.getPlayerNatural = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.setPlayerNatural = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool banker_natural = 3;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.getBankerNatural = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.setBankerNatural = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool player_pair = 4;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.getPlayerPair = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.setPlayerPair = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool banker_pair = 5;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.getBankerPair = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.BaccaratPlaySummary.prototype.setBankerPair = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DragonTigerPlaySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DragonTigerPlaySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    winner: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlaySummary}
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DragonTigerPlaySummary;
  return proto.sands.live_dealer.spoke.DragonTigerPlaySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerPlaySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlaySummary}
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DragonTigerPlaySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerPlaySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWinner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string winner = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary.prototype.getWinner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlaySummary} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerPlaySummary.prototype.setWinner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RoulettePlaySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RoulettePlaySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    wheelStop: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RoulettePlaySummary}
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RoulettePlaySummary;
  return proto.sands.live_dealer.spoke.RoulettePlaySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RoulettePlaySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RoulettePlaySummary}
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWheelStop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RoulettePlaySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RoulettePlaySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWheelStop();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string wheel_stop = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary.prototype.getWheelStop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RoulettePlaySummary} returns this
 */
proto.sands.live_dealer.spoke.RoulettePlaySummary.prototype.setWheelStop = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.PlaySeatData.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.PlaySeatData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.PlaySeatData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PlaySeatData.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actionsPossible: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.sands.live_dealer.spoke.SeatDataAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.PlaySeatData}
 */
proto.sands.live_dealer.spoke.PlaySeatData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.PlaySeatData;
  return proto.sands.live_dealer.spoke.PlaySeatData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.PlaySeatData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.PlaySeatData}
 */
proto.sands.live_dealer.spoke.PlaySeatData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeatNum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActionsPossible(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.SeatDataAction;
      reader.readMessage(value,proto.sands.live_dealer.spoke.SeatDataAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.PlaySeatData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.PlaySeatData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PlaySeatData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActionsPossible();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.SeatDataAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 seat_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.PlaySeatData} returns this
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 actions_possible = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.getActionsPossible = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.PlaySeatData} returns this
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.setActionsPossible = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated SeatDataAction actions = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.SeatDataAction>}
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.SeatDataAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.SeatDataAction, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.SeatDataAction>} value
 * @return {!proto.sands.live_dealer.spoke.PlaySeatData} returns this
*/
proto.sands.live_dealer.spoke.PlaySeatData.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.SeatDataAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.SeatDataAction}
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.SeatDataAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.PlaySeatData} returns this
 */
proto.sands.live_dealer.spoke.PlaySeatData.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.SeatDataAction.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.SeatDataAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.SeatDataAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SeatDataAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.SeatDataAction}
 */
proto.sands.live_dealer.spoke.SeatDataAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.SeatDataAction;
  return proto.sands.live_dealer.spoke.SeatDataAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.SeatDataAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.SeatDataAction}
 */
proto.sands.live_dealer.spoke.SeatDataAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.SeatDataAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.SeatDataAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.SeatDataAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.SeatDataAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string action = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.SeatDataAction.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.SeatDataAction} returns this
 */
proto.sands.live_dealer.spoke.SeatDataAction.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.SeatDataAction.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.SeatDataAction} returns this
 */
proto.sands.live_dealer.spoke.SeatDataAction.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.PlayStates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.PlayStates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PlayStates.toObject = function(includeInstance, msg) {
  var f, obj = {
    baccarat: (f = msg.getBaccarat()) && proto.sands.live_dealer.spoke.BaccaratPlayState.toObject(includeInstance, f),
    blackjack: (f = msg.getBlackjack()) && proto.sands.live_dealer.spoke.BlackjackPlayState.toObject(includeInstance, f),
    roulette: (f = msg.getRoulette()) && proto.sands.live_dealer.spoke.RoulettePlayState.toObject(includeInstance, f),
    threeCardPoker: (f = msg.getThreeCardPoker()) && proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.toObject(includeInstance, f),
    dragonTiger: (f = msg.getDragonTiger()) && proto.sands.live_dealer.spoke.DragonTigerPlayState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.PlayStates}
 */
proto.sands.live_dealer.spoke.PlayStates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.PlayStates;
  return proto.sands.live_dealer.spoke.PlayStates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.PlayStates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.PlayStates}
 */
proto.sands.live_dealer.spoke.PlayStates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaccaratPlayState;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratPlayState.deserializeBinaryFromReader);
      msg.setBaccarat(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BlackjackPlayState;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BlackjackPlayState.deserializeBinaryFromReader);
      msg.setBlackjack(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.RoulettePlayState;
      reader.readMessage(value,proto.sands.live_dealer.spoke.RoulettePlayState.deserializeBinaryFromReader);
      msg.setRoulette(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.ThreeCardPokerPlayState;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.deserializeBinaryFromReader);
      msg.setThreeCardPoker(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.DragonTigerPlayState;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DragonTigerPlayState.deserializeBinaryFromReader);
      msg.setDragonTiger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.PlayStates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.PlayStates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PlayStates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaccarat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaccaratPlayState.serializeBinaryToWriter
    );
  }
  f = message.getBlackjack();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BlackjackPlayState.serializeBinaryToWriter
    );
  }
  f = message.getRoulette();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.RoulettePlayState.serializeBinaryToWriter
    );
  }
  f = message.getThreeCardPoker();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.serializeBinaryToWriter
    );
  }
  f = message.getDragonTiger();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.DragonTigerPlayState.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaccaratPlayState baccarat = 1;
 * @return {?proto.sands.live_dealer.spoke.BaccaratPlayState}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.getBaccarat = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratPlayState} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratPlayState, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratPlayState|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
*/
proto.sands.live_dealer.spoke.PlayStates.prototype.setBaccarat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.clearBaccarat = function() {
  return this.setBaccarat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.hasBaccarat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BlackjackPlayState blackjack = 2;
 * @return {?proto.sands.live_dealer.spoke.BlackjackPlayState}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.getBlackjack = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BlackjackPlayState} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BlackjackPlayState, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BlackjackPlayState|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
*/
proto.sands.live_dealer.spoke.PlayStates.prototype.setBlackjack = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.clearBlackjack = function() {
  return this.setBlackjack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.hasBlackjack = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RoulettePlayState roulette = 3;
 * @return {?proto.sands.live_dealer.spoke.RoulettePlayState}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.getRoulette = function() {
  return /** @type{?proto.sands.live_dealer.spoke.RoulettePlayState} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.RoulettePlayState, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.RoulettePlayState|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
*/
proto.sands.live_dealer.spoke.PlayStates.prototype.setRoulette = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.clearRoulette = function() {
  return this.setRoulette(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.hasRoulette = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ThreeCardPokerPlayState three_card_poker = 4;
 * @return {?proto.sands.live_dealer.spoke.ThreeCardPokerPlayState}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.getThreeCardPoker = function() {
  return /** @type{?proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.ThreeCardPokerPlayState, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.ThreeCardPokerPlayState|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
*/
proto.sands.live_dealer.spoke.PlayStates.prototype.setThreeCardPoker = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.clearThreeCardPoker = function() {
  return this.setThreeCardPoker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.hasThreeCardPoker = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DragonTigerPlayState dragon_tiger = 5;
 * @return {?proto.sands.live_dealer.spoke.DragonTigerPlayState}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.getDragonTiger = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DragonTigerPlayState} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DragonTigerPlayState, 5));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DragonTigerPlayState|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
*/
proto.sands.live_dealer.spoke.PlayStates.prototype.setDragonTiger = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PlayStates} returns this
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.clearDragonTiger = function() {
  return this.setDragonTiger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PlayStates.prototype.hasDragonTiger = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BaccaratPlayState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BaccaratPlayState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerHand: (f = msg.getPlayerHand()) && proto.sands.live_dealer.spoke.BaccaratHand.toObject(includeInstance, f),
    bankerHand: (f = msg.getBankerHand()) && proto.sands.live_dealer.spoke.BaccaratHand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlayState}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BaccaratPlayState;
  return proto.sands.live_dealer.spoke.BaccaratPlayState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BaccaratPlayState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlayState}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BaccaratHand;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratHand.deserializeBinaryFromReader);
      msg.setPlayerHand(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.BaccaratHand;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratHand.deserializeBinaryFromReader);
      msg.setBankerHand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BaccaratPlayState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BaccaratPlayState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerHand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BaccaratHand.serializeBinaryToWriter
    );
  }
  f = message.getBankerHand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.BaccaratHand.serializeBinaryToWriter
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlayState} returns this
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BaccaratHand player_hand = 2;
 * @return {?proto.sands.live_dealer.spoke.BaccaratHand}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.getPlayerHand = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratHand} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratHand, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratHand|undefined} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlayState} returns this
*/
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.setPlayerHand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlayState} returns this
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.clearPlayerHand = function() {
  return this.setPlayerHand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.hasPlayerHand = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BaccaratHand banker_hand = 3;
 * @return {?proto.sands.live_dealer.spoke.BaccaratHand}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.getBankerHand = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BaccaratHand} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BaccaratHand, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BaccaratHand|undefined} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlayState} returns this
*/
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.setBankerHand = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlayState} returns this
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.clearBankerHand = function() {
  return this.setBankerHand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratPlayState.prototype.hasBankerHand = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DragonTigerPlayState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DragonTigerPlayState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dragonhand: (f = msg.getDragonhand()) && proto.sands.live_dealer.spoke.DragonTigerHand.toObject(includeInstance, f),
    tigerhand: (f = msg.getTigerhand()) && proto.sands.live_dealer.spoke.DragonTigerHand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlayState}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DragonTigerPlayState;
  return proto.sands.live_dealer.spoke.DragonTigerPlayState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerPlayState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlayState}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.DragonTigerHand;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DragonTigerHand.deserializeBinaryFromReader);
      msg.setDragonhand(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.DragonTigerHand;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DragonTigerHand.deserializeBinaryFromReader);
      msg.setTigerhand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DragonTigerPlayState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerPlayState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDragonhand();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.DragonTigerHand.serializeBinaryToWriter
    );
  }
  f = message.getTigerhand();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.DragonTigerHand.serializeBinaryToWriter
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlayState} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DragonTigerHand dragonHand = 4;
 * @return {?proto.sands.live_dealer.spoke.DragonTigerHand}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.getDragonhand = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DragonTigerHand} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DragonTigerHand, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DragonTigerHand|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlayState} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.setDragonhand = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlayState} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.clearDragonhand = function() {
  return this.setDragonhand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.hasDragonhand = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DragonTigerHand tigerHand = 5;
 * @return {?proto.sands.live_dealer.spoke.DragonTigerHand}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.getTigerhand = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DragonTigerHand} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DragonTigerHand, 5));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DragonTigerHand|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlayState} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.setTigerhand = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlayState} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.clearTigerhand = function() {
  return this.setTigerhand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DragonTigerPlayState.prototype.hasTigerhand = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.DragonTigerHand.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DragonTigerHand.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DragonTigerHand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DragonTigerHand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerHand.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.sands.live_dealer.spoke.Card.toObject, includeInstance),
    score: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHand}
 */
proto.sands.live_dealer.spoke.DragonTigerHand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DragonTigerHand;
  return proto.sands.live_dealer.spoke.DragonTigerHand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerHand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHand}
 */
proto.sands.live_dealer.spoke.DragonTigerHand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.Card;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DragonTigerHand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DragonTigerHand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerHand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerHand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.Card.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated Card cards = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.Card>}
 */
proto.sands.live_dealer.spoke.DragonTigerHand.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Card, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Card>} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHand} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerHand.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Card}
 */
proto.sands.live_dealer.spoke.DragonTigerHand.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHand} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHand.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional uint64 score = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DragonTigerHand.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHand} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHand.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DragonTigerCard.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DragonTigerCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DragonTigerCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.sands.live_dealer.spoke.Card.toObject(includeInstance, f),
    score: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerCard}
 */
proto.sands.live_dealer.spoke.DragonTigerCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DragonTigerCard;
  return proto.sands.live_dealer.spoke.DragonTigerCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerCard}
 */
proto.sands.live_dealer.spoke.DragonTigerCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.Card;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Card.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DragonTigerCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DragonTigerCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.Card.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional Card card = 1;
 * @return {?proto.sands.live_dealer.spoke.Card}
 */
proto.sands.live_dealer.spoke.DragonTigerCard.prototype.getCard = function() {
  return /** @type{?proto.sands.live_dealer.spoke.Card} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.Card, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.Card|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerCard} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerCard.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerCard} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerCard.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DragonTigerCard.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 score = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DragonTigerCard.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerCard} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerCard.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.BaccaratHand.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BaccaratHand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BaccaratHand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratHand.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.sands.live_dealer.spoke.Card.toObject, includeInstance),
    scoresList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    score: jspb.Message.getFieldWithDefault(msg, 3, 0),
    natural: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    pair: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand}
 */
proto.sands.live_dealer.spoke.BaccaratHand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BaccaratHand;
  return proto.sands.live_dealer.spoke.BaccaratHand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BaccaratHand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand}
 */
proto.sands.live_dealer.spoke.BaccaratHand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.Card;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScores(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNatural(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPair(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BaccaratHand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BaccaratHand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratHand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.Card.serializeBinaryToWriter
    );
  }
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getNatural();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPair();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * repeated Card cards = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.Card>}
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Card, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Card>} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand} returns this
*/
proto.sands.live_dealer.spoke.BaccaratHand.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Card}
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * repeated uint64 scores = 2;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.getScoresList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.setScoresList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.addScores = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};


/**
 * optional uint64 score = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool natural = 4;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.getNatural = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.setNatural = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool pair = 5;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.getPair = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHand} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHand.prototype.setPair = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.repeatedFields_ = [3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BlackjackPlayState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BlackjackPlayState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealerHand: (f = msg.getDealerHand()) && proto.sands.live_dealer.spoke.BlackjackHand.toObject(includeInstance, f),
    seatHandsList: jspb.Message.toObjectList(msg.getSeatHandsList(),
    proto.sands.live_dealer.spoke.BlackjackSeatHands.toObject, includeInstance),
    activePlayerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    activeSeatNum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    activeHandNum: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dealingRulesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BlackjackPlayState;
  return proto.sands.live_dealer.spoke.BlackjackPlayState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BlackjackPlayState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BlackjackHand;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BlackjackHand.deserializeBinaryFromReader);
      msg.setDealerHand(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.BlackjackSeatHands;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BlackjackSeatHands.deserializeBinaryFromReader);
      msg.addSeatHands(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivePlayerId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActiveSeatNum(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActiveHandNum(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDealingRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BlackjackPlayState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BlackjackPlayState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealerHand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BlackjackHand.serializeBinaryToWriter
    );
  }
  f = message.getSeatHandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.BlackjackSeatHands.serializeBinaryToWriter
    );
  }
  f = message.getActivePlayerId();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getActiveSeatNum();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getActiveHandNum();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getDealingRulesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BlackjackHand dealer_hand = 2;
 * @return {?proto.sands.live_dealer.spoke.BlackjackHand}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.getDealerHand = function() {
  return /** @type{?proto.sands.live_dealer.spoke.BlackjackHand} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.BlackjackHand, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.BlackjackHand|undefined} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
*/
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.setDealerHand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.clearDealerHand = function() {
  return this.setDealerHand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.hasDealerHand = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated BlackjackSeatHands seat_hands = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.BlackjackSeatHands>}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.getSeatHandsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.BlackjackSeatHands>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.BlackjackSeatHands, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.BlackjackSeatHands>} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
*/
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.setSeatHandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.BlackjackSeatHands=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BlackjackSeatHands}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.addSeatHands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.BlackjackSeatHands, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.clearSeatHandsList = function() {
  return this.setSeatHandsList([]);
};


/**
 * optional uint64 active_player_id = 4;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.getActivePlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.setActivePlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 active_seat_num = 5;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.getActiveSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.setActiveSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 active_hand_num = 6;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.getActiveHandNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.setActiveHandNum = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string dealing_rules = 7;
 * @return {!Array<string>}
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.getDealingRulesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.setDealingRulesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.addDealingRules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BlackjackPlayState} returns this
 */
proto.sands.live_dealer.spoke.BlackjackPlayState.prototype.clearDealingRulesList = function() {
  return this.setDealingRulesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BlackjackSeatHands.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BlackjackSeatHands} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.toObject = function(includeInstance, msg) {
  var f, obj = {
    handsList: jspb.Message.toObjectList(msg.getHandsList(),
    proto.sands.live_dealer.spoke.BlackjackHand.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BlackjackSeatHands}
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BlackjackSeatHands;
  return proto.sands.live_dealer.spoke.BlackjackSeatHands.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BlackjackSeatHands} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BlackjackSeatHands}
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BlackjackHand;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BlackjackHand.deserializeBinaryFromReader);
      msg.addHands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BlackjackSeatHands.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BlackjackSeatHands} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BlackjackHand.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlackjackHand hands = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.BlackjackHand>}
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.prototype.getHandsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.BlackjackHand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.BlackjackHand, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.BlackjackHand>} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackSeatHands} returns this
*/
proto.sands.live_dealer.spoke.BlackjackSeatHands.prototype.setHandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.BlackjackHand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand}
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.prototype.addHands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.BlackjackHand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BlackjackSeatHands} returns this
 */
proto.sands.live_dealer.spoke.BlackjackSeatHands.prototype.clearHandsList = function() {
  return this.setHandsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.BlackjackHand.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BlackjackHand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BlackjackHand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BlackjackHand.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.sands.live_dealer.spoke.Card.toObject, includeInstance),
    scoresList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    isBlackjack: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isSurrender: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isTimeout: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isBust: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    seatNum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    playerId: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand}
 */
proto.sands.live_dealer.spoke.BlackjackHand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BlackjackHand;
  return proto.sands.live_dealer.spoke.BlackjackHand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BlackjackHand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand}
 */
proto.sands.live_dealer.spoke.BlackjackHand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.Card;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScores(values[i]);
      }
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBlackjack(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSurrender(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTimeout(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBust(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeatNum(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BlackjackHand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BlackjackHand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BlackjackHand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.Card.serializeBinaryToWriter
    );
  }
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getIsBlackjack();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsSurrender();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsTimeout();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsBust();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
};


/**
 * repeated Card cards = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.Card>}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Card, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Card>} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
*/
proto.sands.live_dealer.spoke.BlackjackHand.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Card}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * repeated uint64 scores = 2;
 * @return {!Array<number>}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.getScoresList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.setScoresList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.addScores = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};


/**
 * optional bool is_blackjack = 3;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.getIsBlackjack = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.setIsBlackjack = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_surrender = 4;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.getIsSurrender = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.setIsSurrender = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_timeout = 5;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.getIsTimeout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.setIsTimeout = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_bust = 6;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.getIsBust = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.setIsBust = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint32 seat_num = 7;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 player_id = 8;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BlackjackHand} returns this
 */
proto.sands.live_dealer.spoke.BlackjackHand.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RoulettePlayState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RoulettePlayState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RoulettePlayState.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wheelStop: jspb.Message.getFieldWithDefault(msg, 2, ""),
    variants: (f = msg.getVariants()) && proto.sands.live_dealer.spoke.RouletteVariants.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RoulettePlayState}
 */
proto.sands.live_dealer.spoke.RoulettePlayState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RoulettePlayState;
  return proto.sands.live_dealer.spoke.RoulettePlayState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RoulettePlayState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RoulettePlayState}
 */
proto.sands.live_dealer.spoke.RoulettePlayState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWheelStop(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.RouletteVariants;
      reader.readMessage(value,proto.sands.live_dealer.spoke.RouletteVariants.deserializeBinaryFromReader);
      msg.setVariants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RoulettePlayState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RoulettePlayState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RoulettePlayState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWheelStop();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVariants();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.RouletteVariants.serializeBinaryToWriter
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RoulettePlayState} returns this
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string wheel_stop = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.getWheelStop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RoulettePlayState} returns this
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.setWheelStop = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RouletteVariants variants = 3;
 * @return {?proto.sands.live_dealer.spoke.RouletteVariants}
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.getVariants = function() {
  return /** @type{?proto.sands.live_dealer.spoke.RouletteVariants} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.RouletteVariants, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.RouletteVariants|undefined} value
 * @return {!proto.sands.live_dealer.spoke.RoulettePlayState} returns this
*/
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.setVariants = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.RoulettePlayState} returns this
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.clearVariants = function() {
  return this.setVariants(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.RoulettePlayState.prototype.hasVariants = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RouletteVariants.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RouletteVariants.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RouletteVariants} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteVariants.toObject = function(includeInstance, msg) {
  var f, obj = {
    fastAction: (f = msg.getFastAction()) && proto.sands.live_dealer.spoke.RouletteFastAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RouletteVariants}
 */
proto.sands.live_dealer.spoke.RouletteVariants.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RouletteVariants;
  return proto.sands.live_dealer.spoke.RouletteVariants.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RouletteVariants} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RouletteVariants}
 */
proto.sands.live_dealer.spoke.RouletteVariants.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.RouletteFastAction;
      reader.readMessage(value,proto.sands.live_dealer.spoke.RouletteFastAction.deserializeBinaryFromReader);
      msg.setFastAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RouletteVariants.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RouletteVariants.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RouletteVariants} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteVariants.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFastAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.RouletteFastAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional RouletteFastAction fast_action = 1;
 * @return {?proto.sands.live_dealer.spoke.RouletteFastAction}
 */
proto.sands.live_dealer.spoke.RouletteVariants.prototype.getFastAction = function() {
  return /** @type{?proto.sands.live_dealer.spoke.RouletteFastAction} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.RouletteFastAction, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.RouletteFastAction|undefined} value
 * @return {!proto.sands.live_dealer.spoke.RouletteVariants} returns this
*/
proto.sands.live_dealer.spoke.RouletteVariants.prototype.setFastAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.RouletteVariants} returns this
 */
proto.sands.live_dealer.spoke.RouletteVariants.prototype.clearFastAction = function() {
  return this.setFastAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.RouletteVariants.prototype.hasFastAction = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.RouletteFastAction.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RouletteFastAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RouletteFastAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteFastAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    extraStopsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    patternHitsList: jspb.Message.toObjectList(msg.getPatternHitsList(),
    proto.sands.live_dealer.spoke.RoulettePatternHit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RouletteFastAction}
 */
proto.sands.live_dealer.spoke.RouletteFastAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RouletteFastAction;
  return proto.sands.live_dealer.spoke.RouletteFastAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RouletteFastAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RouletteFastAction}
 */
proto.sands.live_dealer.spoke.RouletteFastAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addExtraStops(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.RoulettePatternHit;
      reader.readMessage(value,proto.sands.live_dealer.spoke.RoulettePatternHit.deserializeBinaryFromReader);
      msg.addPatternHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RouletteFastAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RouletteFastAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RouletteFastAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtraStopsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPatternHitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.RoulettePatternHit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string extra_stops = 1;
 * @return {!Array<string>}
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.getExtraStopsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sands.live_dealer.spoke.RouletteFastAction} returns this
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.setExtraStopsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.RouletteFastAction} returns this
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.addExtraStops = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.RouletteFastAction} returns this
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.clearExtraStopsList = function() {
  return this.setExtraStopsList([]);
};


/**
 * repeated RoulettePatternHit pattern_hits = 2;
 * @return {!Array<!proto.sands.live_dealer.spoke.RoulettePatternHit>}
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.getPatternHitsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.RoulettePatternHit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.RoulettePatternHit, 2));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.RoulettePatternHit>} value
 * @return {!proto.sands.live_dealer.spoke.RouletteFastAction} returns this
*/
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.setPatternHitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.RoulettePatternHit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.RoulettePatternHit}
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.addPatternHits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sands.live_dealer.spoke.RoulettePatternHit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.RouletteFastAction} returns this
 */
proto.sands.live_dealer.spoke.RouletteFastAction.prototype.clearPatternHitsList = function() {
  return this.setPatternHitsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.RoulettePatternHit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.RoulettePatternHit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patternList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.RoulettePatternHit}
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.RoulettePatternHit;
  return proto.sands.live_dealer.spoke.RoulettePatternHit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.RoulettePatternHit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.RoulettePatternHit}
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPattern(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.RoulettePatternHit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.RoulettePatternHit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatternList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.RoulettePatternHit} returns this
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string pattern = 2;
 * @return {!Array<string>}
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.prototype.getPatternList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sands.live_dealer.spoke.RoulettePatternHit} returns this
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.prototype.setPatternList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.RoulettePatternHit} returns this
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.prototype.addPattern = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.RoulettePatternHit} returns this
 */
proto.sands.live_dealer.spoke.RoulettePatternHit.prototype.clearPatternList = function() {
  return this.setPatternList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.sands.live_dealer.spoke.ThreeCardPokerSeat.toObject, includeInstance),
    dealerHand: (f = msg.getDealerHand()) && proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.toObject(includeInstance, f),
    player: (f = msg.getPlayer()) && proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ThreeCardPokerPlayState;
  return proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.ThreeCardPokerSeat;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ThreeCardPokerSeat.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.deserializeBinaryFromReader);
      msg.setDealerHand(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.ThreeCardPokerPlayer;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.deserializeBinaryFromReader);
      msg.setPlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.ThreeCardPokerSeat.serializeBinaryToWriter
    );
  }
  f = message.getDealerHand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.serializeBinaryToWriter
    );
  }
  f = message.getPlayer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ThreeCardPokerSeat seats = 2;
 * @return {!Array<!proto.sands.live_dealer.spoke.ThreeCardPokerSeat>}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ThreeCardPokerSeat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.ThreeCardPokerSeat, 2));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ThreeCardPokerSeat>} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} returns this
*/
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sands.live_dealer.spoke.ThreeCardPokerSeat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};


/**
 * optional ThreeCardPokerDealerHand dealer_hand = 3;
 * @return {?proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.getDealerHand = function() {
  return /** @type{?proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand|undefined} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} returns this
*/
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.setDealerHand = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.clearDealerHand = function() {
  return this.setDealerHand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.hasDealerHand = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ThreeCardPokerPlayer player = 4;
 * @return {?proto.sands.live_dealer.spoke.ThreeCardPokerPlayer}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.getPlayer = function() {
  return /** @type{?proto.sands.live_dealer.spoke.ThreeCardPokerPlayer} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.ThreeCardPokerPlayer, 4));
};


/**
 * @param {?proto.sands.live_dealer.spoke.ThreeCardPokerPlayer|undefined} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} returns this
*/
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.setPlayer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayState} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.clearPlayer = function() {
  return this.setPlayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayState.prototype.hasPlayer = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ThreeCardPokerSeat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hand: (f = msg.getHand()) && proto.sands.live_dealer.spoke.ThreeCardPokerHand.toObject(includeInstance, f),
    result: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ThreeCardPokerSeat;
  return proto.sands.live_dealer.spoke.ThreeCardPokerSeat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeatNum(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.ThreeCardPokerHand;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ThreeCardPokerHand.deserializeBinaryFromReader);
      msg.setHand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ThreeCardPokerSeat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getHand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.ThreeCardPokerHand.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint64 seat_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ThreeCardPokerHand hand = 2;
 * @return {?proto.sands.live_dealer.spoke.ThreeCardPokerHand}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.getHand = function() {
  return /** @type{?proto.sands.live_dealer.spoke.ThreeCardPokerHand} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.ThreeCardPokerHand, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.ThreeCardPokerHand|undefined} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat} returns this
*/
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.setHand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.clearHand = function() {
  return this.setHand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.hasHand = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string result = 3;
 * @return {string}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerSeat} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerSeat.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ThreeCardPokerHand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerHand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.sands.live_dealer.spoke.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerHand}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ThreeCardPokerHand;
  return proto.sands.live_dealer.spoke.ThreeCardPokerHand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerHand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerHand}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.Card;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ThreeCardPokerHand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerHand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.Card.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Card cards = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.Card>}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Card, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Card>} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerHand} returns this
*/
proto.sands.live_dealer.spoke.ThreeCardPokerHand.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Card}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerHand} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerHand.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.sands.live_dealer.spoke.Card.toObject, includeInstance),
    qualified: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand;
  return proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.Card;
      reader.readMessage(value,proto.sands.live_dealer.spoke.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQualified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.Card.serializeBinaryToWriter
    );
  }
  f = message.getQualified();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated Card cards = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.Card>}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.Card, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.Card>} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand} returns this
*/
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.Card}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional bool qualified = 2;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.prototype.getQualified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerDealerHand.prototype.setQualified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayer}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ThreeCardPokerPlayer;
  return proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayer}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat;
      reader.readMessage(value,proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 player_id = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayer} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ThreeCardPokerPlayerSeat seats = 2;
 * @return {!Array<!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat>}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat, 2));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat>} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayer} returns this
*/
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayer} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayer.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playerAction: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat;
  return proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeatNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPlayerAction();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 seat_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string player_action = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.prototype.getPlayerAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat} returns this
 */
proto.sands.live_dealer.spoke.ThreeCardPokerPlayerSeat.prototype.setPlayerAction = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.BaccaratHistory.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BaccaratHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BaccaratHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    beadPlateList: jspb.Message.toObjectList(msg.getBeadPlateList(),
    proto.sands.live_dealer.spoke.BaccaratPlaySummary.toObject, includeInstance),
    bigRoadList: jspb.Message.toObjectList(msg.getBigRoadList(),
    proto.sands.live_dealer.spoke.BigRoadStreak.toObject, includeInstance),
    bigEyeBoyList: jspb.Message.toObjectList(msg.getBigEyeBoyList(),
    proto.sands.live_dealer.spoke.DerivedRoadStreak.toObject, includeInstance),
    smallRoadList: jspb.Message.toObjectList(msg.getSmallRoadList(),
    proto.sands.live_dealer.spoke.DerivedRoadStreak.toObject, includeInstance),
    cockroachPigList: jspb.Message.toObjectList(msg.getCockroachPigList(),
    proto.sands.live_dealer.spoke.DerivedRoadStreak.toObject, includeInstance),
    ifPlayerNext: (f = msg.getIfPlayerNext()) && proto.sands.live_dealer.spoke.PossibleDerivedProgress.toObject(includeInstance, f),
    ifBankerNext: (f = msg.getIfBankerNext()) && proto.sands.live_dealer.spoke.PossibleDerivedProgress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BaccaratHistory;
  return proto.sands.live_dealer.spoke.BaccaratHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BaccaratHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BaccaratPlaySummary;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BaccaratPlaySummary.deserializeBinaryFromReader);
      msg.addBeadPlate(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.BigRoadStreak;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BigRoadStreak.deserializeBinaryFromReader);
      msg.addBigRoad(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.DerivedRoadStreak;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DerivedRoadStreak.deserializeBinaryFromReader);
      msg.addBigEyeBoy(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.DerivedRoadStreak;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DerivedRoadStreak.deserializeBinaryFromReader);
      msg.addSmallRoad(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.DerivedRoadStreak;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DerivedRoadStreak.deserializeBinaryFromReader);
      msg.addCockroachPig(value);
      break;
    case 6:
      var value = new proto.sands.live_dealer.spoke.PossibleDerivedProgress;
      reader.readMessage(value,proto.sands.live_dealer.spoke.PossibleDerivedProgress.deserializeBinaryFromReader);
      msg.setIfPlayerNext(value);
      break;
    case 7:
      var value = new proto.sands.live_dealer.spoke.PossibleDerivedProgress;
      reader.readMessage(value,proto.sands.live_dealer.spoke.PossibleDerivedProgress.deserializeBinaryFromReader);
      msg.setIfBankerNext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BaccaratHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BaccaratHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BaccaratHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeadPlateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BaccaratPlaySummary.serializeBinaryToWriter
    );
  }
  f = message.getBigRoadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.BigRoadStreak.serializeBinaryToWriter
    );
  }
  f = message.getBigEyeBoyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.DerivedRoadStreak.serializeBinaryToWriter
    );
  }
  f = message.getSmallRoadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.DerivedRoadStreak.serializeBinaryToWriter
    );
  }
  f = message.getCockroachPigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.DerivedRoadStreak.serializeBinaryToWriter
    );
  }
  f = message.getIfPlayerNext();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.sands.live_dealer.spoke.PossibleDerivedProgress.serializeBinaryToWriter
    );
  }
  f = message.getIfBankerNext();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.sands.live_dealer.spoke.PossibleDerivedProgress.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BaccaratPlaySummary bead_plate = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.BaccaratPlaySummary>}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.getBeadPlateList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.BaccaratPlaySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.BaccaratPlaySummary, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.BaccaratPlaySummary>} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
*/
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.setBeadPlateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.BaccaratPlaySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BaccaratPlaySummary}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.addBeadPlate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.BaccaratPlaySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.clearBeadPlateList = function() {
  return this.setBeadPlateList([]);
};


/**
 * repeated BigRoadStreak big_road = 2;
 * @return {!Array<!proto.sands.live_dealer.spoke.BigRoadStreak>}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.getBigRoadList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.BigRoadStreak>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.BigRoadStreak, 2));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.BigRoadStreak>} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
*/
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.setBigRoadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.BigRoadStreak=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BigRoadStreak}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.addBigRoad = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sands.live_dealer.spoke.BigRoadStreak, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.clearBigRoadList = function() {
  return this.setBigRoadList([]);
};


/**
 * repeated DerivedRoadStreak big_eye_boy = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.getBigEyeBoyList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DerivedRoadStreak, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
*/
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.setBigEyeBoyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadStreak=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.addBigEyeBoy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.DerivedRoadStreak, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.clearBigEyeBoyList = function() {
  return this.setBigEyeBoyList([]);
};


/**
 * repeated DerivedRoadStreak small_road = 4;
 * @return {!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.getSmallRoadList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DerivedRoadStreak, 4));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
*/
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.setSmallRoadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadStreak=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.addSmallRoad = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sands.live_dealer.spoke.DerivedRoadStreak, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.clearSmallRoadList = function() {
  return this.setSmallRoadList([]);
};


/**
 * repeated DerivedRoadStreak cockroach_pig = 5;
 * @return {!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.getCockroachPigList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DerivedRoadStreak, 5));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DerivedRoadStreak>} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
*/
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.setCockroachPigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadStreak=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.addCockroachPig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sands.live_dealer.spoke.DerivedRoadStreak, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.clearCockroachPigList = function() {
  return this.setCockroachPigList([]);
};


/**
 * optional PossibleDerivedProgress if_player_next = 6;
 * @return {?proto.sands.live_dealer.spoke.PossibleDerivedProgress}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.getIfPlayerNext = function() {
  return /** @type{?proto.sands.live_dealer.spoke.PossibleDerivedProgress} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.PossibleDerivedProgress, 6));
};


/**
 * @param {?proto.sands.live_dealer.spoke.PossibleDerivedProgress|undefined} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
*/
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.setIfPlayerNext = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.clearIfPlayerNext = function() {
  return this.setIfPlayerNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.hasIfPlayerNext = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PossibleDerivedProgress if_banker_next = 7;
 * @return {?proto.sands.live_dealer.spoke.PossibleDerivedProgress}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.getIfBankerNext = function() {
  return /** @type{?proto.sands.live_dealer.spoke.PossibleDerivedProgress} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.PossibleDerivedProgress, 7));
};


/**
 * @param {?proto.sands.live_dealer.spoke.PossibleDerivedProgress|undefined} value
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
*/
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.setIfBankerNext = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.BaccaratHistory} returns this
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.clearIfBankerNext = function() {
  return this.setIfBankerNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BaccaratHistory.prototype.hasIfBankerNext = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.BigRoadStreak.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BigRoadStreak.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BigRoadStreak.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BigRoadStreak} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BigRoadStreak.toObject = function(includeInstance, msg) {
  var f, obj = {
    streakList: jspb.Message.toObjectList(msg.getStreakList(),
    proto.sands.live_dealer.spoke.BigRoadEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BigRoadStreak}
 */
proto.sands.live_dealer.spoke.BigRoadStreak.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BigRoadStreak;
  return proto.sands.live_dealer.spoke.BigRoadStreak.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BigRoadStreak} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BigRoadStreak}
 */
proto.sands.live_dealer.spoke.BigRoadStreak.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.BigRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.BigRoadEntry.deserializeBinaryFromReader);
      msg.addStreak(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BigRoadStreak.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BigRoadStreak.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BigRoadStreak} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BigRoadStreak.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreakList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.BigRoadEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BigRoadEntry streak = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.BigRoadEntry>}
 */
proto.sands.live_dealer.spoke.BigRoadStreak.prototype.getStreakList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.BigRoadEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.BigRoadEntry, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.BigRoadEntry>} value
 * @return {!proto.sands.live_dealer.spoke.BigRoadStreak} returns this
*/
proto.sands.live_dealer.spoke.BigRoadStreak.prototype.setStreakList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.BigRoadEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry}
 */
proto.sands.live_dealer.spoke.BigRoadStreak.prototype.addStreak = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.BigRoadEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.BigRoadStreak} returns this
 */
proto.sands.live_dealer.spoke.BigRoadStreak.prototype.clearStreakList = function() {
  return this.setStreakList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.BigRoadEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.BigRoadEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BigRoadEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    streakNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    winner: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tieCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    playerNatural: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    bankerNatural: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    playerPair: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    bankerPair: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.BigRoadEntry;
  return proto.sands.live_dealer.spoke.BigRoadEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.BigRoadEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStreakNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWinner(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTieCount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlayerNatural(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBankerNatural(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlayerPair(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBankerPair(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.BigRoadEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.BigRoadEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.BigRoadEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreakNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWinner();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTieCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPlayerNatural();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBankerNatural();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPlayerPair();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getBankerPair();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 streak_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.getStreakNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.setStreakNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string winner = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.getWinner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.setWinner = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 tie_count = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.getTieCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.setTieCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool player_natural = 4;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.getPlayerNatural = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.setPlayerNatural = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool banker_natural = 5;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.getBankerNatural = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.setBankerNatural = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool player_pair = 6;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.getPlayerPair = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.setPlayerPair = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool banker_pair = 7;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.getBankerPair = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.BigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.BigRoadEntry.prototype.setBankerPair = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.PossibleDerivedProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    bigEyeBoy: (f = msg.getBigEyeBoy()) && proto.sands.live_dealer.spoke.DerivedRoadEntry.toObject(includeInstance, f),
    smallRoad: (f = msg.getSmallRoad()) && proto.sands.live_dealer.spoke.DerivedRoadEntry.toObject(includeInstance, f),
    cockroachPig: (f = msg.getCockroachPig()) && proto.sands.live_dealer.spoke.DerivedRoadEntry.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.PossibleDerivedProgress}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.PossibleDerivedProgress;
  return proto.sands.live_dealer.spoke.PossibleDerivedProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.PossibleDerivedProgress}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.DerivedRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DerivedRoadEntry.deserializeBinaryFromReader);
      msg.setBigEyeBoy(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.DerivedRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DerivedRoadEntry.deserializeBinaryFromReader);
      msg.setSmallRoad(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.DerivedRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DerivedRoadEntry.deserializeBinaryFromReader);
      msg.setCockroachPig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.PossibleDerivedProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBigEyeBoy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.DerivedRoadEntry.serializeBinaryToWriter
    );
  }
  f = message.getSmallRoad();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.DerivedRoadEntry.serializeBinaryToWriter
    );
  }
  f = message.getCockroachPig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.DerivedRoadEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional DerivedRoadEntry big_eye_boy = 1;
 * @return {?proto.sands.live_dealer.spoke.DerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.getBigEyeBoy = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DerivedRoadEntry} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DerivedRoadEntry, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DerivedRoadEntry|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} returns this
*/
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.setBigEyeBoy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} returns this
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.clearBigEyeBoy = function() {
  return this.setBigEyeBoy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.hasBigEyeBoy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DerivedRoadEntry small_road = 2;
 * @return {?proto.sands.live_dealer.spoke.DerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.getSmallRoad = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DerivedRoadEntry} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DerivedRoadEntry, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DerivedRoadEntry|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} returns this
*/
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.setSmallRoad = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} returns this
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.clearSmallRoad = function() {
  return this.setSmallRoad(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.hasSmallRoad = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DerivedRoadEntry cockroach_pig = 3;
 * @return {?proto.sands.live_dealer.spoke.DerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.getCockroachPig = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DerivedRoadEntry} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DerivedRoadEntry, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DerivedRoadEntry|undefined} value
 * @return {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} returns this
*/
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.setCockroachPig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.PossibleDerivedProgress} returns this
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.clearCockroachPig = function() {
  return this.setCockroachPig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.PossibleDerivedProgress.prototype.hasCockroachPig = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DerivedRoadStreak.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadStreak} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.toObject = function(includeInstance, msg) {
  var f, obj = {
    streakList: jspb.Message.toObjectList(msg.getStreakList(),
    proto.sands.live_dealer.spoke.DerivedRoadEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DerivedRoadStreak;
  return proto.sands.live_dealer.spoke.DerivedRoadStreak.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadStreak} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.DerivedRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DerivedRoadEntry.deserializeBinaryFromReader);
      msg.addStreak(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DerivedRoadStreak.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadStreak} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreakList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.DerivedRoadEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DerivedRoadEntry streak = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.DerivedRoadEntry>}
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.prototype.getStreakList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DerivedRoadEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DerivedRoadEntry, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DerivedRoadEntry>} value
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadStreak} returns this
*/
proto.sands.live_dealer.spoke.DerivedRoadStreak.prototype.setStreakList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.prototype.addStreak = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.DerivedRoadEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadStreak} returns this
 */
proto.sands.live_dealer.spoke.DerivedRoadStreak.prototype.clearStreakList = function() {
  return this.setStreakList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DerivedRoadEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    streakNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    predictable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DerivedRoadEntry;
  return proto.sands.live_dealer.spoke.DerivedRoadEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStreakNum(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPredictable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DerivedRoadEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DerivedRoadEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreakNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPredictable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 streak_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.prototype.getStreakNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.prototype.setStreakNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool predictable = 2;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.prototype.getPredictable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.DerivedRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.DerivedRoadEntry.prototype.setPredictable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DragonTigerHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DragonTigerHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    beadPlateList: jspb.Message.toObjectList(msg.getBeadPlateList(),
    proto.sands.live_dealer.spoke.DragonTigerPlaySummary.toObject, includeInstance),
    bigRoadList: jspb.Message.toObjectList(msg.getBigRoadList(),
    proto.sands.live_dealer.spoke.DTBigRoadStreak.toObject, includeInstance),
    bigEyeBoyList: jspb.Message.toObjectList(msg.getBigEyeBoyList(),
    proto.sands.live_dealer.spoke.DTDerivedRoadStreak.toObject, includeInstance),
    smallRoadList: jspb.Message.toObjectList(msg.getSmallRoadList(),
    proto.sands.live_dealer.spoke.DTDerivedRoadStreak.toObject, includeInstance),
    cockroachPigList: jspb.Message.toObjectList(msg.getCockroachPigList(),
    proto.sands.live_dealer.spoke.DTDerivedRoadStreak.toObject, includeInstance),
    ifDragonNext: (f = msg.getIfDragonNext()) && proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.toObject(includeInstance, f),
    ifTigerNext: (f = msg.getIfTigerNext()) && proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DragonTigerHistory;
  return proto.sands.live_dealer.spoke.DragonTigerHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.DragonTigerPlaySummary;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DragonTigerPlaySummary.deserializeBinaryFromReader);
      msg.addBeadPlate(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.DTBigRoadStreak;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTBigRoadStreak.deserializeBinaryFromReader);
      msg.addBigRoad(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.DTDerivedRoadStreak;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTDerivedRoadStreak.deserializeBinaryFromReader);
      msg.addBigEyeBoy(value);
      break;
    case 4:
      var value = new proto.sands.live_dealer.spoke.DTDerivedRoadStreak;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTDerivedRoadStreak.deserializeBinaryFromReader);
      msg.addSmallRoad(value);
      break;
    case 5:
      var value = new proto.sands.live_dealer.spoke.DTDerivedRoadStreak;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTDerivedRoadStreak.deserializeBinaryFromReader);
      msg.addCockroachPig(value);
      break;
    case 6:
      var value = new proto.sands.live_dealer.spoke.DTPossibleDerivedProgress;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.deserializeBinaryFromReader);
      msg.setIfDragonNext(value);
      break;
    case 7:
      var value = new proto.sands.live_dealer.spoke.DTPossibleDerivedProgress;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.deserializeBinaryFromReader);
      msg.setIfTigerNext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DragonTigerHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DragonTigerHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeadPlateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.DragonTigerPlaySummary.serializeBinaryToWriter
    );
  }
  f = message.getBigRoadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.DTBigRoadStreak.serializeBinaryToWriter
    );
  }
  f = message.getBigEyeBoyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.DTDerivedRoadStreak.serializeBinaryToWriter
    );
  }
  f = message.getSmallRoadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sands.live_dealer.spoke.DTDerivedRoadStreak.serializeBinaryToWriter
    );
  }
  f = message.getCockroachPigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sands.live_dealer.spoke.DTDerivedRoadStreak.serializeBinaryToWriter
    );
  }
  f = message.getIfDragonNext();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.serializeBinaryToWriter
    );
  }
  f = message.getIfTigerNext();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DragonTigerPlaySummary bead_plate = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.DragonTigerPlaySummary>}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.getBeadPlateList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DragonTigerPlaySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DragonTigerPlaySummary, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DragonTigerPlaySummary>} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.setBeadPlateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DragonTigerPlaySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DragonTigerPlaySummary}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.addBeadPlate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.DragonTigerPlaySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.clearBeadPlateList = function() {
  return this.setBeadPlateList([]);
};


/**
 * repeated DTBigRoadStreak big_road = 2;
 * @return {!Array<!proto.sands.live_dealer.spoke.DTBigRoadStreak>}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.getBigRoadList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DTBigRoadStreak>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DTBigRoadStreak, 2));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DTBigRoadStreak>} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.setBigRoadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DTBigRoadStreak=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadStreak}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.addBigRoad = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sands.live_dealer.spoke.DTBigRoadStreak, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.clearBigRoadList = function() {
  return this.setBigRoadList([]);
};


/**
 * repeated DTDerivedRoadStreak big_eye_boy = 3;
 * @return {!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.getBigEyeBoyList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DTDerivedRoadStreak, 3));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.setBigEyeBoyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.addBigEyeBoy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sands.live_dealer.spoke.DTDerivedRoadStreak, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.clearBigEyeBoyList = function() {
  return this.setBigEyeBoyList([]);
};


/**
 * repeated DTDerivedRoadStreak small_road = 4;
 * @return {!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.getSmallRoadList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DTDerivedRoadStreak, 4));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.setSmallRoadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.addSmallRoad = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sands.live_dealer.spoke.DTDerivedRoadStreak, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.clearSmallRoadList = function() {
  return this.setSmallRoadList([]);
};


/**
 * repeated DTDerivedRoadStreak cockroach_pig = 5;
 * @return {!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.getCockroachPigList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DTDerivedRoadStreak, 5));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadStreak>} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.setCockroachPigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.addCockroachPig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sands.live_dealer.spoke.DTDerivedRoadStreak, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.clearCockroachPigList = function() {
  return this.setCockroachPigList([]);
};


/**
 * optional DTPossibleDerivedProgress if_dragon_next = 6;
 * @return {?proto.sands.live_dealer.spoke.DTPossibleDerivedProgress}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.getIfDragonNext = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DTPossibleDerivedProgress, 6));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DTPossibleDerivedProgress|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.setIfDragonNext = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.clearIfDragonNext = function() {
  return this.setIfDragonNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.hasIfDragonNext = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DTPossibleDerivedProgress if_tiger_next = 7;
 * @return {?proto.sands.live_dealer.spoke.DTPossibleDerivedProgress}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.getIfTigerNext = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DTPossibleDerivedProgress, 7));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DTPossibleDerivedProgress|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
*/
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.setIfTigerNext = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DragonTigerHistory} returns this
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.clearIfTigerNext = function() {
  return this.setIfTigerNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DragonTigerHistory.prototype.hasIfTigerNext = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DTBigRoadStreak.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DTBigRoadStreak} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.toObject = function(includeInstance, msg) {
  var f, obj = {
    streakList: jspb.Message.toObjectList(msg.getStreakList(),
    proto.sands.live_dealer.spoke.DTBigRoadEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadStreak}
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DTBigRoadStreak;
  return proto.sands.live_dealer.spoke.DTBigRoadStreak.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DTBigRoadStreak} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadStreak}
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.DTBigRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTBigRoadEntry.deserializeBinaryFromReader);
      msg.addStreak(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DTBigRoadStreak.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DTBigRoadStreak} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreakList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.DTBigRoadEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DTBigRoadEntry streak = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.DTBigRoadEntry>}
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.prototype.getStreakList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DTBigRoadEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DTBigRoadEntry, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DTBigRoadEntry>} value
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadStreak} returns this
*/
proto.sands.live_dealer.spoke.DTBigRoadStreak.prototype.setStreakList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DTBigRoadEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadEntry}
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.prototype.addStreak = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.DTBigRoadEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadStreak} returns this
 */
proto.sands.live_dealer.spoke.DTBigRoadStreak.prototype.clearStreakList = function() {
  return this.setStreakList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DTBigRoadEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DTBigRoadEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    streakNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    winner: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tieCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadEntry}
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DTBigRoadEntry;
  return proto.sands.live_dealer.spoke.DTBigRoadEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DTBigRoadEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadEntry}
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStreakNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWinner(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTieCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DTBigRoadEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DTBigRoadEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreakNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWinner();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTieCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 streak_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.prototype.getStreakNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.prototype.setStreakNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string winner = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.prototype.getWinner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.prototype.setWinner = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 tie_count = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.prototype.getTieCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DTBigRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.DTBigRoadEntry.prototype.setTieCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    bigEyeBoy: (f = msg.getBigEyeBoy()) && proto.sands.live_dealer.spoke.DTDerivedRoadEntry.toObject(includeInstance, f),
    smallRoad: (f = msg.getSmallRoad()) && proto.sands.live_dealer.spoke.DTDerivedRoadEntry.toObject(includeInstance, f),
    cockroachPig: (f = msg.getCockroachPig()) && proto.sands.live_dealer.spoke.DTDerivedRoadEntry.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DTPossibleDerivedProgress;
  return proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.DTDerivedRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTDerivedRoadEntry.deserializeBinaryFromReader);
      msg.setBigEyeBoy(value);
      break;
    case 2:
      var value = new proto.sands.live_dealer.spoke.DTDerivedRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTDerivedRoadEntry.deserializeBinaryFromReader);
      msg.setSmallRoad(value);
      break;
    case 3:
      var value = new proto.sands.live_dealer.spoke.DTDerivedRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTDerivedRoadEntry.deserializeBinaryFromReader);
      msg.setCockroachPig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBigEyeBoy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.DTDerivedRoadEntry.serializeBinaryToWriter
    );
  }
  f = message.getSmallRoad();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sands.live_dealer.spoke.DTDerivedRoadEntry.serializeBinaryToWriter
    );
  }
  f = message.getCockroachPig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sands.live_dealer.spoke.DTDerivedRoadEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional DTDerivedRoadEntry big_eye_boy = 1;
 * @return {?proto.sands.live_dealer.spoke.DTDerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.getBigEyeBoy = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DTDerivedRoadEntry} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DTDerivedRoadEntry, 1));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DTDerivedRoadEntry|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} returns this
*/
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.setBigEyeBoy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} returns this
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.clearBigEyeBoy = function() {
  return this.setBigEyeBoy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.hasBigEyeBoy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DTDerivedRoadEntry small_road = 2;
 * @return {?proto.sands.live_dealer.spoke.DTDerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.getSmallRoad = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DTDerivedRoadEntry} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DTDerivedRoadEntry, 2));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DTDerivedRoadEntry|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} returns this
*/
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.setSmallRoad = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} returns this
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.clearSmallRoad = function() {
  return this.setSmallRoad(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.hasSmallRoad = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DTDerivedRoadEntry cockroach_pig = 3;
 * @return {?proto.sands.live_dealer.spoke.DTDerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.getCockroachPig = function() {
  return /** @type{?proto.sands.live_dealer.spoke.DTDerivedRoadEntry} */ (
    jspb.Message.getWrapperField(this, proto.sands.live_dealer.spoke.DTDerivedRoadEntry, 3));
};


/**
 * @param {?proto.sands.live_dealer.spoke.DTDerivedRoadEntry|undefined} value
 * @return {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} returns this
*/
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.setCockroachPig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sands.live_dealer.spoke.DTPossibleDerivedProgress} returns this
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.clearCockroachPig = function() {
  return this.setCockroachPig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DTPossibleDerivedProgress.prototype.hasCockroachPig = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DTDerivedRoadStreak.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.toObject = function(includeInstance, msg) {
  var f, obj = {
    streakList: jspb.Message.toObjectList(msg.getStreakList(),
    proto.sands.live_dealer.spoke.DTDerivedRoadEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DTDerivedRoadStreak;
  return proto.sands.live_dealer.spoke.DTDerivedRoadStreak.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sands.live_dealer.spoke.DTDerivedRoadEntry;
      reader.readMessage(value,proto.sands.live_dealer.spoke.DTDerivedRoadEntry.deserializeBinaryFromReader);
      msg.addStreak(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DTDerivedRoadStreak.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreakList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sands.live_dealer.spoke.DTDerivedRoadEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DTDerivedRoadEntry streak = 1;
 * @return {!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadEntry>}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.prototype.getStreakList = function() {
  return /** @type{!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sands.live_dealer.spoke.DTDerivedRoadEntry, 1));
};


/**
 * @param {!Array<!proto.sands.live_dealer.spoke.DTDerivedRoadEntry>} value
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak} returns this
*/
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.prototype.setStreakList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.prototype.addStreak = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sands.live_dealer.spoke.DTDerivedRoadEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadStreak} returns this
 */
proto.sands.live_dealer.spoke.DTDerivedRoadStreak.prototype.clearStreakList = function() {
  return this.setStreakList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.DTDerivedRoadEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    streakNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    predictable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.DTDerivedRoadEntry;
  return proto.sands.live_dealer.spoke.DTDerivedRoadEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStreakNum(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPredictable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.DTDerivedRoadEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreakNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPredictable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 streak_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.prototype.getStreakNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.prototype.setStreakNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool predictable = 2;
 * @return {boolean}
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.prototype.getPredictable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sands.live_dealer.spoke.DTDerivedRoadEntry} returns this
 */
proto.sands.live_dealer.spoke.DTDerivedRoadEntry.prototype.setPredictable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.Card.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.Card.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.Card} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Card.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealtNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    face: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.Card}
 */
proto.sands.live_dealer.spoke.Card.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.Card;
  return proto.sands.live_dealer.spoke.Card.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.Card} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.Card}
 */
proto.sands.live_dealer.spoke.Card.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDealtNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.Card.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.Card.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.Card} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.Card.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealtNum();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getFace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 dealt_num = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.Card.prototype.getDealtNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.Card} returns this
 */
proto.sands.live_dealer.spoke.Card.prototype.setDealtNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string face = 2;
 * @return {string}
 */
proto.sands.live_dealer.spoke.Card.prototype.getFace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.Card} returns this
 */
proto.sands.live_dealer.spoke.Card.prototype.setFace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.toObject = function(opt_includeInstance) {
  return proto.sands.live_dealer.spoke.FutureAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sands.live_dealer.spoke.FutureAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.FutureAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    playId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seatNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    action: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sands.live_dealer.spoke.FutureAction}
 */
proto.sands.live_dealer.spoke.FutureAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sands.live_dealer.spoke.FutureAction;
  return proto.sands.live_dealer.spoke.FutureAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sands.live_dealer.spoke.FutureAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sands.live_dealer.spoke.FutureAction}
 */
proto.sands.live_dealer.spoke.FutureAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeatNum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sands.live_dealer.spoke.FutureAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sands.live_dealer.spoke.FutureAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sands.live_dealer.spoke.FutureAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getSeatNum();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint64 play_id = 1;
 * @return {number}
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.getPlayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.FutureAction} returns this
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.setPlayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 player_id = 2;
 * @return {number}
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.getPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.FutureAction} returns this
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 seat_num = 3;
 * @return {number}
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.getSeatNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sands.live_dealer.spoke.FutureAction} returns this
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.setSeatNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sands.live_dealer.spoke.FutureAction} returns this
 */
proto.sands.live_dealer.spoke.FutureAction.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.sands.live_dealer.spoke);
