/**********************************************************************************************************************
 * Creates and initializes the main game store. This is deliberately seperate from `stores.ts` because the game store
 * is currently proxying multiple stores together and we want to avoid circular deps.
 *********************************************************************************************************************/
import { GameStore } from './storeClasses/GameStore';

let _gameStore: GameStore;
let _isInitialized = false;

const initialize = () => {
	if (_isInitialized) {
		return false;
	}

	_gameStore = new GameStore();

	_isInitialized = true;

	return true;
};

const getGameStore = (): GameStore => _gameStore;
const setGameStore = (val: GameStore) => (_gameStore = val);

// ---- Export --------------------------------------------------------------------------------------------------------

export { initialize as initGameStore };
export { getGameStore, setGameStore };
export * from './storeClasses/GameStore';
